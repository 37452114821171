// src/pages/IntegrationEdit.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

function IntegrationEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [integrationData, setIntegrationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [platformType, setPlatformType] = useState('');
  const [apiKeyModified, setApiKeyModified] = useState(false);
  const [apiSecretModified, setApiSecretModified] = useState(false);
  const [whatsappAccessTokenModified, setWhatsAppAccessTokenModified] = useState(false);
  const [whatsappPhoneNumberIdModified, setWhatsAppPhoneNumberIdModified] = useState(false);
  const [whatsappVerifyTokenModified, setWhatsAppVerifyTokenModified] = useState(false);
  const [originalApiKey, setOriginalApiKey] = useState('');
  const [originalApiSecret, setOriginalApiSecret] = useState('');
  const [originalWhatsAppAccessToken, setOriginalWhatsAppAccessToken] = useState('');
  const [originalWhatsAppPhoneNumberId, setOriginalWhatsAppPhoneNumberId] = useState('');
  const [originalWhatsAppVerifyToken, setOriginalWhatsAppVerifyToken] = useState('');
  const [apiKeyError, setApiKeyError] = useState('');
  const [apiSecretError, setApiSecretError] = useState('');
  const [whatsappAccessTokenError, setWhatsAppAccessTokenError] = useState('');
  const [whatsappPhoneNumberIdError, setWhatsAppPhoneNumberIdError] = useState('');
  const [whatsappVerifyTokenError, setWhatsAppVerifyTokenError] = useState('');

  useEffect(() => {
    async function fetchIntegration() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/integrations/${id}`);
        setIntegrationData(response.data);

        // Manejar campos sensibles para plataformas no WhatsApp
        if (response.data.platform.toLowerCase() !== 'whatsapp') {
          if (response.data.api_key) {
            setOriginalApiKey(response.data.api_key);
          } else {
            setApiKeyError('La clave API es inválida o está corrupta. Por favor, ingrese una nueva clave.');
          }
          if (response.data.api_secret) {
            setOriginalApiSecret(response.data.api_secret);
          } else {
            setApiSecretError('El secreto API es inválido o está corrupto. Por favor, ingrese un nuevo secreto.');
          }
        }

        // Manejar campos sensibles para WhatsApp
        if (response.data.platform.toLowerCase() === 'whatsapp') {
          if (response.data.whatsapp_access_token) {
            setOriginalWhatsAppAccessToken(response.data.whatsapp_access_token);
          } else {
            setWhatsAppAccessTokenError('El Access Token de WhatsApp es inválido o está corrupto. Por favor, ingrese un nuevo Access Token.');
          }
          if (response.data.whatsapp_phone_number_id) {
            setOriginalWhatsAppPhoneNumberId(response.data.whatsapp_phone_number_id);
          } else {
            setWhatsAppPhoneNumberIdError('El ID del número de teléfono de WhatsApp es inválido o está corrupto. Por favor, ingrese un nuevo ID.');
          }
          if (response.data.whatsapp_verify_token) {
            setOriginalWhatsAppVerifyToken(response.data.whatsapp_verify_token);
          } else {
            setWhatsAppVerifyTokenError('El Verify Token de WhatsApp es inválido o está corrupto. Por favor, ingrese un nuevo Verify Token.');
          }
        }

        setPlatformType(response.data.platform.toLowerCase() === 'otros' ? 'custom' : 'default');
      } catch (error) {
        console.error('Error al obtener la integración:', error);
        alert('Error al obtener la integración');
      }
    }

    fetchIntegration();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIntegrationData({ ...integrationData, [name]: value });
  };

  const handlePlatformChange = (e) => {
    const selectedPlatform = e.target.value;
    setIntegrationData({ ...integrationData, platform: selectedPlatform });
    setPlatformType(selectedPlatform.toLowerCase() === 'otros' ? 'custom' : 'default');

    // Resetear errores al cambiar de plataforma
    setApiKeyError('');
    setApiSecretError('');
    setWhatsAppAccessTokenError('');
    setWhatsAppPhoneNumberIdError('');
    setWhatsAppVerifyTokenError('');

    // Resetear modificaciones si se cambia de plataforma
    if (selectedPlatform.toLowerCase() !== 'whatsapp') {
      setWhatsAppAccessTokenModified(false);
      setWhatsAppPhoneNumberIdModified(false);
      setWhatsAppVerifyTokenModified(false);
    } else {
      setApiKeyModified(false);
      setApiSecretModified(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validar campos obligatorios
    let hasError = false;
    if (!integrationData.integration_name) {
      alert('El nombre de la integración es obligatorio');
      setLoading(false);
      return;
    }
    if (!integrationData.platform) {
      alert('La plataforma es obligatoria');
      setLoading(false);
      return;
    }

    // Validaciones específicas según la plataforma
    if (integrationData.platform.toLowerCase() !== 'whatsapp') {
      if (!apiKeyModified && !originalApiKey) {
        setApiKeyError('La clave API es inválida o está corrupta. Por favor, ingrese una nueva clave.');
        hasError = true;
      }
      if (!apiSecretModified && !originalApiSecret) {
        setApiSecretError('El secreto API es inválido o está corrupto. Por favor, ingrese un nuevo secreto.');
        hasError = true;
      }
    } else {
      if (!whatsappAccessTokenModified && !originalWhatsAppAccessToken) {
        setWhatsAppAccessTokenError('El Access Token de WhatsApp es inválido o está corrupto. Por favor, ingrese un nuevo Access Token.');
        hasError = true;
      }
      if (!whatsappPhoneNumberIdModified && !originalWhatsAppPhoneNumberId) {
        setWhatsAppPhoneNumberIdError('El ID del número de teléfono de WhatsApp es inválido o está corrupto. Por favor, ingrese un nuevo ID.');
        hasError = true;
      }
      if (!whatsappVerifyTokenModified && !originalWhatsAppVerifyToken) {
        setWhatsAppVerifyTokenError('El Verify Token de WhatsApp es inválido o está corrupto. Por favor, ingrese un nuevo Verify Token.');
        hasError = true;
      }
    }

    if (hasError) {
      setLoading(false);
      return;
    }

    try {
      // Preparar los datos a enviar
      const dataToSend = { ...integrationData };

      // Manejar campos sensibles según la plataforma
      if (integrationData.platform.toLowerCase() !== 'whatsapp') {
        dataToSend.api_key = apiKeyModified ? integrationData.api_key : originalApiKey;
        dataToSend.api_secret = apiSecretModified ? integrationData.api_secret : originalApiSecret;

        // Eliminar campos específicos de WhatsApp si no son necesarios
        delete dataToSend.whatsapp_access_token;
        delete dataToSend.whatsapp_phone_number_id;
        delete dataToSend.whatsapp_verify_token;
      } else {
        dataToSend.whatsapp_access_token = whatsappAccessTokenModified ? integrationData.whatsapp_access_token : originalWhatsAppAccessToken;
        dataToSend.whatsapp_phone_number_id = whatsappPhoneNumberIdModified ? integrationData.whatsapp_phone_number_id : originalWhatsAppPhoneNumberId;
        dataToSend.whatsapp_verify_token = whatsappVerifyTokenModified ? integrationData.whatsapp_verify_token : originalWhatsAppVerifyToken;

        // Eliminar API Key y Secret si no son necesarios para WhatsApp
        delete dataToSend.api_key;
        delete dataToSend.api_secret;
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/api/integrations/edit/${id}`, dataToSend);
      alert('Integración actualizada correctamente');
      navigate(`/integrations/view/${id}`);
    } catch (error) {
      console.error('Error al actualizar la integración:', error);
      alert('Error al actualizar la integración');
    } finally {
      setLoading(false);
    }
  };

  if (!integrationData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  // Enmascarar campos sensibles
  const maskValue = (value) => {
    if (!value) return '';
    const length = value.length;
    if (length <= 4) return '****';
    return '****' + value.slice(-4);
  };

  const maskedApiKey = integrationData.platform.toLowerCase() !== 'whatsapp' && originalApiKey && !apiKeyError ? maskValue(originalApiKey) : '';
  const maskedApiSecret = integrationData.platform.toLowerCase() !== 'whatsapp' && originalApiSecret && !apiSecretError ? maskValue(originalApiSecret) : '';

  const maskedWhatsAppAccessToken = integrationData.platform.toLowerCase() === 'whatsapp' && originalWhatsAppAccessToken && !whatsappAccessTokenError ? maskValue(originalWhatsAppAccessToken) : '';
  const maskedWhatsAppPhoneNumberId = integrationData.platform.toLowerCase() === 'whatsapp' && originalWhatsAppPhoneNumberId && !whatsappPhoneNumberIdError ? maskValue(originalWhatsAppPhoneNumberId) : '';
  const maskedWhatsAppVerifyToken = integrationData.platform.toLowerCase() === 'whatsapp' && originalWhatsAppVerifyToken && !whatsappVerifyTokenError ? maskValue(originalWhatsAppVerifyToken) : '';

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Editar Integración
      </Typography>

      <Card>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Nombre de la Integración"
              name="integration_name"
              value={integrationData.integration_name || ''}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />

            <FormControl fullWidth margin="normal">
              <InputLabel>Plataforma</InputLabel>
              <Select
                name="platform"
                value={integrationData.platform || ''}
                onChange={handlePlatformChange}
                fullWidth
              >
                <MenuItem value="Jumpseller">Jumpseller</MenuItem>
                <MenuItem value="WooCommerce">WooCommerce</MenuItem>
                <MenuItem value="Shopify">Shopify</MenuItem>
                <MenuItem value="MercadoLibre">MercadoLibre</MenuItem>
                <MenuItem value="MercadoPago">MercadoPago</MenuItem>
                <MenuItem value="Starken">Starken</MenuItem>
                <MenuItem value="Chilexpress">Chilexpress</MenuItem>
                <MenuItem value="Bluexpress">Bluexpress</MenuItem>
                <MenuItem value="otros">Otros</MenuItem>
                <MenuItem value="WhatsApp">WhatsApp</MenuItem> {/* Añadido WhatsApp */}
              </Select>
            </FormControl>

            {/* Mostrar campo "Otra Plataforma" si la plataforma es "otros" */}
            {platformType === 'custom' && (
              <TextField
                label="Otra Plataforma"
                name="platform"
                value={integrationData.platform || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            )}

            <FormControl fullWidth margin="normal">
              <InputLabel>Estado</InputLabel>
              <Select
                name="status"
                value={integrationData.status || ''}
                onChange={handleChange}
                fullWidth
              >
                <MenuItem value="activo">Habilitado</MenuItem>
                <MenuItem value="deshabilitado">Deshabilitado</MenuItem>
              </Select>
            </FormControl>

            {/* Campos específicos para WhatsApp */}
            {integrationData.platform.toLowerCase() === 'whatsapp' && (
              <>
                <TextField
                  label="Access Token de WhatsApp"
                  name="whatsapp_access_token"
                  type="password"
                  value={whatsappAccessTokenModified ? integrationData.whatsapp_access_token : maskedWhatsAppAccessToken}
                  onFocus={() => {
                    if (!whatsappAccessTokenModified) {
                      setIntegrationData({ ...integrationData, whatsapp_access_token: '' });
                      setWhatsAppAccessTokenModified(true);
                    }
                  }}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={!!whatsappAccessTokenError}
                  helperText={whatsappAccessTokenError || 'Ingrese el Access Token de WhatsApp'}
                />
                <TextField
                  label="ID del Número de Teléfono de WhatsApp"
                  name="whatsapp_phone_number_id"
                  type="password"
                  value={whatsappPhoneNumberIdModified ? integrationData.whatsapp_phone_number_id : maskedWhatsAppPhoneNumberId}
                  onFocus={() => {
                    if (!whatsappPhoneNumberIdModified) {
                      setIntegrationData({ ...integrationData, whatsapp_phone_number_id: '' });
                      setWhatsAppPhoneNumberIdModified(true);
                    }
                  }}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={!!whatsappPhoneNumberIdError}
                  helperText={whatsappPhoneNumberIdError || 'Ingrese el ID del Número de Teléfono de WhatsApp'}
                />
                <TextField
                  label="Verify Token de WhatsApp"
                  name="whatsapp_verify_token"
                  type="password"
                  value={whatsappVerifyTokenModified ? integrationData.whatsapp_verify_token : maskedWhatsAppVerifyToken}
                  onFocus={() => {
                    if (!whatsappVerifyTokenModified) {
                      setIntegrationData({ ...integrationData, whatsapp_verify_token: '' });
                      setWhatsAppVerifyTokenModified(true);
                    }
                  }}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={!!whatsappVerifyTokenError}
                  helperText={whatsappVerifyTokenError || 'Ingrese el Verify Token de WhatsApp'}
                />
              </>
            )}

            {/* Campos para otras plataformas */}
            {integrationData.platform.toLowerCase() !== 'whatsapp' && (
              <>
                <TextField
                  label="API Key"
                  name="api_key"
                  type="password"
                  value={apiKeyModified ? integrationData.api_key : maskedApiKey}
                  onFocus={() => {
                    if (!apiKeyModified) {
                      setIntegrationData({ ...integrationData, api_key: '' });
                      setApiKeyModified(true);
                    }
                  }}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={!!apiKeyError}
                  helperText={apiKeyError || 'Ingrese el API Key'}
                />

                <TextField
                  label="API Secret"
                  name="api_secret"
                  type="password"
                  value={apiSecretModified ? integrationData.api_secret : maskedApiSecret}
                  onFocus={() => {
                    if (!apiSecretModified) {
                      setIntegrationData({ ...integrationData, api_secret: '' });
                      setApiSecretModified(true);
                    }
                  }}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={!!apiSecretError}
                  helperText={apiSecretError || 'Ingrese el API Secret'}
                />
              </>
            )}

            {/* Campo adicional opcional para store_url */}
            <TextField
              label="URL de la Tienda"
              name="store_url"
              value={integrationData.store_url || ''}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={loading}
              sx={{ mt: 2 }}
            >
              {loading ? 'Guardando...' : 'Guardar Cambios'}
            </Button>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
}

export default IntegrationEdit;
