import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Checkbox,
  Pagination,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';

function CategoryPage() {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const fetchAllCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories/all`, {
        params: {
          page,
          pageSize,
        },
      });

      console.log('Respuesta del servidor:', response.data);

      if (response.data) {
        setCategories(response.data);  // Cambiado para trabajar con el array directamente
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.error('Error al obtener todas las categorías:', error);
      setCategories([]);
    }
  };

  const fetchSearchCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories/search`, {
        params: {
          searchTerm,  // Esto enviará el término de búsqueda al servidor
        },
      });

      if (response.data) {
        setCategories(response.data);  // Asume que response.data ya contiene las categorías filtradas
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.error('Error al buscar categorías:', error);
      setCategories([]);
    }
  };


  useEffect(() => {
    if (isSearching) {
      fetchSearchCategories();
    } else {
      fetchAllCategories();
    }
  }, [page, searchTerm]);

  const handleSelectCategory = (id) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((cid) => cid !== id) : [...prevSelected, id]
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = categories.map((category) => category.id);
      setSelectedCategories(newSelected);
    } else {
      setSelectedCategories([]);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      await axios.post('${process.env.REACT_APP_API_URL}/api/categories/delete-multiple', {
        ids: selectedCategories,
      });
      alert('Categorías eliminadas correctamente');
      setSelectedCategories([]);
      setPage(1);
    } catch (error) {
      console.error('Error al eliminar las categorías:', error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = () => {
    setIsSearching(true);
    setPage(1);
    fetchSearchCategories();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  return (
    <div>
      <h2>Categorías del ERP</h2>

      {/* Buscador de categorías */}
      <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
        <TextField
          label="Buscar categoría por nombre"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          onKeyPress={handleKeyPress}
          fullWidth
        />
        <Button variant="contained" onClick={handleSearchSubmit}>
          Buscar
        </Button>
      </Box>

      {/* Botones de acciones */}
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/categories/create"
        >
          Crear nueva categoría
        </Button>
      </Box>

      {selectedCategories.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Button variant="contained" color="error" onClick={handleDeleteSelected}>
            Eliminar {selectedCategories.length} categoría(s) seleccionada(s)
          </Button>
        </Box>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectedCategories.length === categories.length && categories.length > 0}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Categoría Padre</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.length > 0 ? (
              categories.map((category) => (
                <TableRow key={category.id}>
                  <TableCell>
                    <Checkbox
                      checked={selectedCategories.includes(category.id)}
                      onChange={() => handleSelectCategory(category.id)}
                    />
                  </TableCell>
                  <TableCell>{category.id}</TableCell>
                  <TableCell>{category.name}</TableCell>
                  <TableCell>{category.description}</TableCell>
                  <TableCell>
                    {category.parent_category_id ? `ID ${category.parent_category_id}` : 'Ninguna'}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/categories/view/${category.id}`}
                      sx={{ marginRight: 1 }}
                    >
                      Ver
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      component={Link}
                      to={`/categories/edit/${category.id}`}
                    >
                      Editar
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>No se encontraron categorías.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </Box>
    </div>
  );
}

export default CategoryPage;
