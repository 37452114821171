// src/pages/inadmin/AdminLoginForm.js

import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Snackbar, Alert, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminLoginForm = ({ onLogin }) => {
    const [formData, setFormData] = useState({ username: '', password: '' });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post('https://api.integranube.cl/inadmin/login', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const { token, admin } = response.data;
            // Llamar a la función onLogin pasada como prop
            onLogin({ token, admin });
            // Redirigir al dashboard de administración
            navigate('/inadmin/AdminDashboard');
        } catch (error) {
            console.error('Error en login de admin:', error.response ? error.response.data : error.message);
            const errorMessage = error.response?.data?.message || 'Error al iniciar sesión.';
            setSnackbar({
                open: true,
                message: errorMessage,
                severity: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'center', 
                height: '100vh',
                backgroundColor: '#f5f5f5'
            }}
        >
            <Typography variant="h4" gutterBottom>
                Login de Administración
            </Typography>
            <Box 
                component="form" 
                onSubmit={handleSubmit} 
                sx={{ 
                    mt: 1, 
                    width: '300px', 
                    padding: '20px', 
                    backgroundColor: 'white', 
                    borderRadius: '8px',
                    boxShadow: 3 
                }}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Nombre de Usuario"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Contraseña"
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    sx={{ mt: 3, mb: 2 }}
                >
                    {loading ? <CircularProgress size={24} /> : 'Iniciar Sesión'}
                </Button>
            </Box>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AdminLoginForm;
