// src/pages/SupplierDetailsPage.js

import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Stack,
  TextField,
  TableSortLabel,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  Link as LinkIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

// Estilos personalizados
const SupplierHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(4),
}));

const StatChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const formatPrice = (value) => {
  if (value == null || value === '') {
    return '$0';
  }
  const numberValue = Number(value);
  return !isNaN(numberValue)
    ? numberValue.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })
    : '$0';
};

// Función de comparación para ordenamiento
const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

// Función para ordenar y filtrar
const stableSort = (array, comparator, query) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  // Filtrar según la consulta de búsqueda
  const filtered = stabilizedThis.filter(([el]) => {
    const searchString = query.toLowerCase();
    return (
      (el.product_name && el.product_name.toLowerCase().includes(searchString)) ||
      (el.supplier_product_name && el.supplier_product_name.toLowerCase().includes(searchString)) ||
      (el.product_sku && el.product_sku.toLowerCase().includes(searchString)) ||
      (el.supplier_sku && el.supplier_sku.toLowerCase().includes(searchString))
    );
  });

  // Ordenar
  filtered.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return filtered.map((el) => el[0]);
};

const SupplierDetailsPage = () => {
  const { tax_id } = useParams();
  const navigate = useNavigate();

  const [supplier, setSupplier] = useState(null);
  const [linkedProducts, setLinkedProducts] = useState([]);
  const [unlinkedProducts, setUnlinkedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [tabIndex, setTabIndex] = useState(0);

  // Estados para búsqueda y ordenamiento
  const [searchQuery, setSearchQuery] = useState('');
  const [order, setOrder] = useState('asc'); // 'asc' o 'desc'
  const [orderBy, setOrderBy] = useState('product_name'); // Columna por la cual ordenar

  // Estados para paginación
  const [linkedPage, setLinkedPage] = useState(1);
  const [unlinkedPage, setUnlinkedPage] = useState(1);
  const [linkedTotalPages, setLinkedTotalPages] = useState(1);
  const [unlinkedTotalPages, setUnlinkedTotalPages] = useState(1);
  const [linkedTotalCount, setLinkedTotalCount] = useState(0);
  const [unlinkedTotalCount, setUnlinkedTotalCount] = useState(0);

  // Estado para el modal de vinculación
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [selectedSupplierProduct, setSelectedSupplierProduct] = useState(null);
  const [internalProducts, setInternalProducts] = useState([]);
  const [selectedInternalProduct, setSelectedInternalProduct] = useState(null);
  const [internalProductSearch, setInternalProductSearch] = useState('');

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const API_URL = 'https://api.integranube.cl'; // Define correctamente la URL base

  const fetchSupplierData = useCallback(async () => {
    if (!tax_id) {
      setError('El tax_id no está definido.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_URL}/api/suppliers/${tax_id}`, {
        params: {
          linked_page: linkedPage,
          linked_limit: 25,
          unlinked_page: unlinkedPage,
          unlinked_limit: 25,
        },
      });

      const { supplier, pagination } = response.data;

      // Preprocesar productos vinculados
      const linkedProductsData = supplier.linked_products
        ? supplier.linked_products
            .filter((product) => product && product.id)
            .map((product) => ({
              ...product,
              product_price: parseFloat(product.product_price) || 0,
              supplier_price: parseFloat(product.supplier_price) || 0,
              discount_percentage: parseFloat(product.discount_percentage) || 0,
              stock: product.stock != null ? product.stock : 0,
            }))
        : [];

      // Preprocesar productos por vincular
      const unlinkedProductsData = supplier.unlinked_products
        ? supplier.unlinked_products
            .filter((product) => product && product.id)
            .map((product) => ({
              ...product,
              supplier_price: parseFloat(product.supplier_price) || 0,
              discount_percentage: parseFloat(product.discount_percentage) || 0,
              stock: product.stock != null ? product.stock : '-',
            }))
        : [];

      setSupplier(supplier);
      setLinkedProducts(linkedProductsData);
      setUnlinkedProducts(unlinkedProductsData);

      setLinkedTotalPages(pagination.linked.total_pages);
      setUnlinkedTotalPages(pagination.unlinked.total_pages);
      setLinkedTotalCount(pagination.linked.total);
      setUnlinkedTotalCount(pagination.unlinked.total);
    } catch (err) {
      console.error('Error al cargar los productos:', err.response?.data || err.message);
      setError(err.response?.data?.error || 'Error al cargar los productos.');
    }
    setLoading(false);
  }, [tax_id, linkedPage, unlinkedPage]);

  useEffect(() => {
    fetchSupplierData();
  }, [fetchSupplierData]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setSearchQuery(''); // Limpiar búsqueda al cambiar de pestaña
    setOrder('asc'); // Resetear orden al cambiar de pestaña
    setOrderBy('product_name');
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleViewProduct = (productId) => {
    if (productId) {
      navigate(`/products/view/${productId}`);
    }
  };

  // Funciones para manejar cambios de página
  const handleLinkedPageChange = (event, value) => {
    setLinkedPage(value);
  };

  const handleUnlinkedPageChange = (event, value) => {
    setUnlinkedPage(value);
  };

  // Función para abrir el modal de vinculación
  const handleOpenLinkModal = async (supplierProduct) => {
    setSelectedSupplierProduct(supplierProduct);
    setLoading(true);
    try {
      // Obtener lista de productos internos para seleccionar
      const response = await axios.get(`${API_URL}/api/products/internal`, {
        params: {
          search: '',
        },
      });
      setInternalProducts(response.data.products || []);
      setOpenLinkModal(true);
    } catch (err) {
      console.error('Error al cargar productos internos:', err);
      setSnackbar({
        open: true,
        message: 'Error al cargar productos internos.',
        severity: 'error',
      });
    }
    setLoading(false);
  };

  // Función para cerrar el modal de vinculación
  const handleCloseLinkModal = () => {
    setOpenLinkModal(false);
    setSelectedSupplierProduct(null);
    setSelectedInternalProduct(null);
    setInternalProductSearch('');
    setInternalProducts([]);
  };

  const handleInternalProductSearch = useCallback(async (event, value, reason) => {
    if (reason === 'input' && value.length >= 1) {
      setLoadingInternalProducts(true);
      try {
        const response = await axios.get(`${API_URL}/api/products/internal`, {
          params: {
            search: value,
          },
        });
        setInternalProducts(response.data.products || []);
      } catch (err) {
        console.error('Error al buscar productos internos:', err);
        setSnackbar({
          open: true,
          message: 'Error al buscar productos internos.',
          severity: 'error',
        });
      }
      setLoadingInternalProducts(false);
    } else if (reason === 'clear') {
      setInternalProducts([]);
    }
  }, [API_URL]);

  // Asegúrate de inicializar loadingInternalProducts en el estado
  const [loadingInternalProducts, setLoadingInternalProducts] = useState(false);

  // Función para vincular producto
  const handleLinkProduct = async () => {
    if (!selectedInternalProduct || !selectedSupplierProduct) {
      setSnackbar({
        open: true,
        message: 'Debe seleccionar un producto interno.',
        severity: 'warning',
      });
      return;
    }

    try {
      const payload = {
        internal_product_id: selectedInternalProduct.id,
        supplier_sku: selectedSupplierProduct.supplier_sku,
        supplier_price: selectedSupplierProduct.supplier_price,
        supplier_product_name: selectedSupplierProduct.supplier_product_name,
        stock: selectedSupplierProduct.stock,
        discount_percentage: selectedSupplierProduct.discount_percentage,
        discount_start_date: selectedSupplierProduct.discount_start_date,
        discount_end_date: selectedSupplierProduct.discount_end_date,
      };

      await axios.post(
        `${API_URL}/api/suppliers/${supplier.id}/products`,
        payload
      );

      setSnackbar({
        open: true,
        message: `Producto "${selectedSupplierProduct.supplier_product_name}" vinculado correctamente.`,
        severity: 'success',
      });

      // Actualizar las listas de productos
      setUnlinkedProducts((prev) =>
        prev.filter((p) => p.id !== selectedSupplierProduct.id)
      );
      setLinkedProducts((prev) => [...prev, { ...selectedSupplierProduct, ...selectedInternalProduct }]);

      handleCloseLinkModal();
    } catch (err) {
      console.error('Error al vincular producto:', err.response?.data || err.message);
      setSnackbar({
        open: true,
        message: err.response?.data?.error || 'Error al vincular el producto.',
        severity: 'error',
      });
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', mt: 10 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!supplier) {
    return null;
  }

  return (
    <Box sx={{ p: { xs: 2, md: 4 } }}>
      {/* Detalles del Proveedor */}
      <SupplierHeader>
        <Typography variant="h4" gutterBottom>
          {supplier.name} ({supplier.tax_id})
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Dirección:</strong> {supplier.address || 'N/A'}
            </Typography>
            <Typography variant="body1">
              <strong>Teléfono:</strong> {supplier.phone || 'N/A'}
            </Typography>
            <Typography variant="body1">
              <strong>Email:</strong> {supplier.email || 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <strong>Contacto:</strong> {supplier.contact_person || 'N/A'}
            </Typography>
            <Typography variant="body1">
              <strong>Estado:</strong> {supplier.status}
            </Typography>
            <Typography variant="body1">
              <strong>País:</strong> {supplier.country || 'N/A'}
            </Typography>
          </Grid>
        </Grid>

        {/* Chips de estadísticas */}
        <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
          <StatChip
            label={`Productos Vinculados: ${linkedTotalCount}`}
            color="primary"
          />
          <StatChip
            label={`Productos por Vincular: ${unlinkedTotalCount}`}
            color="secondary"
          />
        </Stack>

        {/* Buscador */}
        <Box sx={{ mt: 3 }}>
          <TextField
            label="Buscar Productos"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Box>
      </SupplierHeader>

      {/* Pestañas para Productos Vinculados y por Vincular */}
      <Paper sx={{ mb: 4 }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Productos Vinculados" />
          <Tab label="Productos por Vincular" />
        </Tabs>
      </Paper>

      {/* Contenido de las pestañas */}
      <Box>
        {tabIndex === 0 && (
          <Box>
            <TableContainer component={Paper} sx={{ mb: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'product_sku'}
                        direction={orderBy === 'product_sku' ? order : 'asc'}
                        onClick={() => handleRequestSort('product_sku')}
                      >
                        SKU Producto
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'supplier_sku'}
                        direction={orderBy === 'supplier_sku' ? order : 'asc'}
                        onClick={() => handleRequestSort('supplier_sku')}
                      >
                        SKU Proveedor
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'product_name'}
                        direction={orderBy === 'product_name' ? order : 'asc'}
                        onClick={() => handleRequestSort('product_name')}
                      >
                        Nombre del Producto
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        active={orderBy === 'product_price'}
                        direction={orderBy === 'product_price' ? order : 'asc'}
                        onClick={() => handleRequestSort('product_price')}
                      >
                        Precio de Venta
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        active={orderBy === 'supplier_price'}
                        direction={orderBy === 'supplier_price' ? order : 'asc'}
                        onClick={() => handleRequestSort('supplier_price')}
                      >
                        Precio Proveedor
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        active={orderBy === 'discount_percentage'}
                        direction={orderBy === 'discount_percentage' ? order : 'asc'}
                        onClick={() => handleRequestSort('discount_percentage')}
                      >
                        Descuento (%)
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        active={orderBy === 'stock'}
                        direction={orderBy === 'stock' ? order : 'asc'}
                        onClick={() => handleRequestSort('stock')}
                      >
                        Stock
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(
                    linkedProducts,
                    getComparator(order, orderBy),
                    searchQuery
                  ).map((product) => (
                    <TableRow key={product.id} hover>
                      <TableCell>{product.product_sku}</TableCell>
                      <TableCell>{product.supplier_sku}</TableCell>
                      <TableCell>{product.product_name}</TableCell>
                      <TableCell align="right">
                        {formatPrice(product.product_price)}
                      </TableCell>
                      <TableCell align="right">
                        {formatPrice(product.supplier_price)}
                      </TableCell>
                      <TableCell align="right">
                        {product.discount_percentage.toFixed(2)}%
                      </TableCell>
                      <TableCell align="right">{product.stock}</TableCell>
                      <TableCell>
                        <Tooltip title="Ver Detalles">
                          <IconButton
                            color="primary"
                            onClick={() => handleViewProduct(product.id)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        {/* Agrega más acciones si es necesario */}
                      </TableCell>
                    </TableRow>
                  ))}
                  {stableSort(
                    linkedProducts,
                    getComparator(order, orderBy),
                    searchQuery
                  ).length === 0 && (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No hay productos vinculados que coincidan con la búsqueda.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Paginación para Productos Vinculados */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Pagination
                count={linkedTotalPages}
                page={linkedPage}
                onChange={handleLinkedPageChange}
                color="primary"
              />
            </Box>
          </Box>
        )}

        {tabIndex === 1 && (
          <Box>
            <TableContainer component={Paper} sx={{ mb: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'supplier_sku'}
                        direction={orderBy === 'supplier_sku' ? order : 'asc'}
                        onClick={() => handleRequestSort('supplier_sku')}
                      >
                        SKU Proveedor
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'supplier_product_name'}
                        direction={orderBy === 'supplier_product_name' ? order : 'asc'}
                        onClick={() => handleRequestSort('supplier_product_name')}
                      >
                        Nombre del Producto
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        active={orderBy === 'supplier_price'}
                        direction={orderBy === 'supplier_price' ? order : 'asc'}
                        onClick={() => handleRequestSort('supplier_price')}
                      >
                        Precio Proveedor
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        active={orderBy === 'stock'}
                        direction={orderBy === 'stock' ? order : 'asc'}
                        onClick={() => handleRequestSort('stock')}
                      >
                        Stock
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(
                    unlinkedProducts,
                    getComparator(order, orderBy),
                    searchQuery
                  ).map((product) => (
                    <TableRow key={product.id} hover>
                      <TableCell>{product.supplier_sku}</TableCell>
                      <TableCell>{product.supplier_product_name}</TableCell>
                      <TableCell align="right">
                        {formatPrice(product.supplier_price)}
                      </TableCell>
                      <TableCell align="right">{product.stock}</TableCell>
                      <TableCell>
                        <Tooltip title="Vincular Producto">
                          <IconButton
                            color="primary"
                            onClick={() => handleOpenLinkModal(product)}
                          >
                            <LinkIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                  {stableSort(
                    unlinkedProducts,
                    getComparator(order, orderBy),
                    searchQuery
                  ).length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No hay productos por vincular que coincidan con la búsqueda.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Paginación para Productos por Vincular */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Pagination
                count={unlinkedTotalPages}
                page={unlinkedPage}
                onChange={handleUnlinkedPageChange}
                color="secondary"
              />
            </Box>
          </Box>
        )}
      </Box>

      {/* Modal para vincular producto */}
      <Dialog open={openLinkModal} onClose={handleCloseLinkModal} maxWidth="sm" fullWidth>
        <DialogTitle>Vincular Producto</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            Producto del Proveedor: {selectedSupplierProduct?.supplier_product_name}
          </Typography>
          <Autocomplete
            options={internalProducts}
            getOptionLabel={(option) => `${option.name} (${option.sku})`}
            renderOption={(props, option) => (
              <li {...props}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body1">
                    {option.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    SKU: {option.sku} | Precio Venta: {formatPrice(option.price)}
                  </Typography>
                </Box>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar Producto Interno"
                variant="outlined"
              />
            )}
            onInputChange={handleInternalProductSearch}
            onChange={(event, value) => setSelectedInternalProduct(value)}
            loading={loadingInternalProducts}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLinkModal}>Cancelar</Button>
          <Button onClick={handleLinkProduct} variant="contained" color="primary">
            Vincular
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SupplierDetailsPage;
