// src/pages/ProductView.js 

import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Typography,
  Grid,
  Card,
  Button,
  CircularProgress,
  Alert,
  Box,
  Divider,
  Chip,
  IconButton,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Paper,
} from '@mui/material';
import {
  ArrowBack,
  ArrowForwardIos,
  ArrowBackIos,
  Edit,
  Inventory,
  Build,
  Category,
  AttachMoney,
  AccessTime as AccessTimeIcon,
  ShoppingCart,
  Sync,
  Print,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';

const SectionCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[4],
  backgroundColor: theme.palette.background.paper,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const ProductHeader = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[3],
}));

const ProductImage = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[3],
  transition: 'transform 0.3s',
  width: '200px',
  height: '200px',
  objectFit: 'cover',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const NavigationButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  width: '40px',
  height: '40px',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const PrevButton = styled(NavigationButton)(({ theme }) => ({
  left: '-30px',
}));

const NextButton = styled(NavigationButton)(({ theme }) => ({
  right: '-30px',
}));

const StatCard = styled(Paper)(({ theme, bgcolor }) => ({
  backgroundColor: bgcolor || theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  textAlign: 'center',
  boxShadow: theme.shadows[3],
}));

const PriceWithVATBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#4caf50',
  color: theme.palette.common.white,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  textAlign: 'center',
  flex: 1,
}));

const ProfitBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  textAlign: 'center',
  flex: 1,
}));

const TopRightContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  display: 'flex',
  alignItems: 'stretch',
  zIndex: 1,
  gap: theme.spacing(1),
}));

const formatDate = (dateString) => {
  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  return new Date(dateString).toLocaleString('es-ES', options).replace(',', '');
};

const calculatePriceNet = (price) => {
  const ivaRate = 0.19;
  return price / (1 + ivaRate);
};

const formatPriceSale = (price) => {
  return `$${Number(price).toLocaleString('es-CL', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })}`;
};

const formatPriceNet = (price) => {
  return `$${Number(price).toLocaleString('es-CL', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const AlertSnackbar = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProductView = () => {
  const { id } = useParams();
  console.log('Product ID:', id);
  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const tenantId = localStorage.getItem('tenantId') || 'default';
  const imageBaseUrl = `${process.env.REACT_APP_PUBLIC_URL}/images/products/tenant_${tenantId}/`;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/products/${id}/details`, {
        headers: {
          'x-tenant-id': tenantId,
        },
      })
      .then((response) => {
        const data = response.data;

        if (data.stock && !Array.isArray(data.stock)) {
          data.stock = [data.stock];
        } else if (!data.stock) {
          data.stock = [];
        }

        if (data.images && !Array.isArray(data.images)) {
          data.images = [data.images];
        } else if (!data.images) {
          data.images = [];
        }

        console.log('API Response:', data);
        setProductDetails(data);
        setLoading(false);
      })
      .catch((err) => {
        setError('Error al cargar los detalles del producto');
        setLoading(false);
      });
  }, [id, tenantId]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? productDetails.images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === productDetails.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleDownloadLabel = () => {
    window.open(`${process.env.REACT_APP_API_URL}/api/products/${id}/label`, '_blank');
  };

  if (loading) {
    return (
      <Container sx={{ textAlign: 'center', mt: 10 }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Cargando...
        </Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ mt: 5 }}>
        <Alert severity="error">{error}</Alert>
        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <StyledButton
            variant="contained"
            color="primary"
            startIcon={<ArrowBack />}
            component={Link}
            to="/products"
          >
            Volver a Productos
          </StyledButton>
        </Box>
      </Container>
    );
  }

  if (!productDetails) {
    return null;
  }

  const { product, suppliers, stock, compatibility, images, integrations } = productDetails;

  const totalStock = Array.isArray(stock)
    ? stock.reduce((sum, warehouse) => sum + parseInt(warehouse.stock_quantity || 0, 10), 0)
    : 0;

  const totalMinStock = Array.isArray(stock)
    ? stock
        .filter((warehouse) => warehouse.status === 'activo')
        .reduce((sum, warehouse) => sum + parseInt(warehouse.minimum_stock || 0, 10), 0)
    : 0;

  const lastUpdatedDate =
    Array.isArray(stock) && stock.length > 0 ? stock[0].last_updated : 'No disponible';

  const averageCost = productDetails.average_cost || 0;
  const lastCost = productDetails.latest_cost ? parseFloat(productDetails.latest_cost.cost) : null;

  const netPrice = calculatePriceNet(parseFloat(product.price));
  const profit = lastCost !== null ? netPrice - lastCost : null;
  const profitPercentage = profit !== null && netPrice !== 0 ? (profit / netPrice) * 100 : null;

  return (
    <Container sx={{ py: 5, position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: -8, right: 25, display: 'flex', gap: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Edit />}
          component={Link}
          to={`/products/edit/${id}`}
        >
          Editar Producto
        </Button>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Print />}
          onClick={handleDownloadLabel}
        >
          Generar Etiqueta
        </Button>
      </Box>

      <ProductHeader>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h4" color="primary" gutterBottom>
              {product.name}
            </Typography>
            <Typography variant="h5" color="text.secondary">
              <strong>SKU:</strong> {product.sku}
            </Typography>
            <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
              <Chip
                label={`Precio Neto: ${formatPriceNet(netPrice)}`}
                color="success"
                icon={<ShoppingCart />}
              />
              <Chip
                label={`Categoría: ${product.category_name || 'Sin Categoría'}`}
                color="primary"
                icon={<Category />}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            {images.length > 0 && (
              <Box
                sx={{
                  mt: 3,
                  position: 'relative',
                  display: 'inline-block',
                }}
              >
                <ProductImage
                  src={`${imageBaseUrl}${images[currentImageIndex]}`}
                  alt={`Producto ${currentImageIndex + 1}`}
                />
                <PrevButton onClick={handlePrevImage}>
                  <ArrowBackIos />
                </PrevButton>
                <NextButton onClick={handleNextImage}>
                  <ArrowForwardIos />
                </NextButton>
              </Box>
            )}
          </Grid>
        </Grid>

        <Box sx={{ mt: 5, width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <StatCard bgcolor="#4caf50">
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <AttachMoney sx={{ mr: 1 }} />
                  Precio Venta
                </Typography>
                <Typography variant="h6">{formatPriceSale(product.price)}</Typography>
              </StatCard>
            </Grid>
            <Grid item xs={12} md={3}>
              <StatCard bgcolor="#0288d1">
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Inventory sx={{ mr: 1 }} />
                  Stock Total
                </Typography>
                <Typography variant="h6">{totalStock}</Typography>
              </StatCard>
            </Grid>
            <Grid item xs={12} md={3}>
              <StatCard bgcolor="#e53935">
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Inventory sx={{ mr: 1 }} />
                  Stock Mínimo
                </Typography>
                <Typography variant="h6">{totalMinStock}</Typography>
              </StatCard>
            </Grid>
            <Grid item xs={12} md={3}>
              <StatCard bgcolor="#fb8c00">
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <AttachMoney sx={{ mr: 1 }} />
                  Margen
                </Typography>
                <Typography variant="h6">
                  {profitPercentage !== null ? `${profitPercentage.toFixed(2)}%` : 'No disponible'}
                </Typography>
              </StatCard>
            </Grid>
          </Grid>

          <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            <AccessTimeIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
            Última Actualización:{' '}
            {lastUpdatedDate ? formatDate(lastUpdatedDate) : 'No disponible'}
          </Typography>
        </Box>
      </ProductHeader>

      <Box sx={{ width: '100%', my: 5 }}>
        <Paper>
          <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
            <Tab icon={<Inventory />} label="Stock" />
            <Tab icon={<Build />} label="Proveedores" />
            <Tab icon={<Category />} label="Compatibilidad" />
            <Tab icon={<Sync />} label="Integraciones" />
          </Tabs>
        </Paper>
        <Box sx={{ p: 3 }}>
          {tabValue === 0 && (
            <Box>
              <Typography variant="h5" gutterBottom>
                Stock
              </Typography>
              {Array.isArray(stock) && stock.length > 0 ? (
                <Grid container spacing={3}>
                  {stock
                    .filter((warehouse) => warehouse.status === 'activo')
                    .map((warehouse, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card
                          sx={{
                            padding: 2,
                            borderRadius: 2,
                            boxShadow: 3,
                            transition: 'transform 0.3s',
                            '&:hover': { transform: 'translateY(-5px)' },
                          }}
                        >
                          <Typography variant="h6" color="primary">
                            {warehouse.warehouse_name}
                          </Typography>
                          <Typography variant="body1">Cantidad: {warehouse.stock_quantity}</Typography>
                          <Typography variant="body2">Mínimo: {warehouse.minimum_stock}</Typography>
                          <Typography variant="body2">
                            Última actualización: {formatDate(warehouse.last_updated)}
                          </Typography>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No hay stock disponible
                </Typography>
              )}
            </Box>
          )}

          {tabValue === 1 && (
            <Box>
              <Typography variant="h5" gutterBottom>
                Proveedores
              </Typography>
              {suppliers.length > 0 ? (
                suppliers.map((supplier, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                          <strong>Proveedor:</strong> {supplier.supplier_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Stack direction="row" spacing={1}>
                          <Chip label={`Precio: ${formatPriceSale(supplier.supplier_price)}`} color="success" />
                          <Chip label={`SKU: ${supplier.supplier_sku}`} color="info" />
                        </Stack>
                      </Grid>
                    </Grid>
                    <Typography variant="body2">
                      <strong>Contacto:</strong> {supplier.contact_person}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Última Actualización:</strong> {formatDate(supplier.updated_at)}
                    </Typography>
                    <Divider sx={{ mt: 1 }} />
                  </Box>
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No hay proveedores disponibles
                </Typography>
              )}
            </Box>
          )}

          {tabValue === 2 && (
            <Box>
              <Typography variant="h5" gutterBottom>
                Compatibilidad
              </Typography>
              {compatibility && compatibility.length > 0 ? (
                <Grid container spacing={2}>
                  {compatibility.map((vehicle, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card
                        sx={{
                          padding: 2,
                          borderRadius: 2,
                          boxShadow: 3,
                          transition: 'transform 0.3s',
                          '&:hover': { transform: 'translateY(-5px)' },
                        }}
                      >
                        <Typography variant="h6" color="primary">
                          {vehicle.make}
                        </Typography>
                        <Typography variant="body1">
                          {vehicle.model} ({vehicle.technical_model}) {vehicle.engine_displacement_l}L{' '}
                          {vehicle.engine_displacement_cc} cc {vehicle.drive_type.toUpperCase()}{' '}
                          {vehicle.year_start}-{vehicle.year_end}
                        </Typography>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No hay compatibilidad disponible
                </Typography>
              )}
            </Box>
          )}

          {tabValue === 3 && (
            <Box>
              <Typography variant="h5" gutterBottom>
                Integraciones
              </Typography>
              {integrations ? (
                Object.entries(integrations).map(([key, integration], idx) => (
                  <Box key={idx} sx={{ mb: 3 }}>
                    <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                      {key.replace('_', ' ')}
                    </Typography>
                    {integration && Array.isArray(integration) ? (
                      integration.map((item, index) => (
                        <Box key={index} sx={{ pl: 2, mb: 1 }}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body1">
                                <strong>ID:</strong> {item.id}
                              </Typography>
                              <Typography variant="body2">
                                <strong>ID Externo:</strong> {item.external_product_id}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body2">
                                <strong>Estado:</strong> {item.sync_status}
                              </Typography>
                              <Typography variant="body2">
                                <strong>Última Sincronización:</strong> {formatDate(item.last_synced)}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 1 }} />
                        </Box>
                      ))
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        No hay integraciones disponibles
                      </Typography>
                    )}
                  </Box>
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No hay integraciones disponibles
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>

      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <AlertSnackbar onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          URL del producto copiada al portapapeles!
        </AlertSnackbar>
      </Snackbar>
    </Container>
  );
};

export default ProductView;
