import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box
} from '@mui/material';

function IntegrationCreate() {
  const [formData, setFormData] = useState({
    platform: '',
    api_key: '',
    api_secret: '',
    status: 'activo',
    integration_name: '',
    store_url: '',
    // Campos específicos para WhatsApp
    whatsapp_access_token: '',
    whatsapp_phone_number_id: '',
    whatsapp_verify_token: '',
  });
  
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  // Función para manejar cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Validar campos obligatorios
  const validateForm = () => {
    let formErrors = {};
    if (!formData.platform) formErrors.platform = 'La plataforma es obligatoria';
    if (!formData.integration_name) formErrors.integration_name = 'El nombre de la integración es obligatorio';

    // Validaciones específicas para plataformas
    if (formData.platform.toLowerCase() !== 'whatsapp') {
      if (!formData.api_key) formErrors.api_key = 'El API Key es obligatorio';
      if (!formData.api_secret) formErrors.api_secret = 'El API Secret es obligatorio';
    } else {
      // Validaciones específicas para WhatsApp
      if (!formData.whatsapp_access_token) formErrors.whatsapp_access_token = 'El Access Token de WhatsApp es obligatorio';
      if (!formData.whatsapp_phone_number_id) formErrors.whatsapp_phone_number_id = 'El ID del número de teléfono de WhatsApp es obligatorio';
      if (!formData.whatsapp_verify_token) formErrors.whatsapp_verify_token = 'El Verify Token de WhatsApp es obligatorio';
    }

    return formErrors;
  };

  // Función para manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      // Preparar los datos a enviar
      const dataToSend = { ...formData };

      // Si la plataforma no es WhatsApp, eliminar los campos específicos de WhatsApp
      if (formData.platform.toLowerCase() !== 'whatsapp') {
        delete dataToSend.whatsapp_access_token;
        delete dataToSend.whatsapp_phone_number_id;
        delete dataToSend.whatsapp_verify_token;
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/integrations/create`, dataToSend);
      alert('Integración creada correctamente');
      navigate(`/integrations`);
    } catch (error) {
      console.error('Error al crear la integración:', error);
      alert('Error al crear la integración');
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={12} sm={8} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Crear Nueva Integración
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Nombre de la Integración"
                name="integration_name"
                value={formData.integration_name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.integration_name}
                helperText={errors.integration_name}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Plataforma</InputLabel>
                <Select
                  name="platform"
                  value={formData.platform}
                  onChange={handleChange}
                  error={!!errors.platform}
                >
                  <MenuItem value="jumpseller">Jumpseller</MenuItem>
                  <MenuItem value="shopify">Shopify</MenuItem>
                  <MenuItem value="woocommerce">WooCommerce</MenuItem>
                  <MenuItem value="whatsapp">WhatsApp</MenuItem>
                  <MenuItem value="otras">Otras</MenuItem>
                </Select>
                {errors.platform && <Typography color="error">{errors.platform}</Typography>}
              </FormControl>

              {/* Campos específicos para WhatsApp */}
              {formData.platform.toLowerCase() === 'whatsapp' && (
                <>
                  <TextField
                    label="Access Token de WhatsApp"
                    name="whatsapp_access_token"
                    value={formData.whatsapp_access_token}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!!errors.whatsapp_access_token}
                    helperText={errors.whatsapp_access_token}
                  />
                  <TextField
                    label="ID del Número de Teléfono de WhatsApp"
                    name="whatsapp_phone_number_id"
                    value={formData.whatsapp_phone_number_id}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!!errors.whatsapp_phone_number_id}
                    helperText={errors.whatsapp_phone_number_id}
                  />
                  <TextField
                    label="Verify Token de WhatsApp"
                    name="whatsapp_verify_token"
                    value={formData.whatsapp_verify_token}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!!errors.whatsapp_verify_token}
                    helperText={errors.whatsapp_verify_token}
                  />
                </>
              )}

              {/* Campos para otras plataformas */}
              {formData.platform.toLowerCase() !== 'whatsapp' && (
                <>
                  <TextField
                    label="API Key"
                    name="api_key"
                    value={formData.api_key}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!!errors.api_key}
                    helperText={errors.api_key}
                  />
                  <TextField
                    label="API Secret"
                    name="api_secret"
                    value={formData.api_secret}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!!errors.api_secret}
                    helperText={errors.api_secret}
                  />
                </>
              )}

              <FormControl fullWidth margin="normal">
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                >
                  <MenuItem value="activo">Activo</MenuItem>
                  <MenuItem value="inactivo">Inactivo</MenuItem>
                </Select>
              </FormControl>

              {/* Campo adicional opcional para store_url */}
              <TextField
                label="URL de la Tienda"
                name="store_url"
                value={formData.store_url}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />

              <Box sx={{ mt: 2 }}>
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  Crear Integración
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default IntegrationCreate;
