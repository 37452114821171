// src/pages/inadmin/AdminUserPermissionsPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AdminUserPermissionsPage = () => {
    const [userPermissions, setUserPermissions] = useState([]);
    const [users, setUsers] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({ user_id: '', permission_id: '' });
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserPermissions();
        fetchUsers();
        fetchPermissions();
    }, []);

    const fetchUserPermissions = () => {
        axios.get('/inadmin/user_permissions')
            .then(response => setUserPermissions(response.data))
            .catch(error => console.error('Error al obtener relaciones user_permissions:', error));
    };

    const fetchUsers = () => {
        axios.get('/inadmin/users')
            .then(response => setUsers(response.data))
            .catch(error => console.error('Error al obtener usuarios:', error));
    };

    const fetchPermissions = () => {
        axios.get('/inadmin/permissions')
            .then(response => setPermissions(response.data))
            .catch(error => console.error('Error al obtener permisos:', error));
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setFormData({ user_id: '', permission_id: '' });
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        axios.post('/inadmin/user_permissions', formData)
            .then(() => {
                fetchUserPermissions();
                handleClose();
            })
            .catch(error => console.error('Error al crear relación user_permission:', error));
    };

    const handleEdit = (id) => {
        navigate(`/inadmin/user_permissions/edit/${id}`);
    };

    const handleDelete = (id) => {
        axios.delete(`/inadmin/user_permissions/${id}`)
            .then(() => fetchUserPermissions())
            .catch(error => console.error('Error al eliminar relación user_permission:', error));
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'user_id', headerName: 'User ID', width: 150 },
        { field: 'permission_id', headerName: 'Permission ID', width: 150 },
        {
            field: 'actions',
            headerName: 'Acciones',
            width: 200,
            renderCell: (params) => (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleEdit(params.row.id)}
                        style={{ marginRight: 8 }}
                    >
                        Editar
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => handleDelete(params.row.id)}
                    >
                        Eliminar
                    </Button>
                </>
            ),
        },
    ];

    return (
        <Box sx={{ height: 600, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <h2>Gestión de Relaciones Usuario-Permiso</h2>
                <Button variant="contained" color="primary" onClick={handleOpen}>
                    Crear Relación
                </Button>
            </Box>
            <DataGrid
                rows={userPermissions}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
            />

            {/* Dialog para Crear Relación */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Crear Nueva Relación Usuario-Permiso</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="dense" variant="standard">
                        <InputLabel>User ID</InputLabel>
                        <Select
                            name="user_id"
                            value={formData.user_id}
                            onChange={handleChange}
                            label="User ID"
                        >
                            {users.map(user => (
                                <MenuItem key={user.id} value={user.id}>
                                    {user.id} - {user.username}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense" variant="standard">
                        <InputLabel>Permission ID</InputLabel>
                        <Select
                            name="permission_id"
                            value={formData.permission_id}
                            onChange={handleChange}
                            label="Permission ID"
                        >
                            {permissions.map(permission => (
                                <MenuItem key={permission.id} value={permission.id}>
                                    {permission.id} - {permission.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSubmit}>Crear</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdminUserPermissionsPage;
