// SuppliersBulkUploadPage.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
  Grid,
  MenuItem,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Upload as UploadIcon } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';

const SuppliersBulkUploadPage = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(''); // Aquí está el supplier seleccionado
  const [file, setFile] = useState(null);
  const [parsedData, setParsedData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [mapping, setMapping] = useState({});
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [invalidProducts, setInvalidProducts] = useState([]); // Almacenar productos con problemas

  const requiredFields = [
    { label: 'SKU', value: 'sku', required: true },
    { label: 'Supplier Product Name', value: 'supplier_product_name', required: true },
    { label: 'Supplier Price', value: 'supplier_price', required: true },
    { label: 'Stock', value: 'stock', required: true },
    { label: 'Discount Percentage (Opcional)', value: 'discount_percentage', required: false },
  ];

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/suppliers`);
        setSuppliers(response.data.suppliers || []);
      } catch (error) {
        console.error('Error al obtener proveedores:', error);
        setSnackbar({ open: true, message: 'Error al obtener la lista de proveedores', severity: 'error' });
      }
    };

    fetchSuppliers();
  }, []);

  const handleSupplierChange = (e) => {
    setSelectedSupplier(e.target.value); // Aquí guardamos el supplier seleccionado
    setFile(null);
    setParsedData([]);
    setColumns([]);
    setMapping({});
    setInvalidProducts([]); // Limpiar productos inválidos al cambiar de proveedor
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      if (!['csv', 'xls', 'xlsx'].includes(fileExtension)) {
        setSnackbar({ open: true, message: 'Solo se permiten archivos CSV o Excel (.xls, .xlsx)', severity: 'error' });
        return;
      }
      setFile(selectedFile);
      parseFile(selectedFile, fileExtension);
    }
  };

  const parseFile = (file, fileExtension) => {
    setLoading(true);
    if (fileExtension === 'csv') {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setParsedData(results.data);
          setColumns(results.meta.fields);
          setLoading(false);
        },
        error: function (error) {
          console.error('Error parsing CSV:', error);
          setSnackbar({ open: true, message: 'Error al parsear el archivo CSV', severity: 'error' });
          setLoading(false);
        },
      });
    } else if (['xls', 'xlsx'].includes(fileExtension)) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        try {
          const data = new Uint8Array(evt.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: '' });
          const sheetColumns = Object.keys(jsonData[0] || {});
          setParsedData(jsonData);
          setColumns(sheetColumns);
        } catch (error) {
          console.error('Error reading Excel file:', error);
          setSnackbar({ open: true, message: 'Error al leer el archivo Excel', severity: 'error' });
        }
        setLoading(false);
      };
      reader.onerror = (error) => {
        console.error('Error reading Excel file:', error);
        setSnackbar({ open: true, message: 'Error al leer el archivo Excel', severity: 'error' });
        setLoading(false);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleMappingChange = (requiredField, mappedColumn) => {
    setMapping((prevMapping) => ({
      ...prevMapping,
      [requiredField]: mappedColumn,
    }));
  };

  const isMappingComplete = requiredFields
    .filter((field) => field.required)
    .every((field) => mapping[field.value]);

  const handleSubmit = async () => {
    if (!isMappingComplete) {
      setSnackbar({ open: true, message: 'Por favor, mapea todos los campos requeridos', severity: 'warning' });
      return;
    }

    if (!selectedSupplier) {
      setSnackbar({ open: true, message: 'Debe seleccionar un proveedor', severity: 'warning' });
      return;
    }

    // Transformar los datos
    const transformedData = parsedData.map((row) => {
      const transformedRow = {};
      requiredFields.forEach((field) => {
        let value = row[mapping[field.value]];
        if (field.value === 'discount_percentage') {
          value = value !== '' ? parseFloat(value) : null;
        }
        if (field.value === 'supplier_price') {
          value = value !== '' ? parseFloat(value) : null;
        }
        if (field.value === 'stock') {
          value = value !== '' ? parseInt(value, 10) : null;
        }
        if (field.value === 'sku') {
          transformedRow['supplier_sku'] = value || null;
        } else {
          transformedRow[field.value] = value || null;
        }
      });
      return transformedRow;
    });

    // Separar productos inválidos (aquellos que tienen campos requeridos faltantes)
    const invalidProducts = transformedData.filter(
      (product) =>
        !product.supplier_sku || 
        !product.supplier_product_name || 
        product.supplier_price == null || 
        product.stock == null
    );

    // Filtrar productos válidos para enviar
    const validProducts = transformedData.filter(
      (product) =>
        product.supplier_sku && 
        product.supplier_product_name && 
        product.supplier_price != null && 
        product.stock != null
    );

    if (validProducts.length === 0) {
      setSnackbar({
        open: true,
        message: 'Todos los productos son inválidos. Revisa los campos faltantes.',
        severity: 'error',
      });
      return;
    }

    // Dividir los datos válidos en lotes de máximo 500 productos
    const batchSize = 500;
    const batches = [];
    for (let i = 0; i < validProducts.length; i += batchSize) {
      batches.push(validProducts.slice(i, i + batchSize));
    }

    console.log('Total de lotes:', batches.length); // Para depuración
    setLoading(true);

    try {
      for (const [index, batch] of batches.entries()) {
        console.log(`Enviando lote ${index + 1} de ${batches.length}`, batch); // Log de cada lote que se envía
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/suppliers/${selectedSupplier}/products/bulk`,
          { products: batch },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        console.log(`Lote ${index + 1} enviado con éxito`, response.data);
      }

      // Guardar productos inválidos y mostrar el resumen
      setInvalidProducts(invalidProducts);
      if (invalidProducts.length > 0) {
        console.warn('Productos inválidos:', invalidProducts);
        setSnackbar({
          open: true,
          message: `Carga completada con errores. ${invalidProducts.length} productos no se procesaron.`,
          severity: 'warning',
        });
      } else {
        setSnackbar({ open: true, message: 'Carga masiva completada exitosamente', severity: 'success' });
      }

      setFile(null);
      setParsedData([]);
      setColumns([]);
      setMapping({});
    } catch (error) {
      console.error('Error al enviar datos:', error.response?.data || error);
      setSnackbar({ open: true, message: 'Error al realizar la carga masiva de productos', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>
        Carga Masiva de Productos
      </Typography>

      {/* Selección de Proveedor */}
      <Box mb={4}>
        <FormControl fullWidth>
          <InputLabel>Seleccionar Proveedor</InputLabel>
          <Select
            value={selectedSupplier} // Mostrar el proveedor seleccionado
            label="Seleccionar Proveedor"
            onChange={handleSupplierChange}
          >
            {suppliers.map((supplier) => (
              <MenuItem key={supplier.id} value={supplier.id}>
                {supplier.name} (ID: {supplier.id})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Carga de Archivo */}
      {selectedSupplier && (
        <Box mb={4}>
          <input
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            style={{ display: 'none' }}
            id="upload-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="upload-file">
            <Button variant="contained" color="primary" component="span" startIcon={<UploadIcon />}>
              Cargar Archivo
            </Button>
          </label>
          {file && (
            <Typography variant="body1" ml={2} display="inline">
              {file.name}
            </Typography>
          )}
        </Box>
      )}

      {/* Vista Previa de Datos y Mapeo */}
      {parsedData.length > 0 && (
        <>
          {/* Mapeo de Campos */}
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Mapeo de Campos
            </Typography>
            <Grid container spacing={2}>
              {requiredFields.map((field) => (
                <Grid item xs={12} sm={6} md={2.4} key={field.value}>
                  <FormControl fullWidth>
                    <InputLabel>{field.label}</InputLabel>
                    <Select
                      value={mapping[field.value] || ''}
                      label={field.label}
                      onChange={(e) => handleMappingChange(field.value, e.target.value)}
                    >
                      {columns.map((col) => (
                        <MenuItem key={col} value={col}>
                          {col}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Vista Previa de la Tabla */}
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Vista Previa de Datos
            </Typography>
            <TableContainer component={Paper} style={{ maxHeight: 400 }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((col) => (
                      <TableCell key={col} style={{ minWidth: 100 }}>
                        {col}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parsedData.slice(0, 10).map((row, index) => (
                    <TableRow key={index}>
                      {columns.map((col) => (
                        <TableCell key={col} style={{ minWidth: 100 }}>
                          {row[col]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  {parsedData.length > 10 && (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        Mostrar primeros 10 registros de {parsedData.length}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}

      {/* Botón de Envío */}
      {parsedData.length > 0 && (
        <Box textAlign="center" mb={4}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={!isMappingComplete || loading}
            startIcon={loading && <CircularProgress size={20} />}
          >
            {loading ? 'Enviando...' : 'Enviar Datos'}
          </Button>
        </Box>
      )}

      {/* Resumen de Productos con Problemas */}
      {invalidProducts.length > 0 && (
        <Box mt={4}>
          <Typography variant="h6" color="error" gutterBottom>
            Productos no procesados ({invalidProducts.length})
          </Typography>
          <TableContainer component={Paper} style={{ maxHeight: 300 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>SKU</TableCell>
                  <TableCell>Nombre del Producto</TableCell>
                  <TableCell>Precio del Proveedor</TableCell>
                  <TableCell>Stock</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invalidProducts.slice(0, 10).map((product, index) => (
                  <TableRow key={index}>
                    <TableCell>{product.supplier_sku}</TableCell>
                    <TableCell>{product.supplier_product_name}</TableCell>
                    <TableCell>{product.supplier_price || 'N/A'}</TableCell>
                    <TableCell>{product.stock || 'N/A'}</TableCell>
                  </TableRow>
                ))}
                {invalidProducts.length > 10 && (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      Mostrar primeros 10 registros de {invalidProducts.length}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {/* Snackbar para Notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SuppliersBulkUploadPage;
