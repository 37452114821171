import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Snackbar,
  Button,
  Tooltip,
  Card,
  CardContent,
  Divider,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SellIcon from '@mui/icons-material/Sell';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const OrderDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [items, setItems] = useState([]);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const fetchOrderDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/${id}/details`, {
        headers: {
          'x-tenant-id': localStorage.getItem('tenantId') || 'default',
        },
      });
      const fetchedOrder = response.data.order;
      setOrder(fetchedOrder);
      setItems(response.data.items);

      // Construir la dirección de envío a partir de los campos planos
      const shipping = {
        name: fetchedOrder.shipping_name,
        surname: fetchedOrder.shipping_surname,
        address: fetchedOrder.shipping_address,
        street_number: fetchedOrder.shipping_street_number,
        complement: fetchedOrder.shipping_complement,
        city: fetchedOrder.shipping_city,
        postal: fetchedOrder.shipping_postal,
        region: fetchedOrder.shipping_region,
        country: fetchedOrder.shipping_country,
        country_code: fetchedOrder.shipping_country_code,
        region_code: fetchedOrder.shipping_region_code,
        latitude: fetchedOrder.shipping_latitude,
        longitude: fetchedOrder.shipping_longitude,
        municipality: fetchedOrder.shipping_municipality,
      };
      setShippingAddress(shipping);

      // Construir la dirección de facturación a partir de los campos planos
      const billing = {
        name: fetchedOrder.billing_name,
        surname: fetchedOrder.billing_surname,
        taxid: fetchedOrder.billing_taxid,
        address: fetchedOrder.billing_address,
        street_number: fetchedOrder.billing_street_number,
        complement: fetchedOrder.billing_complement,
        city: fetchedOrder.billing_city,
        postal: fetchedOrder.billing_postal,
        region: fetchedOrder.billing_region,
        country: fetchedOrder.billing_country,
        country_code: fetchedOrder.billing_country_code,
        region_code: fetchedOrder.billing_region_code,
        municipality: fetchedOrder.billing_municipality,
      };
      setBillingAddress(billing);

      setLoading(false);
    } catch (err) {
      console.error('Error fetching order details:', err);
      setError(true);
      setLoading(false);
      setOpenSnackbar(true);
      setSnackbarMessage('Error al cargar los detalles del pedido.');
      setSnackbarSeverity('error');
    }
  };

  useEffect(() => {
    fetchOrderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleBack = () => {
    navigate('/orders');
  };

  const handleConvertToSale = async () => {
    setProcessing(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/sales/convert-order/${id}`,
        {},
        {
          headers: {
            'x-tenant-id': localStorage.getItem('tenantId') || 'default',
            // Añade cualquier otro encabezado necesario, como tokens de autenticación
          },
        }
      );
      setSnackbarMessage(response.data.message || 'Orden convertida en venta exitosamente.');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      // Actualizar el estado de la orden local
      setOrder((prevOrder) => ({
        ...prevOrder,
        status_enum: 'sold',
        fulfillment_status: 'fulfilled',
      }));
    } catch (err) {
      console.error('Error al convertir la orden en venta:', err);
      const errorMsg =
        err.response?.data?.error || 'Error al convertir la orden en venta.';
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
    setProcessing(false);
    setOpenConfirmDialog(false);
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  // Funciones para eliminar el pedido
  const handleDeleteOrder = async () => {
    setProcessing(true);
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/orders/${id}`, {
        headers: {
          'x-tenant-id': localStorage.getItem('tenantId') || 'default',
          // Añade cualquier otro encabezado necesario, como tokens de autenticación
        },
      });
      setSnackbarMessage(response.data.message || 'Pedido eliminado exitosamente.');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      navigate('/orders'); // Redirigir a la lista de pedidos después de eliminar
    } catch (err) {
      console.error('Error al eliminar el pedido:', err);
      const errorMsg =
        err.response?.data?.error || 'Error al eliminar el pedido.';
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
    setProcessing(false);
    setOpenDeleteDialog(false);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  if (loading) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container sx={{ textAlign: 'center', mt: 4 }}>
          <CircularProgress />
        </Container>
      </LocalizationProvider>
    );
  }

  if (error || !order) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container sx={{ mt: 4 }}>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            sx={{ mt: 2 }}
          >
            Volver a la Lista de Pedidos
          </Button>
        </Container>
      </LocalizationProvider>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container sx={{ mt: 4, mb: 4 }}>
        {/* Barra de Acciones Superior */}
        <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={handleBack}
            >
              Volver a la Lista
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={handleOpenDeleteDialog}
              disabled={processing}
            >
              Eliminar Pedido
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="success"
              startIcon={<SellIcon />}
              onClick={handleOpenConfirmDialog}
              disabled={order.status_enum === 'sold' || processing}
            >
              {order.status_enum === 'sold' ? 'Pedido Confirmado' : 'Convertir en Venta'}
            </Button>
          </Grid>
        </Grid>

        <Typography variant="h4" gutterBottom>
          Detalles del Pedido #{order.order_number}
        </Typography>

        {/* Información del Pedido */}
        <Grid container spacing={4} sx={{ mb: 3 }}>
          <Grid item xs={12} md={6}>
            <Card elevation={3} sx={{ backgroundColor: '#f5f5f5' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Información del Cliente
                </Typography>
                <Typography>
                  <strong>Nombre:</strong> {order.customer_fullname || 'Sin Nombre'}
                </Typography>
                <Typography>
                  <strong>Email:</strong> {order.customer_email || 'No proporcionado'}
                </Typography>
                <Typography>
                  <strong>Teléfono:</strong> {order.customer_phone || 'No proporcionado'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card elevation={3} sx={{ backgroundColor: '#f5f5f5' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Detalles del Pedido
                </Typography>
                <Typography>
                  <strong>Fecha del Pedido:</strong>{' '}
                  {new Date(order.order_date).toLocaleString('es-CL')}
                </Typography>
                <Typography>
                  <strong>Método de Pago:</strong> {order.payment_method_name || 'N/A'}
                </Typography>
                <Typography>
                  <strong>Estado de Pago:</strong>{' '}
                  <Tooltip
                    title={
                      order.status_enum === 'sold'
                        ? 'Pago Completo'
                        : 'Pago Pendiente'
                    }
                  >
                    <Typography
                      color={
                        order.status_enum === 'sold'
                          ? 'success.main'
                          : 'error.main'
                      }
                      component="span"
                    >
                      {order.status_enum === 'sold' ? 'Pagado' : 'Pendiente'}
                    </Typography>
                  </Tooltip>
                </Typography>
                <Typography>
                  <strong>Estado de Envío:</strong>{' '}
                  <Tooltip
                    title={
                      order.fulfillment_status === 'fulfilled'
                        ? 'Pedido Entregado'
                        : 'Pedido en Proceso'
                    }
                  >
                    <Typography
                      color={
                        order.fulfillment_status === 'fulfilled'
                          ? 'success.main'
                          : 'warning.main'
                      }
                      component="span"
                    >
                      {order.fulfillment_status === 'fulfilled'
                        ? 'Entregado'
                        : 'No Procesado'}
                    </Typography>
                  </Tooltip>
                </Typography>
                <Typography>
                  <strong>Total:</strong> $
                  {Number(order.total).toLocaleString('es-CL', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
                <Typography>
                  <strong>IVA:</strong> $
                  {Number(order.tax).toLocaleString('es-CL', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
                <Typography>
                  <strong>Descuento:</strong> $
                  {Number(order.discount).toLocaleString('es-CL', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Direcciones */}
        <Grid container spacing={4} sx={{ mb: 3 }}>
          <Grid item xs={12} md={6}>
            <Card elevation={3} sx={{ backgroundColor: '#f0f8ff' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Dirección de Envío
                </Typography>
                {shippingAddress ? (
                  <>
                    <Typography>
                      <strong>Nombre:</strong> {shippingAddress.name}{' '}
                      {shippingAddress.surname}
                    </Typography>
                    <Typography>
                      <strong>Dirección:</strong> {shippingAddress.address},{' '}
                      {shippingAddress.street_number}
                    </Typography>
                    <Typography>
                      <strong>Complemento:</strong>{' '}
                      {shippingAddress.complement || 'N/A'}
                    </Typography>
                    <Typography>
                      <strong>Ciudad:</strong> {shippingAddress.city}
                    </Typography>
                    <Typography>
                      <strong>Región:</strong> {shippingAddress.region}
                    </Typography>
                    <Typography>
                      <strong>País:</strong> {shippingAddress.country} (
                      {shippingAddress.country_code})
                    </Typography>
                    <Typography>
                      <strong>Código Postal:</strong> {shippingAddress.postal}
                    </Typography>
                    <Typography>
                      <strong>Municipio:</strong> {shippingAddress.municipality}
                    </Typography>
                  </>
                ) : (
                  <Typography>No se proporcionó dirección de envío.</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card elevation={3} sx={{ backgroundColor: '#f0f8ff' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Dirección de Facturación
                </Typography>
                {billingAddress ? (
                  <>
                    <Typography>
                      <strong>Nombre:</strong> {billingAddress.name}{' '}
                      {billingAddress.surname}
                    </Typography>
                    <Typography>
                      <strong>Tax ID:</strong> {billingAddress.taxid || 'N/A'}
                    </Typography>
                    <Typography>
                      <strong>Dirección:</strong> {billingAddress.address},{' '}
                      {billingAddress.street_number}
                    </Typography>
                    <Typography>
                      <strong>Complemento:</strong>{' '}
                      {billingAddress.complement || 'N/A'}
                    </Typography>
                    <Typography>
                      <strong>Ciudad:</strong> {billingAddress.city}
                    </Typography>
                    <Typography>
                      <strong>Región:</strong> {billingAddress.region}
                    </Typography>
                    <Typography>
                      <strong>País:</strong> {billingAddress.country} (
                      {billingAddress.country_code})
                    </Typography>
                    <Typography>
                      <strong>Código Postal:</strong> {billingAddress.postal}
                    </Typography>
                    <Typography>
                      <strong>Municipio:</strong> {billingAddress.municipality}
                    </Typography>
                  </>
                ) : (
                  <Typography>No se proporcionó dirección de facturación.</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Ítems del Pedido */}
        <Typography variant="h5" sx={{ mb: 2 }}>
          Ítems del Pedido
        </Typography>
        <TableContainer component={Paper} elevation={3}>
          <Table aria-label="order items table">
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>Nombre del Producto</TableCell>
                <TableCell align="right">Cantidad</TableCell>
                <TableCell align="right">Precio Unitario</TableCell>
                <TableCell align="right">IVA</TableCell>
                <TableCell align="right">Monto</TableCell>
                <TableCell align="right">Medida</TableCell>
                <TableCell align="right">Descuento</TableCell>
                <TableCell align="right">Total Ítem</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.length > 0 ? (
                items.map((item) => (
                  <TableRow key={item.id} hover>
                    <TableCell>{item.sku}</TableCell>
                    <TableCell>{item.product_name}</TableCell>
                    <TableCell align="right">{item.quantity}</TableCell>
                    <TableCell align="right">
                      $
                      {Number(item.unit_price).toLocaleString('es-CL', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="right">
                      $
                      {Number(item.vat).toLocaleString('es-CL', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="right">
                      $
                      {Number(item.amount).toLocaleString('es-CL', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="right">{item.unit_measure}</TableCell>
                    <TableCell align="right">
                      $
                      {Number(item.item_discount).toLocaleString('es-CL', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="right">
                      $
                      {Number(item.total_item).toLocaleString('es-CL', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    No hay ítems para este pedido.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Resumen Financiero */}
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} sx={{ p: 2, backgroundColor: '#fafafa' }}>
                <Typography variant="h6" gutterBottom>
                  Resumen Financiero
                </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography>
                      <strong>Subtotal:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      $
                      {Number(order.subtotal).toLocaleString('es-CL', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>
                      <strong>IVA:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      $
                      {Number(order.tax).toLocaleString('es-CL', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>
                      <strong>Descuento:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right" color="error">
                      -$
                      {Number(order.discount).toLocaleString('es-CL', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </Grid>

                  <Divider sx={{ my: 1, width: '100%' }} />

                  <Grid item xs={6}>
                    <Typography variant="subtitle1">
                      <strong>Total:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" align="right">
                      $
                      {Number(order.total).toLocaleString('es-CL', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* Botón para Convertir en Venta (opcional si ya está en la barra superior) */}
        {/* <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="success"
            startIcon={<SellIcon />}
            onClick={handleOpenConfirmDialog}
            disabled={order.status_enum === 'sold' || processing}
          >
            {order.status_enum === 'sold' ? 'Pedido Confirmado y Enviado a Ventas' : processing ? 'Procesando...' : 'Convertir en Venta'}
          </Button>
        </Box> */}

        {/* Diálogo de Confirmación para Convertir en Venta */}
        <Dialog
          open={openConfirmDialog}
          onClose={handleCloseConfirmDialog}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="confirm-dialog-description"
        >
          <DialogTitle id="confirm-dialog-title">Confirmar Conversión a Venta</DialogTitle>
          <DialogContent>
            <DialogContentText id="confirm-dialog-description">
              ¿Estás seguro de que deseas convertir esta orden en una venta? Esta acción no se puede deshacer.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleConvertToSale} color="success" autoFocus>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Diálogo de Confirmación para Eliminar Pedido */}
        <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
        >
          <DialogTitle id="delete-dialog-title">Eliminar Pedido</DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-dialog-description">
              ¿Estás seguro de que deseas eliminar este pedido? Esta acción no se puede deshacer.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleDeleteOrder} color="error" autoFocus>
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar para notificaciones */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </LocalizationProvider>
  );
};

export default OrderDetailsPage;
