import React, { useEffect, useState } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, CircularProgress, Box, Button, LinearProgress, Pagination, Chip
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const JumpsellerOrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0); // Barra de progreso
  const [currentOrder, setCurrentOrder] = useState(''); // Pedido actual que se está descargando
  const [page, setPage] = useState(1); // Página actual
  const [totalPages, setTotalPages] = useState(1); // Total de páginas
  const [limit, setLimit] = useState(10); // Límites de pedidos por página
  const navigate = useNavigate();

  // Función para iniciar la importación de pedidos
  const startImportProcess = async () => {
    setLoading(true);
    setProgress(0);
    setCurrentOrder('Iniciando importación de pedidos...');

    try {
      // Paso 1: Iniciar la importación de pedidos en el backend
      const importResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/jumpsellerorders/orders/import`);

      setCurrentOrder(importResponse.data.message || 'Importación completada.');
      setProgress(100); // Actualizamos el progreso al 100%

      // Paso 2: Recargar la lista de pedidos
      fetchOrdersWithPagination(page);
    } catch (error) {
      console.error('Error durante la importación:', error);
      setCurrentOrder('Error durante la importación.');
    }

    setLoading(false);
  };

  // Función para cargar pedidos con paginación
  const fetchOrdersWithPagination = async (currentPage) => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jumpsellerorders/orders?page=${currentPage}&limit=${limit}`);
      if (response.data?.orders) {
        setOrders(response.data.orders);
        setTotalPages(response.data.totalPages); // Actualizar total de páginas
      }
    } catch (error) {
      console.error('Error al cargar los pedidos:', error);
    }
    setLoading(false);
  };

  // Manejar el cambio de página
  const handlePageChange = (event, value) => {
    setPage(value);
    fetchOrdersWithPagination(value); // Cargar la página seleccionada
  };

  // Cargar pedidos iniciales desde la base de datos con paginación
  useEffect(() => {
    fetchOrdersWithPagination(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // Función para ir a la página de detalles de un pedido
  const goToOrderDetails = (orderId) => {
    navigate(`/orders/${orderId}`);
  };

  // Función para obtener el color del estado
  const getStatusColor = (status) => {
    switch (status) {
      case 'Paid':
        return 'success';
      case 'Canceled':
      case 'Cancelled':
        return 'error';
      case 'Pending':
        return 'warning';
      case 'Processing':
        return 'info';
      default:
        return 'default';
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Pedidos de Jumpseller
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ ml: 2, mt: 2 }}>{currentOrder}</Typography>
          <LinearProgress variant="determinate" value={progress} sx={{ width: '100%', mt: 2 }} />
        </Box>
      ) : (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">
            Lista de pedidos
          </Typography>
        </Box>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Orden Jumpseller</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.length > 0 ? (
              orders.map((order) => (
                <TableRow key={order.jumpseller_order_id}>
                  <TableCell>{order.jumpseller_order_id || 'Sin número'}</TableCell>
                  <TableCell>{order.customer_name || 'Sin nombre'}</TableCell>
                  <TableCell>
                    <Chip label={order.status} color={getStatusColor(order.status)} />
                  </TableCell>
                  <TableCell>{new Date(order.created_at).toLocaleString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</TableCell>
                  <TableCell>
                    {order.total_amount !== undefined && order.total_amount !== null
                      ? new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(order.total_amount)
                      : '$ 0'}
                  </TableCell>
                  <TableCell>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={() => goToOrderDetails(order.jumpseller_order_id)}
                    >
                      Ver Detalle
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">No se encontraron pedidos</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        sx={{ mt: 3 }}
        color="primary"
      />

      <Button 
        variant="contained" 
        color="primary" 
        sx={{ mt: 3 }} 
        onClick={startImportProcess}
        disabled={loading}
      >
        Importar Nuevos Pedidos
      </Button>
    </Box>
  );
};

export default JumpsellerOrdersPage;
