// src/pages/SaleDetailsView.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  IconButton,
  Tooltip,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SaleDetailsView = () => {
  const { saleId } = useParams();
  const navigate = useNavigate();
  const apiUrl = `${process.env.REACT_APP_API_URL}/api`;

  const [saleDetails, setSaleDetails] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customerExists, setCustomerExists] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [completeDialogOpen, setCompleteDialogOpen] = useState(false);
  const [processDialogOpen, setProcessDialogOpen] = useState(false); // Nuevo estado para el diálogo de procesamiento

  /**
   * Obtener los detalles de la venta
   */
  const fetchSaleDetails = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${apiUrl}/sales/${saleId}`);
      const { sale, items } = response.data;
      setSaleDetails(sale);
      setItems(items);

      const taxId = sale.tax_id;

      if (taxId && taxId !== 'null') {
        await checkCustomerExists(taxId);
      } else {
        setCustomerExists(false);
        setCustomerData(null);
      }
    } catch (err) {
      console.error('Error al obtener los detalles de la venta:', err);
      setError('Error al obtener los detalles de la venta.');
      setSnackbar({
        open: true,
        message: 'Error al obtener los detalles de la venta.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Verificar si el cliente existe basado en tax_id
   */
  const checkCustomerExists = async (tax_id) => {
    if (!tax_id || tax_id === 'null') {
      setCustomerExists(false);
      setCustomerData(null);
      return;
    }

    try {
      const response = await axios.get(`${apiUrl}/customers/tax_id/${tax_id}`);
      setCustomerExists(true);
      setCustomerData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Cliente no existe
        setCustomerExists(false);
        setCustomerData(null);
      } else {
        console.error('Error al verificar el cliente:', error);
        setSnackbar({
          open: true,
          message: 'Error al verificar el cliente.',
          severity: 'error',
        });
      }
    }
  };

  /**
   * Eliminar la venta
   */
  const handleDeleteSale = async () => {
    try {
      await axios.delete(`${apiUrl}/sales/${saleId}`);
      setSnackbar({
        open: true,
        message: 'Venta eliminada exitosamente.',
        severity: 'success',
      });
      navigate('/sales');
    } catch (err) {
      console.error('Error al eliminar la venta:', err);
      setSnackbar({
        open: true,
        message: 'Error al eliminar la venta.',
        severity: 'error',
      });
    }
  };

  /**
   * Completar la venta
   */
  const handleCompleteSale = async () => {
    try {
      // Asumiendo que hay un endpoint para completar la venta
      await axios.post(`${apiUrl}/sales/${saleId}/complete`);
      setSnackbar({
        open: true,
        message: 'Venta completada exitosamente.',
        severity: 'success',
      });
      // Actualizar el estado local
      setSaleDetails((prev) => ({ ...prev, status: 'completed' }));
    } catch (err) {
      console.error('Error al completar la venta:', err);
      setSnackbar({
        open: true,
        message: 'Error al completar la venta.',
        severity: 'error',
      });
    }
  };

  /**
   * Procesar la venta utilizando el endpoint proporcionado
   */
  const handleProcessSale = async () => {
    // Construir los datos necesarios para procesar la venta
    const saleData = {
      customer_id: saleDetails.customer_id || null,
      payment_method: saleDetails.payment_method,
      items: items.map((item) => ({
        product_id: item.product_id,
        item_code: item.external_sku || null, // Usar 'external_sku' como 'item_code'
        item_name: item.product_name || 'No disponible',
        quantity: item.quantity,
        unit_price: parseFloat(item.unit_price),
        total_item: parseFloat(item.total_price),
        vat: parseFloat(saleDetails.vat) || 0,
        discount_pct: parseFloat(item.discount_pct) || 0,
        discount_amount: parseFloat(item.discount_amount) || 0,
        unit_measure: item.unit || 'unit',
        external_sku: item.external_sku || null,
      })),
      total_amount: parseFloat(saleDetails.total_amount),
      amount_paid: 0, // Puedes ajustar esto según tu lógica
      status: 'completed', // Puedes ajustar esto según tu lógica
    };

    try {
      const response = await axios.post(`${apiUrl}/sales/`, saleData);
      setSnackbar({
        open: true,
        message: response.data.message || 'Venta procesada exitosamente.',
        severity: 'success',
      });
      // Opcional: actualizar el estado de la venta local si es necesario
      // Por ejemplo, puedes actualizar el saleDetails con los nuevos datos
    } catch (err) {
      console.error('Error al procesar la venta:', err);
      const errorMsg =
        err.response?.data?.error || 'Error al procesar la venta.';
      setSnackbar({
        open: true,
        message: errorMsg,
        severity: 'error',
      });
    } finally {
      setProcessDialogOpen(false);
    }
  };

  /**
   * Obtener los detalles de la venta al montar el componente
   */
  useEffect(() => {
    if (saleId) {
      fetchSaleDetails();
    } else {
      setError('ID de venta no proporcionado.');
      setSnackbar({
        open: true,
        message: 'ID de venta no proporcionado.',
        severity: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleId]);

  /**
   * Formatear fecha y hora
   */
  const formatDateTime = (isoString) => {
    if (!isoString) return 'No disponible';
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };
    return new Date(isoString).toLocaleString('es-CL', options).replace(',', '');
  };

  /**
   * Formatear moneda
   */
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
    }).format(amount);
  };

  /**
   * Render loading state
   */
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  /**
   * Render error state
   */
  if (error) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
        <Button variant="contained" onClick={() => navigate('/sales')} sx={{ mt: 2 }}>
          Volver a Ventas
        </Button>
      </Box>
    );
  }

  /**
   * Render no sale details found
   */
  if (!saleDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography variant="h6">
          No se encontraron detalles para la venta #{saleId}
        </Typography>
        <Button variant="contained" onClick={() => navigate('/sales')} sx={{ mt: 2 }}>
          Volver a Ventas
        </Button>
      </Box>
    );
  }

  /**
   * Desestructuración de los detalles de la venta
   */
  const {
    customer_name,
    tax_id,
    sale_date,
    document_type,
    folio,
    total_amount,
    receiver_address,
    receiver_business,
    vat,
    discount,
    contact,
    source,
    status: saleStatus,
    issuer_company_name,
    issuer_address,
    issuer_business_activity,
    issuer_tax_id,
    issuer_business,
    customer_email,
    customer_phone,
    // Puedes agregar más campos aquí si es necesario
  } = saleDetails;

  /**
   * Formatear datos del cliente
   */
  const customerDataFormatted = {
    'Razón Social Cliente': customerData ? customerData.name : customer_name || 'No disponible',
    'RUT Cliente': customerData ? customerData.tax_id : tax_id || 'No disponible',
    Dirección: customerData ? customerData.address : receiver_address || 'No disponible',
    Comuna: customerData ? customerData.commune : 'No disponible',
    Ciudad: customerData ? customerData.city : 'No disponible',
    País: customerData ? customerData.country : 'Chile',
    'Código Postal': customerData ? customerData.postal_code : 'No disponible',
    Giro: customerData ? customerData.business_line : receiver_business || 'No disponible',
    Contacto: customerData
      ? customerData.contact_person
      : contact || customer_phone || 'No disponible',
    'Correo Electrónico': customer_email || (customerData && customerData.email) || 'No disponible',
    'Información Adicional': customerData ? customerData.additional_info : 'No disponible',
    'Fecha/Hora de Venta': formatDateTime(sale_date),
    'Tipo de Documento': document_type || 'No disponible',
    Folio: folio || 'No disponible',
  };

  /**
   * Formatear datos del emisor
   */
  const emisorData = {
    'Razón Social Emisor': issuer_company_name || 'No disponible',
    'RUT Emisor': issuer_tax_id || 'No disponible',
    Dirección: issuer_address || 'No disponible',
    Giro: issuer_business || 'No disponible',
    'Actividad Económica': issuer_business_activity || 'No disponible',
  };

  /**
   * Formatear detalles financieros
   */
  const detallesFinancieros = {
    Subtotal: formatCurrency(
      (
        (parseFloat(total_amount) || 0) -
        (parseFloat(vat) || 0) -
        (parseFloat(discount) || 0)
      ).toFixed(2)
    ),
    IVA: formatCurrency(parseFloat(vat) || 0),
    Descuento: formatCurrency(parseFloat(discount) || 0),
    Total: formatCurrency(parseFloat(total_amount) || 0),
  };

  return (
    <Box sx={{ p: { xs: 2, md: 4 }, backgroundColor: '#fafafa', minHeight: '100vh' }}>
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Typography variant="h4" gutterBottom>
          Detalles de la Venta #{folio || saleId}
        </Typography>
        <Box mt={{ xs: 2, sm: 0 }} display="flex" alignItems="center" gap={2}>
          <Tooltip title="Eliminar Venta">
            <IconButton
              color="error"
              onClick={() => setDeleteDialogOpen(true)}
              sx={{
                backgroundColor: '#fdecea',
                '&:hover': {
                  backgroundColor: '#f8d7da',
                },
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar Venta">
            <IconButton
              color="primary"
              onClick={() => navigate(`/sales/${saleId}/edit`)}
              sx={{
                backgroundColor: '#e7f3ff',
                '&:hover': {
                  backgroundColor: '#d0ebff',
                },
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Completar Venta">
            <Button
              variant="contained"
              color="success"
              startIcon={<CheckCircleIcon />}
              onClick={() => setCompleteDialogOpen(true)}
              disabled={saleStatus === 'completed'}
            >
              {saleStatus === 'completed' ? 'Venta Completada' : 'Completar Venta'}
            </Button>
          </Tooltip>
          <Tooltip title="Procesar Venta">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setProcessDialogOpen(true)}
              // Puedes agregar estilos adicionales si lo deseas
            >
              Procesar Venta
            </Button>
          </Tooltip>
        </Box>
      </Box>

      {/* Información del Cliente */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Información del Cliente
        </Typography>
        <Paper elevation={3} sx={{ p: 2 }}>
          {Object.entries(customerDataFormatted).map(([key, value]) => (
            <Box
              key={key}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              py={1}
              borderBottom="1px solid #e0e0e0"
            >
              <Typography variant="body1" color="textSecondary">
                {key}
              </Typography>
              <Typography variant="body1">{value || 'No disponible'}</Typography>
            </Box>
          ))}
        </Paper>
      </Box>

      {/* Información del Emisor */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Información del Emisor
        </Typography>
        <Paper elevation={3} sx={{ p: 2 }}>
          {Object.entries(emisorData).map(([key, value]) => (
            <Box
              key={key}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              py={1}
              borderBottom="1px solid #e0e0e0"
            >
              <Typography variant="body1" color="textSecondary">
                {key}
              </Typography>
              <Typography variant="body1">{value || 'No disponible'}</Typography>
            </Box>
          ))}
        </Paper>
      </Box>

      {/* Detalles Financieros */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Detalles Financieros
        </Typography>
        <Paper elevation={3} sx={{ p: 2 }}>
          {Object.entries(detallesFinancieros).map(([key, value]) => (
            <Box
              key={key}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              py={1}
              borderBottom="1px solid #e0e0e0"
            >
              <Typography variant="body1" color="textSecondary">
                {key}
              </Typography>
              <Typography variant="body1">{value}</Typography>
            </Box>
          ))}
        </Paper>
      </Box>

      {/* Productos o Servicios */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Productos/Servicios
        </Typography>
        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell>Nombre del Producto</TableCell>
                <TableCell align="right">Cantidad</TableCell>
                <TableCell align="right">Precio Unitario</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items && items.length > 0 ? (
                items.map((item, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.external_sku || 'No disponible'}</TableCell>
                    <TableCell>{item.item_name || 'No disponible'}</TableCell>
                    <TableCell align="right">{item.quantity || '0'}</TableCell>
                    <TableCell align="right">
                      {item.unit_price ? formatCurrency(parseFloat(item.unit_price)) : 'No disponible'}
                    </TableCell>
                    <TableCell align="right">
                      {item.total_price ? formatCurrency(parseFloat(item.total_price)) : 'No disponible'}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No hay productos o servicios en esta venta.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Diálogo de Confirmación para Eliminar Venta */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Eliminar Venta</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            ¿Estás seguro de que deseas eliminar esta venta? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteSale} color="error" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo de Confirmación para Completar Venta */}
      <Dialog
        open={completeDialogOpen}
        onClose={() => setCompleteDialogOpen(false)}
        aria-labelledby="complete-dialog-title"
        aria-describedby="complete-dialog-description"
      >
        <DialogTitle id="complete-dialog-title">Completar Venta</DialogTitle>
        <DialogContent>
          <DialogContentText id="complete-dialog-description">
            ¿Estás seguro de que deseas completar esta venta? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCompleteDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleCompleteSale} color="success" autoFocus>
            Completar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo de Confirmación para Procesar Venta */}
      <Dialog
        open={processDialogOpen}
        onClose={() => setProcessDialogOpen(false)}
        aria-labelledby="process-dialog-title"
        aria-describedby="process-dialog-description"
      >
        <DialogTitle id="process-dialog-title">Procesar Venta</DialogTitle>
        <DialogContent>
          <DialogContentText id="process-dialog-description">
            ¿Estás seguro de que deseas procesar esta venta? Esta acción creará una nueva venta en el sistema.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setProcessDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleProcessSale} color="secondary" autoFocus>
            Procesar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SaleDetailsView;
