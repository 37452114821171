// Frontend: src/pages/JumpsellerProductView.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  TextField,
  Button,
  Paper,
  Grid,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
  Chip,
  Divider,
  IconButton,
  Avatar,
  Snackbar,
  Alert,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Save as SaveIcon,
  Cancel as CancelIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { styled } from '@mui/system';

// Estilo personalizado para el componente Paper
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(2),
}));

const JumpsellerProductView = () => {
  const { productId } = useParams(); // Obtener el productId desde la URL
  const navigate = useNavigate();

  // Estados principales
  const [product, setProduct] = useState(null);
  const [originalProduct, setOriginalProduct] = useState(null); // Para comparar cambios
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Estados para notificaciones y diálogos
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);

  const [generatingField, setGeneratingField] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [openSuggestionsDialog, setOpenSuggestionsDialog] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false); // Indicador de carga para generación de campos

  // Función para obtener los detalles del producto
  const fetchProductDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/jumpsellerProducts/products/${productId}`
      );
      setProduct(response.data);
      setOriginalProduct(response.data);
    } catch (err) {
      setError('Error al cargar los detalles del producto.');
      console.error(err);
    }
    setLoading(false);
  };

  // Función para obtener opciones de categorías y tags
  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/jumpseller/products/options`
      );
      setCategoriesOptions(response.data.categories);
      setTagsOptions(response.data.brands); // Cambiado de 'tags' a 'brands' si corresponde
    } catch (error) {
      console.error('Error al cargar opciones:', error);
      setSnackbarMessage('Error al cargar opciones de categorías y marcas.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchProductDetails();
    fetchOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  // Función para manejar cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Función para manejar cambios en campos booleanos
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  // Función para manejar cambios en categorías
  const handleCategoriesChange = (event, value) => {
    setProduct((prevState) => ({
      ...prevState,
      categories: value,
    }));
  };

  // Función para manejar cambios en marcas
  const handleTagsChange = (event, value) => {
    setProduct((prevState) => ({
      ...prevState,
      brand: value, // Cambiado de 'tags' a 'brand' si corresponde
    }));
  };

  // Función para guardar los cambios
  const handleSave = async () => {
    setLoading(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/jumpseller/products/${productId}`,
        product
      );
      setSnackbarMessage('Producto actualizado correctamente.');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      setOriginalProduct(product);
    } catch (err) {
      setSnackbarMessage('Error al actualizar el producto.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      console.error(err);
    }
    setLoading(false);
  };

  // Función para cancelar los cambios
  const handleCancel = () => {
    setProduct(originalProduct);
    setSnackbarMessage('Cambios cancelados.');
    setSnackbarSeverity('info');
    setOpenSnackbar(true);
  };

  // Función para eliminar una imagen
  const handleDeleteImage = (index) => {
    const newImages = [...product.images];
    newImages.splice(index, 1);
    setProduct((prevState) => ({
      ...prevState,
      images: newImages,
    }));
  };

  // Función para cerrar el Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Función genérica para generar un campo específico usando IA
  const handleGenerateField = async (fieldName) => {
    setGeneratingField(fieldName);
    setIsGenerating(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/jumpseller/products/${productId}/generate-field`,
        { field: fieldName, product }
      );

      setSuggestions(response.data.suggestions || []);
      if (response.data.suggestions && response.data.suggestions.length > 0) {
        setOpenSuggestionsDialog(true);
      } else {
        setSnackbarMessage(`No se generaron sugerencias para ${fieldName}.`);
        setSnackbarSeverity('warning');
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error(`Error al generar ${fieldName}:`, error.response ? error.response.data : error.message);
      setSnackbarMessage(`Error al generar ${fieldName}.`);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }

    setIsGenerating(false);
  };

  // Función para seleccionar una sugerencia
  const handleSelectSuggestion = (suggestion) => {
    setProduct((prevState) => ({
      ...prevState,
      [generatingField]: suggestion,
    }));
    setOpenSuggestionsDialog(false);
    setGeneratingField(null);
    setSuggestions([]);
    setSnackbarMessage(`${generatingField} actualizado con la sugerencia seleccionada.`);
    setSnackbarSeverity('success');
    setOpenSnackbar(true);
  };

  // Función para agregar una nueva imagen vacía
  const handleAddImage = () => {
    setProduct((prevState) => ({
      ...prevState,
      images: [...(prevState.images || []), { url: '' }],
    }));
  };

  // Renderizado condicional basado en el estado de carga y errores
  if (loading && !product) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!product) {
    return null;
  }

  return (
    <Box sx={{ p: 3 }}>
      {/* Botón para volver a la lista de productos */}
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/jumpseller/products')}
        sx={{ mb: 2 }}
        variant="outlined"
      >
        Volver
      </Button>

      {/* Título del producto */}
      <Typography variant="h4" gutterBottom>
        {product.name}
      </Typography>

      {/* Información adicional del producto */}
      <Typography variant="subtitle1" gutterBottom color="textSecondary">
        ID Producto Jumpseller: {product.jumpseller_product_id}
      </Typography>

      {/* Formulario de edición del producto */}
      <StyledPaper elevation={3}>
        <Grid container spacing={2}>
          {/* Sección de Imágenes */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6">Imágenes</Typography>
            {product.images && product.images.length > 0 ? (
              product.images.map((image, index) => (
                <Box key={index} sx={{ position: 'relative', mb: 2 }}>
                  <Avatar
                    variant="rounded"
                    src={image.url}
                    alt={`Imagen ${index}`}
                    sx={{ width: '100%', height: 'auto' }}
                  />
                  <IconButton
                    onClick={() => handleDeleteImage(index)}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.7)',
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Typography>No hay imágenes disponibles.</Typography>
            )}
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              fullWidth
              onClick={handleAddImage}
              sx={{ mt: 1 }}
            >
              Agregar Imagen
            </Button>
          </Grid>

          {/* Sección de Información del Producto */}
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              {/* Nombre del Producto */}
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <TextField
                    label="Nombre"
                    name="name"
                    value={product.name || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                  <Button
                    variant="text"
                    onClick={() => handleGenerateField('name')}
                    disabled={isGenerating && generatingField === 'name'}
                    sx={{ ml: 1 }}
                  >
                    {isGenerating && generatingField === 'name' ? 'Generando...' : 'Generar con IA'}
                  </Button>
                </Box>
              </Grid>

              {/* SKU y Marca */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="SKU"
                  name="sku"
                  value={product.sku || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Box display="flex" alignItems="center">
                  <TextField
                    label="Marca"
                    name="brand"
                    value={product.brand || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                  <Button
                    variant="text"
                    onClick={() => handleGenerateField('brand')}
                    disabled={isGenerating && generatingField === 'brand'}
                    sx={{ ml: 1 }}
                  >
                    {isGenerating && generatingField === 'brand' ? 'Generando...' : 'Generar con IA'}
                  </Button>
                </Box>
              </Grid>

              {/* Precio y Stock */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Precio"
                  name="price"
                  type="number"
                  value={product.price || 0}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Stock"
                  name="stock"
                  type="number"
                  value={product.stock || 0}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              {/* Peso y Condición */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Peso (kg)"
                  name="weight"
                  type="number"
                  value={product.weight || 0}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Condición</InputLabel>
                  <Select
                    name="condition"
                    value={product.condition || ''}
                    onChange={handleChange}
                  >
                    <MenuItem value="new">Nuevo</MenuItem>
                    <MenuItem value="used">Usado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Categorías y Marcas */}
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={categoriesOptions}
                  value={product.categories || []}
                  onChange={handleCategoriesChange}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip key={index} label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Categorías"
                      placeholder="Selecciona categorías"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  freeSolo
                  options={tagsOptions}
                  value={product.brand || []}
                  onChange={handleTagsChange}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip key={index} label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Marcas"
                      placeholder="Selecciona o agrega marcas"
                    />
                  )}
                />
              </Grid>

              {/* Descripción */}
              <Grid item xs={12}>
                <Box display="flex" alignItems="flex-start">
                  <TextField
                    label="Descripción"
                    name="description"
                    value={product.description || ''}
                    onChange={handleChange}
                    multiline
                    rows={6}
                    fullWidth
                  />
                  <Button
                    variant="text"
                    onClick={() => handleGenerateField('description')}
                    disabled={isGenerating && generatingField === 'description'}
                    sx={{ ml: 1, mt: 1 }}
                  >
                    {isGenerating && generatingField === 'description' ? 'Generando...' : 'Generar con IA'}
                  </Button>
                </Box>
              </Grid>

              {/* Visibilidad y Envío */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={product.visibility || false}
                      onChange={handleCheckboxChange}
                      name="visibility"
                    />
                  }
                  label="Producto Visible"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={product.shipping_required || false}
                      onChange={handleCheckboxChange}
                      name="shipping_required"
                    />
                  }
                  label="Requiere Envío"
                />
              </Grid>

              {/* Meta Información */}
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6">Meta Información</Typography>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <TextField
                    label="Título de la Página"
                    name="meta_title"
                    value={product.meta_title || ''}
                    onChange={handleChange}
                    fullWidth
                  />
                  <Button
                    variant="text"
                    onClick={() => handleGenerateField('meta_title')}
                    disabled={isGenerating && generatingField === 'meta_title'}
                    sx={{ ml: 1 }}
                  >
                    {isGenerating && generatingField === 'meta_title' ? 'Generando...' : 'Generar con IA'}
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" alignItems="flex-start">
                  <TextField
                    label="Descripción Meta"
                    name="meta_description"
                    value={product.meta_description || ''}
                    onChange={handleChange}
                    multiline
                    rows={2}
                    fullWidth
                  />
                  <Button
                    variant="text"
                    onClick={() => handleGenerateField('meta_description')}
                    disabled={isGenerating && generatingField === 'meta_description'}
                    sx={{ ml: 1, mt: 1 }}
                  >
                    {isGenerating && generatingField === 'meta_description' ? 'Generando...' : 'Generar con IA'}
                  </Button>
                </Box>
              </Grid>

              {/* Botones de Acción */}
              <Grid item xs={12} sx={{ textAlign: 'right', mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleSave}
                  sx={{ mr: 2 }}
                  disabled={loading}
                >
                  Guardar Cambios
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                  disabled={loading}
                >
                  Cancelar Cambios
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledPaper>

      {/* Diálogo para mostrar sugerencias generadas por IA */}
      <Dialog
        open={openSuggestionsDialog}
        onClose={() => setOpenSuggestionsDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Selecciona una opción para <strong>{generatingField}</strong>
        </DialogTitle>
        <DialogContent>
          {suggestions.length > 0 ? (
            suggestions.map((suggestion, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Paper
                  sx={{ p: 2, cursor: 'pointer' }}
                  onClick={() => handleSelectSuggestion(suggestion)}
                  elevation={1}
                >
                  <Typography variant="body1">{suggestion}</Typography>
                </Paper>
              </Box>
            ))
          ) : (
            <Typography>No se generaron sugerencias.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSuggestionsDialog(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default JumpsellerProductView;
