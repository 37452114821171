// ../components/modals/DeleteConfirmationDialog.js
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteConfirmationDialog = ({ open, handleClose, handleDelete }) => (
  <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
    <DialogTitle>Confirmar Eliminación</DialogTitle>
    <DialogContent>
      <Typography>
        ¿Estás seguro de que deseas eliminar esta compra? Esta acción no se puede deshacer.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={handleClose}
        variant="outlined"
        color="secondary"
        startIcon={<CloseIcon />}
      >
        Cancelar
      </Button>
      <Button
        onClick={handleDelete}
        variant="contained"
        color="error"
        startIcon={<DeleteIcon />}
      >
        Eliminar
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteConfirmationDialog;
