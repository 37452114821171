// src/pages/SettingsPage.js

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Paper,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';

const SettingsPage = () => {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentSetting, setCurrentSetting] = useState(null);
  const [dialog, setDialog] = useState({
    type: null, // 'edit', 'delete', 'add'
    open: false,
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

  useEffect(() => {
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSettings = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/settings`);
      setSettings(response.data);
    } catch (error) {
      console.error('Error fetching settings:', error);
      showSnackbar('Error al obtener las configuraciones', 'error');
    } finally {
      setLoading(false);
    }
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenDialog = (type, setting = null) => {
    setCurrentSetting(setting);
    setDialog({ type, open: true });
  };

  const handleCloseDialog = () => {
    setCurrentSetting(null);
    setDialog({ type: null, open: false });
  };

  // Form Management using react-hook-form
  const { control: editControl, handleSubmit: handleEditSubmit, reset: resetEditForm, formState: { errors: editErrors } } = useForm();
  const { control: addControl, handleSubmit: handleAddSubmit, reset: resetAddForm, formState: { errors: addErrors } } = useForm();

  useEffect(() => {
    if (dialog.type === 'edit' && currentSetting) {
      resetEditForm(currentSetting);
    }
    if (dialog.type === 'add') {
      resetAddForm({ key: '', value: '', description: '', category: '' });
    }
  }, [dialog, currentSetting, resetEditForm, resetAddForm]);

  const handleSaveEdit = async (data) => {
    if (!data.key || !data.value || !data.category) {
      showSnackbar('Por favor, completa todos los campos requeridos', 'warning');
      return;
    }

    try {
      await axios.put(`${apiUrl}/api/settings/${currentSetting.id}`, data);
      showSnackbar('Configuración actualizada correctamente', 'success');
      fetchSettings();
      handleCloseDialog();
    } catch (error) {
      console.error('Error updating setting:', error);
      showSnackbar('Error al actualizar la configuración', 'error');
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${apiUrl}/api/settings/${currentSetting.id}`);
      showSnackbar('Configuración eliminada correctamente', 'success');
      fetchSettings();
      handleCloseDialog();
    } catch (error) {
      console.error('Error deleting setting:', error);
      showSnackbar('Error al eliminar la configuración', 'error');
    }
  };

  const handleAddSetting = async (data) => {
    if (!data.key || !data.value || !data.category) {
      showSnackbar('Por favor, completa todos los campos requeridos', 'warning');
      return;
    }

    try {
      await axios.post(`${apiUrl}/api/settings`, data);
      showSnackbar('Configuración agregada correctamente', 'success');
      fetchSettings();
      handleCloseDialog();
    } catch (error) {
      console.error('Error adding setting:', error);
      showSnackbar('Error al agregar la configuración', 'error');
    }
  };

  // Define columns for DataGrid
  const columns = [
    { field: 'key', headerName: 'Clave', flex: 1 },
    { field: 'value', headerName: 'Valor', flex: 1 },
    { field: 'description', headerName: 'Descripción', flex: 2 },
    { field: 'category', headerName: 'Categoría', flex: 1 },
    {
      field: 'actions',
      headerName: 'Acciones',
      sortable: false,
      filterable: false,
      align: 'right',
      headerAlign: 'right',
      width: 200,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<EditIcon />}
            size="small"
            onClick={() => handleOpenDialog('edit', params.row)}
            sx={{ mr: 1 }}
          >
            Editar
          </Button>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            size="small"
            onClick={() => handleOpenDialog('delete', params.row)}
          >
            Eliminar
          </Button>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Configuraciones
      </Typography>

      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenDialog('add')}
        sx={{ mb: 2 }}
      >
        Agregar Configuración
      </Button>

      <Paper sx={{ height: 600, width: '100%' }}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            rows={settings}
            columns={columns}
            getRowId={(row) => row.id}
            pageSize={10}
            rowsPerPageOptions={[10, 25, 50]}
            disableSelectionOnClick
            autoHeight
            sx={{
              '& .MuiDataGrid-cell': {
                alignItems: 'center',
              },
            }}
            components={{
              NoRowsOverlay: () => (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Typography>No hay configuraciones disponibles.</Typography>
                </Box>
              ),
            }}
          />
        )}
      </Paper>

      {/* Edit Dialog */}
      <Dialog open={dialog.open && dialog.type === 'edit'} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Editar Configuración</DialogTitle>
        <DialogContent>
          <Box component="form" noValidate sx={{ mt: 2 }}>
            <Controller
              name="key"
              control={editControl}
              rules={{ required: 'La clave es requerida' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Clave"
                  fullWidth
                  margin="normal"
                  error={!!editErrors.key}
                  helperText={editErrors.key ? editErrors.key.message : ''}
                />
              )}
            />
            <Controller
              name="value"
              control={editControl}
              rules={{ required: 'El valor es requerido' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Valor"
                  fullWidth
                  margin="normal"
                  error={!!editErrors.value}
                  helperText={editErrors.value ? editErrors.value.message : ''}
                />
              )}
            />
            <Controller
              name="description"
              control={editControl}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Descripción"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={3}
                />
              )}
            />
            <Controller
              name="category"
              control={editControl}
              rules={{ required: 'La categoría es requerida' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Categoría"
                  fullWidth
                  margin="normal"
                  error={!!editErrors.category}
                  helperText={editErrors.category ? editErrors.category.message : ''}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleEditSubmit(handleSaveEdit)} variant="contained" color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog open={dialog.open && dialog.type === 'delete'} onClose={handleCloseDialog}>
        <DialogTitle>Eliminar Configuración</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas eliminar la configuración "{currentSetting?.key}"? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Dialog */}
      <Dialog open={dialog.open && dialog.type === 'add'} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Agregar Nueva Configuración</DialogTitle>
        <DialogContent>
          <Box component="form" noValidate sx={{ mt: 2 }}>
            <Controller
              name="key"
              control={addControl}
              rules={{ required: 'La clave es requerida' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Clave"
                  fullWidth
                  margin="normal"
                  error={!!addErrors.key}
                  helperText={addErrors.key ? addErrors.key.message : ''}
                />
              )}
            />
            <Controller
              name="value"
              control={addControl}
              rules={{ required: 'El valor es requerido' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Valor"
                  fullWidth
                  margin="normal"
                  error={!!addErrors.value}
                  helperText={addErrors.value ? addErrors.value.message : ''}
                />
              )}
            />
            <Controller
              name="description"
              control={addControl}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Descripción"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={3}
                />
              )}
            />
            <Controller
              name="category"
              control={addControl}
              rules={{ required: 'La categoría es requerida' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Categoría"
                  fullWidth
                  margin="normal"
                  error={!!addErrors.category}
                  helperText={addErrors.category ? addErrors.category.message : ''}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleAddSubmit(handleAddSetting)} variant="contained" color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para Retroalimentación */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SettingsPage;
