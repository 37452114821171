// src/pages/InventoryPage.js
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import {
  Save as SaveIcon,
  Close as CloseIcon,
  Search as SearchIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import axios from 'axios';

const InventoryPage = () => {
  const [warehouseDialogOpen, setWarehouseDialogOpen] = useState(true);
  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const [warehouses, setWarehouses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [inventoryItems, setInventoryItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [modalOpen, setModalOpen] = useState(false);
  const [suggestionsModalOpen, setSuggestionsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [quantity, setQuantity] = useState(1);

  const searchInputRef = useRef(null);

  useEffect(() => {
    fetchWarehouses();
  }, []);

  const fetchWarehouses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/warehouses`);
      setWarehouses(Array.isArray(response.data) ? response.data : response.data.warehouses);
    } catch (error) {
      console.error('Error al obtener las bodegas:', error);
      setSnackbar({ open: true, message: 'Error al obtener las bodegas.', severity: 'error' });
    }
  };

  const handleWarehouseSelect = () => {
    if (!selectedWarehouse) {
      setSnackbar({ open: true, message: 'Por favor, selecciona una bodega.', severity: 'warning' });
      return;
    }
    setWarehouseDialogOpen(false);
  };

  // Función para obtener detalles del producto
  const fetchProductDetails = async (productId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/${productId}/details`);
      return response.data.product;
    } catch (error) {
      console.error('Error al obtener los detalles del producto:', error);
      setSnackbar({ open: true, message: 'Error al obtener los detalles del producto.', severity: 'error' });
      return null;
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchQuery.trim()) {
      setSnackbar({ open: true, message: 'Ingresa un término de búsqueda.', severity: 'warning' });
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/inventory/search`, {
        params: { query: searchQuery.trim(), warehouseId: selectedWarehouse },
      });

      if (response.data.exactMatch) {
        // Obtener detalles del producto exacto
        const detailedProduct = await fetchProductDetails(response.data.exactMatch.id);
        if (detailedProduct) {
          setSelectedProduct(detailedProduct);
          setModalOpen(true);
        }
      } else if (response.data.suggestions && response.data.suggestions.length > 0) {
        // Obtener detalles de cada sugerencia
        const detailedSuggestions = await Promise.all(
          response.data.suggestions.map(async (suggestion) => {
            const details = await fetchProductDetails(suggestion.id);
            return details ? details : suggestion; // Si falla, usar la sugerencia básica
          })
        );
        setSuggestions(detailedSuggestions);
        setSuggestionsModalOpen(true);
      } else {
        setSnackbar({ open: true, message: 'No se encontraron productos.', severity: 'warning' });
      }
    } catch (error) {
      console.error('Error al buscar el producto:', error);
      if (error.response && error.response.status === 404) {
        setSnackbar({ open: true, message: 'Producto no encontrado.', severity: 'warning' });
      } else {
        setSnackbar({ open: true, message: 'Error al buscar el producto.', severity: 'error' });
      }
    }
    setLoading(false);
  };

  const handleAddToInventory = () => {
    if (quantity === '' || isNaN(quantity) || quantity < 0) {
      setSnackbar({ open: true, message: 'Por favor, ingresa una cantidad válida.', severity: 'warning' });
      return;
    }

    const existingItem = inventoryItems.find(
      (item) => item.product.id === selectedProduct.id
    );
    if (existingItem) {
      const updatedItems = inventoryItems.map((item) =>
        item.product.id === selectedProduct.id
          ? { ...item, correctedStock: parseInt(quantity, 10) }
          : item
      );
      setInventoryItems(updatedItems);
    } else {
      setInventoryItems([
        ...inventoryItems,
        { product: selectedProduct, correctedStock: parseInt(quantity, 10) },
      ]);
    }

    setSnackbar({ open: true, message: 'Item agregado al inventario.', severity: 'success' });
    setModalOpen(false);
    setSelectedProduct(null);
    setQuantity(1);
    setSearchQuery('');
  };

  const handleSelectSuggestion = async (product) => {
    setSuggestionsModalOpen(false);
    setLoading(true);
    const detailedProduct = await fetchProductDetails(product.id);
    if (detailedProduct) {
      setSelectedProduct(detailedProduct);
      setModalOpen(true);
    }
    setLoading(false);
  };

  const handleSubmitInventory = async () => {
    if (inventoryItems.length === 0) {
      setSnackbar({ open: true, message: 'No hay items para enviar.', severity: 'warning' });
      return;
    }

    setLoading(true);
    try {
      const payload = {
        warehouseId: selectedWarehouse,
        items: inventoryItems.map((item) => ({
          productId: item.product.id,
          correctedStock: item.correctedStock,
        })),
      };
      await axios.post(`${process.env.REACT_APP_API_URL}/api/inventory/add`, payload);
      setSnackbar({ open: true, message: 'Inventario actualizado correctamente.', severity: 'success' });
      setInventoryItems([]);
    } catch (error) {
      console.error('Error al actualizar el inventario:', error);
      setSnackbar({ open: true, message: 'Error al actualizar el inventario.', severity: 'error' });
    }
    setLoading(false);
  };

  const handleRemoveInventoryItem = (index) => {
    const updatedItems = [...inventoryItems];
    updatedItems.splice(index, 1);
    setInventoryItems(updatedItems);
    setSnackbar({ open: true, message: 'Item removido del inventario.', severity: 'info' });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ p: 3, minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10} lg={8}>
          <Card elevation={3} sx={{ padding: 4, borderRadius: 2 }}>
            <CardContent>
              <Box sx={{ textAlign: 'center', mb: 3 }}>
                <Typography variant="h4" gutterBottom>
                  Toma de Inventario
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Verifica físicamente la cantidad de cada producto y actualiza el inventario.
                </Typography>
              </Box>

              {/* Diálogo para seleccionar bodega */}
              <Dialog open={warehouseDialogOpen} onClose={() => {}} fullWidth maxWidth="sm">
                <DialogTitle>Seleccionar Bodega</DialogTitle>
                <DialogContent>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel>Bodega</InputLabel>
                    <Select
                      value={selectedWarehouse}
                      onChange={(e) => setSelectedWarehouse(e.target.value)}
                      label="Bodega"
                    >
                      {Array.isArray(warehouses) && warehouses.map((warehouse) => (
                        <MenuItem key={warehouse.id} value={warehouse.id}>
                          {warehouse.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleWarehouseSelect}
                    startIcon={<SaveIcon />}
                  >
                    Seleccionar
                  </Button>
                </DialogActions>
              </Dialog>

              {selectedWarehouse && (
                <>
                  <form onSubmit={handleSearch}>
                    <TextField
                      inputRef={searchInputRef}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      variant="outlined"
                      placeholder="Buscar por nombre, SKU o código de barras"
                      fullWidth
                      sx={{ mb: 2 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box sx={{ textAlign: 'right', mb: 3 }}>
                      <Button type="submit" variant="contained" color="primary" startIcon={<SearchIcon />}>
                        Buscar
                      </Button>
                    </Box>
                  </form>

                  {loading && (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                      <CircularProgress size={24} sx={{ mr: 2 }} />
                      <Typography variant="body1">Procesando...</Typography>
                    </Box>
                  )}

                  {/* Modal para agregar al inventario */}
                  <Dialog open={modalOpen} onClose={() => setModalOpen(false)} fullWidth maxWidth="sm">
                    <DialogTitle>Agregar al Inventario</DialogTitle>
                    <DialogContent>
                      {selectedProduct && (
                        <Box sx={{ mt: 2 }}>
                          <Typography variant="subtitle1"><strong>Nombre:</strong> {selectedProduct.name}</Typography>
                          <Typography variant="subtitle1"><strong>SKU:</strong> {selectedProduct.sku}</Typography>
                          <Typography variant="subtitle1">
                            <strong>Código de Barras:</strong> {selectedProduct.oem_identifiers?.length > 0 ? selectedProduct.oem_identifiers[0].identifier : 'N/A'}
                          </Typography>
                          <Typography variant="subtitle1"><strong>Stock Actual:</strong> {selectedProduct.stock?.total_stock ?? 'N/A'}</Typography>
                        </Box>
                      )}

                      <Box sx={{ mt: 3 }}>
                        <TextField
                          label="Cantidad Física"
                          type="number"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          fullWidth
                          variant="outlined"
                          InputProps={{ inputProps: { min: 0 } }}
                          sx={{ mb: 2 }}
                        />
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setModalOpen(false)} startIcon={<CloseIcon />}>
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddToInventory}
                        startIcon={<SaveIcon />}
                      >
                        Agregar
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {/* Modal para sugerencias */}
                  <Dialog open={suggestionsModalOpen} onClose={() => setSuggestionsModalOpen(false)} fullWidth maxWidth="sm">
                    <DialogTitle>Sugerencias de Productos</DialogTitle>
                    <DialogContent>
                      <Typography variant="body1" gutterBottom>
                        No se encontró una coincidencia exacta. Por favor, selecciona uno de los siguientes productos:
                      </Typography>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell>Código de Barras</TableCell>
                            <TableCell align="center">Acción</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {suggestions.map((suggestion) => (
                            <TableRow key={suggestion.id}>
                              <TableCell>{suggestion.name}</TableCell>
                              <TableCell>{suggestion.sku}</TableCell>
                              <TableCell>
                                {suggestion.oem_identifiers?.length > 0
                                  ? suggestion.oem_identifiers[0].identifier
                                  : 'N/A'}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => handleSelectSuggestion(suggestion)}
                                >
                                  Seleccionar
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setSuggestionsModalOpen(false)} startIcon={<CloseIcon />}>
                        Cancelar
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Box sx={{ mt: 4 }}>
                    <Typography variant="h5" gutterBottom>
                      Items en Inventario
                    </Typography>
                    {inventoryItems.length === 0 ? (
                      <Typography variant="body1">No hay items agregados al inventario.</Typography>
                    ) : (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell>Código de Barras</TableCell>
                            <TableCell>Stock Actual</TableCell>
                            <TableCell>Stock Corregido</TableCell>
                            <TableCell align="center">Acciones</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {inventoryItems.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item.product.name}</TableCell>
                              <TableCell>{item.product.sku}</TableCell>
                              <TableCell>
                                {item.product.oem_identifiers?.length > 0
                                  ? item.product.oem_identifiers[0].identifier
                                  : 'N/A'}
                              </TableCell>
                              <TableCell>{item.product.stock?.total_stock ?? 'N/A'}</TableCell>
                              <TableCell>{item.correctedStock}</TableCell>
                              <TableCell align="center">
                                <IconButton onClick={() => handleRemoveInventoryItem(index)} color="error" size="small">
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </Box>

                  <Box sx={{ textAlign: 'right', mt: 3 }}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleSubmitInventory}
                      startIcon={<SaveIcon />}
                      disabled={loading || inventoryItems.length === 0}
                    >
                      Enviar al Inventario
                    </Button>
                  </Box>
                </>
              )}

              <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                  {snackbar.message}
                </Alert>
              </Snackbar>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InventoryPage;
