// src/pages/PurchasesPage.js

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Button,
    Pagination,
    Snackbar,
    Alert,
    Grid,
    TextField,
    MenuItem,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    Tooltip,
    Stack,
    Checkbox,
    FormControlLabel,
    Card,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import debounce from 'lodash.debounce';

// Función para traducir el tipo de documento
const translateDocumentType = (tipoDTE) => {
    const tipoMap = {
        30: 'Factura',
        32: 'Factura de Venta Bienes y Servicios No Afectos o Exentos de IVA',
        35: 'Boleta',
        38: 'Boleta Exenta',
        45: 'Factura de Compra',
        55: 'Nota de Débito',
        60: 'Nota de Crédito',
        103: 'Liquidación',
        40: 'Liquidación Factura',
        43: 'Liquidación Factura Electrónica',
        33: 'Factura Electrónica',
        34: 'Factura No Afecta o Exenta Electrónica',
        39: 'Boleta Electrónica',
        41: 'Boleta Exenta Electrónica',
        46: 'Factura de Compra Electrónica',
        56: 'Nota de Débito Electrónica',
        61: 'Nota de Crédito Electrónica',
        50: 'Guía de Despacho',
        52: 'Guía de Despacho Electrónica',
        110: 'Factura de Exportación Electrónica',
        111: 'Nota de Débito de Exportación Electrónica',
        112: 'Nota de Crédito de Exportación Electrónica',
        801: 'Orden de Compra',
        802: 'Nota de Pedido',
        803: 'Contrato',
        804: 'Resolución',
        805: 'Proceso ChileCompra',
        806: 'Ficha ChileCompra',
        807: 'DUS',
        808: 'B/L (Conocimiento de Embarque)',
        809: 'AWB (Air Will Bill)',
        810: 'MIC/DTA',
        811: 'Carta de Porte',
        812: 'Resolución del SNA donde Califica Servicios de Exportación',
        813: 'Pasaporte',
        814: 'Certificado de Depósito Bolsa Prod. Chile',
        815: 'Vale de Prenda Bolsa Prod. Chile',
        820: 'Código de Inscripción en el Registro de Acuerdos con Plazo de Pago Excepcional',
    };
    return tipoMap[tipoDTE] || 'Tipo de documento desconocido';
};


// 1. SearchBar Component
const SearchBar = ({ searchParams, setSearchParams }) => {
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setSearchParams({ 
            ...searchParams, 
            [name]: type === 'checkbox' ? checked : value 
        });
    };

    const handleReset = () => {
        setSearchParams({
            fecha_emision: '',
            search: '',
            folio: '',
            tipoDte: '',
            monto_total: '',
            notReceived: false, // Nuevo campo para el filtro
        });
    };

    return (
        <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6} md={3}>
                <TextField
                    label="Fecha"
                    type="date"
                    variant="outlined"
                    name="fecha_emision"
                    value={searchParams.fecha_emision}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <TextField
                    label="Emisor"
                    variant="outlined"
                    name="search"
                    value={searchParams.search}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <TextField
                    label="Folio"
                    variant="outlined"
                    name="folio"
                    value={searchParams.folio}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
			<Grid item xs={12} sm={6} md={3}>
			    <TextField
			        select
			        label="Tipo de Documento"
			        variant="outlined"
			        name="tipoDte"
			        value={searchParams.tipoDte}
			        onChange={handleChange}
			        fullWidth
			    >
			        <MenuItem value="">
			            <em>Todos</em>
			        </MenuItem>
			        <MenuItem value={43}>Liquidación Factura Electrónica</MenuItem>
			        <MenuItem value={33}>Factura Electrónica</MenuItem>
			        <MenuItem value={34}>Factura No Afecta o Exenta Electrónica</MenuItem>
			        <MenuItem value={39}>Boleta Electrónica</MenuItem>
			        <MenuItem value={41}>Boleta Exenta Electrónica</MenuItem>
			        <MenuItem value={46}>Factura de Compra Electrónica</MenuItem>
			        <MenuItem value={56}>Nota de Débito Electrónica</MenuItem>
			        <MenuItem value={61}>Nota de Crédito Electrónica</MenuItem>
			        <MenuItem value={52}>Guía de Despacho Electrónica</MenuItem>
			        <MenuItem value={110}>Factura de Exportación Electrónica</MenuItem>
			        <MenuItem value={111}>Nota de Débito de Exportación Electrónica</MenuItem>
			        <MenuItem value={112}>Nota de Crédito de Exportación Electrónica</MenuItem>
			        <MenuItem value={801}>Orden de Compra</MenuItem>
			        <MenuItem value={802}>Nota de Pedido</MenuItem>
			    </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <TextField
                    label="Total"
                    variant="outlined"
                    name="monto_total"
                    value={searchParams.monto_total}
                    onChange={handleChange}
                    type="number"
                    fullWidth
                />
            </Grid>
            {/* Nuevo Filtro: Solo Compras Sin Recibir */}
            <Grid item xs={12} sm={6} md={3}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={searchParams.notReceived}
                            onChange={handleChange}
                            name="notReceived"
                            color="primary"
                        />
                    }
                    label="Solo compras sin recibir"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleReset}
                    fullWidth
                >
                    Resetear
                </Button>
            </Grid>
        </Grid>
    );
};

// 2. PurchasesTable Component
const PurchasesTable = ({
    purchases,
    orderBy,
    orderDirection,
    handleSortRequest,
    goToPurchaseDetails,
}) => {
    const getSortDirection = (property) => {
        return orderBy === property ? orderDirection : 'asc';
    };

    const handleRowClick = (id) => {
        if (id) {
            goToPurchaseDetails(id);
        }
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'fecha_emision'}
                                direction={getSortDirection('fecha_emision')}
                                onClick={() => handleSortRequest('fecha_emision')}
                            >
                                Fecha y Hora
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'razon_social_emisor'}
                                direction={getSortDirection('razon_social_emisor')}
                                onClick={() => handleSortRequest('razon_social_emisor')}
                            >
                                Emisor
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'folio'}
                                direction={getSortDirection('folio')}
                                onClick={() => handleSortRequest('folio')}
                            >
                                Folio
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'tipo_dte'}
                                direction={getSortDirection('tipo_dte')}
                                onClick={() => handleSortRequest('tipo_dte')}
                            >
                                Tipo de Documento
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'monto_total'}
                                direction={getSortDirection('monto_total')}
                                onClick={() => handleSortRequest('monto_total')}
                            >
                                Total
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>Estado</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {purchases.length > 0 ? (
                        purchases.map((purchase) => (
                            <TableRow key={purchase.id} hover onClick={() => handleRowClick(purchase.id)}>
                                <TableCell>
                                    {new Date(purchase.fecha_emision).toLocaleDateString('es-CL')} {new Date(purchase.fecha_emision).toLocaleTimeString('es-CL')}
                                </TableCell>
                                <TableCell>{purchase.razon_social_emisor}</TableCell>
                                <TableCell>{purchase.folio}</TableCell>
                                <TableCell>{translateDocumentType(purchase.tipo_dte)}</TableCell>
                                <TableCell>{`$ ${Number(purchase.monto_total).toLocaleString('es-CL')}`}</TableCell>
                                <TableCell>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        {!purchase.accepted && (
                                            <Tooltip title="Compra no recibida">
                                                <ErrorIcon color="error" />
                                            </Tooltip>
                                        )}
                                        {purchase.accepted && (
                                            <Tooltip title="Compra recibida">
                                                <CheckCircleIcon color="success" />
                                            </Tooltip>
                                        )}
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={7} align="center">
                                No se encontraron compras
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

// 3. SummaryCards Component
const SummaryCards = ({ totalPurchases, purchasesNotReceived }) => {
    const percentageNotReceived = totalPurchases > 0 
        ? ((purchasesNotReceived / totalPurchases) * 100).toFixed(2) 
        : '0.00';

    return (
        <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ p: 2, textAlign: 'center', boxShadow: 3 }}>
                    <Typography variant="h6">Total de Compras</Typography>
                    <Typography variant="h4">{totalPurchases}</Typography>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ p: 2, textAlign: 'center', boxShadow: 3 }}>
                    <Typography variant="h6">Compras Sin Recibir</Typography>
                    <Typography variant="h4">{purchasesNotReceived}</Typography>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ p: 2, textAlign: 'center', boxShadow: 3 }}>
                    <Typography variant="h6">Compras Recibidas</Typography>
                    <Typography variant="h4">{totalPurchases - purchasesNotReceived}</Typography>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ p: 2, textAlign: 'center', boxShadow: 3 }}>
                    <Typography variant="h6">Porcentaje Sin Recibir</Typography>
                    <Typography variant="h4">{percentageNotReceived}%</Typography>
                </Card>
            </Grid>
        </Grid>
    );
};

// 4. LoadingSpinner Component
const LoadingSpinner = () => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60vh',
        }}
    >
        <CircularProgress />
    </Box>
);

// 5. ErrorMessage Component
const ErrorMessage = ({ message }) => (
    <Box sx={{ p: 3 }}>
        <Typography variant="h6" color="error">
            {message}
        </Typography>
    </Box>
);

// 6. Main PurchasesPage Component
const PurchasesPage = () => {
    const [purchases, setPurchases] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit] = useState(10);

    const [searchParams, setSearchParams] = useState({
        fecha_emision: '',
        search: '',
        folio: '',
        tipoDte: '',
        monto_total: '',
        notReceived: false, // Nuevo campo para el filtro
    });

    const [orderBy, setOrderBy] = useState('fecha_emision');
    const [orderDirection, setOrderDirection] = useState('desc');

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
    });

    const navigate = useNavigate();

    const [totalPurchases, setTotalPurchases] = useState(0);
    const [purchasesNotReceived, setPurchasesNotReceived] = useState(0);

    const debouncedFetchPurchases = useMemo(
        () =>
            debounce(async (currentPage = 1, params = {}) => {
                setLoading(true);
                setError(null);
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}/api/purchases`,
                        {
                            params: {
                                page: currentPage,
                                limit,
                                ...params,
                                orderBy,
                                orderDirection,
                            },
                        }
                    );
                    if (response.data?.purchases) {
                        setPurchases(response.data.purchases);
                        setTotalPages(response.data.totalPages);
                        setTotalPurchases(response.data.totalPurchases);
                        setPurchasesNotReceived(response.data.purchasesNotReceived);
                    }
                } catch (err) {
                    console.error('Error al cargar las compras:', err.response?.data || err.message);
                    setError(err.response?.data?.error || 'Error al cargar las compras.');
                }
                setLoading(false);
            }, 500),
        [limit, orderBy, orderDirection]
    );

    const fetchPurchases = useCallback(
        (currentPage = 1) => {
            debouncedFetchPurchases(currentPage, searchParams);
        },
        [debouncedFetchPurchases, searchParams]
    );

    const handlePageChange = (event, value) => {
        setPage(value);
        fetchPurchases(value);
    };

    const handleSearch = useCallback(() => {
        setPage(1);
        fetchPurchases(1);
    }, [fetchPurchases]);

    const importPurchases = async () => {
        setLoading(true);
        try {
            await axios.get(`${process.env.REACT_APP_API_URL}/api/purchases/import`);
            setSnackbar({
                open: true,
                message: 'Compras importadas exitosamente.',
                severity: 'success',
            });
            fetchPurchases(page);
        } catch (error) {
            console.error('Error al importar las compras:', error.response?.data || error.message);
            setSnackbar({
                open: true,
                message: 'Error al importar las compras.',
                severity: 'error',
            });
        }
        setLoading(false);
    };

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        fetchPurchases(page);
    };

    const goToPurchaseDetails = (id) => {
        if (id) {
            navigate(`/purchases/${id}`);
        } else {
            console.warn('ID de compra no válido:', id);
        }
    };

    useEffect(() => {
        fetchPurchases(page);
    }, [page, orderBy, orderDirection]);

    useEffect(() => {
        return () => {
            debouncedFetchPurchases.cancel();
        };
    }, [debouncedFetchPurchases]);

    useEffect(() => {
        handleSearch();
    }, [searchParams]);

    return (
        <Box sx={{ p: { xs: 2, md: 4 } }}>
            <Typography variant="h4" gutterBottom>
                Administración de Compras
            </Typography>

            <Box sx={{ mb: 3 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={importPurchases}
                >
                    Importar Compras desde el Correo
                </Button>
            </Box>

            <SearchBar
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />

            {/* Tarjetas de Resumen */}
            <SummaryCards 
                totalPurchases={totalPurchases} 
                purchasesNotReceived={purchasesNotReceived} 
            />

            {loading ? (
                <LoadingSpinner />
            ) : error ? (
                <ErrorMessage message={error} />
            ) : (
                <>
                    <PurchasesTable
                        purchases={purchases}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                        handleSortRequest={handleSortRequest}
                        goToPurchaseDetails={goToPurchaseDetails}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                        />
                    </Box>
                </>
            )}

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default PurchasesPage;
