// src/components/QuotationPage.js
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  InputAdornment,
  Divider,
  Snackbar,
  Alert,
  Tooltip,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  Search as SearchIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const VAT_RATE = 19;

const formatter = new Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'CLP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const QuotationPage = () => {
  const [customer, setCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [customerSearch, setCustomerSearch] = useState('');
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [selectedCustomerIndex, setSelectedCustomerIndex] = useState(-1);
  const [products, setProducts] = useState([]);
  const [productSearch, setProductSearch] = useState('');
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(-1);
  const [items, setItems] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [globalDiscount, setGlobalDiscount] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const customerSearchRef = useRef(null);
  const productSearchRef = useRef(null);

  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api`,
    timeout: 10000,
  });

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await axiosInstance.get('/settings/category/Payment%20Methods');
        setPaymentMethods(response.data || []);
      } catch (error) {
        console.error('Error al obtener métodos de pago:', error);
        setPaymentMethods([]);
      }
    };

    fetchPaymentMethods();
  }, []);

  const handleOpenCustomerModal = () => {
    setCustomerModalOpen(true);
    setCustomers([]);
    setCustomerSearch('');
    setSelectedCustomerIndex(-1);
    setTimeout(() => {
      if (customerSearchRef.current) {
        customerSearchRef.current.focus();
      }
    }, 100);
  };

  const handleCustomerSearch = async () => {
    try {
      const response = await axiosInstance.get('/customers', {
        params: { search: customerSearch },
      });
      setCustomers(response.data.data || []);
      setSelectedCustomerIndex(0);
    } catch (error) {
      console.error('Error al buscar clientes:', error);
      setCustomers([]);
    }
  };

  const handleSelectCustomer = (selectedCustomer) => {
    setCustomer(selectedCustomer);
    setCustomerModalOpen(false);
    setSelectedCustomerIndex(-1);
  };

  const handleKeyDownCustomer = (e) => {
    if (e.key === 'ArrowDown') {
      setSelectedCustomerIndex((prevIndex) => Math.min(prevIndex + 1, customers.length - 1));
    } else if (e.key === 'ArrowUp') {
      setSelectedCustomerIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (e.key === 'Enter') {
      if (customerSearch.trim() !== '') {
        handleCustomerSearch();
      } else if (selectedCustomerIndex >= 0) {
        handleSelectCustomer(customers[selectedCustomerIndex]);
      }
    }
  };

  const handleOpenProductModal = () => {
    setProductModalOpen(true);
    setProducts([]);
    setProductSearch('');
    setSelectedProductIndex(-1);
    setTimeout(() => {
      if (productSearchRef.current) {
        productSearchRef.current.focus();
      }
    }, 100);
  };

  const handleProductSearch = async () => {
    try {
      const response = await axiosInstance.get('/products/internal', {
        params: { search: productSearch },
      });
      setProducts(response.data.products || []);
      setSelectedProductIndex(0);
    } catch (error) {
      console.error('Error al buscar productos:', error);
      setProducts([]);
    }
  };

  const handleSelectProduct = (product) => {
    const existingItem = items.find((item) => item.product_id === product.id);
    const priceWithVAT = parseFloat(product.price);

    if (isNaN(priceWithVAT)) {
      console.error('Precio del producto inválido.');
      return;
    }

    const netPrice = parseFloat((priceWithVAT / (1 + VAT_RATE / 100)).toFixed(2));
    const vatAmount = parseFloat((priceWithVAT - netPrice).toFixed(2));

    if (existingItem) {
      const updatedItems = items.map((item) =>
        item.product_id === product.id
          ? {
              ...item,
              quantity: item.quantity + 1,
            }
          : item
      );
      setItems(updatedItems);
    } else {
      setItems([
        ...items,
        {
          product_id: product.id,
          name: product.name,
          sku: product.sku || '',
          quantity: 1,
          unit_price: priceWithVAT,
          net_price: netPrice,
          vat_amount: vatAmount,
          discount: 0,
        },
      ]);
    }
    setProductModalOpen(false);
    setSelectedProductIndex(-1);
  };

  const handleKeyDownProduct = (e) => {
    if (e.key === 'ArrowDown') {
      setSelectedProductIndex((prevIndex) => Math.min(prevIndex + 1, products.length - 1));
    } else if (e.key === 'ArrowUp') {
      setSelectedProductIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (e.key === 'Enter') {
      if (productSearch.trim() !== '') {
        handleProductSearch();
      } else if (selectedProductIndex >= 0) {
        handleSelectProduct(products[selectedProductIndex]);
      }
    }
  };

  const handleRemove = (product_id) => {
    const updatedItems = items.filter((item) => item.product_id !== product_id);
    setItems(updatedItems);
  };

  const handleItemDiscountChange = (product_id, discount) => {
    const updatedItems = items.map((item) =>
      item.product_id === product_id
        ? {
            ...item,
            discount: discount,
          }
        : item
    );
    setItems(updatedItems);
  };

  const handleGlobalDiscountChange = (e) => {
    let value = parseFloat(e.target.value);
    if (isNaN(value) || value < 0) value = 0;
    if (value > 100) value = 100;
    setGlobalDiscount(value);
  };

  const subtotal = items.reduce(
    (sum, item) =>
      sum +
      item.unit_price *
        item.quantity *
        (1 - item.discount / 100),
    0
  );

  const totalNetAmount = items.reduce(
    (sum, item) =>
      sum +
      item.net_price *
        item.quantity *
        (1 - item.discount / 100),
    0
  );

  const totalVATAmount = subtotal - totalNetAmount;

  const globalDiscountAmount = subtotal * (globalDiscount / 100);
  const netAmountAfterDiscount = totalNetAmount * (1 - globalDiscount / 100);
  const vatAmountAfterDiscount = netAmountAfterDiscount * (VAT_RATE / 100);
  const totalAmount = netAmountAfterDiscount + vatAmountAfterDiscount;

  const handleSaveQuotation = async () => {
    if (!customer) {
      setSnackbar({ open: true, message: 'Seleccione un cliente.', severity: 'warning' });
      return;
    }
    if (items.length === 0) {
      setSnackbar({ open: true, message: 'Agregue al menos un producto.', severity: 'warning' });
      return;
    }
    try {
      const quotationData = {
        customer_id: customer.id,
        payment_method: paymentMethod,
        global_discount: globalDiscount,
        items: items.map((item) => ({
          product_id: item.product_id,
          quantity: item.quantity,
          unit_price: item.unit_price,
          discount: item.discount,
        })),
      };
      const response = await axiosInstance.post('/quotations', quotationData);
      setSnackbar({ open: true, message: 'Cotización guardada exitosamente.', severity: 'success' });
      generatePDF(response.data.id);
    } catch (error) {
      console.error('Error al guardar la cotización:', error);
      setSnackbar({ open: true, message: 'Error al guardar la cotización.', severity: 'error' });
    }
  };

  const generatePDF = (quotationId) => {
    const doc = new jsPDF('p', 'pt');

    // Encabezado
    doc.setFontSize(12);
    // Detalles de la empresa
    doc.text('Nombre de la Empresa', 40, 50);
    doc.text('Dirección de la Empresa', 40, 65);
    doc.text('Teléfono: 123456789', 40, 80);
    doc.text('Email: empresa@correo.com', 40, 95);

    // Detalles del cliente
    doc.text('Datos del Cliente:', 400, 50);
    doc.text(`Nombre: ${customer.name}`, 400, 65);
    doc.text(`RUT: ${customer.tax_id}`, 400, 80);
    doc.text(`Método de Pago: ${paymentMethod}`, 400, 95);

    // Título de la cotización
    doc.setFontSize(16);
    doc.text('COTIZACIÓN', 300, 130, null, null, 'center');

    // Detalles de la cotización
    doc.setFontSize(12);
    doc.text(`Número de Cotización: ${quotationId}`, 40, 160);
    doc.text(`Fecha: ${new Date().toLocaleDateString()}`, 400, 160);

    // Tabla de productos
    autoTable(doc, {
      startY: 180,
      head: [
        ['Código', 'Descripción', 'Cantidad', 'Precio Unitario', 'Descuento', 'Total'],
      ],
      body: items.map((item) => {
        const itemTotal = item.unit_price * item.quantity * (1 - item.discount / 100);
        return [
          item.sku,
          item.name,
          item.quantity,
          formatter.format(item.unit_price),
          `${item.discount}%`,
          formatter.format(itemTotal),
        ];
      }),
      styles: { fontSize: 10 },
      headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255] },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 200 },
        2: { cellWidth: 60, halign: 'right' },
        3: { cellWidth: 80, halign: 'right' },
        4: { cellWidth: 70, halign: 'right' },
        5: { cellWidth: 80, halign: 'right' },
      },
    });

    const finalY = doc.lastAutoTable.finalY || 180;

    // Totales
    doc.setFontSize(12);
    doc.text(`Subtotal: ${formatter.format(subtotal)}`, 400, finalY + 20);
    doc.text(
      `Descuento Global (${globalDiscount}%): ${formatter.format(globalDiscountAmount)}`,
      400,
      finalY + 35
    );
    doc.text(
      `Total Neto: ${formatter.format(netAmountAfterDiscount)}`,
      400,
      finalY + 50
    );
    doc.text(
      `IVA (${VAT_RATE}%): ${formatter.format(vatAmountAfterDiscount)}`,
      400,
      finalY + 65
    );
    doc.text(`Total a Pagar: ${formatter.format(totalAmount)}`, 400, finalY + 80);

    // Observaciones
    doc.text('Observaciones:', 40, finalY + 110);
    doc.rect(40, finalY + 115, 515, 60); // Rectángulo para observaciones

    // Pie de página
    doc.setFontSize(10);
    doc.text('Gracias por su preferencia.', 300, 780, null, null, 'center');
    doc.text('Página 1 de 1', 550, 780, null, null, 'right');

    doc.save(`Cotizacion_${quotationId}.pdf`);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Crear Cotización
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Detalles de la Cotización
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  variant="outlined"
                  onClick={handleOpenCustomerModal}
                  startIcon={<SearchIcon />}
                  fullWidth
                >
                  Seleccionar Cliente
                </Button>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  {customer ? `${customer.name} (${customer.tax_id})` : 'Cliente no seleccionado'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="payment-method-label">Método de Pago</InputLabel>
                  <Select
                    labelId="payment-method-label"
                    value={paymentMethod}
                    label="Método de Pago"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                    {Array.isArray(paymentMethods) && paymentMethods.length > 0 ? (
                      paymentMethods.map((method) => (
                        <MenuItem key={method.key} value={method.value}>
                          {method.value}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No hay métodos de pago disponibles</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Button
              variant="contained"
              onClick={handleOpenProductModal}
              startIcon={<AddIcon />}
            >
              Agregar Producto
            </Button>

            <Table sx={{ mt: 2 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Producto</TableCell>
                  <TableCell>Código</TableCell>
                  <TableCell>Cantidad</TableCell>
                  <TableCell>Precio Unitario</TableCell>
                  <TableCell>Descuento (%)</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.product_id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.sku}</TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={item.quantity}
                        onChange={(e) =>
                          setItems((prevItems) =>
                            prevItems.map((i) =>
                              i.product_id === item.product_id
                                ? { ...i, quantity: parseInt(e.target.value) || 1 }
                                : i
                            )
                          )
                        }
                        inputProps={{ min: 1 }}
                        sx={{ width: '80px' }}
                      />
                    </TableCell>
                    <TableCell>{formatter.format(item.unit_price)}</TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={item.discount}
                        onChange={(e) =>
                          handleItemDiscountChange(
                            item.product_id,
                            parseFloat(e.target.value) || 0
                          )
                        }
                        inputProps={{ min: 0, max: 100 }}
                        sx={{ width: '80px' }}
                      />
                    </TableCell>
                    <TableCell>
                      {formatter.format(
                        item.unit_price *
                          item.quantity *
                          (1 - item.discount / 100)
                      )}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Eliminar Producto">
                        <IconButton onClick={() => handleRemove(item.product_id)} color="error">
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
                {items.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No hay productos agregados.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, position: 'sticky', top: '80px' }}>
            <Typography variant="h6" gutterBottom>
              Resumen
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1">
                Subtotal: {formatter.format(subtotal)}
              </Typography>
              <Typography variant="body1">
                Descuento Global (%):
                <TextField
                  type="number"
                  value={globalDiscount}
                  onChange={handleGlobalDiscountChange}
                  inputProps={{ min: 0, max: 100 }}
                  sx={{ ml: 1, width: '80px' }}
                />
              </Typography>
              <Typography variant="body1">
                Descuento Global: {formatter.format(globalDiscountAmount)}
              </Typography>
              <Typography variant="body1">
                Total Neto: {formatter.format(netAmountAfterDiscount)}
              </Typography>
              <Typography variant="body1">
                IVA ({VAT_RATE}%): {formatter.format(vatAmountAfterDiscount)}
              </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h5">
              Total a Pagar: {formatter.format(totalAmount)}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSaveQuotation}
              sx={{ mt: 3 }}
              fullWidth
            >
              Guardar Cotización
            </Button>
          </Paper>
        </Grid>
      </Grid>

      {/* Modal de Selección de Clientes */}
      <Dialog
        open={customerModalOpen}
        onClose={() => setCustomerModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Seleccionar Cliente</DialogTitle>
        <DialogContent>
          <TextField
            inputRef={customerSearchRef}
            value={customerSearch}
            onChange={(e) => setCustomerSearch(e.target.value)}
            variant="outlined"
            placeholder="Buscar cliente..."
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onKeyDown={handleKeyDownCustomer}
          />
          <List>
            {customers.map((cust, index) => (
              <ListItem key={cust.id} disablePadding>
                <ListItemButton
                  selected={index === selectedCustomerIndex}
                  onClick={() => handleSelectCustomer(cust)}
                >
                  <ListItemText primary={`${cust.name} (${cust.tax_id})`} />
                </ListItemButton>
              </ListItem>
            ))}
            {customers.length === 0 && (
              <ListItem>
                <ListItemText primary="No se encontraron clientes." />
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCustomerModalOpen(false)} startIcon={<CloseIcon />}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de Selección de Productos */}
      <Dialog
        open={productModalOpen}
        onClose={() => setProductModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Agregar Producto</DialogTitle>
        <DialogContent>
          <TextField
            inputRef={productSearchRef}
            value={productSearch}
            onChange={(e) => setProductSearch(e.target.value)}
            variant="outlined"
            placeholder="Buscar producto..."
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onKeyDown={handleKeyDownProduct}
          />
          <List>
            {products.map((prod, index) => (
              <ListItem key={prod.id} disablePadding>
                <ListItemButton
                  selected={index === selectedProductIndex}
                  onClick={() => handleSelectProduct(prod)}
                >
                  <ListItemText primary={`${prod.name} (SKU: ${prod.sku})`} />
                </ListItemButton>
              </ListItem>
            ))}
            {products.length === 0 && (
              <ListItem>
                <ListItemText primary="No se encontraron productos." />
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setProductModalOpen(false)} startIcon={<CloseIcon />}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default QuotationPage;
