// src/pages/IntegrationPage.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Checkbox,
  Pagination,
} from '@mui/material';
import { Link } from 'react-router-dom';

function IntegrationPage() {
  const [integrations, setIntegrations] = useState([]);
  const [selectedIntegrations, setSelectedIntegrations] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  // Función para obtener todas las integraciones
  const fetchAllIntegrations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/integrations/`, {
        params: {
          page,
          pageSize,
        },
      });

      if (response.data && response.data.length > 0) {
        setIntegrations(response.data);
        // Si necesitas paginación real basada en los resultados totales, ajusta esto:
        setTotalPages(Math.ceil(response.data.length / pageSize));
      } else {
        setIntegrations([]);
      }
    } catch (error) {
      console.error('Error al obtener las integraciones:', error);
      setIntegrations([]);
    }
  };

  useEffect(() => {
    fetchAllIntegrations();
  }, [page]);

  const handleSelectIntegration = (id) => {
    setSelectedIntegrations((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((iid) => iid !== id) : [...prevSelected, id]
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = integrations.map((integration) => integration.id);
      setSelectedIntegrations(newSelected);
    } else {
      setSelectedIntegrations([]);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/integrations/delete-multiple`, {
        ids: selectedIntegrations,
      });
      alert('Integraciones eliminadas correctamente');
      setSelectedIntegrations([]);
      setPage(1);
      fetchAllIntegrations();
    } catch (error) {
      console.error('Error al eliminar las integraciones:', error);
    }
  };

  return (
    <div>
      <h2>Integraciones</h2>

      {/* Botón para crear nueva integración */}
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-start' }}>
        <Button variant="contained" color="primary" component={Link} to="/integrations/create">
          Crear nueva integración
        </Button>
      </Box>

      {/* Mostrar un botón de eliminar si hay integraciones seleccionadas */}
      {selectedIntegrations.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Button variant="contained" color="error" onClick={handleDeleteSelected}>
            Eliminar {selectedIntegrations.length} integración(es) seleccionada(s)
          </Button>
        </Box>
      )}

      {/* Tabla para mostrar las integraciones */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectedIntegrations.length === integrations.length && integrations.length > 0}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Plataforma</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>URL de la Tienda</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrations.length > 0 ? (
              integrations.map((integration) => (
                <TableRow key={integration.id}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIntegrations.includes(integration.id)}
                      onChange={() => handleSelectIntegration(integration.id)}
                    />
                  </TableCell>
                  <TableCell>{integration.id}</TableCell>
                  <TableCell>{integration.integration_name}</TableCell>
                  <TableCell>{integration.platform || 'No asignada'}</TableCell>
                  <TableCell>{integration.status}</TableCell>
                  <TableCell>{integration.store_url || 'N/A'}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/integrations/view/${integration.id}`}
                      sx={{ marginRight: 1 }}
                    >
                      Ver
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      component={Link}
                      to={`/integrations/edit/${integration.id}`}
                    >
                      Editar
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>No se encontraron integraciones.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Paginación */}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </Box>
    </div>
  );
}

export default IntegrationPage;
