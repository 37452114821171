import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Box,
  Grid,
  Select,
  MenuItem,
  IconButton,
  Input,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

function ProductCreate() {
  const [productData, setProductData] = useState({
    product: { name: '', sku: '', description: '', price: '' },
    cost: { cost: '', method_name: '', effective_date: '' },
    suppliers: [],
    stock: [],
  });
  const [suppliers, setSuppliers] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState(false); // Estado para el mensaje de éxito

  const navigate = useNavigate(); // Hook para redireccionar después de crear el producto

  useEffect(() => {
    async function fetchSuppliers() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/suppliers`);
        setSuppliers(response.data);
      } catch (error) {
        console.error('Error al obtener los proveedores:', error);
      }
    }
    fetchSuppliers();
  }, []);

  const handleChange = (e, section) => {
    setProductData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [e.target.name]: e.target.value,
      },
    }));
  };

  const handleSupplierChange = (index, e) => {
    const updatedSuppliers = [...productData.suppliers];
    updatedSuppliers[index][e.target.name] = e.target.value;
    setProductData({ ...productData, suppliers: updatedSuppliers });
  };

  const addSupplier = () => {
    setProductData((prevData) => ({
      ...prevData,
      suppliers: [
        ...prevData.suppliers,
        { supplier_name: '', supplier_price: '', supplier_sku: '' },
      ],
    }));
  };

  const removeSupplier = (index) => {
    const updatedSuppliers = [...productData.suppliers];
    updatedSuppliers.splice(index, 1);
    setProductData({ ...productData, suppliers: updatedSuppliers });
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const validateForm = () => {
    let newErrors = {};
    if (!productData.product.name) newErrors.name = 'El nombre es obligatorio';
    if (!productData.product.sku) newErrors.sku = 'El SKU es obligatorio';
    if (!productData.product.price) newErrors.price = 'El precio es obligatorio';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/products`, productData.product);
      const newProductId = response.data.id; // Obtenemos el ID del nuevo producto creado

      setSuccessMessage(true); // Mostramos el mensaje de éxito

      // Redirigir después de 3 segundos al detalle del producto recién creado
      setTimeout(() => {
        navigate(`/products/view/${newProductId}`);
      }, 3000);
    } catch (error) {
      console.error('Error al crear el producto:', error);
      alert('Error al crear el producto');
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={12} sm={8} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Crear Producto
            </Typography>
            <Tabs value={tabIndex} onChange={handleTabChange} centered>
              <Tab label="Detalles Básicos" />
              <Tab label="Costo" />
              <Tab label="Proveedores" />
              <Tab label="Inventario" />
              <Tab label="Imágenes" />
            </Tabs>

            <form onSubmit={handleSubmit}>
              {/* Pestaña: Detalles Básicos */}
              {tabIndex === 0 && (
                <Box>
                  <TextField
                    label="Nombre"
                    name="name"
                    value={productData.product.name}
                    onChange={(e) => handleChange(e, 'product')}
                    fullWidth
                    margin="normal"
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                  <TextField
                    label="SKU"
                    name="sku"
                    value={productData.product.sku}
                    onChange={(e) => handleChange(e, 'product')}
                    fullWidth
                    margin="normal"
                    error={!!errors.sku}
                    helperText={errors.sku}
                  />
                  <TextField
                    label="Descripción"
                    name="description"
                    value={productData.product.description}
                    onChange={(e) => handleChange(e, 'product')}
                    fullWidth
                    margin="normal"
                    multiline
                    minRows={3}
                  />
                  <TextField
                    label="Precio"
                    name="price"
                    value={productData.product.price}
                    onChange={(e) => handleChange(e, 'product')}
                    fullWidth
                    margin="normal"
                    type="number"
                    error={!!errors.price}
                    helperText={errors.price}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Box>
              )}

              {/* Pestaña: Costo */}
              {tabIndex === 1 && (
                <Box>
                  <TextField
                    label="Costo"
                    name="cost"
                    value={productData.cost.cost}
                    onChange={(e) => handleChange(e, 'cost')}
                    fullWidth
                    margin="normal"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                  <TextField
                    label="Método de Costeo"
                    name="method_name"
                    value={productData.cost.method_name}
                    onChange={(e) => handleChange(e, 'cost')}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Fecha de Vigencia"
                    name="effective_date"
                    value={productData.cost.effective_date}
                    onChange={(e) => handleChange(e, 'cost')}
                    fullWidth
                    margin="normal"
                    type="date"
                  />
                </Box>
              )}

              {/* Pestaña: Proveedores */}
              {tabIndex === 2 && (
                <Box>
                  {productData.suppliers.map((supplier, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <Select
                        label="Nombre del Proveedor"
                        value={supplier.supplier_id || ''}
                        onChange={(e) => handleSupplierChange(index, e)}
                        fullWidth
                      >
                        {suppliers.map((supplierOption) => (
                          <MenuItem key={supplierOption.id} value={supplierOption.id}>
                            {supplierOption.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <TextField
                        label="Precio del Proveedor"
                        name="supplier_price"
                        value={supplier.supplier_price}
                        onChange={(e) => handleSupplierChange(index, e)}
                        fullWidth
                        margin="normal"
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                      />
                      <TextField
                        label="SKU del Proveedor"
                        name="supplier_sku"
                        value={supplier.supplier_sku}
                        onChange={(e) => handleSupplierChange(index, e)}
                        fullWidth
                        margin="normal"
                      />
                      <IconButton onClick={() => removeSupplier(index)} color="secondary">
                        <Remove />
                      </IconButton>
                    </Box>
                  ))}
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Add />}
                    onClick={addSupplier}
                  >
                    Agregar Proveedor
                  </Button>
                </Box>
              )}

              {/* Pestaña: Inventario */}
              {tabIndex === 3 && (
                <Box>
                  <TextField
                    label="Almacén"
                    name="warehouse_name"
                    value=""
                    fullWidth
                    margin="normal"
                    disabled
                  />
                  <TextField
                    label="Cantidad en Stock"
                    name="stock_quantity"
                    value=""
                    fullWidth
                    margin="normal"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                  <TextField
                    label="Stock Mínimo"
                    name="minimum_stock"
                    value=""
                    fullWidth
                    margin="normal"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Box>
              )}

              {/* Pestaña: Imágenes */}
              {tabIndex === 4 && (
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Imágenes del Producto
                  </Typography>

                  <Box>
                    <Input type="file" multiple onChange={handleFileChange} />
                    <Box sx={{ mt: 2 }}>
                      {selectedFiles.map((file, index) => (
                        <Typography key={index} variant="body2">
                          {file.name}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Box>
              )}

              <Button variant="contained" color="primary" type="submit" fullWidth>
                Crear Producto
              </Button>
            </form>
          </CardContent>
        </Card>
      </Grid>

      {/* Mensaje de éxito */}
      <Snackbar
        open={successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage(false)}
      >
        <Alert onClose={() => setSuccessMessage(false)} severity="success">
          ¡Producto creado exitosamente!
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default ProductCreate;
