// SuppliersPage.js
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Pagination,
  Snackbar,
  Alert,
  Grid,
  TextField,
  MenuItem,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';

/**
 * Servicio para interactuar con la API de proveedores
 */
const API_URL = process.env.REACT_APP_API_URL;

// Función para obtener proveedores
const getSuppliers = (params) => {
  return axios.get(`${API_URL}/api/suppliers`, { params });
};

// Función para crear un nuevo proveedor
const createSupplier = (supplierData) => {
  return axios.post(`${API_URL}/api/suppliers`, supplierData);
};

// Función para actualizar un proveedor existente
const updateSupplier = (tax_id, supplierData) => {
  return axios.put(`${API_URL}/api/suppliers/${tax_id}`, supplierData);
};

// Función para eliminar un proveedor
const deleteSupplierApi = (tax_id) => {
  return axios.delete(`${API_URL}/api/suppliers/${tax_id}`);
};

/**
 * Componente SearchBar
 */
const SearchBar = React.memo(({ searchParams, setSearchParams, onSearch }) => {
  const handleChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  const handleReset = () => {
    const resetParams = {
      name: '',
      tax_id: '',
      status: '',
      contact_person: '',
      email: '',
    };
    setSearchParams(resetParams);
    onSearch(resetParams);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchParams);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Nombre"
            variant="outlined"
            name="name"
            value={searchParams.name}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="RUT"
            variant="outlined"
            name="tax_id"
            value={searchParams.tax_id}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            select
            label="Estado"
            variant="outlined"
            name="status"
            value={searchParams.status}
            onChange={handleChange}
            fullWidth
          >
            <MenuItem value="">
              <em>Todos</em>
            </MenuItem>
            <MenuItem value="Activo">Activo</MenuItem>
            <MenuItem value="Inactivo">Inactivo</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Contacto"
            variant="outlined"
            name="contact_person"
            value={searchParams.contact_person}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            value={searchParams.email}
            onChange={handleChange}
            fullWidth
            type="email"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            Buscar
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleReset}
            fullWidth
          >
            Resetear
          </Button>
        </Grid>
      </Grid>
    </form>
  );
});

/**
 * Componente SuppliersTable
 */
const SuppliersTable = React.memo(({
  suppliers,
  orderBy,
  orderDirection,
  handleSortRequest,
  onEditSupplier,
  onDeleteSupplier,
  onViewProducts,
}) => {
  const createSortHandler = (property) => () => {
    handleSortRequest(property);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'name'}
                direction={orderDirection}
                onClick={createSortHandler('name')}
              >
                Nombre
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'tax_id'}
                direction={orderDirection}
                onClick={createSortHandler('tax_id')}
              >
                RUT
              </TableSortLabel>
            </TableCell>
            <TableCell>Dirección</TableCell>
            <TableCell>Teléfono</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Contacto</TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'status'}
                direction={orderDirection}
                onClick={createSortHandler('status')}
              >
                Estado
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {suppliers.length > 0 ? (
            suppliers.map((supplier) => (
              <TableRow
                key={supplier.tax_id}
                hover
                sx={{ cursor: 'pointer' }}
                onClick={(e) => {
                  if (
                    e.target.closest('button') ||
                    e.target.closest('svg')
                  ) {
                    return;
                  }
                  onViewProducts(supplier);
                }}
              >
                <TableCell>{supplier.name}</TableCell>
                <TableCell>{supplier.tax_id}</TableCell>
                <TableCell>{supplier.address}</TableCell>
                <TableCell>{supplier.phone}</TableCell>
                <TableCell>{supplier.email}</TableCell>
                <TableCell>{supplier.contact_person}</TableCell>
                <TableCell>{supplier.status}</TableCell>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEditSupplier(supplier);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteSupplier(supplier);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} align="center">
                No se encontraron proveedores
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

/**
 * Componente SupplierForm
 */
const SupplierForm = React.memo(({ open, onClose, supplier, onSave }) => {
  const [formValues, setFormValues] = useState({
    company_id: '',
    name: '',
    tax_id: '',
    address: '',
    phone: '',
    email: '',
    contact_person: '',
    status: 'Activo',
    country: '',
  });

  useEffect(() => {
    if (supplier) {
      setFormValues({
        company_id: supplier.company_id || '',
        name: supplier.name || '',
        tax_id: supplier.tax_id || '',
        address: supplier.address || '',
        phone: supplier.phone || '',
        email: supplier.email || '',
        contact_person: supplier.contact_person || '',
        status: supplier.status || 'Activo',
        country: supplier.country || '',
      });
    } else {
      setFormValues({
        company_id: '',
        name: '',
        tax_id: '',
        address: '',
        phone: '',
        email: '',
        contact_person: '',
        status: 'Activo',
        country: '',
      });
    }
  }, [supplier]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    onSave(formValues);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{supplier ? 'Editar Proveedor' : 'Agregar Proveedor'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="RUT"
              name="tax_id"
              value={formValues.tax_id}
              onChange={handleChange}
              fullWidth
              required
              disabled={!!supplier}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Dirección"
              name="address"
              value={formValues.address}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Teléfono"
              name="phone"
              value={formValues.phone}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              fullWidth
              required
              type="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Contacto"
              name="contact_person"
              value={formValues.contact_person}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="País"
              name="country"
              value={formValues.country}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              label="Estado"
              name="status"
              value={formValues.status}
              onChange={handleChange}
              fullWidth
              required
            >
              <MenuItem value="Activo">Activo</MenuItem>
              <MenuItem value="Inactivo">Inactivo</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
});

/**
 * Componente LoadingSpinner
 */
const LoadingSpinner = React.memo(() => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '60vh',
    }}
  >
    <CircularProgress />
  </Box>
));

/**
 * Componente ErrorMessage
 */
const ErrorMessage = React.memo(({ message }) => (
  <Box sx={{ p: 3 }}>
    <Typography variant="h6" color="error" align="center">
      {message}
    </Typography>
  </Box>
));

/**
 * Hook personalizado para manejar la lógica de proveedores
 */
const useSuppliers = (searchParams, orderBy, orderDirection, limit = 10) => {
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Función para obtener proveedores
  const fetchSuppliers = useCallback(
    async (currentPage = 1, params = {}) => {
      setLoading(true);
      setError(null);
      try {
        const response = await getSuppliers({
          page: currentPage,
          limit,
          ...params,
          orderBy,
          orderDirection,
        });
        if (response.data?.suppliers) {
          setSuppliers(response.data.suppliers);
          setTotalPages(response.data.totalPages);
        } else {
          setSuppliers(response.data);
          setTotalPages(1);
        }
      } catch (err) {
        console.error('Error al cargar los proveedores:', err.response?.data || err.message);
        setError(err.response?.data?.error || 'Error al cargar los proveedores.');
      }
      setLoading(false);
    },
    [limit, orderBy, orderDirection]
  );

  // Debounce para optimizar las llamadas a la API
  const debouncedFetch = useMemo(
    () => debounce(fetchSuppliers, 500),
    [fetchSuppliers]
  );

  // Efecto para cargar proveedores cuando cambian los parámetros de búsqueda u orden
  useEffect(() => {
    debouncedFetch(page, searchParams);
    return () => {
      debouncedFetch.cancel();
    };
  }, [debouncedFetch, page, searchParams]);

  // Función para cambiar de página
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return {
    suppliers,
    loading,
    error,
    page,
    totalPages,
    handlePageChange,
    fetchSuppliers,
  };
};

/**
 * Componente Principal SuppliersPage
 */
const SuppliersPage = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useState({
    name: '',
    tax_id: '',
    status: '',
    contact_person: '',
    email: '',
  });

  const [orderBy, setOrderBy] = useState('name');
  const [orderDirection, setOrderDirection] = useState('asc');

  const {
    suppliers,
    loading,
    error,
    page,
    totalPages,
    handlePageChange,
  } = useSuppliers(searchParams, orderBy, orderDirection);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [currentSupplier, setCurrentSupplier] = useState(null);

  // Manejar ordenación por columnas
  const handleSortRequest = useCallback(
    (property) => {
      const isAsc = orderBy === property && orderDirection === 'asc';
      setOrderDirection(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      handlePageChange(page); // Refrescar la lista con el nuevo orden
    },
    [orderBy, orderDirection, handlePageChange, page]
  );

  // Abrir formulario para agregar un nuevo proveedor
  const handleAddSupplier = () => {
    setCurrentSupplier(null);
    setIsFormOpen(true);
  };

  // Abrir formulario para editar un proveedor existente
  const handleEditSupplier = (supplier) => {
    setCurrentSupplier(supplier);
    setIsFormOpen(true);
  };

  // Eliminar un proveedor
  const handleDeleteSupplier = async (supplier) => {
    if (window.confirm(`¿Está seguro de que desea eliminar al proveedor ${supplier.name}?`)) {
      try {
        await deleteSupplierApi(supplier.tax_id);
        setSnackbar({
          open: true,
          message: 'Proveedor eliminado exitosamente.',
          severity: 'success',
        });
        // Refrescar la lista de proveedores
        handlePageChange(page);
      } catch (err) {
        console.error('Error al eliminar el proveedor:', err.response?.data || err.message);
        setSnackbar({
          open: true,
          message: err.response?.data?.error || 'Error al eliminar el proveedor.',
          severity: 'error',
        });
      }
    }
  };

  // Guardar un proveedor (nuevo o editado)
  const handleSaveSupplier = async (supplierData) => {
    try {
      if (currentSupplier) {
        // Editar proveedor existente
        await updateSupplier(currentSupplier.tax_id, supplierData);
        setSnackbar({
          open: true,
          message: 'Proveedor actualizado exitosamente.',
          severity: 'success',
        });
      } else {
        // Crear nuevo proveedor
        await createSupplier(supplierData);
        setSnackbar({
          open: true,
          message: 'Proveedor creado exitosamente.',
          severity: 'success',
        });
      }
      setIsFormOpen(false);
      // Refrescar la lista de proveedores
      handlePageChange(page);
    } catch (err) {
      console.error('Error al guardar el proveedor:', err.response?.data || err.message);
      setSnackbar({
        open: true,
        message: err.response?.data?.error || 'Error al guardar el proveedor.',
        severity: 'error',
      });
    }
  };

  // Manejar la búsqueda
  const handleSearch = useCallback(
    (params) => {
      setSearchParams(params);
      handlePageChange(1);
    },
    [handlePageChange]
  );

  // Manejar la visualización de productos del proveedor
  const handleViewProducts = (supplier) => {
    navigate(`/suppliers/${supplier.tax_id}`);
  };

  return (
    <Box sx={{ p: { xs: 2, md: 4 } }}>
      <Typography variant="h4" gutterBottom>
        Administración de Proveedores
      </Typography>

      {/* Botón para agregar proveedor */}
      <Box sx={{ mb: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddSupplier}
          startIcon={<AddIcon />}
        >
          Agregar Proveedor
        </Button>
      </Box>

      {/* Barra de Búsqueda y Filtros */}
      <SearchBar
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onSearch={handleSearch}
      />

      {/* Indicador de Carga */}
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <ErrorMessage message={error} />
      ) : (
        <>
          {/* Tabla de Proveedores */}
          <SuppliersTable
            suppliers={suppliers}
            orderBy={orderBy}
            orderDirection={orderDirection}
            handleSortRequest={handleSortRequest}
            onEditSupplier={handleEditSupplier}
            onDeleteSupplier={handleDeleteSupplier}
            onViewProducts={handleViewProducts}
          />

          {/* Paginación */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={(e, value) => handlePageChange(value)}
              color="primary"
              variant="outlined"
              shape="rounded"
            />
          </Box>
        </>
      )}

      {/* Formulario de Proveedor */}
      <SupplierForm
        open={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        supplier={currentSupplier}
        onSave={handleSaveSupplier}
      />

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SuppliersPage;
