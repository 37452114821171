// src/pages/WarehousesPage.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Checkbox,
  Pagination,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  Alert,
  IconButton,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add'; // Added import

function WarehousesPage() {
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [openBulkDeleteDialog, setOpenBulkDeleteDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Función para obtener todas las bodegas
  const fetchWarehouses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/warehouses`, {
        params: {
          page,
          pageSize,
          search: searchQuery,
        },
      });

      if (response.data) {
        setWarehouses(response.data.warehouses);
        setTotalPages(response.data.totalPages);
      } else {
        setWarehouses([]);
        setTotalPages(1);
      }
    } catch (error) {
      console.error('Error al obtener las bodegas:', error);
      setWarehouses([]);
      setTotalPages(1);
      setSnackbar({
        open: true,
        message: 'Error al cargar las bodegas.',
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    fetchWarehouses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchQuery]);

  const handleSelectWarehouse = (id) => {
    setSelectedWarehouses((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((wid) => wid !== id) : [...prevSelected, id]
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = warehouses.map((warehouse) => warehouse.id);
      setSelectedWarehouses(newSelected);
    } else {
      setSelectedWarehouses([]);
    }
  };

  const handleDeleteWarehouse = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const confirmDeleteWarehouse = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/warehouses/${deleteId}`);
      setSnackbar({
        open: true,
        message: 'Bodega eliminada correctamente',
        severity: 'success',
      });
      setOpenDeleteDialog(false);
      setDeleteId(null);
      fetchWarehouses();
    } catch (error) {
      console.error('Error al eliminar la bodega:', error);
      setSnackbar({
        open: true,
        message: 'Error al eliminar la bodega',
        severity: 'error',
      });
    }
  };

  const handleBulkDelete = () => {
    setOpenBulkDeleteDialog(true);
  };

  const confirmBulkDelete = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/warehouses/bulk-delete`, {
        ids: selectedWarehouses,
      });
      setSnackbar({
        open: true,
        message: 'Bodegas eliminadas correctamente',
        severity: 'success',
      });
      setOpenBulkDeleteDialog(false);
      setSelectedWarehouses([]);
      fetchWarehouses();
    } catch (error) {
      console.error('Error al eliminar las bodegas:', error);
      setSnackbar({
        open: true,
        message: 'Error al eliminar las bodegas',
        severity: 'error',
      });
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Gestión de Bodegas
      </Typography>

      {/* Barra de Búsqueda y Botón de Crear */}
      <Toolbar sx={{ justifyContent: 'space-between', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <SearchIcon color="action" sx={{ mr: 1 }} />
          <TextField
            label="Buscar Bodegas"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            size="small"
          />
        </Box>
        <Button variant="contained" color="primary" component={Link} to="/warehouses/create" startIcon={<AddIcon />}>
          Crear Nueva Bodega
        </Button>
      </Toolbar>

      {/* Botón para eliminar seleccionadas */}
      {selectedWarehouses.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleBulkDelete}>
            Eliminar {selectedWarehouses.length} Bodega(s) Seleccionada(s)
          </Button>
        </Box>
      )}

      {/* Tabla para mostrar las bodegas */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedWarehouses.length === warehouses.length && warehouses.length > 0}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Ubicación</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {warehouses.length > 0 ? (
              warehouses.map((warehouse) => (
                <TableRow key={warehouse.id} hover>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedWarehouses.includes(warehouse.id)}
                      onChange={() => handleSelectWarehouse(warehouse.id)}
                    />
                  </TableCell>
                  <TableCell>{warehouse.id}</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={`/warehouses/${warehouse.id}`}
                      color="primary"
                      startIcon={<VisibilityIcon />}
                      variant="text"
                    >
                      {warehouse.name}
                    </Button>
                  </TableCell>
                  <TableCell>{warehouse.location}</TableCell>
                  <TableCell>{warehouse.description}</TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      color={warehouse.status === 'activo' ? 'green' : 'red'}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {warehouse.status}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Editar Bodega">
                      <IconButton
                        component={Link}
                        to={`/warehouses/edit/${warehouse.id}`}
                        color="primary"
                        sx={{ mr: 1 }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar Bodega">
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteWarehouse(warehouse.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No se encontraron bodegas.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Paginación */}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </Box>

      {/* Diálogo de Confirmación para Eliminar */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Typography>¿Estás seguro de que deseas eliminar esta bodega?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancelar</Button>
          <Button onClick={confirmDeleteWarehouse} color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo de Confirmación para Eliminación Masiva */}
      <Dialog open={openBulkDeleteDialog} onClose={() => setOpenBulkDeleteDialog(false)}>
        <DialogTitle>Confirmar Eliminación Masiva</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro de que deseas eliminar las {selectedWarehouses.length} bodegas seleccionadas? Esta acción no se puede deshacer.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenBulkDeleteDialog(false)}>Cancelar</Button>
          <Button onClick={confirmBulkDelete} color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para Notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default WarehousesPage;
