// src/pages/tabs/IntegrationsTab.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  FormControlLabel,
  Button,
} from '@mui/material';
import axios from 'axios';
import { Sync as SyncIcon } from '@mui/icons-material';
import { Edit as EditIcon } from '@mui/icons-material';

const IntegrationsTab = ({ productId, setSnackbar }) => {
  const [integrations, setIntegrations] = useState({});
  const [availableIntegrations, setAvailableIntegrations] = useState([]);

  // Obtener tenantId
  const tenantId = localStorage.getItem('tenantId') || 'default';

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        // Obtener detalles del producto
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/${productId}/details`, {
          headers: {
            'x-tenant-id': tenantId,
          },
        });
        const productDetails = response.data;
        setIntegrations(productDetails.integrations || {});
      } catch (error) {
        console.error('Error al cargar los detalles del producto:', error);
        setSnackbar({
          open: true,
          message: 'Error al cargar las integraciones del producto.',
          severity: 'error',
        });
      }
    };

    const fetchAvailableIntegrations = async () => {
      try {
        // Obtener integraciones disponibles
        const integrationsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/integrations`, {
          headers: {
            'x-tenant-id': tenantId,
          },
        });
        setAvailableIntegrations(integrationsResponse.data);
      } catch (error) {
        console.error('Error al cargar las integraciones disponibles:', error);
        setSnackbar({
          open: true,
          message: 'Error al cargar las integraciones disponibles.',
          severity: 'error',
        });
      }
    };

    fetchProductDetails();
    fetchAvailableIntegrations();
  }, [productId, setSnackbar, tenantId]);

  const handleIntegrationChange = async (integrationName, enabled) => {
    try {
      if (enabled) {
        // Activar integración: llamar al endpoint de sincronización para asociar y sincronizar el producto
        await axios.post(`${process.env.REACT_APP_API_URL}/api/integrations/sync/${integrationName}/${productId}`, {}, {
          headers: {
            'x-tenant-id': tenantId,
          },
        });

        // Actualizar el estado de las integraciones
        setIntegrations((prevIntegrations) => {
          const updatedIntegrations = { ...prevIntegrations };
          updatedIntegrations[integrationName] = [{
            sync_status: 'synced',
            last_synced: new Date().toISOString(),
            integration_name: integrationName.charAt(0).toUpperCase() + integrationName.slice(1),
            external_product_id: null, // Puedes obtener esto de la respuesta si está disponible
          }];
          return updatedIntegrations;
        });

        setSnackbar({
          open: true,
          message: `Integración con ${integrationName} activada y sincronizada correctamente.`,
          severity: 'success',
        });
      } else {
        // Desactivar integración: llamar al endpoint DELETE para eliminar la asociación
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/integrations/${integrationName}/${productId}`, {
          headers: {
            'x-tenant-id': tenantId,
          },
        });

        // Actualizar el estado de las integraciones
        setIntegrations((prevIntegrations) => {
          const updatedIntegrations = { ...prevIntegrations };
          delete updatedIntegrations[integrationName];
          return updatedIntegrations;
        });

        setSnackbar({
          open: true,
          message: `Integración con ${integrationName} desactivada correctamente.`,
          severity: 'success',
        });
      }
    } catch (error) {
      console.error(`Error al ${enabled ? 'activar' : 'desactivar'} la integración con ${integrationName}:`, error);
      setSnackbar({
        open: true,
        message: `Error al ${enabled ? 'activar' : 'desactivar'} la integración con ${integrationName}.`,
        severity: 'error',
      });
    }
  };

  const handleManualSync = async (integrationName) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/integrations/sync/${integrationName}/${productId}`, {}, {
        headers: {
          'x-tenant-id': tenantId,
        },
      });
      setIntegrations((prevIntegrations) => ({
        ...prevIntegrations,
        [integrationName]: [{
          ...prevIntegrations[integrationName][0],
          sync_status: 'synced',
          last_synced: new Date().toISOString(),
        }],
      }));
      setSnackbar({
        open: true,
        message: `Sincronización con ${integrationName} completada.`,
        severity: 'success',
      });
    } catch (error) {
      console.error(`Error al sincronizar con ${integrationName}:`, error);
      setSnackbar({
        open: true,
        message: `Error al sincronizar con ${integrationName}.`,
        severity: 'error',
      });
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Integraciones</Typography>
      <Grid container spacing={3}>
        {availableIntegrations.map((integration) => {
          const integrationName = integration.platform.toLowerCase();
          const integrationData = integrations[integrationName];
          const isEnabled = Array.isArray(integrationData) && integrationData.length > 0;
          const integrationInfo = isEnabled ? integrationData[0] : null;
          const externalProductId = integrationInfo ? integrationInfo.external_product_id : null;
          const productEditUrl = integration.product_edit_url;

          return (
            <Grid item xs={12} md={6} key={integrationName}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {integration.integration_name}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isEnabled}
                        onChange={(e) => handleIntegrationChange(integrationName, e.target.checked)}
                        color="primary"
                      />
                    }
                    label={isEnabled ? 'Activado' : 'Desactivado'}
                  />
                  {isEnabled && integrationInfo && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2">
                        Estado de Sincronización: {integrationInfo.sync_status}
                      </Typography>
                      <Typography variant="body2">
                        Última Sincronización: {integrationInfo.last_synced ? new Date(integrationInfo.last_synced).toLocaleString() : 'Nunca'}
                      </Typography>
                      <Typography variant="body2">
                        ID del Producto: {externalProductId || 'N/A'}
                      </Typography>
                      {productEditUrl && externalProductId && (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ mt: 1, mr: 1 }}
                          startIcon={<EditIcon />}
                          href={`${productEditUrl}${externalProductId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Editar en {integration.integration_name}
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ mt: 1 }}
                        onClick={() => handleManualSync(integrationName)}
                        startIcon={<SyncIcon />}
                      >
                        Sincronizar Ahora
                      </Button>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default IntegrationsTab;
