// src/pages/CustomersPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Pagination,
  Tooltip,
  Grid,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

const CustomersPage = () => {
  const [customers, setCustomers] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    totalPages: 0,
    currentPage: 1,
    limit: 10,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // States para manejo de diálogos
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState('create'); // 'create' or 'edit'
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  // States para manejo de formularios
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    tax_id: '',
    address: '',
    commune: '',
    city: '',
    country: 'Chile',
    postal_code: '',
    business_line: '',
    contact_person: '',
    additional_info: '',
  });

  // States para manejo de notificaciones
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // 'success', 'error', 'warning', 'info'
  });

  // Configurar axios con la URL base
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  // Función para obtener la lista de clientes
  const fetchCustomers = async (page = 1, limit = 10, search = '') => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/api/customers', {
        params: {
          page,
          limit,
          search,
        },
      });
      setCustomers(response.data.data);
      setPagination(response.data.pagination);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching customers:', err);
      setError('Error al obtener los clientes.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers(pagination.currentPage, pagination.limit, searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage, pagination.limit, searchTerm]);

  // Manejo de cambios en el formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Abrir diálogo para crear o editar
  const handleOpenDialog = (mode, customer = null) => {
    setDialogMode(mode);
    if (mode === 'edit' && customer) {
      setSelectedCustomer(customer);
      setFormData({
        name: customer.name || '',
        email: customer.email || '',
        phone: customer.phone || '',
        tax_id: customer.tax_id || '',
        address: customer.address || '',
        commune: customer.commune || '',
        city: customer.city || '',
        country: customer.country || 'Chile',
        postal_code: customer.postal_code || '',
        business_line: customer.business_line || '',
        contact_person: customer.contact_person || '',
        additional_info: customer.additional_info || '',
      });
    } else {
      setSelectedCustomer(null);
      setFormData({
        name: '',
        email: '',
        phone: '',
        tax_id: '',
        address: '',
        commune: '',
        city: '',
        country: 'Chile',
        postal_code: '',
        business_line: '',
        contact_person: '',
        additional_info: '',
      });
    }
    setOpenDialog(true);
  };

  // Cerrar diálogo
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCustomer(null);
    setFormData({
      name: '',
      email: '',
      phone: '',
      tax_id: '',
      address: '',
      commune: '',
      city: '',
      country: 'Chile',
      postal_code: '',
      business_line: '',
      contact_person: '',
      additional_info: '',
    });
  };

  // Enviar formulario para crear o editar
  const handleSubmit = async () => {
    // Validación básica
    if (!formData.name || !formData.email) {
      setSnackbar({
        open: true,
        message: 'El nombre y el email son obligatorios.',
        severity: 'warning',
      });
      return;
    }

    try {
      if (dialogMode === 'create') {
        // Crear nuevo cliente
        const response = await axiosInstance.post('/api/customers', formData);
        setCustomers([response.data, ...customers]);
        setSnackbar({
          open: true,
          message: 'Cliente creado exitosamente.',
          severity: 'success',
        });
      } else if (dialogMode === 'edit' && selectedCustomer) {
        // Editar cliente existente
        const response = await axiosInstance.put(`/api/customers/${selectedCustomer.id}`, formData);
        const updatedCustomers = customers.map((cust) =>
          cust.id === selectedCustomer.id ? response.data : cust
        );
        setCustomers(updatedCustomers);
        setSnackbar({
          open: true,
          message: 'Cliente actualizado exitosamente.',
          severity: 'success',
        });
      }
      handleCloseDialog();
    } catch (err) {
      console.error('Error al guardar el cliente:', err);
      const errorMsg =
        err.response && err.response.data && err.response.data.error
          ? err.response.data.error
          : 'Error al guardar el cliente.';
      setSnackbar({
        open: true,
        message: errorMsg,
        severity: 'error',
      });
    }
  };

  // Eliminar cliente
  const handleDelete = async (customer) => {
    if (!window.confirm(`¿Estás seguro de eliminar a ${customer.name}?`)) return;

    try {
      await axiosInstance.delete(`/api/customers/${customer.id}`);
      const updatedCustomers = customers.filter((cust) => cust.id !== customer.id);
      setCustomers(updatedCustomers);
      setSnackbar({
        open: true,
        message: 'Cliente eliminado exitosamente.',
        severity: 'success',
      });
    } catch (err) {
      console.error('Error al eliminar el cliente:', err);
      const errorMsg =
        err.response && err.response.data && err.response.data.error
          ? err.response.data.error
          : 'Error al eliminar el cliente.';
      setSnackbar({
        open: true,
        message: errorMsg,
        severity: 'error',
      });
    }
  };

  // Manejo de cierre de Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Manejo de cambio de página
  const handleChangePage = (event, value) => {
    setPagination({ ...pagination, currentPage: value });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Gestión de Clientes
      </Typography>

      {/* Barra de Herramientas */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <TextField
          variant="outlined"
          placeholder="Buscar clientes..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon sx={{ mr: 1, color: 'action.active' }} />,
          }}
          sx={{ width: '300px' }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog('create')}
        >
          Crear Cliente
        </Button>
      </Box>

      {/* Tabla de Clientes */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>RUT</TableCell>
              <TableCell>Dirección</TableCell>
              <TableCell>Comuna</TableCell>
              <TableCell>Ciudad</TableCell>
              <TableCell>País</TableCell>
              <TableCell>Código Postal</TableCell>
              <TableCell>Línea de Negocio</TableCell>
              <TableCell>Persona de Contacto</TableCell>
              <TableCell>Información Adicional</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow key={customer.id}>
                <TableCell>{customer.id}</TableCell>
                <TableCell>{customer.name}</TableCell>
                <TableCell>{customer.email}</TableCell>
                <TableCell>{customer.phone}</TableCell>
                <TableCell>{customer.tax_id}</TableCell>
                <TableCell>{customer.address}</TableCell>
                <TableCell>{customer.commune}</TableCell>
                <TableCell>{customer.city}</TableCell>
                <TableCell>{customer.country}</TableCell>
                <TableCell>{customer.postal_code}</TableCell>
                <TableCell>{customer.business_line}</TableCell>
                <TableCell>{customer.contact_person}</TableCell>
                <TableCell>{customer.additional_info}</TableCell>
                <TableCell>
                  <Tooltip title="Editar">
                    <IconButton
                      color="primary"
                      onClick={() => handleOpenDialog('edit', customer)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(customer)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            {customers.length === 0 && !loading && (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  No se encontraron clientes.
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  Cargando...
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Paginación */}
      {pagination.totalPages > 1 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Pagination
            count={pagination.totalPages}
            page={pagination.currentPage}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      )}

      {/* Diálogo para Crear/Editar Cliente */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {dialogMode === 'create' ? 'Crear Nuevo Cliente' : 'Editar Cliente'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Nombre"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Teléfono"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="RUT"
                name="tax_id"
                value={formData.tax_id}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Dirección"
                name="address"
                value={formData.address}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Comuna"
                name="commune"
                value={formData.commune}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Ciudad"
                name="city"
                value={formData.city}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="País"
                name="country"
                value={formData.country}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option value="Chile">Chile</option>
                <option value="Argentina">Argentina</option>
                <option value="Brasil">Brasil</option>
                {/* Agrega más opciones según sea necesario */}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Código Postal"
                name="postal_code"
                value={formData.postal_code}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Línea de Negocio"
                name="business_line"
                value={formData.business_line}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Persona de Contacto"
                name="contact_person"
                value={formData.contact_person}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Información Adicional"
                name="additional_info"
                value={formData.additional_info}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary" startIcon={<CloseIcon />}>
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained" startIcon={<AddIcon />}>
            {dialogMode === 'create' ? 'Crear' : 'Guardar'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Manejo de errores */}
      {error && (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
    </Box>
  );
};

export default CustomersPage;
