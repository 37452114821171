// ../components/modals/ViewEditSupplierModal.js
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

const ViewEditSupplierModal = ({ open, handleClose, supplierData, handleUpdateSupplier }) => {
  const [formData, setFormData] = useState({ ...supplierData });

  useEffect(() => {
    setFormData({ ...supplierData });
  }, [supplierData]);

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = () => {
    if (!formData.name || !formData.tax_id) {
      return;
    }
    handleUpdateSupplier(formData);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Ver/Editar Proveedor</DialogTitle>
      <DialogContent>
        <TextField
          label="Nombre"
          fullWidth
          margin="normal"
          value={formData.name || ''}
          onChange={(e) => handleInputChange('name', e.target.value)}
        />
        <TextField
          label="RUT/TAX ID"
          fullWidth
          margin="normal"
          value={formData.tax_id || ''}
          onChange={(e) => handleInputChange('tax_id', e.target.value)}
        />
        <TextField
          label="Dirección"
          fullWidth
          margin="normal"
          value={formData.address || ''}
          onChange={(e) => handleInputChange('address', e.target.value)}
        />
        <TextField
          label="Teléfono"
          fullWidth
          margin="normal"
          value={formData.phone || ''}
          onChange={(e) => handleInputChange('phone', e.target.value)}
        />
        <TextField
          label="Email"
          fullWidth
          margin="normal"
          value={formData.email || ''}
          onChange={(e) => handleInputChange('email', e.target.value)}
        />
        <TextField
          label="Persona de Contacto"
          fullWidth
          margin="normal"
          value={formData.contact_person || ''}
          onChange={(e) => handleInputChange('contact_person', e.target.value)}
        />
        <TextField
          label="Estado"
          fullWidth
          margin="normal"
          value={formData.status || ''}
          onChange={(e) => handleInputChange('status', e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} startIcon={<CloseIcon />}>Cancelar</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" startIcon={<SaveIcon />}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewEditSupplierModal;
