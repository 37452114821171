// src/pages/tabs/ImagesTab.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Button,
  LinearProgress,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Upload as UploadIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const ImageContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  '&:hover .delete-button': {
    opacity: 1,
  },
}));

const StyledDeleteButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  color: theme.palette.error.main,
  backgroundColor: theme.palette.background.paper,
  opacity: 0,
  transition: 'opacity 0.3s',
}));

const ImagesTab = ({ productId, setSnackbar }) => {
  const [images, setImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  // Obtener tenantId
  const tenantId = localStorage.getItem('tenantId') || 'default';

  const fetchImages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/${productId}/images`, {
        headers: {
          'x-tenant-id': tenantId,
        },
      });
      setImages(response.data);
    } catch (error) {
      console.error('Error al obtener las imágenes:', error);
      setSnackbar({ open: true, message: 'Error al obtener las imágenes', severity: 'error' });
    }
  };

  useEffect(() => {
    fetchImages();
  }, [productId]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
  };

  const handleUploadImages = async () => {
    if (selectedFiles.length === 0) return;

    setUploading(true);

    const formData = new FormData();
    selectedFiles.forEach(file => {
      formData.append('images', file); // Asegúrate de que el campo se llama 'images'
    });

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/products/${productId}/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-tenant-id': tenantId, // Incluye el tenantId aquí
        },
      });

      setSnackbar({ open: true, message: 'Imágenes subidas exitosamente', severity: 'success' });
      setSelectedFiles([]);
      await fetchImages(); // Actualiza la lista de imágenes
    } catch (error) {
      console.error('Error al subir imágenes:', error);
      setSnackbar({ open: true, message: 'Error al subir las imágenes', severity: 'error' });
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteImage = async (image) => {
    if (!window.confirm('¿Estás seguro de que deseas eliminar esta imagen?')) return;

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/products/${productId}/images/${image.image_filename}`, {
        headers: {
          'x-tenant-id': tenantId,
        },
      });

      setSnackbar({ open: true, message: 'Imagen eliminada exitosamente', severity: 'success' });
      await fetchImages(); // Actualiza la lista de imágenes
    } catch (error) {
      console.error('Error al eliminar la imagen:', error);
      setSnackbar({ open: true, message: 'Error al eliminar la imagen', severity: 'error' });
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Imágenes del Producto
      </Typography>

      {/* Mostrar imágenes existentes */}
      <Grid container spacing={2}>
        {images && images.map((image, index) => {
          // Construir la URL de la imagen
          const imageUrl = `${process.env.REACT_APP_API_URL}/images/products/tenant_${tenantId}/${image.image_filename}`;

          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <ImageContainer>
                <img
                  src={imageUrl}
                  alt={`Imagen ${index + 1}`}
                  style={{ width: '60%', height: 'auto', borderRadius: 8 }}
                />
                <StyledDeleteButton
                  className="delete-button"
                  onClick={() => handleDeleteImage(image)}
                >
                  <DeleteIcon />
                </StyledDeleteButton>
              </ImageContainer>
            </Grid>
          );
        })}
      </Grid>

      {/* Selector de archivos */}
      <Box sx={{ mt: 3 }}>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="upload-images"
          multiple
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="upload-images">
          <Button variant="contained" component="span" startIcon={<AddIcon />}>
            Seleccionar Imágenes
          </Button>
        </label>
      </Box>

      {/* Vista previa de imágenes seleccionadas */}
      {selectedFiles.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Imágenes Seleccionadas:</Typography>
          <Grid container spacing={2}>
            {selectedFiles.map((file, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <ImageContainer>
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Seleccionada ${index + 1}`}
                    style={{ width: '60%', height: 'auto', borderRadius: 8 }}
                  />
                  <StyledDeleteButton
                    className="delete-button"
                    onClick={() => {
                      setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                    }}
                  >
                    <DeleteIcon />
                  </StyledDeleteButton>
                </ImageContainer>
              </Grid>
            ))}
          </Grid>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleUploadImages}
            startIcon={<UploadIcon />}
            disabled={uploading}
          >
            {uploading ? 'Subiendo...' : 'Subir Imágenes'}
          </Button>
          {uploading && <LinearProgress sx={{ mt: 1 }} />}
        </Box>
      )}
    </Box>
  );
};

export default ImagesTab;
