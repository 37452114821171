// src/pages/OrderCreatePage.js
import React from 'react';

const OrderCreatePage = () => {
  return (
    <div>
      <h1>Crear Pedido</h1>
      {/* Aquí irá el formulario para crear un pedido */}
    </div>
  );
};

export default OrderCreatePage;
