// src/pages/ProductEdit.js

import React, { useEffect, useState } from 'react';
import {
  useParams,
  useNavigate,
} from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  Tabs,
  Tab,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import {
  Save as SaveIcon,
  Delete as DeleteIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';

// Componentes de Tabs
import GeneralInfoTab from './tabs/GeneralInfoTab';
import SuppliersTab from './tabs/SuppliersTab';
import StockTab from './tabs/StockTab';
import ImagesTab from './tabs/ImagesTab';
import IntegrationsTab from './tabs/IntegrationsTab';
import CompatibilityOEMTab from './tabs/CompatibilityOEMTab';

function ProductEdit() {
  const { id } = useParams();
  const navigate = useNavigate();

  // Estados principales
  const [productData, setProductData] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(true);

  // Estados para los diálogos de eliminación
  const [deleteProductDialogOpen, setDeleteProductDialogOpen] = useState(false);
  const [skuConfirmation, setSkuConfirmation] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  // Estado para el formulario en GeneralInfoTab
  const [formData, setFormData] = useState(null);

  // Estados para las integraciones
  const [productIntegrations, setProductIntegrations] = useState({});
  const [integrationsAvailable, setIntegrationsAvailable] = useState([]);

  // Función para obtener imágenes del producto
  const fetchImages = async () => {
    try {
      const tenantId = localStorage.getItem('tenantId') || 'default';

      const imagesRes = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/${id}/images`, {
        headers: {
          'x-tenant-id': tenantId,
        },
      });

      const imageBaseUrl = `${process.env.REACT_APP_WEB_URL}/images/products/tenant_${tenantId}/`;

      const imageUrls = imagesRes.data.length > 0
        ? imagesRes.data.map((img) => ({
            url: `${imageBaseUrl}${img.image_filename}`,
            filename: img.image_filename,
          }))
        : [];

      setImages(imageUrls);
    } catch (error) {
      console.error('Error al obtener imágenes:', error);
      setSnackbar({
        open: true,
        message: 'Ocurrió un error al obtener las imágenes.',
        severity: 'error',
      });
    }
  };

  // Función para obtener datos iniciales
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const tenantId = localStorage.getItem('tenantId') || 'default';

        const [productRes, suppliersRes, integrationsRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/api/products/${id}/details`, {
            headers: {
              'x-tenant-id': tenantId,
            },
          }),
          axios.get(`${process.env.REACT_APP_API_URL}/api/suppliers`, {
            headers: {
              'x-tenant-id': tenantId,
            },
          }),
          axios.get(`${process.env.REACT_APP_API_URL}/api/integrations`, {
            headers: {
              'x-tenant-id': tenantId,
            },
          }),
        ]);

        setProductData(productRes.data);
        setSuppliers(suppliersRes.data);

        // Obtener imágenes del producto
        await fetchImages();

        // Inicializar formData
        const initialFormData = {
          name: productRes.data.product.name || '',
          sku: productRes.data.product.sku || '',
          description: productRes.data.product.description || '',
          price: productRes.data.product.price || '',
          status: productRes.data.product.status || '',
          unit: productRes.data.product.unit || '',
        };
        setFormData(initialFormData);

        // Guardar integraciones disponibles y activas para el producto
        setIntegrationsAvailable(integrationsRes.data); // Integraciones disponibles globalmente
        setProductIntegrations(productRes.data.integrations); // Integraciones activas para el producto

      } catch (error) {
        console.error('Error al cargar los datos:', error);
        setSnackbar({
          open: true,
          message: 'Ocurrió un error al cargar los datos.',
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // Manejo de cambio de pestañas
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Manejo de cierre de Snackbar
  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  // Manejo de eliminación de producto
  const handleDeleteProduct = async () => {
    if (skuConfirmation !== productData.product.sku) {
      setSnackbar({
        open: true,
        message: 'El SKU ingresado no coincide.',
        severity: 'error',
      });
      return;
    }

    setIsDeleting(true);

    try {
      const tenantId = localStorage.getItem('tenantId') || 'default';

      await axios.delete(`${process.env.REACT_APP_API_URL}/api/products/${id}`, {
        headers: {
          'x-tenant-id': tenantId,
        },
      });
      setSnackbar({ open: true, message: 'Producto eliminado correctamente', severity: 'success' });
      navigate('/products');
    } catch (error) {
      console.error('Error al eliminar producto:', error);
      setSnackbar({ open: true, message: 'Error al eliminar el producto', severity: 'error' });
    } finally {
      setIsDeleting(false);
      setDeleteProductDialogOpen(false);
      setSkuConfirmation('');
    }
  };

  // Manejo de apertura y cierre de diálogos
  const handleOpenDeleteProductDialog = () => {
    setDeleteProductDialogOpen(true);
  };

  // Función para guardar el producto
  const handleSaveProduct = async () => {
    try {
      const tenantId = localStorage.getItem('tenantId') || 'default';

      await axios.put(`${process.env.REACT_APP_API_URL}/api/products/${id}`, formData, {
        headers: {
          'x-tenant-id': tenantId,
        },
      });
      setSnackbar({ open: true, message: 'Producto actualizado correctamente', severity: 'success' });

      const updatedProductRes = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/${id}/details`, {
        headers: {
          'x-tenant-id': tenantId,
        },
      });
      setProductData(updatedProductRes.data);
      setProductIntegrations(updatedProductRes.data.integrations);

      // Sincronizar con integraciones activas para el producto
      for (const [integrationName, integrationData] of Object.entries(updatedProductRes.data.integrations)) {
        if (integrationData) {
          try {
            await axios.post(
              `${process.env.REACT_APP_API_URL}/api/integrations/sync/${integrationName}/${id}`,
              {},
              {
                headers: {
                  'x-tenant-id': tenantId,
                },
              }
            );
            setSnackbar({
              open: true,
              message: `Producto sincronizado con ${integrationName}.`,
              severity: 'success',
            });
          } catch (error) {
            console.error(`Error al sincronizar con ${integrationName}:`, error);
            setSnackbar({
              open: true,
              message: `Error al sincronizar con ${integrationName}.`,
              severity: 'error',
            });
          }
        }
      }

    } catch (error) {
      console.error('Error al actualizar el producto:', error);
      setSnackbar({ open: true, message: 'Error al actualizar el producto', severity: 'error' });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Función para cancelar los cambios y volver a la vista del producto
  const handleCancelEdit = () => {
    navigate(`/products/view/${id}`);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!productData || !productData.product) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" color="error">
          Producto no encontrado.
        </Typography>
      </Box>
    );
  }

  const { product } = productData;

  return (
    <Box sx={{ p: { xs: 2, md: 4 } }}>
      {/* Header con botones */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4">Editar Producto</Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSaveProduct}
            sx={{ mr: 1 }}
          >
            Guardar
          </Button>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleOpenDeleteProductDialog}
            sx={{ mr: 1 }}
          >
            Eliminar
          </Button>
          <Button
            variant="outlined"
            color="default"
            startIcon={<CancelIcon />}
            onClick={handleCancelEdit}
          >
            Cancelar
          </Button>
        </Box>
      </Box>

      {/* Diálogo para eliminar el producto */}
      <Dialog open={deleteProductDialogOpen} onClose={() => setDeleteProductDialogOpen(false)}>
        <DialogTitle>Eliminar Producto</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para confirmar la eliminación, por favor ingresa el SKU del producto ({product.sku}).
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="SKU"
            fullWidth
            value={skuConfirmation}
            onChange={(e) => setSkuConfirmation(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteProductDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteProduct} color="error" disabled={isDeleting}>
            {isDeleting ? 'Eliminando...' : 'Eliminar'}
          </Button>
        </DialogActions>
      </Dialog>

      <Card>
        <CardContent>
          <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" sx={{ mb: 3 }}>
            <Tab label="Información General" />
            <Tab label="Proveedores" />
            <Tab label="Stock" />
            <Tab label="Imágenes" />
            <Tab label="Integraciones" />
            <Tab label="Compatibilidades y OEM" />
          </Tabs>

          {/* Renderizado de Tabs */}
          {selectedTab === 0 && (
            <GeneralInfoTab
              product={product}
              formData={formData}
              handleInputChange={handleInputChange}
              statuses={[
                { value: 'available', label: 'Disponible' },
                { value: 'not-available', label: 'No Disponible' },
                { value: 'disabled', label: 'Deshabilitado' },
                { value: 'featured', label: 'Destacado' },
              ]}
              handleSaveProduct={handleSaveProduct}
            />
          )}

          {selectedTab === 1 && (
            <SuppliersTab
              productId={id}
              suppliers={productData.suppliers}
              onSuppliersUpdate={setProductData}
              setSnackbar={setSnackbar}
            />
          )}

          {selectedTab === 2 && (
            <StockTab
              productId={id}
              stockData={productData.stock}
              productCost={productData.product_cost}
              setSnackbar={setSnackbar}
              onStockUpdate={setProductData}
            />
          )}

          {selectedTab === 3 && (
            <ImagesTab
              images={images}
              productId={id}
              setSnackbar={setSnackbar}
              fetchImages={fetchImages}
            />
          )}

          {selectedTab === 4 && (
            <IntegrationsTab
              productId={id}
              integrationsAvailable={integrationsAvailable}
              productIntegrations={productIntegrations}
              setProductIntegrations={setProductIntegrations}
              setSnackbar={setSnackbar}
            />
          )}

          {selectedTab === 5 && (
            <CompatibilityOEMTab
              compatibility={productData.compatibility}
              oem_identifiers={productData.oem_identifiers}
            />
          )}
        </CardContent>
      </Card>

      {/* Snackbar para notificaciones generales */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ProductEdit;
