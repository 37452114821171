// src/pages/inadmin/AdminDashboard.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Outlet } from 'react-router-dom';
import { 
    AppBar, 
    Toolbar, 
    Typography, 
    CssBaseline, 
    Drawer, 
    List, 
    ListItem, 
    ListItemIcon, 
    ListItemText, 
    Box, 
    IconButton 
} from '@mui/material';
import {
    Menu as MenuIcon,
    People as PeopleIcon,
    Block as BlockIcon,
    Business as BusinessIcon,
    Security as SecurityIcon,
    Refresh as RefreshIcon,
    Apartment as ApartmentIcon,
    AssignmentInd as AssignmentIndIcon,
    Settings as SettingsIcon,
    Group as GroupIcon,
    Lock as LockIcon,
    Person as PersonIcon,
    Home as HomeIcon
} from '@mui/icons-material';

const drawerWidth = 240;

const AdminDashboard = () => {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Toolbar>
                <Typography variant="h6" noWrap component="div">
                    InAdmin
                </Typography>
            </Toolbar>
            <List>
                <ListItem button component={Link} to="/inadmin">
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Inicio" />
                </ListItem>
                <ListItem button component={Link} to="/admins">
                    <ListItemIcon>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Administradores" />
                </ListItem>
                <ListItem button component={Link} to="/inadmin/banned_ips">
                    <ListItemIcon>
                        <BlockIcon />
                    </ListItemIcon>
                    <ListItemText primary="IPs Bloqueadas" />
                </ListItem>
                <ListItem button component={Link} to="/inadmin/companies">
                    <ListItemIcon>
                        <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary="Compañías" />
                </ListItem>
                <ListItem button component={Link} to="/inadmin/permissions">
                    <ListItemIcon>
                        <SecurityIcon />
                    </ListItemIcon>
                    <ListItemText primary="Permisos" />
                </ListItem>
                <ListItem button component={Link} to="/inadmin/refresh_tokens">
                    <ListItemIcon>
                        <RefreshIcon />
                    </ListItemIcon>
                    <ListItemText primary="Refresh Tokens" />
                </ListItem>
                <ListItem button component={Link} to="/inadmin/tenants">
                    <ListItemIcon>
                        <ApartmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tenants" />
                </ListItem>
                <ListItem button component={Link} to="/inadmin/user_companies">
                    <ListItemIcon>
                        <AssignmentIndIcon />
                    </ListItemIcon>
                    <ListItemText primary="User Companies" />
                </ListItem>
                <ListItem button component={Link} to="/inadmin/user_permissions">
                    <ListItemIcon>
                        <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary="User Permissions" />
                </ListItem>
                <ListItem button component={Link} to="/inadmin/users">
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Usuarios" />
                </ListItem>
                <ListItem button component={Link} to="/inadmin/settings">
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Configuraciones" />
                </ListItem>
            </List>
        </div>
    );

    return (
        <Router>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        backgroundColor: '#1976d2'
                    }}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            Panel de Administración
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="mailbox folders"
                >
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        backgroundColor: '#f0f2f5',
                        minHeight: '100vh'
                    }}
                >
                    <Toolbar />
                    <Routes>
                        <Route path="/inadmin" element={<Home />} />
                        <Route path="/inadmin/admins" element={<Admins />} />
                        <Route path="/inadmin/banned_ips" element={<BannedIps />} />
                        <Route path="/inadmin/companies" element={<Companies />} />
                        <Route path="/inadmin/permissions" element={<Permissions />} />
                        <Route path="/inadmin/refresh_tokens" element={<RefreshTokens />} />
                        <Route path="/inadmin/tenants" element={<Tenants />} />
                        <Route path="/inadmin/user_companies" element={<UserCompanies />} />
                        <Route path="/inadmin/user_permissions" element={<UserPermissions />} />
                        <Route path="/inadmin/users" element={<Users />} />
                        <Route path="/inadmin/settings" element={<Settings />} />
                    </Routes>
                </Box>
            </Box>
        </Router>
    );
};

const Home = () => (
    <Box>
        <Typography variant="h4" gutterBottom>
            Bienvenido al Panel de Administración
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            <CardWidget title="Usuarios" value={1200} icon={<PeopleIcon fontSize="large" color="primary" />} />
            <CardWidget title="Compañías" value={75} icon={<BusinessIcon fontSize="large" color="secondary" />} />
            <CardWidget title="Permisos" value={300} icon={<SecurityIcon fontSize="large" color="action" />} />
            <CardWidget title="IPs Bloqueadas" value={45} icon={<BlockIcon fontSize="large" color="error" />} />
        </Box>
    </Box>
);

const Admins = () => (
    <Typography variant="h5">Administradores</Typography>
);

const BannedIps = () => (
    <Typography variant="h5">IPs Bloqueadas</Typography>
);

const Companies = () => (
    <Typography variant="h5">Compañías</Typography>
);

const Permissions = () => (
    <Typography variant="h5">Permisos</Typography>
);

const RefreshTokens = () => (
    <Typography variant="h5">Refresh Tokens</Typography>
);

const Tenants = () => (
    <Typography variant="h5">Tenants</Typography>
);

const UserCompanies = () => (
    <Typography variant="h5">User Companies</Typography>
);

const UserPermissions = () => (
    <Typography variant="h5">User Permissions</Typography>
);

const Users = () => (
    <Typography variant="h5">Usuarios</Typography>
);

const Settings = () => (
    <Typography variant="h5">Configuraciones</Typography>
);

const CardWidget = ({ title, value, icon }) => (
    <Box sx={{ width: 250, p: 2, bgcolor: 'white', borderRadius: 2, boxShadow: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
        {icon}
        <Box>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="h4">{value}</Typography>
        </Box>
    </Box>
);

export default AdminDashboard;
