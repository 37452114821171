// ../components/modals/CreateProductModal.js
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

const CreateProductModal = ({ open, handleClose, supplierProduct, handleCreateProduct }) => {
  const [formData, setFormData] = useState({
    name: '',
    sku: '',
    description: '',
    price: 0,
    category_id: null,
    unit: 'unidad',
    status: 'disponible',
  });

  useEffect(() => {
    if (supplierProduct) {
      setFormData((prev) => ({
        ...prev,
        name: supplierProduct.supplierProductName || '',
        sku: supplierProduct.supplierProductCode || '',
        price: supplierProduct.supplierPrice || 0,
      }));
    }
  }, [supplierProduct]);

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = () => {
    if (!formData.name || !formData.sku) {
      return;
    }
    handleCreateProduct(formData);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Crear Producto Interno</DialogTitle>
      <DialogContent>
        <TextField
          label="Nombre del Producto"
          fullWidth
          margin="normal"
          value={formData.name}
          onChange={(e) => handleInputChange('name', e.target.value)}
        />
        <TextField
          label="SKU"
          fullWidth
          margin="normal"
          value={formData.sku}
          onChange={(e) => handleInputChange('sku', e.target.value)}
        />
        <TextField
          label="Descripción"
          fullWidth
          margin="normal"
          value={formData.description}
          onChange={(e) => handleInputChange('description', e.target.value)}
        />
        <TextField
          label="Precio"
          fullWidth
          margin="normal"
          type="number"
          value={formData.price}
          onChange={(e) => handleInputChange('price', parseFloat(e.target.value) || 0)}
        />
        <TextField
          label="Unidad"
          fullWidth
          margin="normal"
          value={formData.unit}
          onChange={(e) => handleInputChange('unit', e.target.value)}
        />
        <TextField
          label="Estado"
          fullWidth
          margin="normal"
          value={formData.status}
          onChange={(e) => handleInputChange('status', e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} startIcon={<CloseIcon />}>Cancelar</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" startIcon={<SaveIcon />}>
          Crear
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateProductModal;
