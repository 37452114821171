import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Switch } from '@mui/material';
import axios from 'axios';

const AutomationPage = () => {
  const [automations, setAutomations] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const fetchAutomations = async () => {
    setLoading(true);
    try {
      console.log('API URL:', process.env.REACT_APP_API_URL);  // Verifica que la URL esté configurada correctamente
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/automations`);
      console.log('Datos recibidos:', response.data);  // Verifica si se están recibiendo datos
      setAutomations(response.data);
    } catch (error) {
      console.error("Error al cargar las automatizaciones:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAutomations();
  }, []);

  const handleToggleStatus = async (id, status) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/automations/${id}`, { status: !status });
      fetchAutomations(); // Refrescar lista
    } catch (error) {
      console.error("Error al actualizar el estado:", error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <h2>Administración de Automatizaciones</h2>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Acción</TableCell>
              <TableCell>Intervalo</TableCell>
              <TableCell>Última ejecución</TableCell>
              <TableCell>Próxima ejecución</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {automations.length > 0 ? (
              automations.map((automation) => (
                <TableRow key={automation.id}>
                  <TableCell>{automation.name}</TableCell>
                  <TableCell>{automation.description}</TableCell>
                  <TableCell>{automation.action}</TableCell>
                  <TableCell>{`${automation.interval_value} ${automation.interval_type}`}</TableCell>
                  
                  {/* Manejo de valores null para last_run y next_run */}
                  <TableCell>{automation.last_run ? new Date(automation.last_run).toLocaleString() : 'No ejecutada'}</TableCell>
                  <TableCell>{automation.next_run ? new Date(automation.next_run).toLocaleString() : 'No programada'}</TableCell>
                  
                  {/* Estado */}
                  <TableCell>
                    <Switch 
                      checked={automation.status}
                      onChange={() => handleToggleStatus(automation.id, automation.status)}
                    />
                  </TableCell>
                  
                  {/* Acciones */}
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => console.log('Editar', automation.id)}>Editar</Button>
                    <Button variant="outlined" color="secondary" onClick={() => console.log('Eliminar', automation.id)}>Eliminar</Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">No hay automatizaciones disponibles</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={() => console.log('Crear nueva')}>Crear Nueva Automatización</Button>
    </Box>
  );
};

export default AutomationPage;
