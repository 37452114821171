// src/pages/PurchaseDetailsView.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  Divider,
  Link,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import MapIcon from '@mui/icons-material/Map';
import axios from 'axios';

// Importación de los modales externos
import ViewEditSupplierModal from './modals/ViewEditSupplierModal';
import CreateSupplierModal from './modals/CreateSupplierModal';
import CreateProductModal from './modals/CreateProductModal';
import ProductMappingModal from './modals/ProductMappingModal';
import DeleteConfirmationDialog from './modals/DeleteConfirmationDialog';
import AcceptPurchaseModal from './modals/AcceptPurchaseModal';

/**
 * Componente para mostrar información en tablas con opción de renderizado personalizado
 */
const InfoTable = ({ title, data, renderCell }) => (
  <Box mb={4}>
    <Typography variant="h6" gutterBottom>
      {title}
    </Typography>
    <TableContainer component={Paper} elevation={3}>
      <Table>
        <TableBody>
          {Object.entries(data).map(([key, value], index) => (
            <TableRow key={index}>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  width: '30%',
                  backgroundColor: '#f0f0f0',
                }}
              >
                {key.replace(/_/g, ' ')}
              </TableCell>
              <TableCell>
                {renderCell ? renderCell(key, value) : value || 'No disponible'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

/**
 * Componente principal para ver los detalles de una compra
 */
const PurchaseDetailsView = () => {
  const { purchaseId } = useParams();
  const navigate = useNavigate();
  const [purchaseDetails, setPurchaseDetails] = useState(null);
  const [supplierExists, setSupplierExists] = useState(false);
  const [supplierData, setSupplierData] = useState(null);
  const [openCreateSupplierModal, setOpenCreateSupplierModal] = useState(false);
  const [openViewEditSupplierModal, setOpenViewEditSupplierModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [productMappings, setProductMappings] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [openMappingModal, setOpenMappingModal] = useState(false);
  const [openCreateProductModal, setOpenCreateProductModal] = useState(false);
  const [currentSupplierProduct, setCurrentSupplierProduct] = useState(null);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [selectedPurchaseItem, setSelectedPurchaseItem] = useState(null);

  /**
   * Fetch detalles de la compra desde el backend
   */
  const fetchPurchaseDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/purchases/${purchaseId}`
      );
      console.log('Detalles de la compra:', response.data); // Log de los detalles de la compra
      setPurchaseDetails(response.data);
      await checkSupplierExists(response.data.supplier_id); // Cambiado a supplier_id

      // Inicializar mappings
      const initialMappings = {};
      response.data.items.forEach((item) => {
        initialMappings[item.id] = [
          {
            supplierProductCode: item.codigo_item || 'SIN_CODIGO',
            supplierProductName: item.nombre_item || 'No disponible',
            quantity: parseInt(item.cantidad, 10) || 1,
            price: parseFloat(item.precio) || 0,
            total: (parseFloat(item.precio) * (parseInt(item.cantidad, 10) || 1)).toFixed(2),
            internalProduct: item.internal_product_id
              ? {
                  id: item.internal_product_id,
                  name: item.internal_product_name || 'Producto Interno',
                  sku: item.internal_product_sku || '',
                }
              : null,
          },
        ];
      });
      setProductMappings(initialMappings);
    } catch (err) {
      console.error(err);
      if (err.response) {
        setError(
          `Error ${err.response.status}: ${err.response.data.message || 'Error al cargar los detalles de la compra.'}`
        );
        setSnackbar({
          open: true,
          message: err.response.data.message || 'Error al obtener los datos',
          severity: 'error',
        });
      } else if (err.request) {
        setError('No se recibió respuesta del servidor.');
        setSnackbar({
          open: true,
          message: 'No se recibió respuesta del servidor.',
          severity: 'error',
        });
      } else {
        setError(`Error: ${err.message}`);
        setSnackbar({
          open: true,
          message: `Error: ${err.message}`,
          severity: 'error',
        });
      }
    }
    setLoading(false);
  };

  /**
   * Check if supplier exists by supplier_id
   */
  const checkSupplierExists = async (supplier_id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/suppliers/${supplier_id}`
      );
      console.log('Datos del proveedor:', response.data); // Log de los datos del proveedor
      setSupplierExists(true);
      setSupplierData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log('Proveedor no encontrado.');
        setSupplierExists(false);
        setSupplierData(null);
      } else {
        console.error('Error al verificar el proveedor:', error);
        setSnackbar({
          open: true,
          message: 'Error al verificar el proveedor.',
          severity: 'error',
        });
      }
    }
  };

  /**
   * Create a new supplier
   */
  const handleCreateSupplier = async (supplierData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/suppliers`,
        supplierData
      );
      setSnackbar({
        open: true,
        message: 'Proveedor creado exitosamente.',
        severity: 'success',
      });
      setOpenCreateSupplierModal(false);
      setSupplierExists(true);
      setSupplierData(response.data.supplier);
    } catch (error) {
      console.error('Error al crear el proveedor:', error);
      setSnackbar({
        open: true,
        message: 'Error al crear el proveedor.',
        severity: 'error',
      });
    }
  };

  /**
   * Update supplier data
   */
  const handleUpdateSupplier = async (updatedSupplier) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/suppliers/${supplierData.id}`,
        updatedSupplier
      );
      setSnackbar({
        open: true,
        message: 'Proveedor actualizado exitosamente.',
        severity: 'success',
      });
      setSupplierData(response.data.supplier);
      setOpenViewEditSupplierModal(false);
    } catch (error) {
      console.error('Error al actualizar el proveedor:', error);
      setSnackbar({
        open: true,
        message: 'Error al actualizar el proveedor.',
        severity: 'error',
      });
    }
  };

  /**
   * Delete purchase
   */
  const deletePurchase = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/purchases/${purchaseId}`
      );
      setSnackbar({
        open: true,
        message: 'Compra eliminada exitosamente.',
        severity: 'success',
      });
      navigate('/purchases');
    } catch (err) {
      console.error('Error al eliminar la compra:', err);
      setSnackbar({
        open: true,
        message: 'Error al eliminar la compra.',
        severity: 'error',
      });
    }
  };

  /**
   * Handle mapping change
   */
  const handleMappingChange = (updatedMappings) => {
    setProductMappings(updatedMappings);
  };

  /**
   * Save mappings to the backend
   */
  const handleSaveMappings = async () => {
    // Preparar las mappings para enviar al backend
    const mappingsToSend = [];
    Object.entries(productMappings).forEach(([purchaseItemId, mappings]) => {
      mappings.forEach((mapping) => {
        mappingsToSend.push({
          purchaseItemId: parseInt(purchaseItemId, 10),
          supplierProductCode: mapping.supplierProductCode,
          supplierProductName: mapping.supplierProductName,
          internalProductId: mapping.internalProduct ? mapping.internalProduct.id : null,
        });
      });
    });

    const incompleteMappings = mappingsToSend.filter(
      (mapping) => !mapping.internalProductId
    );

    if (incompleteMappings.length > 0) {
      setSnackbar({
        open: true,
        message: 'Por favor, completa todos los pareos de productos.',
        severity: 'warning',
      });
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/purchases/${purchaseId}/mappings`,
        {
          mappings: mappingsToSend,
        }
      );
      setSnackbar({
        open: true,
        message: 'Pareo de productos guardado exitosamente.',
        severity: 'success',
      });
      fetchPurchaseDetails();
      setOpenMappingModal(false);
    } catch (err) {
      console.error('Error al guardar pareo de productos:', err);
      setSnackbar({
        open: true,
        message: 'Error al guardar pareo de productos.',
        severity: 'error',
      });
    }
  };

  /**
   * Open create product modal
   */
  const handleOpenCreateProduct = (supplierProduct) => {
    setCurrentSupplierProduct(supplierProduct);
    setOpenCreateProductModal(true);
  };

  /**
   * Create a new internal product
   */
  const handleCreateProduct = async (newProduct) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/products`,
        newProduct
      );
      setSnackbar({
        open: true,
        message: 'Producto creado exitosamente.',
        severity: 'success',
      });
      setOpenCreateProductModal(false);
      // Actualizar mappings con el nuevo producto
      const updatedMappings = { ...productMappings };
      Object.entries(updatedMappings).forEach(([purchaseItemId, mappings]) => {
        mappings.forEach((mapping, index) => {
          if (
            mapping.supplierProductCode ===
              currentSupplierProduct.supplierProductCode &&
            mapping.supplierProductName ===
              currentSupplierProduct.supplierProductName
          ) {
            updatedMappings[purchaseItemId][index].internalProduct = response.data.product;
          }
        });
      });
      setProductMappings(updatedMappings);
    } catch (err) {
      console.error('Error al crear el producto:', err);
      setSnackbar({
        open: true,
        message: 'Error al crear el producto.',
        severity: 'error',
      });
    }
  };

  /**
   * Manejar la aceptación exitosa de la compra
   */
  const handleAcceptSuccess = () => {
    fetchPurchaseDetails();
  };

  useEffect(() => {
    fetchPurchaseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseId]);

  /**
   * Render loading state
   */
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  /**
   * Render error state
   */
  if (error) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  /**
   * Render no purchase details found
   */
  if (!purchaseDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography variant="h6">
          No se encontraron detalles para la compra #{purchaseId}
        </Typography>
      </Box>
    );
  }

  /**
   * Desestructuración de los detalles de la compra
   */
  const {
    razon_social_emisor,
    rut_emisor,
    fecha_emision,
    tipo_dte,
    folio,
    monto_total,
    items,
    giro_emisor,
    iva,
    descuento,
    contacto,
    razon_social_receptor,
    direccion_receptor,
    accepted: invoiceAccepted,
  } = purchaseDetails;

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
    }).format(amount);
  };

  const supplierDataFormatted = {
    'Razón Social Emisor': supplierData ? supplierData.name : razon_social_emisor || 'No disponible',
    'RUT Emisor': supplierData ? supplierData.tax_id : rut_emisor || 'No disponible',
    Dirección: supplierData ? supplierData.address : 'No disponible',
    Comuna: supplierData ? supplierData.commune : 'No disponible',
    Ciudad: supplierData ? supplierData.city : 'No disponible',
    País: supplierData ? supplierData.country : 'Chile', // Por defecto 'Chile' si no hay supplierData
    'Código Postal': supplierData ? supplierData.postal_code || 'No disponible' : 'No disponible',
    Giro: supplierData ? supplierData.business_line || 'No disponible' : giro_emisor || 'No disponible',
    Contacto: supplierData ? supplierData.contact_person || 'No disponible' : contacto || 'No disponible',
    'Información Adicional': supplierData ? supplierData.additional_info || 'No disponible' : 'No disponible',
    'Fecha de Emisión': fecha_emision ? new Date(fecha_emision).toLocaleDateString('es-CL') : 'No disponible',
    'Tipo de Documento': tipo_dte || 'No disponible',
    Folio: folio || 'No disponible',
  };

  const receptorData = {
    'Razón Social Receptor': razon_social_receptor || 'No disponible',
    Dirección: direccion_receptor || 'No disponible',
    // Se eliminan Comuna y Ciudad ya que no existen en la respuesta de la API
  };

  const detallesFinancieros = {
    Subtotal: formatCurrency(
      (
        (parseFloat(monto_total) || 0) -
        (parseFloat(iva) || 0) -
        (parseFloat(descuento) || 0)
      ).toFixed(2)
    ),
    IVA: formatCurrency(parseFloat(iva) || 0),
    Descuento: formatCurrency(parseFloat(descuento) || 0),
    Total: formatCurrency(parseFloat(monto_total) || 0),
  };

  return (
    <Box sx={{ p: { xs: 2, md: 4 }, backgroundColor: '#fafafa', minHeight: '100vh' }}>
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Typography variant="h4" gutterBottom>
          Detalles de la Compra #{folio || purchaseId}
        </Typography>
        <Box mt={{ xs: 2, sm: 0 }} display="flex" alignItems="center" gap={2}>
          <Tooltip title="Eliminar Compra">
            <IconButton
              color="error"
              onClick={() => setOpenDeleteDialog(true)}
              sx={{
                backgroundColor: '#fdecea',
                '&:hover': {
                  backgroundColor: '#f8d7da',
                },
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar Compra">
            <IconButton
              color="primary"
              onClick={() => navigate(`/purchases/${purchaseId}/edit`)}
              sx={{
                backgroundColor: '#e7f3ff',
                '&:hover': {
                  backgroundColor: '#d0ebff',
                },
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Button
            variant="contained"
            color="success"
            startIcon={<CheckCircleIcon />}
            onClick={() => setOpenAcceptModal(true)}
            disabled={invoiceAccepted}
          >
            {invoiceAccepted ? 'Compra Aceptada' : 'Aceptar Compra'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<VisibilityIcon />}
            onClick={() => setOpenMappingModal(true)}
          >
            Gestionar Pareo
          </Button>
        </Box>
      </Box>

      {/* Información del Emisor */}
      <InfoTable
        title="Información del Proveedor (Supplier)"
        data={supplierDataFormatted}
        renderCell={(key, value) => {
          if (key === 'Razón Social Emisor') {
            return (
              <Link
                href={`http://www.integranube.cl:3001/suppliers/${rut_emisor}`}
                target="_parent"
                rel="noopener"
                underline="hover"
                color="primary"
              >
                {value || 'No disponible'}
              </Link>
            );
          }
          return value || 'No disponible';
        }}
      />

      {/* Separador */}
      <Divider sx={{ my: 4 }} />

      {/* Información del Receptor */}
      <InfoTable title="Información del Receptor" data={receptorData} />

      {/* Separador */}
      <Divider sx={{ my: 4 }} />

      {/* Detalles Financieros */}
      <InfoTable title="Detalles Financieros" data={detallesFinancieros} />

      {/* Separador */}
      <Divider sx={{ my: 4 }} />

      {/* Productos o Servicios */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Productos/Servicios
        </Typography>
        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Código del Producto</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell align="right">Cantidad</TableCell>
                <TableCell align="right">Precio Unitario</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell>Producto Interno</TableCell>
                <TableCell>SKU Interno</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items && items.length > 0 ? (
                items.map((item, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.codigo_item || 'No disponible'}</TableCell>
                    <TableCell>{item.nombre_item || 'No disponible'}</TableCell>
                    <TableCell align="right">{item.cantidad || 0}</TableCell>
                    <TableCell align="right">
                      {formatCurrency(parseFloat(item.precio) || 0)}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency((parseFloat(item.cantidad) || 0) * (parseFloat(item.precio) || 0))}
                    </TableCell>
                    <TableCell>
                      {productMappings[item.id] && productMappings[item.id][0].internalProduct ? (
                        <Link
                          component={RouterLink}
                          to={`/products/view/${productMappings[item.id][0].internalProduct.id}`}
                          underline="hover"
                          color="primary"
                        >
                          {productMappings[item.id][0].internalProduct.name}
                        </Link>
                      ) : (
                        <Typography color="textSecondary">No mapeado</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {productMappings[item.id] && productMappings[item.id][0].internalProduct ? (
                        <Link
                          component={RouterLink}
                          to={`/products/view/${productMappings[item.id][0].internalProduct.id}`}
                          underline="hover"
                          color="primary"
                        >
                          {productMappings[item.id][0].internalProduct.sku || 'No disponible'}
                        </Link>
                      ) : (
                        <Typography color="textSecondary">No disponible</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Mapear Producto">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setSelectedPurchaseItem(item);
                            setOpenMappingModal(true);
                          }}
                        >
                          <MapIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    No hay productos o servicios en esta compra.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Separador */}
      <Divider sx={{ my: 4 }} />

      {/* Diálogo de confirmación para eliminar */}
      <DeleteConfirmationDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleDelete={deletePurchase}
      />

      {/* Modal para Pareo de Productos */}
      <ProductMappingModal
        open={openMappingModal}
        handleClose={() => {
          setOpenMappingModal(false);
          setSelectedPurchaseItem(null);
        }}
        purchaseItem={selectedPurchaseItem}
        productMappings={productMappings}
        handleMappingChange={handleMappingChange}
        handleOpenCreateProduct={handleOpenCreateProduct}
        handleSaveMappings={handleSaveMappings}
      />

      {/* Modal para Crear Producto Interno */}
      <CreateProductModal
        open={openCreateProductModal}
        handleClose={() => setOpenCreateProductModal(false)}
        supplierProduct={currentSupplierProduct}
        handleCreateProduct={handleCreateProduct}
      />

      {/* Modal para Crear Proveedor */}
      <CreateSupplierModal
        open={openCreateSupplierModal}
        handleClose={() => setOpenCreateSupplierModal(false)}
        initialData={purchaseDetails}
        handleCreateSupplier={handleCreateSupplier}
      />

      {/* Modal para Ver/Editar Proveedor */}
      {supplierData && (
        <ViewEditSupplierModal
          open={openViewEditSupplierModal}
          handleClose={() => setOpenViewEditSupplierModal(false)}
          supplierData={supplierData}
          handleUpdateSupplier={handleUpdateSupplier}
        />
      )}

      {/* Modal para Aceptar Compra */}
      <AcceptPurchaseModal
        open={openAcceptModal}
        handleClose={() => setOpenAcceptModal(false)}
        purchaseItems={purchaseDetails.items}
        onAcceptSuccess={handleAcceptSuccess}
      />

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PurchaseDetailsView;
