// src/pages/inadmin/AdminRefreshTokensPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AdminRefreshTokensPage = () => {
    const [refreshTokens, setRefreshTokens] = useState([]);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({ token: '', user_id: '', expires_at: '' });
    const navigate = useNavigate();

    useEffect(() => {
        fetchRefreshTokens();
    }, []);

    const fetchRefreshTokens = () => {
        axios.get('/inadmin/refresh_tokens')
            .then(response => setRefreshTokens(response.data))
            .catch(error => console.error('Error al obtener refresh tokens:', error));
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setFormData({ token: '', user_id: '', expires_at: '' });
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        axios.post('/inadmin/refresh_tokens', formData)
            .then(() => {
                fetchRefreshTokens();
                handleClose();
            })
            .catch(error => console.error('Error al crear refresh token:', error));
    };

    const handleEdit = (id) => {
        navigate(`/inadmin/refresh_tokens/edit/${id}`);
    };

    const handleDelete = (id) => {
        axios.delete(`/inadmin/refresh_tokens/${id}`)
            .then(() => fetchRefreshTokens())
            .catch(error => console.error('Error al eliminar refresh token:', error));
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'token', headerName: 'Token', width: 300 },
        { field: 'user_id', headerName: 'User ID', width: 150 },
        { field: 'expires_at', headerName: 'Expira At', width: 200 },
        {
            field: 'actions',
            headerName: 'Acciones',
            width: 200,
            renderCell: (params) => (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleEdit(params.row.id)}
                        style={{ marginRight: 8 }}
                    >
                        Editar
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => handleDelete(params.row.id)}
                    >
                        Eliminar
                    </Button>
                </>
            ),
        },
    ];

    return (
        <Box sx={{ height: 600, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <h2>Gestión de Refresh Tokens</h2>
                <Button variant="contained" color="primary" onClick={handleOpen}>
                    Crear Refresh Token
                </Button>
            </Box>
            <DataGrid
                rows={refreshTokens}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
            />

            {/* Dialog para Crear Refresh Token */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Crear Nuevo Refresh Token</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Token"
                        name="token"
                        fullWidth
                        variant="standard"
                        value={formData.token}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        label="User ID"
                        name="user_id"
                        type="number"
                        fullWidth
                        variant="standard"
                        value={formData.user_id}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        label="Expira At"
                        name="expires_at"
                        type="datetime-local"
                        fullWidth
                        variant="standard"
                        value={formData.expires_at}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSubmit}>Crear</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdminRefreshTokensPage;
