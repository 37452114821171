// src/pages/SalesPage.js
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Pagination,
  Snackbar,
  Alert,
  Grid,
  TextField,
  MenuItem,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  IconButton,
  Tooltip,
  Stack,
  Checkbox,
  FormControlLabel,
  Card,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import axios from 'axios';
import debounce from 'lodash.debounce';

// Función para traducir el tipo de documento
const translateDocumentType = (documentType) => {
  const typeMap = {
    'Factura Electrónica': 'Factura Electrónica',
    'Factura Exenta Electrónica': 'Factura Exenta Electrónica',
    'Boleta Electrónica': 'Boleta Electrónica',
    'Nota de Crédito Electrónica': 'Nota de Crédito Electrónica',
    'Venta Jumpseller': 'Venta Jumpseller',
    null: 'Sin Documento',
  };
  return typeMap[documentType] || 'Documento Desconocido';
};

// 1. SearchBar Component
const SearchBar = ({ searchParams, setSearchParams }) => {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSearchParams({
      ...searchParams,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleReset = () => {
    setSearchParams({
      sale_date: '',
      search: '',
      folio: '',
      document_type: '',
      total_amount: '',
      source: '',
      notDelivered: false,
    });
  };

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Fecha"
          type="date"
          variant="outlined"
          name="sale_date"
          value={searchParams.sale_date}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Cliente"
          variant="outlined"
          name="search"
          value={searchParams.search}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Folio"
          variant="outlined"
          name="folio"
          value={searchParams.folio}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Tipo de Documento</InputLabel>
          <Select
            label="Tipo de Documento"
            variant="outlined"
            name="document_type"
            value={searchParams.document_type}
            onChange={handleChange}
            fullWidth
          >
            <MenuItem value="">
              <em>Todos</em>
            </MenuItem>
            <MenuItem value="Factura Electrónica">Factura Electrónica</MenuItem>
            <MenuItem value="Factura Exenta Electrónica">Factura Exenta Electrónica</MenuItem>
            <MenuItem value="Boleta Electrónica">Boleta Electrónica</MenuItem>
            <MenuItem value="Nota de Crédito Electrónica">Nota de Crédito Electrónica</MenuItem>
            <MenuItem value="Venta Jumpseller">Venta Jumpseller</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Total"
          variant="outlined"
          name="total_amount"
          value={searchParams.total_amount}
          onChange={handleChange}
          type="number"
          fullWidth
        />
      </Grid>
      {/* Nuevo Filtro: Origen */}
      <Grid item xs={12} sm={6} md={3}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Origen</InputLabel>
          <Select
            label="Origen"
            variant="outlined"
            name="source"
            value={searchParams.source}
            onChange={handleChange}
            fullWidth
          >
            <MenuItem value="">
              <em>Todos</em>
            </MenuItem>
            <MenuItem value="pos">POS</MenuItem>
            <MenuItem value="jumpseller">Jumpseller</MenuItem>
            <MenuItem value="manual">Manual</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {/* Nuevo Filtro: Solo Ventas No Entregadas */}
      <Grid item xs={12} sm={6} md={3}>
        <FormControlLabel
          control={
            <Checkbox
              checked={searchParams.notDelivered}
              onChange={handleChange}
              name="notDelivered"
              color="primary"
            />
          }
          label="Solo ventas no entregadas"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleReset}
          fullWidth
        >
          Resetear
        </Button>
      </Grid>
    </Grid>
  );
};

// 2. SalesTable Component
const SalesTable = ({
  sales,
  orderBy,
  orderDirection,
  handleSortRequest,
  goToSaleDetails,
}) => {
  const getSortDirection = (property) => {
    return orderBy === property ? orderDirection : 'asc';
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'source'}
                direction={getSortDirection('source')}
                onClick={() => handleSortRequest('source')}
              >
                Origen
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'sale_date'}
                direction={getSortDirection('sale_date')}
                onClick={() => handleSortRequest('sale_date')}
              >
                Fecha y Hora
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'customer_name'}
                direction={getSortDirection('customer_name')}
                onClick={() => handleSortRequest('customer_name')}
              >
                Cliente
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'folio'}
                direction={getSortDirection('folio')}
                onClick={() => handleSortRequest('folio')}
              >
                Folio
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'document_type'}
                direction={getSortDirection('document_type')}
                onClick={() => handleSortRequest('document_type')}
              >
                Tipo de Documento
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'total_amount'}
                direction={getSortDirection('total_amount')}
                onClick={() => handleSortRequest('total_amount')}
              >
                Total
              </TableSortLabel>
            </TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sales.length > 0 ? (
            sales.map((sale) => (
              <TableRow key={sale.id} hover>
                <TableCell>{sale.source}</TableCell>
                <TableCell>
                  {new Date(sale.sale_date).toLocaleDateString('es-CL')} {new Date(sale.sale_date).toLocaleTimeString('es-CL')}
                </TableCell>
                <TableCell>{sale.customer_name}</TableCell>
                <TableCell>{sale.folio}</TableCell>
                <TableCell>{translateDocumentType(sale.document_type)}</TableCell>
                <TableCell>{`$ ${Number(sale.total_amount).toLocaleString('es-CL')}`}</TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton
                      color="primary"
                      onClick={() => goToSaleDetails(sale.id)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    {!sale.delivered && (
                      <Tooltip title="Venta no entregada">
                        <ErrorIcon color="error" />
                      </Tooltip>
                    )}
                    {sale.delivered && (
                      <Tooltip title="Venta entregada">
                        <CheckCircleIcon color="success" />
                      </Tooltip>
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7} align="center">
                No se encontraron ventas
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// 3. SummaryCards Component
const SummaryCards = ({ totalSales, salesNotDelivered }) => {
  const percentageNotDelivered =
    totalSales > 0 ? ((salesNotDelivered / totalSales) * 100).toFixed(2) : '0.00';

  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid item xs={12} sm={6} md={3}>
        <Card sx={{ p: 2, textAlign: 'center', boxShadow: 3 }}>
          <Typography variant="h6">Total de Ventas</Typography>
          <Typography variant="h4">{totalSales}</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card sx={{ p: 2, textAlign: 'center', boxShadow: 3 }}>
          <Typography variant="h6">Ventas Sin Entregar</Typography>
          <Typography variant="h4">{salesNotDelivered}</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card sx={{ p: 2, textAlign: 'center', boxShadow: 3 }}>
          <Typography variant="h6">Ventas Entregadas</Typography>
          <Typography variant="h4">{totalSales - salesNotDelivered}</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card sx={{ p: 2, textAlign: 'center', boxShadow: 3 }}>
          <Typography variant="h6">Porcentaje Sin Entregar</Typography>
          <Typography variant="h4">{percentageNotDelivered}%</Typography>
        </Card>
      </Grid>
    </Grid>
  );
};

// 4. LoadingSpinner Component
const LoadingSpinner = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '60vh',
    }}
  >
    <CircularProgress />
  </Box>
);

// 5. ErrorMessage Component
const ErrorMessage = ({ message }) => (
  <Box sx={{ p: 3 }}>
    <Typography variant="h6" color="error">
      {message}
    </Typography>
  </Box>
);

// 6. Main SalesPage Component
const SalesPage = () => {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);

  const [searchParams, setSearchParams] = useState({
    sale_date: '',
    search: '',
    folio: '',
    document_type: '',
    total_amount: '',
    source: '',
    notDelivered: false,
  });

  const [orderBy, setOrderBy] = useState('sale_date');
  const [orderDirection, setOrderDirection] = useState('desc');

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const navigate = useNavigate();

  const [totalSales, setTotalSales] = useState(0);
  const [salesNotDelivered, setSalesNotDelivered] = useState(0);

  // Función para importar ventas desde Jumpseller
  const importSales = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/sales/import-sales/jumpseller`,
        {},
        {
          headers: {
            'x-tenant-id': localStorage.getItem('tenantId') || 'default',
          },
        }
      );
      setSnackbar({
        open: true,
        message: 'Ventas importadas exitosamente desde Jumpseller.',
        severity: 'success',
      });
      fetchSales(page);
    } catch (error) {
      console.error('Error al importar las ventas:', error.response?.data || error.message);
      setSnackbar({
        open: true,
        message: 'Error al importar las ventas desde Jumpseller.',
        severity: 'error',
      });
    }
    setLoading(false);
  };

  // Memoizar la función fetchSales para evitar redefiniciones
  const fetchSales = useCallback(
    async (currentPage = 1) => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/sales`,
          {
            params: {
              page: currentPage,
              limit,
              ...searchParams,
              orderBy,
              orderDirection,
            },
            headers: {
              'x-tenant-id': localStorage.getItem('tenantId') || 'default',
            },
          }
        );
        if (response.data?.sales) {
          setSales(response.data.sales);
          setTotalPages(response.data.totalPages);
          setTotalSales(response.data.totalSales);
          setSalesNotDelivered(response.data.salesNotDelivered);
        }
      } catch (err) {
        console.error('Error al cargar las ventas:', err.response?.data || err.message);
        setError(err.response?.data?.error || 'Error al cargar las ventas.');
      }
      setLoading(false);
    },
    [limit, orderBy, orderDirection, searchParams]
  );

  // Debounce para optimizar las solicitudes de búsqueda
  const debouncedFetchSales = useMemo(
    () =>
      debounce((currentPage) => {
        fetchSales(currentPage);
      }, 500),
    [fetchSales]
  );

  // Manejador de búsqueda
  const handleSearch = useCallback(() => {
    setPage(1);
    debouncedFetchSales(1);
  }, [debouncedFetchSales]);

  // Manejador de paginación
  const handlePageChange = (event, value) => {
    setPage(value);
    fetchSales(value);
  };

  // Manejador de ordenamiento
  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    fetchSales(page);
  };

  // Navegar a detalles de la venta
  const goToSaleDetails = (id) => {
    if (id) {
      navigate(`/sales/${id}`);
    } else {
      console.warn('ID de venta no válido:', id);
    }
  };

  // Efecto para cargar las ventas inicialmente y cuando cambian las dependencias
  useEffect(() => {
    fetchSales(page);
  }, [page, fetchSales]);

  // Efecto para manejar la búsqueda cuando cambian los parámetros de búsqueda
  useEffect(() => {
    handleSearch();
  }, [searchParams, handleSearch]);

  // Limpiar el debounce al desmontar el componente
  useEffect(() => {
    return () => {
      debouncedFetchSales.cancel();
    };
  }, [debouncedFetchSales]);

  return (
    <Box sx={{ p: { xs: 2, md: 4 } }}>
      <Typography variant="h4" gutterBottom>
        Administración de Ventas
      </Typography>

      {/* Botón para importar ventas */}
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={importSales}
          startIcon={<ImportExportIcon />}
          disabled={loading}
        >
          Importar Ventas desde Jumpseller
        </Button>
      </Box>

      <SearchBar searchParams={searchParams} setSearchParams={setSearchParams} />

      {/* Tarjetas de Resumen */}
      <SummaryCards totalSales={totalSales} salesNotDelivered={salesNotDelivered} />

      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <ErrorMessage message={error} />
      ) : (
        <>
          <SalesTable
            sales={sales}
            orderBy={orderBy}
            orderDirection={orderDirection}
            handleSortRequest={handleSortRequest}
            goToSaleDetails={goToSaleDetails}
          />

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              variant="outlined"
              shape="rounded"
            />
          </Box>
        </>
      )}

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SalesPage;
