// /src/pages/OrdersPage.js

import React, { useEffect, useState, useCallback } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, Button, TablePagination, Box, TextField, MenuItem, Grid, Select, InputLabel, FormControl, Snackbar, Alert,
  CircularProgress, Tooltip
} from '@mui/material';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalOrders, setTotalOrders] = useState(0);
  const [filters, setFilters] = useState({
    search: '',
    paymentMethod: '',
    paymentStatus: '',
    shippingStatus: '',
    dateRange: [null, null]
  });

  const [integrations, setIntegrations] = useState([]);
  const [jumpsellerActive, setJumpsellerActive] = useState(false);

  const [loading, setLoading] = useState(false);
  const [importing, setImporting] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const navigate = useNavigate(); // Definir navigate

  // Obtener integraciones desde el backend
  const fetchIntegrations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/integrations`, {
        headers: {
          'x-tenant-id': localStorage.getItem('tenantId') || 'default',
        },
      });
      setIntegrations(response.data);
      const jumpseller = response.data.find(
        (integration) => integration.platform.toLowerCase() === 'jumpseller' && integration.status === 'activo'
      );
      setJumpsellerActive(!!jumpseller);
    } catch (error) {
      console.error('Error al obtener las integraciones:', error);
      setSnackbar({
        open: true,
        message: 'Error al obtener las integraciones.',
        severity: 'error',
      });
    }
  };

  // Obtener pedidos desde el backend
  const fetchOrders = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const params = {
        page: page + 1, // Paginación desde 1
        limit: rowsPerPage,
        search: filters.search,
        paymentMethod: filters.paymentMethod,
        paymentStatus: filters.paymentStatus,
        shippingStatus: filters.shippingStatus,
        startDate: filters.dateRange[0] ? filters.dateRange[0].format('YYYY-MM-DD') : '',
        endDate: filters.dateRange[1] ? filters.dateRange[1].format('YYYY-MM-DD') : '',
      };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders`, {
        params,
        headers: {
          'x-tenant-id': localStorage.getItem('tenantId') || 'default',
        },
      });
      setOrders(response.data.orders); // Ajusta a la respuesta
      setTotalOrders(response.data.total); // Ajusta a la respuesta
    } catch (error) {
      console.error('Error al obtener los pedidos:', error);
      setError(error.response?.data?.error || 'Error al obtener los pedidos.');
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Error al obtener los pedidos.',
        severity: 'error',
      });
    }
    setLoading(false);
  }, [page, rowsPerPage, filters]);

  // Función para importar pedidos desde Jumpseller
  const importOrders = async () => {
    if (!jumpsellerActive) {
      setSnackbar({
        open: true,
        message: 'Activar sincronización con Jumpseller para importar pedidos.',
        severity: 'warning',
      });
      return;
    }

    setImporting(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/integrations/import-orders/jumpseller`, {}, {
        headers: {
          'x-tenant-id': localStorage.getItem('tenantId') || 'default',
        },
      });
      setSnackbar({
        open: true,
        message: 'Pedidos importados exitosamente desde Jumpseller.',
        severity: 'success',
      });
      fetchOrders();
    } catch (error) {
      console.error('Error al importar los pedidos:', error.response?.data || error.message);
      setSnackbar({
        open: true,
        message: 'Error al importar los pedidos desde Jumpseller.',
        severity: 'error',
      });
    }
    setImporting(false);
  };

  // Llamada a las integraciones y pedidos al montar el componente
  useEffect(() => {
    fetchIntegrations();
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [page, rowsPerPage, filters, fetchOrders]);

  // Manejadores de filtros
  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    setPage(0); // Resetea la paginación
  };

  // Manejadores de paginación
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Resetea la página a la primera
  };

  // Manejador de Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ p: 3 }}>
        {/* Encabezado */}
        <Typography variant="h4" gutterBottom>
          Pedidos
        </Typography>

        {/* Resumen */}
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant="h6">Total de Pedidos</Typography>
              <Typography variant="h4">{totalOrders}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant="h6">Pendientes</Typography>
              <Typography variant="h4">{orders.filter(order => order.payment_status === 'Pendiente').length}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant="h6">No Procesados</Typography>
              <Typography variant="h4">{orders.filter(order => order.shipping_status === 'No Procesado').length}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant="h6">Procesados</Typography>
              <Typography variant="h4">{orders.filter(order => order.shipping_status === 'Procesado').length}</Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* Botón para importar pedidos */}
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 2 }}>
          <Button
            variant="contained"
            color={jumpsellerActive ? 'primary' : 'secondary'}
            startIcon={<ImportExportIcon />}
            onClick={importOrders}
            disabled={importing}
          >
            {importing ? 'Importando...' : 'Importar Pedidos desde Jumpseller'}
          </Button>
          {!jumpsellerActive && (
            <Tooltip title="Activar sincronización con Jumpseller para importar pedidos">
              <Button variant="outlined" color="secondary">
                Sincronización Inactiva
              </Button>
            </Tooltip>
          )}
        </Box>

        {/* Filtros */}
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Buscar por pedido..."
                variant="outlined"
                fullWidth
                value={filters.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                label="Fecha Inicio"
                value={filters.dateRange[0]}
                onChange={(newDate) => handleFilterChange('dateRange', [newDate, filters.dateRange[1]])}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                label="Fecha Fin"
                value={filters.dateRange[1]}
                onChange={(newDate) => handleFilterChange('dateRange', [filters.dateRange[0], newDate])}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Forma de Pago</InputLabel>
                <Select
                  label="Forma de Pago"
                  value={filters.paymentMethod}
                  onChange={(e) => handleFilterChange('paymentMethod', e.target.value)}
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>
                  <MenuItem value="Mercadolibre">Mercadolibre</MenuItem>
                  <MenuItem value="Tarjeta">Tarjeta</MenuItem>
                  <MenuItem value="Efectivo">Efectivo</MenuItem>
                  {/* Agrega más métodos de pago según sea necesario */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Estado del Pago</InputLabel>
                <Select
                  label="Estado del Pago"
                  value={filters.paymentStatus}
                  onChange={(e) => handleFilterChange('paymentStatus', e.target.value)}
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>
                  <MenuItem value="Pagado">Pagado</MenuItem>
                  <MenuItem value="Pendiente">Pendiente</MenuItem>
                  <MenuItem value="Reembolsado">Reembolsado</MenuItem>
                  {/* Agrega más estados según sea necesario */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Estado del Despacho</InputLabel>
                <Select
                  label="Estado del Despacho"
                  value={filters.shippingStatus}
                  onChange={(e) => handleFilterChange('shippingStatus', e.target.value)}
                >
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>
                  <MenuItem value="No Procesado">No Procesado</MenuItem>
                  <MenuItem value="Procesado">Procesado</MenuItem>
                  <MenuItem value="Enviado">Enviado</MenuItem>
                  <MenuItem value="Entregado">Entregado</MenuItem>
                  {/* Agrega más estados según sea necesario */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {/* Tabla de pedidos */}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Número de Pedido</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Forma de Pago</TableCell>
                  <TableCell>Estado del Pago</TableCell>
                  <TableCell>Estado del Despacho</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.length > 0 ? (
                  orders.map((order) => (
                    <TableRow key={order.id} hover>
                      <TableCell>{order.order_number}</TableCell>
                      <TableCell>{new Date(order.order_date).toLocaleString('es-CL')}</TableCell>
                      <TableCell>{order.customer_name || 'Sin Nombre'}</TableCell>
                      <TableCell>{order.payment_method || 'N/A'}</TableCell>
                      <TableCell>
                        <Tooltip title={order.payment_status === 'Pagado' ? 'Pago Completo' : 'Pago Pendiente'}>
                          <Typography color={order.payment_status === 'Pagado' ? 'success.main' : 'error.main'}>
                            {order.payment_status || 'N/A'}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title={order.shipping_status === 'Entregado' ? 'Pedido Entregado' : 'Pedido en Proceso'}>
                          <Typography color={order.shipping_status === 'Entregado' ? 'success.main' : 'warning.main'}>
                            {order.shipping_status || 'N/A'}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{`$ ${Number(order.total).toLocaleString('es-CL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</TableCell>
                      <TableCell>
                        <Button 
                          variant="outlined" 
                          color="primary" 
                          size="small" 
                          onClick={() => navigate(`/orders/${order.id}/details`)}
                        >
                          Ver Detalles
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No se encontraron pedidos
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Paginación */}
        <TablePagination
          component="div"
          count={totalOrders}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />

        {/* Snackbar para notificaciones */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
};

export default OrdersPage;
