// src/pages/ImbraSync.js

import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Button,
  Box,
  CircularProgress,
  Paper,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material';

const ImbraSync = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSync = async () => {
    if (!searchTerm.trim()) {
      setLogs(['Por favor, ingresa un término de búsqueda válido.']);
      return;
    }

    setLoading(true);
    setLogs((prevLogs) => [...prevLogs, 'Inicio de sincronización...']);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/imbrasync/`, {
        searchTerm: searchTerm.trim(),
      });
      const { logs: responseLogs, message } = response.data;
      setLogs((prevLogs) => [...prevLogs, ...responseLogs, message]);
    } catch (error) {
      let errorMessage = 'Error durante la sincronización.';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage += ` ${error.response.data.error}`;
        if (error.response.data.logs) {
          setLogs((prevLogs) => [...prevLogs, ...error.response.data.logs, errorMessage]);
          setLoading(false);
          return;
        }
      } else if (error.message) {
        errorMessage += ` ${error.message}`;
      }
      setLogs((prevLogs) => [...prevLogs, `Error durante la sincronización: ${errorMessage}`]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container sx={{ mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        Sincronización de Productos con Imbra
      </Typography>
      <Box sx={{ mb: 3 }}>
        <TextField
          label="Término de Búsqueda"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mr: 2, width: '300px' }}
        />
        <Button variant="contained" color="primary" onClick={handleSync} disabled={loading}>
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Iniciar Sincronización'}
        </Button>
      </Box>
      <Paper sx={{ p: 2, maxHeight: 400, overflow: 'auto' }}>
        <Typography variant="h6">Consola de Sincronización:</Typography>
        <List>
          {logs.map((log, index) => (
            <ListItem key={index} disablePadding>
              <ListItemText primary={log} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

export default ImbraSync;
