// ../components/modals/AcceptPurchaseModal.js
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';

const AcceptPurchaseModal = ({ open, handleClose, purchaseItems, onAcceptSuccess, allUnitMappingsComplete }) => {
  const handleAccept = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/purchases/${purchaseItems[0].purchase_id}/accept`,
        {}
      );
      onAcceptSuccess();
      handleClose();
    } catch (error) {
      console.error('Error al aceptar la compra:', error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Aceptar Compra</DialogTitle>
      <DialogContent>
        <Typography>
          ¿Estás seguro de que deseas aceptar esta compra? Una vez aceptada, no podrás modificarla.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} startIcon={<CloseIcon />}>Cancelar</Button>
        <Button
          onClick={handleAccept}
          variant="contained"
          color="success"
          startIcon={<CheckCircleIcon />}
          disabled={!allUnitMappingsComplete}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptPurchaseModal;
