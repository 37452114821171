// src/pages/inadmin/AdminAdminsPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { 
    Box, 
    Button, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    TextField, 
    DialogActions, 
    Typography, 
    IconButton, 
    Tooltip, 
    Chip, 
    AppBar, 
    Toolbar, 
    Drawer, 
    List, 
    ListItem, 
    ListItemIcon, 
    ListItemText, 
    CssBaseline 
} from '@mui/material';
import { 
    Add as AddIcon, 
    Edit as EditIcon, 
    Delete as DeleteIcon, 
    CheckCircle as CheckCircleIcon, 
    Cancel as CancelIcon, 
    Security as SecurityIcon,
    Menu as MenuIcon,
    Home as HomeIcon,
    People as PeopleIcon,
    Block as BlockIcon,
    Business as BusinessIcon,
    Settings as SettingsIcon
} from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const AdminAdminsPage = () => {
    const [admins, setAdmins] = useState([]);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({ username: '', email: '', full_name: '', password: '', role: 'admin', is_active: true });
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchAdmins();
    }, []);

    const fetchAdmins = () => {
        axios.get('https://api.integranube.cl/inadmin/admins')
            .then(response => setAdmins(response.data))
            .catch(error => console.error('Error al obtener admins:', error));
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setFormData({ username: '', email: '', full_name: '', password: '', role: 'admin', is_active: true });
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = () => {
        axios.post('https://api.integranube.cl/inadmin/admins', formData)
            .then(() => {
                fetchAdmins();
                handleClose();
            })
            .catch(error => console.error('Error al crear admin:', error));
    };

    const handleEdit = (id) => {
        navigate(`/inadmin/admins/edit/${id}`);
    };

    const handleDelete = (id) => {
        axios.delete(`https://api.integranube.cl/inadmin/admins/${id}`)
            .then(() => fetchAdmins())
            .catch(error => console.error('Error al eliminar admin:', error));
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'username', headerName: 'Usuario', width: 150 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'full_name', headerName: 'Nombre Completo', width: 200 },
        { 
            field: 'role', 
            headerName: 'Rol', 
            width: 150,
            renderCell: (params) => (
                <Chip 
                    icon={<SecurityIcon />} 
                    label={params.value} 
                    color="primary" 
                    variant="outlined" 
                />
            )
        },
        { 
            field: 'is_active', 
            headerName: 'Activo', 
            width: 100,
            renderCell: (params) => (
                params.value ? 
                <Tooltip title="Activo">
                    <CheckCircleIcon sx={{ color: green[500] }} />
                </Tooltip> :
                <Tooltip title="Inactivo">
                    <CancelIcon sx={{ color: red[500] }} />
                </Tooltip>
            )
        },
        { 
            field: 'otp_enabled', 
            headerName: 'OTP', 
            width: 100,
            renderCell: (params) => (
                params.value ? 
                <Tooltip title="OTP Habilitado">
                    <CheckCircleIcon sx={{ color: green[500] }} />
                </Tooltip> :
                <Tooltip title="OTP Deshabilitado">
                    <CancelIcon sx={{ color: red[500] }} />
                </Tooltip>
            )
        },
        { field: 'created_at', headerName: 'Creado el', width: 180 },
        { field: 'last_login', headerName: 'Último Login', width: 180 },
        {
            field: 'actions',
            headerName: 'Acciones',
            width: 150,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="Editar">
                        <IconButton 
                            color="primary" 
                            onClick={() => handleEdit(params.row.id)}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                        <IconButton 
                            color="error" 
                            onClick={() => handleDelete(params.row.id)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
    ];

    const drawer = (
        <div>
            <Toolbar>
                <Typography variant="h6" noWrap component="div">
                    InAdmin
                </Typography>
            </Toolbar>
            <List>
                <ListItem button onClick={() => navigate('/inadmin')}>
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Inicio" />
                </ListItem>
                <ListItem button onClick={() => navigate('/inadmin/admins')}>
                    <ListItemIcon>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Administradores" />
                </ListItem>
                <ListItem button onClick={() => navigate('/inadmin/banned_ips')}>
                    <ListItemIcon>
                        <BlockIcon />
                    </ListItemIcon>
                    <ListItemText primary="IPs Bloqueadas" />
                </ListItem>
                <ListItem button onClick={() => navigate('/inadmin/companies')}>
                    <ListItemIcon>
                        <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary="Compañías" />
                </ListItem>
                <ListItem button onClick={() => navigate('/inadmin/settings')}>
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Configuraciones" />
                </ListItem>
            </List>
        </div>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: '#1976d2'
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Panel de Administración
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    backgroundColor: '#f0f2f5',
                    minHeight: '100vh'
                }}
            >
                <Toolbar />
                <Box sx={{ height: '80vh', width: '100%', p: 3, bgcolor: '#fff', borderRadius: 2, boxShadow: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h4" color="primary">Administradores</Typography>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            startIcon={<AddIcon />} 
                            onClick={handleOpen}
                        >
                            Crear
                        </Button>
                    </Box>
                    <DataGrid
                        rows={admins}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        checkboxSelection
                        sx={{
                            '& .MuiDataGrid-root': {
                                border: 'none',
                                fontFamily: 'Roboto, sans-serif'
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: 'none'
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#1976d2',
                                color: '#fff',
                                borderBottom: 'none'
                            },
                            '& .MuiDataGrid-virtualScroller': {
                                backgroundColor: '#f5f5f5'
                            },
                            '& .MuiDataGrid-footerContainer': {
                                borderTop: 'none',
                                backgroundColor: '#1976d2',
                                color: '#fff'
                            },
                            '& .MuiCheckbox-root': {
                                color: '#1976d2',
                            },
                        }}
                    />

                    {/* Dialog para Crear Administrador */}
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                        <DialogTitle>Crear Nuevo Administrador</DialogTitle>
                        <DialogContent>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
                                <TextField
                                    label="Nombre de Usuario"
                                    name="username"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.username}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Email"
                                    name="email"
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Nombre Completo"
                                    name="full_name"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.full_name}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Contraseña"
                                    name="password"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Rol"
                                    name="role"
                                    variant="outlined"
                                    fullWidth
                                    select
                                    SelectProps={{ native: true }}
                                    value={formData.role}
                                    onChange={handleChange}
                                >
                                    <option value="admin">Admin</option>
                                    <option value="superadmin">Superadmin</option>
                                </TextField>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ mr: 2 }}>Activo</Typography>
                                    <Button
                                        variant={formData.is_active ? "contained" : "outlined"}
                                        color={formData.is_active ? "success" : "error"}
                                        onClick={() => setFormData(prev => ({ ...prev, is_active: !prev.is_active }))}
                                        startIcon={formData.is_active ? <CheckCircleIcon /> : <CancelIcon />}
                                    >
                                        {formData.is_active ? "Sí" : "No"}
                                    </Button>
                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">Cancelar</Button>
                            <Button onClick={handleSubmit} color="primary" variant="contained">Crear</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Box>
        </Box>
    );
};

export default AdminAdminsPage;
