// src/pages/modals/AssignWarehouseModal.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Box,
} from '@mui/material';
import axios from 'axios';

const AssignWarehouseModal = ({
  open,
  onClose,
  availableWarehouses,
  productId,
  onWarehouseAssigned,
  setSnackbar,
}) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const [stockQuantity, setStockQuantity] = useState('');
  const [minimumStock, setMinimumStock] = useState('');
  const [loading, setLoading] = useState(false);

  // Resetear campos cuando se cierra el modal
  useEffect(() => {
    if (!open) {
      setSelectedWarehouse('');
      setStockQuantity('');
      setMinimumStock('');
      setLoading(false);
    }
  }, [open]);

  const handleAssign = async () => {
    // Validaciones básicas
    if (!selectedWarehouse || stockQuantity === '' || minimumStock === '') {
      setSnackbar({
        open: true,
        message: 'Todos los campos son requeridos.',
        severity: 'warning',
      });
      return;
    }

    // Validar que stockQuantity y minimumStock sean números positivos
    const stockQty = parseInt(stockQuantity, 10);
    const minStockQty = parseInt(minimumStock, 10);

    if (isNaN(stockQty) || isNaN(minStockQty) || stockQty < 0 || minStockQty < 0) {
      setSnackbar({
        open: true,
        message: 'Las cantidades deben ser números positivos.',
        severity: 'warning',
      });
      return;
    }

    setLoading(true);
    try {
      const payload = {
        warehouse_id: selectedWarehouse,
        stock_quantity: stockQty,
        minimum_stock: minStockQty,
      };

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/products/${productId}/warehouses`,
        payload
      );

      setSnackbar({
        open: true,
        message: 'Almacén asignado exitosamente.',
        severity: 'success',
      });

      // Resetear campos
      setSelectedWarehouse('');
      setStockQuantity('');
      setMinimumStock('');

      onWarehouseAssigned(); // Actualizar la lista de almacenes
      onClose(); // Cerrar el modal
    } catch (error) {
      console.error('Error al asignar almacén:', error);
      setSnackbar({
        open: true,
        message: 'Error al asignar almacén.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Asignar Nuevo Almacén</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Selecciona un almacén y completa los detalles para asignarlo al producto.
        </DialogContentText>
        <FormControl fullWidth variant="outlined" margin="dense">
          <InputLabel id="warehouse-select-label">Almacén</InputLabel>
          <Select
            labelId="warehouse-select-label"
            label="Almacén"
            value={selectedWarehouse}
            onChange={(e) => setSelectedWarehouse(e.target.value)}
          >
            <MenuItem value="">
              <em>Selecciona un almacén</em>
            </MenuItem>
            {Array.isArray(availableWarehouses) && availableWarehouses.length > 0 ? (
              availableWarehouses.map((warehouse) => (
                <MenuItem key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                No hay almacenes disponibles
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          label="Cantidad en Stock"
          type="number"
          fullWidth
          variant="outlined"
          value={stockQuantity}
          onChange={(e) => setStockQuantity(e.target.value)}
          inputProps={{ min: '0', step: '1' }}
        />
        <TextField
          margin="dense"
          label="Stock Mínimo"
          type="number"
          fullWidth
          variant="outlined"
          value={minimumStock}
          onChange={(e) => setMinimumStock(e.target.value)}
          inputProps={{ min: '0', step: '1' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancelar
        </Button>
        <Button
          onClick={handleAssign}
          disabled={loading || !selectedWarehouse || stockQuantity === '' || minimumStock === ''}
          variant="contained"
          color="primary"
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? 'Asignando...' : 'Asignar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AssignWarehouseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  availableWarehouses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      // Puedes agregar otras propiedades si es necesario
    })
  ).isRequired,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onWarehouseAssigned: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
};

export default AssignWarehouseModal;
