import React, { useState } from 'react';
import axios from 'axios';

const AutomationForm = () => {
  const [name, setName] = useState('');
  const [interval, setInterval] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const [method, setMethod] = useState('POST');
  const [active, setActive] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/automations', {
        name,
        interval,
        endpoint,
        method,
        active
      });
      alert('Automatización creada exitosamente');
    } catch (error) {
      console.error('Error al crear automatización:', error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Nombre de la Tarea"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Intervalo (Formato Cron)"
        value={interval}
        onChange={(e) => setInterval(e.target.value)}
      />
      <input
        type="text"
        placeholder="Endpoint"
        value={endpoint}
        onChange={(e) => setEndpoint(e.target.value)}
      />
      <select value={method} onChange={(e) => setMethod(e.target.value)}>
        <option value="POST">POST</option>
        <option value="GET">GET</option>
      </select>
      <label>
        Activa:
        <input type="checkbox" checked={active} onChange={(e) => setActive(e.target.checked)} />
      </label>
      <button type="submit">Crear Automatización</button>
    </form>
  );
};

export default AutomationForm;
