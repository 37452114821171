// src/components/Sidebar.js

import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Toolbar,
  IconButton,
  useTheme,
  useMediaQuery,
  Divider,
  Box,
} from '@mui/material';
import {
  Add as AddIcon,
  Autorenew as AutorenewIcon,
  Category as CategoryIcon,
  Dashboard as DashboardIcon,
  ExpandLess,
  ExpandMore,
  LocalShipping as LocalShippingIcon,
  ShoppingCart as ShoppingCartIcon,
  Store as StoreIcon,
  Extension as ExtensionIcon,
  Settings as SettingsIcon,
  Menu as MenuIcon,
  Group as GroupIcon,
  Upload as UploadIcon, // Icono para carga masiva
  Chat as ChatIcon, // Icono para WhatsApp
  People as PeopleIcon, // Icono para Clientes
} from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';

const drawerWidth = 240;

// Componente para los items del Sidebar
const SidebarItem = ({ label, icon, to, handleClick, children }) => {
  const location = useLocation();
  const isActive = to && location.pathname === to;

  return (
    <ListItemButton
      component={to ? Link : 'button'}
      to={to || undefined}
      onClick={handleClick}
      selected={isActive}
      sx={{
        justifyContent: 'initial',
        px: 2.5,
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: 3,
          justifyContent: 'center',
          color: isActive ? 'primary.main' : 'inherit',
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText primary={label} sx={{ opacity: 1 }} />
      {children}
    </ListItemButton>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);

  // Estados para manejar la expansión de submenús
  const [openProducts, setOpenProducts] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);
  const [openIntegrations, setOpenIntegrations] = useState(false);
  const [openOrders, setOpenOrders] = useState(false);
  const [openAutomations, setOpenAutomations] = useState(false);
  const [openPurchases, setOpenPurchases] = useState(false);
  const [openStock, setOpenStock] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openSuppliers, setOpenSuppliers] = useState(false); // Nuevo estado para el menú de proveedores
  const [openWhatsApp, setOpenWhatsApp] = useState(false); // Nuevo estado para el menú de WhatsApp
  const [openCustomers, setOpenCustomers] = useState(false); // Nuevo estado para el menú de Clientes

  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleSubMenu = (setter, current) => {
    setter(!current);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {/* Categorías */}
        <SidebarItem
          label="Categorías"
          icon={<CategoryIcon />}
          handleClick={() => toggleSubMenu(setOpenCategories, openCategories)}
        >
          {openCategories ? <ExpandLess /> : <ExpandMore />}
        </SidebarItem>
        <Collapse in={openCategories} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              component={Link}
              to="/categories"
              sx={{ pl: 4 }}
              selected={location.pathname === '/categories'}
            >
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary="Ver Categorías" />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/categories/create"
              sx={{ pl: 4 }}
              selected={location.pathname === '/categories/create'}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Crear Categoría" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider />

        {/* Compras */}
        <SidebarItem
          label="Compras"
          icon={<ShoppingCartIcon />}
          handleClick={() => toggleSubMenu(setOpenPurchases, openPurchases)}
        >
          {openPurchases ? <ExpandLess /> : <ExpandMore />}
        </SidebarItem>
        <Collapse in={openPurchases} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              component={Link}
              to="/purchases"
              sx={{ pl: 4 }}
              selected={location.pathname === '/purchases'}
            >
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Ver Compras" />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/purchases/create"
              sx={{ pl: 4 }}
              selected={location.pathname === '/purchases/create'}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Crear Compra" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider />

        {/* Dashboard */}
        <SidebarItem label="Dashboard" icon={<DashboardIcon />} to="/" />
        <Divider />

        {/* Integraciones */}
        <SidebarItem
          label="Integraciones"
          icon={<ExtensionIcon />}
          handleClick={() => toggleSubMenu(setOpenIntegrations, openIntegrations)}
        >
          {openIntegrations ? <ExpandLess /> : <ExpandMore />}
        </SidebarItem>
        <Collapse in={openIntegrations} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              component={Link}
              to="/integrations"
              sx={{ pl: 4 }}
              selected={location.pathname === '/integrations'}
            >
              <ListItemIcon>
                <ExtensionIcon />
              </ListItemIcon>
              <ListItemText primary="Ver Integraciones" />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/integrations/create"
              sx={{ pl: 4 }}
              selected={location.pathname === '/integrations/create'}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Crear Integración" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider />

        {/* Ventas */}
        <SidebarItem
          label="Ventas"
          icon={<LocalShippingIcon />}
          handleClick={() => toggleSubMenu(setOpenOrders, openOrders)}
        >
          {openOrders ? <ExpandLess /> : <ExpandMore />}
        </SidebarItem>
        <Collapse in={openOrders} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              component={Link}
              to="/pos"
              sx={{ pl: 4 }}
              selected={location.pathname === '/pos'}
            >
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Punto de Venta" />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/sales"
              sx={{ pl: 4 }}
              selected={location.pathname === '/sales'}
            >
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Ventas" />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/orders"
              sx={{ pl: 4 }}
              selected={location.pathname === '/orders'}
            >
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Ver Pedidos" />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/orders/create"
              sx={{ pl: 4 }}
              selected={location.pathname === '/orders/create'}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Crear Pedido" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider />

        {/* Productos */}
        <SidebarItem
          label="Productos"
          icon={<StoreIcon />}
          handleClick={() => toggleSubMenu(setOpenProducts, openProducts)}
        >
          {openProducts ? <ExpandLess /> : <ExpandMore />}
        </SidebarItem>
        <Collapse in={openProducts} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              component={Link}
              to="/products"
              sx={{ pl: 4 }}
              selected={location.pathname === '/products'}
            >
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Ver Productos" />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/products/create"
              sx={{ pl: 4 }}
              selected={location.pathname === '/products/create'}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Crear Producto" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider />

        {/* Proveedores */}
        <SidebarItem
          label="Proveedores"
          icon={<GroupIcon />}
          handleClick={() => toggleSubMenu(setOpenSuppliers, openSuppliers)}
        >
          {openSuppliers ? <ExpandLess /> : <ExpandMore />}
        </SidebarItem>
        <Collapse in={openSuppliers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              component={Link}
              to="/suppliers"
              sx={{ pl: 4 }}
              selected={location.pathname === '/suppliers'}
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="Ver Proveedores" />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/suppliers-bulk-upload"
              sx={{ pl: 4 }}
              selected={location.pathname === '/suppliers-bulk-upload'}
            >
              <ListItemIcon>
                <UploadIcon />
              </ListItemIcon>
              <ListItemText primary="Carga de Proveedores" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider />

        {/* Stock */}
        <SidebarItem
          label="Stock"
          icon={<StoreIcon />}
          handleClick={() => toggleSubMenu(setOpenStock, openStock)}
        >
          {openStock ? <ExpandLess /> : <ExpandMore />}
        </SidebarItem>
        <Collapse in={openStock} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* Toma de Inventario */}
            <ListItemButton
              component={Link}
              to="/inventory"
              sx={{ pl: 4 }}
              selected={location.pathname === '/inventory'}
            >
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary="Toma de Inventario" />
            </ListItemButton>

            {/* Ver Bodegas */}
            <ListItemButton
              component={Link}
              to="/warehouses"
              sx={{ pl: 4 }}
              selected={location.pathname === '/warehouses'}
            >
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary="Ver Bodegas" />
            </ListItemButton>

            {/* Crear Bodega */}
            <ListItemButton
              component={Link}
              to="/warehouses/create"
              sx={{ pl: 4 }}
              selected={location.pathname === '/warehouses/create'}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Crear Bodega" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider />

        {/* Configuraciones */}
        <SidebarItem
          label="Configuraciones"
          icon={<SettingsIcon />}
          handleClick={() => toggleSubMenu(setOpenSettings, openSettings)}
        >
          {openSettings ? <ExpandLess /> : <ExpandMore />}
        </SidebarItem>
        <Collapse in={openSettings} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              component={Link}
              to="/settings"
              sx={{ pl: 4 }}
              selected={location.pathname === '/settings'}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Ver Configuraciones" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider />

        {/* WhatsApp */}
        <SidebarItem
          label="WhatsApp"
          icon={<ChatIcon />}
          handleClick={() => toggleSubMenu(setOpenWhatsApp, openWhatsApp)}
        >
          {openWhatsApp ? <ExpandLess /> : <ExpandMore />}
        </SidebarItem>
        <Collapse in={openWhatsApp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              component={Link}
              to="/whatsapp"
              sx={{ pl: 4 }}
              selected={location.pathname === '/whatsapp'}
            >
              <ListItemIcon>
                <ChatIcon />
              </ListItemIcon>
              <ListItemText primary="Ver Chats" />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/whatsapp/create-message"
              sx={{ pl: 4 }}
              selected={location.pathname === '/whatsapp/create-message'}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Crear Mensaje" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider />

        {/* Clientes */}
        <SidebarItem
          label="Clientes"
          icon={<PeopleIcon />}
          handleClick={() => toggleSubMenu(setOpenCustomers, openCustomers)}
        >
          {openCustomers ? <ExpandLess /> : <ExpandMore />}
        </SidebarItem>
        <Collapse in={openCustomers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              component={Link}
              to="/customers"
              sx={{ pl: 4 }}
              selected={location.pathname === '/customers'}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Ver Clientes" />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/customers/create"
              sx={{ pl: 4 }}
              selected={location.pathname === '/customers/create'}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Crear Cliente" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider />
      </List>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: drawerWidth, flexShrink: 0 }}
      aria-label="navegación principal"
    >
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="abrir menú"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ m: 1 }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Mejora el rendimiento en dispositivos móviles
        }}
        sx={{
          display: { xs: 'block', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            transition: 'none', // Elimina la transición de ancho
            overflow: 'hidden',
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
