// src/components/StatCard.js

import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme, color }) => ({
  backgroundColor: color,
  color: theme.palette.common.white,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

const StatCard = ({ color, title, value, icon: Icon }) => {
  return (
    <StyledPaper color={color}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Icon sx={{ fontSize: 40, mr: 2 }} />
        <Box>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="h6">{value}</Typography>
        </Box>
      </Box>
    </StyledPaper>
  );
};

export default StatCard;
