// src/pages/WarehouseView.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Checkbox,
  Pagination,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
  Toolbar,
  Tooltip,
  Grid,
} from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import { Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon } from '@mui/icons-material';

const WarehouseView = () => {
  const { warehouseId } = useParams();
  const [warehouse, setWarehouse] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Función para obtener detalles de la bodega
  const fetchWarehouseDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/warehouses/${warehouseId}`);
      setWarehouse(response.data);
    } catch (error) {
      console.error('Error al obtener detalles de la bodega:', error);
      setSnackbar({
        open: true,
        message: 'Error al cargar detalles de la bodega.',
        severity: 'error',
      });
    }
  };

  // Función para obtener productos de la bodega
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/warehouses/${warehouseId}/products`, {
        params: {
          page,
          pageSize,
          search: searchQuery,
        },
      });

      if (response.data) {
        setProducts(response.data.products);
        setTotalPages(response.data.totalPages);
      } else {
        setProducts([]);
        setTotalPages(1);
      }
    } catch (error) {
      console.error('Error al obtener productos de la bodega:', error);
      setSnackbar({
        open: true,
        message: 'Error al cargar productos de la bodega.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWarehouseDetails();
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId, page, searchQuery]);

  const handleSelectProduct = (id) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((pid) => pid !== id) : [...prevSelected, id]
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = products.map((product) => product.id);
      setSelectedProducts(newSelected);
    } else {
      setSelectedProducts([]);
    }
  };

  const handleDeleteProduct = (productId) => {
    setDeleteProductId(productId);
    setOpenDeleteDialog(true);
  };

  const confirmDeleteProduct = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/warehouses/${warehouseId}/products/${deleteProductId}`);
      setSnackbar({
        open: true,
        message: 'Producto eliminado de la bodega correctamente.',
        severity: 'success',
      });
      setOpenDeleteDialog(false);
      setDeleteProductId(null);
      fetchProducts();
    } catch (error) {
      console.error('Error al eliminar el producto de la bodega:', error);
      setSnackbar({
        open: true,
        message: 'Error al eliminar el producto de la bodega.',
        severity: 'error',
      });
    }
  };

  const handleBulkDelete = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/warehouses/${warehouseId}/products/bulk-delete`, {
        ids: selectedProducts,
      });
      setSnackbar({
        open: true,
        message: 'Productos eliminados de la bodega correctamente.',
        severity: 'success',
      });
      setSelectedProducts([]);
      fetchProducts();
    } catch (error) {
      console.error('Error al eliminar productos de la bodega:', error);
      setSnackbar({
        open: true,
        message: 'Error al eliminar productos de la bodega.',
        severity: 'error',
      });
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Detalles de la Bodega: {warehouse ? warehouse.name : 'Cargando...'}
      </Typography>

      {/* Información básica de la bodega */}
      {warehouse && (
        <Paper elevation={3} sx={{ padding: 3, mb: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1"><strong>ID:</strong> {warehouse.id}</Typography>
              <Typography variant="subtitle1"><strong>Ubicación:</strong> {warehouse.location}</Typography>
              <Typography variant="subtitle1"><strong>Estado:</strong> 
                <Box component="span" sx={{ color: warehouse.status === 'activo' ? 'green' : 'red', ml: 1, textTransform: 'capitalize' }}>
                  {warehouse.status}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1"><strong>Descripción:</strong> {warehouse.description || 'N/A'}</Typography>
              <Typography variant="subtitle1"><strong>Creado el:</strong> {new Date(warehouse.created_at).toLocaleString()}</Typography>
              <Typography variant="subtitle1"><strong>Última actualización:</strong> {new Date(warehouse.updated_at).toLocaleString()}</Typography>
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* Botones de acción */}
      <Toolbar sx={{ justifyContent: 'space-between', mb: 2 }}>
        {selectedProducts.length > 0 && (
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleBulkDelete}
          >
            Eliminar {selectedProducts.length} Producto(s)
          </Button>
        )}
      </Toolbar>

      {/* Barra de Búsqueda */}
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
        <SearchIcon color="action" sx={{ mr: 1 }} />
        <TextField
          label="Buscar Producto"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
        />
      </Box>

      {/* Tabla de productos */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedProducts.length === products.length && products.length > 0}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Nombre del Producto</TableCell>
              <TableCell align="right">Cantidad en Stock</TableCell>
              <TableCell align="right">Stock Mínimo</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Última Actualización</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : products.length > 0 ? (
              products.map((product) => (
                <TableRow key={product.id} hover>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedProducts.includes(product.id)}
                      onChange={() => handleSelectProduct(product.id)}
                    />
                  </TableCell>
                  <TableCell>{product.id}</TableCell>
                  <TableCell>{product.product_name}</TableCell>
                  <TableCell align="right">{product.stock_quantity}</TableCell>
                  <TableCell align="right">{product.minimum_stock}</TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      color={product.status === 'activo' ? 'green' : 'red'}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {product.status}
                    </Typography>
                  </TableCell>
                  <TableCell>{new Date(product.last_updated).toLocaleString()}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Editar Producto">
                      <IconButton
                        color="primary"
                        component={Link}
                        to={`/warehouses/${warehouseId}/products/edit/${product.id}`}
                        sx={{ mr: 1 }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar Producto">
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteProduct(product.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No se encontraron productos en esta bodega.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Paginación */}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </Box>

      {/* Diálogo de Confirmación para Eliminar Producto */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas eliminar este producto de la bodega? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancelar</Button>
          <Button onClick={confirmDeleteProduct} color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para Notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default WarehouseView;
