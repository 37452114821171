// src/pages/components/Layout.js

import React, { useState, useEffect } from 'react';
import { Box, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import Sidebar from './Sidebar';

const Layout = ({ children, onLogout }) => {
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    // Obtener datos del usuario y la empresa desde localStorage
    const storedUser = localStorage.getItem('user');
    const storedCompany = localStorage.getItem('company');

    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    if (storedCompany) {
      setCompany(JSON.parse(storedCompany));
    }
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              Panel de Control
            </Typography>
            {user && company && (
              <Typography variant="body1" noWrap component="div" sx={{ marginRight: 2 }}>
                {user.name} - {company.company_name}
              </Typography>
            )}
            <IconButton color="inherit" onClick={onLogout}>
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
