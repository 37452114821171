// ProductsPage.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Checkbox,
  Pagination,
  Chip,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Grid,
} from '@mui/material';
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Upload as UploadIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [statuses] = useState([
    { value: 'available', label: 'Disponible' },
    { value: 'not-available', label: 'Agotado' },
    { value: 'disabled', label: 'Deshabilitado' },
    { value: 'featured', label: 'Destacado' },
  ]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTask, setCurrentTask] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [sortBy, setSortBy] = useState('sku');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [bulkEditOpen, setBulkEditOpen] = useState(false);
  const [bulkEditData, setBulkEditData] = useState({
    price: '',
    stock: '',
  });

  const navigate = useNavigate();

  const tenantId = localStorage.getItem('tenantId') || 'default';
  const imageBaseUrl = `${process.env.REACT_APP_PUBLIC_URL}/images/products/tenant_${tenantId}/`;

  const fetchProductsWithPagination = async (currentPage, search = '') => {
    setLoading(true);
    try {
      const productsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/products/`,
        {
          params: {
            page: currentPage,
            pageSize: limit,
            searchTerm: search,
            category: selectedCategory,
            status: selectedStatus,
            sortBy: sortBy,
            sortOrder: sortOrder,
          },
          headers: {
            'x-tenant-id': tenantId,
          },
        }
      );

      let fetchedProducts = [];
      if (productsResponse.data?.products) {
        fetchedProducts = productsResponse.data.products.map((product) => ({
          ...product,
          image: product.image || null,
        }));
        setProducts(fetchedProducts);
        setTotalPages(productsResponse.data.totalPages);
      }
    } catch (error) {
      console.error('Error al cargar los productos:', error);
      alert('Error al cargar los productos.');
    }
    setLoading(false);
  };

  const fetchFilters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/products/filters`,
        {
          headers: {
            'x-tenant-id': tenantId,
          },
        }
      );
      setCategories(response.data.categories);
    } catch (error) {
      console.error('Error al cargar filtros:', error);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    fetchProductsWithPagination(page, searchTerm);
  }, [page, searchTerm, selectedCategory, selectedStatus, sortBy, sortOrder]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setPage(1);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setPage(1);
  };

  const handleSortByChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  const getStockColor = (stock) => {
    if (typeof stock !== 'number') {
      return 'default';
    }
    if (stock > 10) return 'success';
    if (stock > 0) return 'warning';
    return 'error';
  };

  const totalStock = products.reduce(
    (acc, product) => acc + (parseInt(product.total_stock, 10) || 0),
    0
  );

  const goToProductView = (productId) => {
    navigate(`/products/view/${productId}`);
  };

  const goToProductEdit = (productId) => {
    navigate(`/products/edit/${productId}`);
  };

  const handleSelectProduct = (event, productId) => {
    if (event.target.checked) {
      setSelectedProducts([...selectedProducts, productId]);
    } else {
      setSelectedProducts(selectedProducts.filter((id) => id !== productId));
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = products.map((product) => product.id);
      setSelectedProducts(newSelecteds);
    } else {
      setSelectedProducts([]);
    }
  };

  const handleDeleteProduct = async (productId) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este producto?')) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/products/${productId}`,
          {
            headers: {
              'x-tenant-id': tenantId,
            },
          }
        );
        alert('Producto eliminado correctamente.');
        fetchProductsWithPagination(page, searchTerm);
      } catch (error) {
        console.error('Error al eliminar el producto:', error);
        alert('Error al eliminar el producto.');
      }
    }
  };

  const handleBulkDelete = async () => {
    if (
      window.confirm(
        '¿Estás seguro de que deseas eliminar los productos seleccionados?'
      )
    ) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/products/bulk-delete`,
          { ids: selectedProducts },
          {
            headers: {
              'x-tenant-id': tenantId,
            },
          }
        );
        alert('Productos eliminados correctamente.');
        setSelectedProducts([]);
        fetchProductsWithPagination(page, searchTerm);
      } catch (error) {
        console.error('Error al eliminar los productos:', error);
        alert('Error al eliminar los productos.');
      }
    }
  };

  const handleBulkEditOpen = () => {
    setBulkEditOpen(true);
  };

  const handleBulkEditClose = () => {
    setBulkEditOpen(false);
    setBulkEditData({ price: '', stock: '' });
  };

  const handleBulkEditChange = (event) => {
    const { name, value } = event.target;
    setBulkEditData({ ...bulkEditData, [name]: value });
  };

  const handleBulkEditApply = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/products/bulk-update`,
        {
          productIds: selectedProducts,
          data: bulkEditData,
        },
        {
          headers: {
            'x-tenant-id': tenantId,
          },
        }
      );
      alert('Productos actualizados correctamente.');
      handleBulkEditClose();
      setSelectedProducts([]);
      fetchProductsWithPagination(page, searchTerm);
    } catch (error) {
      console.error('Error al actualizar los productos:', error);
      alert('Error al actualizar los productos.');
    }
  };

  const truncateText = (text, maxLength) => {
    if (!text) return 'Sin nombre';
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const mapStatusLabel = (status) => {
    switch (status) {
      case 'available':
        return 'Disponible';
      case 'not-available':
        return 'Agotado';
      case 'disabled':
        return 'Deshabilitado';
      case 'featured':
        return 'Destacado';
      default:
        return status;
    }
  };

  return (
    <Box sx={{ p: 3, bgcolor: '#f9f9f9', minHeight: '100vh' }}>
      <Box sx={{ backgroundColor: '#fff', borderRadius: 3, p: 3, boxShadow: 3 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
          Gestión de Productos
        </Typography>

        {/* Filtros y Botón de Carga Masiva */}
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              placeholder="Buscar productos..."
              value={searchTerm}
              onChange={handleSearch}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Categoría</InputLabel>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                label="Categoría"
                name="category"
              >
                <MenuItem value="">
                  <em>Todas</em>
                </MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Filtro de Estado */}
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Estado</InputLabel>
              <Select
                value={selectedStatus}
                onChange={handleStatusChange}
                label="Estado"
              >
                <MenuItem value="">
                  <em>Todos</em>
                </MenuItem>
                {statuses.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Ordenar por</InputLabel>
              <Select
                value={sortBy}
                onChange={handleSortByChange}
                label="Ordenar por"
              >
                <MenuItem value="sku">SKU</MenuItem>
                <MenuItem value="name">Nombre</MenuItem>
                <MenuItem value="price">Precio</MenuItem>
                <MenuItem value="total_stock">Stock</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Orden</InputLabel>
              <Select
                value={sortOrder}
                onChange={handleSortOrderChange}
                label="Orden"
              >
                <MenuItem value="asc">Ascendente</MenuItem>
                <MenuItem value="desc">Descendente</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<UploadIcon />}
              onClick={() => navigate('/products/bulk-upload')}
              fullWidth
            >
              Carga Masiva
            </Button>
          </Grid>
        </Grid>

        {/* Tabla de Productos */}
        <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2, overflow: 'hidden' }}>
          <Table size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell padding="checkbox" sx={{ width: '5%' }}>
                  <Checkbox
                    indeterminate={selectedProducts.length > 0 && selectedProducts.length < products.length}
                    checked={products.length > 0 && selectedProducts.length === products.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell sx={{ width: '20%' }}>Imagen</TableCell>
                <TableCell sx={{ width: '35%' }}>Nombre</TableCell>
                <TableCell sx={{ width: '10%' }}>SKU</TableCell>
                <TableCell sx={{ width: '10%' }}>Precio</TableCell>
                <TableCell sx={{ width: '5%' }}>Stock</TableCell>
                <TableCell sx={{ width: '10%' }}>Estado</TableCell>
                <TableCell sx={{ width: '10%' }}>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.length > 0 ? (
                products.map((product) => {
                  const stock = parseInt(product.total_stock, 10) || 0;
                  const imageUrl = product.image
                    ? `${imageBaseUrl}${product.image}`
                    : null;
                  return (
                    <TableRow
                      key={product.id}
                      hover
                      onClick={() => goToProductView(product.id)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                          checked={selectedProducts.includes(product.id)}
                          onChange={(event) => handleSelectProduct(event, product.id)}
                        />
                      </TableCell>
                      <TableCell sx={{ width: '20%', padding: '4px' }}>
                        {imageUrl ? (
                          <Avatar
                            variant="square"
                            src={imageUrl}
                            alt={product.name}
                            sx={{ width: 60, height: 60 }}
                          />
                        ) : (
                          <Avatar variant="square" sx={{ width: 40, height: 40 }}>
                            N/A
                          </Avatar>
                        )}
                      </TableCell>
                      <TableCell>
                        <Tooltip title={product.name || 'Sin nombre'}>
                          <Typography variant="body2">
                            {truncateText(product.name, 80)}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{product.sku || 'Sin SKU'}</TableCell>
                      <TableCell>
                        {product.price !== undefined && product.price !== null
                          ? new Intl.NumberFormat('es-CL', {
                              style: 'currency',
                              currency: 'CLP',
                            }).format(product.price)
                          : '$ 0'}
                      </TableCell>
                      <TableCell>
                        <Chip label={stock} color={getStockColor(stock)} size="small" />
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={mapStatusLabel(product.status)}
                          size="small"
                          color={
                            product.status === 'available'
                              ? 'success'
                              : product.status === 'not-available'
                              ? 'warning'
                              : product.status === 'disabled'
                              ? 'default'
                              : 'primary'
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            goToProductEdit(product.id);
                          }}
                          startIcon={<EditIcon />}
                        >
                          Editar
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No se encontraron productos
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination count={totalPages} page={page} onChange={handlePageChange} sx={{ mt: 3 }} color="primary" />

        {/* Bulk Edit Dialog */}
        <Dialog open={bulkEditOpen} onClose={handleBulkEditClose}>
          <DialogTitle>Modificar Productos Seleccionados</DialogTitle>
          <DialogContent>
            <TextField
              label="Precio"
              name="price"
              type="number"
              value={bulkEditData.price}
              onChange={handleBulkEditChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Stock"
              name="stock"
              type="number"
              value={bulkEditData.stock}
              onChange={handleBulkEditChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleBulkEditClose}>Cancelar</Button>
            <Button variant="contained" onClick={handleBulkEditApply}>
              Aplicar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default ProductsPage;
