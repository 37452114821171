// src/pages/OrderEditPage.js
import React from 'react';
import { useParams } from 'react-router-dom';

const OrderEditPage = () => {
  const { id } = useParams();

  return (
    <div>
      <h1>Editar Pedido {id}</h1>
      {/* Aquí irá el formulario para editar un pedido */}
    </div>
  );
};

export default OrderEditPage;
