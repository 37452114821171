// src/pages/POS.js 
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
  IconButton,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Tooltip,
  Avatar,
  InputAdornment,
  Card,
  CardActionArea,
} from '@mui/material';
import {
  Save as SaveIcon,
  Close as CloseIcon,
  PointOfSale as PointOfSaleIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  Delete as DeleteIcon,
  Receipt as ReceiptIcon,
  Search as SearchIcon,
  Description as DescriptionIcon,
  Assignment as AssignmentIcon,
  LocalShipping as LocalShippingIcon,
  Payment as PaymentIcon,
  CreditCard as CreditCardIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  MoreHoriz as MoreHorizIcon,
} from '@mui/icons-material';
import axios from 'axios';

const apiUrl = `${process.env.REACT_APP_API_URL}/api`;

// Configurar Axios para usar la variable de entorno y agregar headers de autenticación si es necesario
const axiosInstance = axios.create({
  baseURL: apiUrl, // Utiliza la variable de entorno correctamente
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    // Agrega los headers de autenticación si son necesarios
    // Por ejemplo, si usas tokens JWT:
    // Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});

const VAT_RATE = 19; // Porcentaje de IVA

// Formateador para pesos chilenos sin decimales
const formatter = new Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'CLP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const POS = () => {
  const [barcodeBuffer, setBarcodeBuffer] = useState(''); // Buffer para el código de barras
  const [suggestions, setSuggestions] = useState([]); // Sugerencias de productos
  const [items, setItems] = useState([]); // Items en la venta
  const [suggestionsModalOpen, setSuggestionsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState('');
  const [customer, setCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [customerSearch, setCustomerSearch] = useState('');
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [selectedCustomerIndex, setSelectedCustomerIndex] = useState(-1);
  const [discount, setDiscount] = useState(0); // Descuento global
  const [receiptModalOpen, setReceiptModalOpen] = useState(false); // Modal para el recibo

  // Nuevas variables de estado para el manejo del pago
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [cashGiven, setCashGiven] = useState(0);
  const [change, setChange] = useState(0);
  const [cardAmount, setCardAmount] = useState(0);
  const [voucherNumber, setVoucherNumber] = useState('');
  const [amountPaid, setAmountPaid] = useState(0);
  const [remainingBalance, setRemainingBalance] = useState(0);

  // Estado para Tipo de Documento
  const [documentType, setDocumentType] = useState('boleta'); // 'boleta' por defecto
  const [documentTypeModalOpen, setDocumentTypeModalOpen] = useState(false);

  // Estado para Otros Métodos de Pago
  const [otherPaymentModalOpen, setOtherPaymentModalOpen] = useState(false);

  // Referencia al campo de búsqueda de clientes para enfocar
  const customerSearchRef = useRef(null);

  useEffect(() => {
    const fetchPaymentSettings = async () => {
      try {
        const paymentResponse = await axiosInstance.get('/settings/category/Payments'); // Asegúrate de que el endpoint es correcto
        const settings = paymentResponse.data;

        const paymentMethodsList = settings.filter(setting => setting.key.startsWith('payment_method_'));
        setPaymentMethods(paymentMethodsList);
      } catch (error) {
        console.error('Error al obtener métodos de pago:', error.response?.data || error.message);
        setPaymentMethods([]);
        setSnackbar({ open: true, message: 'Error al obtener métodos de pago.', severity: 'error' });
      }
    };

    const fetchPOSSettings = async () => {
      try {
        const posResponse = await axiosInstance.get('/settings/category/POS'); // Asegúrate de que el endpoint es correcto
        const posSettings = posResponse.data;

        const defaultPaymentMethodKey = posSettings.find(setting => setting.key === 'default_payment_method')?.value;
        const defaultCustomerId = posSettings.find(setting => setting.key === 'default_customer_id')?.value;

        if (defaultPaymentMethodKey) {
          setDefaultPaymentMethod(defaultPaymentMethodKey);
          setPaymentMethod(defaultPaymentMethodKey);
        }

        if (defaultCustomerId) {
          const customerResponse = await axiosInstance.get(`/customers/${defaultCustomerId}`);
          if (customerResponse.data && customerResponse.data.id) {
            setCustomer(customerResponse.data);
          } else {
            setSnackbar({ open: true, message: 'Cliente por defecto no encontrado.', severity: 'warning' });
          }
        }
      } catch (error) {
        console.error('Error al obtener configuraciones de POS:', error.response?.data || error.message);
        setSnackbar({ open: true, message: 'Error al obtener configuraciones de POS.', severity: 'error' });
      }
    };

    fetchPaymentSettings();
    fetchPOSSettings();
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        e.target.tagName === 'INPUT' ||
        e.target.tagName === 'TEXTAREA' ||
        e.target.isContentEditable
      ) {
        return;
      }

      if (e.key === 'Enter') {
        e.preventDefault();
        if (barcodeBuffer.trim()) {
          processBarcode(barcodeBuffer.trim());
          setBarcodeBuffer('');
        }
      } else if (e.key.length === 1) {
        setBarcodeBuffer((prev) => prev + e.key);
      }

      if (suggestionsModalOpen) {
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedSuggestionIndex((prevIndex) => Math.min(prevIndex + 1, suggestions.length - 1));
        } else if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (e.key === 'Enter' && selectedSuggestionIndex >= 0) {
          e.preventDefault();
          handleSelectSuggestion(suggestions[selectedSuggestionIndex]);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [barcodeBuffer, suggestionsModalOpen, selectedSuggestionIndex, suggestions]);

  const processBarcode = async (scannedBarcode) => {
    if (!scannedBarcode) return;
    setLoading(true);
    setSuggestions([]);
    try {
      const response = await axiosInstance.get(`/products/internal`, {
        params: { search: scannedBarcode },
      });

      if (response.data.products && response.data.products.length === 1) {
        addItemToSale(response.data.products[0]);
        setSnackbar({ open: true, message: 'Producto agregado a la venta', severity: 'success' });
      } else if (response.data.products && response.data.products.length > 0) {
        setSuggestions(response.data.products);
        setSuggestionsModalOpen(true);
        setSelectedSuggestionIndex(0);
      } else {
        setSnackbar({ open: true, message: 'No se encontraron productos.', severity: 'warning' });
      }
    } catch (error) {
      console.error('Error al buscar el producto:', error.response?.data || error.message);
      setSnackbar({ open: true, message: 'Error al buscar el producto.', severity: 'error' });
    }
    setLoading(false);
  };

  const addItemToSale = (product) => {
    const existingItem = items.find((item) => item.product_id === product.id);
    const price = parseFloat(product.price);

    if (isNaN(price)) {
      setSnackbar({ open: true, message: 'Precio del producto inválido.', severity: 'error' });
      return;
    }

    const netPrice = parseFloat((price / (1 + VAT_RATE / 100)).toFixed(2));
    const vatAmount = parseFloat((netPrice * (VAT_RATE / 100)).toFixed(2));
    const totalPrice = parseFloat(price.toFixed(2)); // Precio por unidad con IVA

    if (existingItem) {
      const updatedItems = items.map((item) =>
        item.product_id === product.id
          ? {
              ...item,
              quantity: item.quantity + 1,
            }
          : item
      );
      setItems(updatedItems);
    } else {
      setItems([
        ...items,
        {
          product_id: product.id,
          name: product.name,
          sku: product.sku || '',
          quantity: 1,
          unit_price: totalPrice,
          net: netPrice,
          vat: vatAmount,
        },
      ]);
    }
  };

  const handleSelectSuggestion = (suggestedProduct) => {
    addItemToSale(suggestedProduct);
    setSuggestionsModalOpen(false);
    setSelectedSuggestionIndex(-1);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleIncrement = (product_id) => {
    const updatedItems = items.map((item) =>
      item.product_id === product_id
        ? {
            ...item,
            quantity: item.quantity + 1,
          }
        : item
    );
    setItems(updatedItems);
  };

  const handleDecrement = (product_id) => {
    const updatedItems = items
      .map((item) =>
        item.product_id === product_id
          ? {
              ...item,
              quantity: item.quantity - 1,
            }
          : item
      )
      .filter((item) => item.quantity > 0);
    setItems(updatedItems);
  };

  const handleRemove = (product_id) => {
    const updatedItems = items.filter((item) => item.product_id !== product_id);
    setItems(updatedItems);
  };

  const handleDiscountChange = (e) => {
    let value = parseFloat(e.target.value);
    if (isNaN(value) || value < 0) value = 0;
    if (value > 100) value = 100;
    setDiscount(value);
  };

  const handleOpenPaymentModal = () => {
    setPaymentModalOpen(true);
    setCashGiven(0);
    setChange(0);
    setCardAmount(totalPayable);
    setVoucherNumber('');
  };

  const handlePaymentSubmit = async () => {
    let amountPaidValue = 0;
    let remainingBalanceValue = 0;

    if (paymentMethod === 'payment_method_cash') {
      amountPaidValue = cashGiven;
      remainingBalanceValue = totalPayable - amountPaidValue;
    } else {
      amountPaidValue = cardAmount;
      remainingBalanceValue = totalPayable - amountPaidValue;
    }

    amountPaidValue = Math.max(0, amountPaidValue);
    remainingBalanceValue = Math.max(0, remainingBalanceValue);

    await handleSubmit(amountPaidValue, remainingBalanceValue);
    setPaymentModalOpen(false);
  };

  const handleSubmit = async (amountPaidValue, remainingBalanceValue) => {
    if (items.length === 0) {
      setSnackbar({ open: true, message: 'No hay productos en la venta.', severity: 'warning' });
      return;
    }

    if (!paymentMethod) {
      setSnackbar({ open: true, message: 'Debe seleccionar un método de pago.', severity: 'warning' });
      return;
    }

    try {
      const saleData = {
        customer_id: customer ? customer.id : null,
        payment_method: paymentMethod,
        payment_method_type: ['payment_method_cash', 'payment_method_debit_card', 'payment_method_credit_card'].includes(paymentMethod) ? (paymentMethod === 'payment_method_cash' ? 'cash' : 'external') : 'external',
        amount_paid: amountPaidValue,
        remaining_balance: remainingBalanceValue,
        status: remainingBalanceValue > 0 ? 'pending' : 'completed',
        document_type: documentType,
        currency: 'CLP',
        tax: parseFloat(totalVAT.toFixed(2)),
        shipping_tax: 0.00,
        shipping: 0.00,
        shipping_discount: 0.00,
        gift_cards_discount: 0.00,
        additional_information: 'Tarifa de venta: 1766.0',
        source: 'manual',
        platform: 'jumpseller',
        ip: '181.43.248.74',
        external_order_id: '785',
        items: items.map((item) => ({
          product_id: item.product_id,
          item_code: item.sku || '',
          item_name: item.name || '',
          quantity: item.quantity,
          unit_price: parseFloat(item.unit_price.toFixed(2)),
          amount: parseFloat((item.unit_price * item.quantity).toFixed(2)),
          vat: parseFloat((item.vat * item.quantity).toFixed(2)),
          total_item: parseFloat((item.unit_price * item.quantity).toFixed(2)),
          item_discount: parseFloat(((item.unit_price * item.quantity) * discount / 100).toFixed(2)),
        })),
        total_amount: parseFloat(totalPayable.toFixed(2)),
      };

      await axiosInstance.post('/sales', saleData);
      setSnackbar({ open: true, message: 'Venta realizada con éxito', severity: 'success' });
      setItems([]);
      setPaymentMethod(defaultPaymentMethod);
      setDiscount(0);
      setAmountPaid(amountPaidValue);
      setRemainingBalance(remainingBalanceValue);
      setReceiptModalOpen(true);
    } catch (error) {
      console.error('Error al procesar la venta:', error.response?.data || error.message);
      setSnackbar({ open: true, message: 'Error al procesar la venta', severity: 'error' });
    }
  };

  const handleOpenCustomerModal = async () => {
    setCustomerModalOpen(true);
    if (customerSearch.trim() !== '') {
      try {
        const response = await axiosInstance.get('/customers', {
          params: { search: customerSearch.trim() },
        });
        setCustomers(response.data || []);
        setSelectedCustomerIndex(0);
      } catch (error) {
        console.error('Error al obtener clientes:', error.response?.data || error.message);
        setCustomers([]);
        setSnackbar({ open: true, message: 'Error al obtener clientes.', severity: 'error' });
      }
    } else {
      setCustomers([]);
    }
    setTimeout(() => {
      if (customerSearchRef.current) {
        customerSearchRef.current.focus();
      }
    }, 100);
  };

  const handleCustomerSearch = async (e) => {
    const searchTerm = e.target.value;
    setCustomerSearch(searchTerm);
    if (searchTerm.trim() === '') {
      setCustomers([]);
      return;
    }
    try {
      const response = await axiosInstance.get('/customers', {
        params: { search: searchTerm.trim() },
      });
      setCustomers(response.data || []);
      setSelectedCustomerIndex(0);
    } catch (error) {
      console.error('Error al buscar clientes:', error.response?.data || error.message);
      setCustomers([]);
      setSnackbar({ open: true, message: 'Error al buscar clientes.', severity: 'error' });
    }
  };

  const handleSelectCustomer = (selectedCustomer) => {
    setCustomer(selectedCustomer);
    setCustomerModalOpen(false);
    setSelectedCustomerIndex(-1);
    setSnackbar({ open: true, message: `Cliente seleccionado: ${selectedCustomer.name}`, severity: 'success' });
  };

  const handleKeyDownCustomer = (e) => {
    if (e.key === 'ArrowDown') {
      setSelectedCustomerIndex((prevIndex) => Math.min(prevIndex + 1, customers.length - 1));
    } else if (e.key === 'ArrowUp') {
      setSelectedCustomerIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (e.key === 'Enter' && selectedCustomerIndex >= 0) {
      handleSelectCustomer(customers[selectedCustomerIndex]);
    }
  };

  const totalNet = items.reduce((sum, item) => sum + item.net * item.quantity, 0);
  const totalVAT = items.reduce((sum, item) => sum + item.vat * item.quantity, 0);
  const totalGross = items.reduce((sum, item) => sum + item.unit_price * item.quantity, 0);
  const totalDiscount = (totalGross * discount) / 100;
  const totalPayable = totalGross - totalDiscount;

  const bills = [
    { value: 1000, color: '#4CAF50' }, // $1.000
    { value: 2000, color: '#2196F3' }, // $2.000
    { value: 5000, color: '#FF9800' }, // $5.000
    { value: 10000, color: '#9C27B0' }, // $10.000
    { value: 20000, color: '#F44336' }, // $20.000
  ];

  const handleBillClick = (value) => {
    setCashGiven((prev) => prev + value);
  };

  useEffect(() => {
    if (paymentMethod === 'payment_method_cash') {
      setChange(Math.max(0, cashGiven - totalPayable));
    }
  }, [cashGiven, totalPayable, paymentMethod]);

  return (
    <Box sx={{ p: 3, minHeight: '100vh', backgroundColor: '#e0f7fa' }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={12} lg={12}>
          <Paper elevation={6} sx={{ padding: 4, maxWidth: '100%' }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={7}>
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                  <PointOfSaleIcon color="primary" sx={{ fontSize: 60 }} />
                  <Typography variant="h3" gutterBottom>
                    IntegraPOS
                  </Typography>
                </Box>

                {loading && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <CircularProgress size={24} sx={{ mr: 2 }} />
                    <Typography variant="body1">Buscando...</Typography>
                  </Box>
                )}

                <Box sx={{ mb: 4 }}>
                  <Typography variant="h5" gutterBottom>
                    Productos en la Venta
                  </Typography>
                  {items.length === 0 ? (
                    <Typography variant="body1">No hay productos agregados.</Typography>
                  ) : (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre</TableCell>
                          <TableCell align="center">Cantidad</TableCell>
                          <TableCell align="right">Neto</TableCell>
                          <TableCell align="right">IVA ({VAT_RATE}%)</TableCell>
                          <TableCell align="right">Total</TableCell>
                          <TableCell align="center">Acciones</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items.map((item) => (
                          <TableRow key={item.product_id}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell align="center">
                              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <IconButton onClick={() => handleDecrement(item.product_id)} color="error" size="small">
                                  <RemoveIcon fontSize="small" />
                                </IconButton>
                                <Typography sx={{ mx: 1 }}>{item.quantity}</Typography>
                                <IconButton onClick={() => handleIncrement(item.product_id)} color="primary" size="small">
                                  <AddIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            </TableCell>
                            <TableCell align="right">{formatter.format(item.net)}</TableCell>
                            <TableCell align="right">{formatter.format(item.vat)}</TableCell>
                            <TableCell align="right">
                              {formatter.format(item.unit_price * item.quantity)}
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title="Remover Producto">
                                <IconButton onClick={() => handleRemove(item.product_id)} color="error" size="small">
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} md={5}>
                {/* Selector y Display de Cliente */}
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Cliente:
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8}>
                      <TextField
                        label="Seleccionar Cliente"
                        value={customer ? `${customer.name} (${customer.tax_id})` : ''}
                        onClick={handleOpenCustomerModal}
                        onChange={() => {}} // Evita cambios manuales
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleOpenCustomerModal}>
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleOpenCustomerModal}
                        fullWidth
                      >
                        Buscar
                      </Button>
                    </Grid>
                  </Grid>
                  {customer && (
                    <Box sx={{ mt: 2, p: 2, border: '1px solid #ccc', borderRadius: 2, backgroundColor: '#f5f5f5' }}>
                      <Typography variant="subtitle1"><strong>Nombre:</strong> {customer.name}</Typography>
                      <Typography variant="subtitle1"><strong>RUT:</strong> {customer.tax_id}</Typography>
                      {/* Puedes agregar más detalles del cliente aquí si es necesario */}
                    </Box>
                  )}
                </Box>

                {/* Cuadro de Totales */}
                <Box sx={{ mb: 3 }}>
                  <Card
                    sx={{
                      backgroundColor: '#1976d2', // Azul de fondo
                      color: '#ffffff', // Texto en blanco
                      p: 3,
                      borderRadius: 2,
                      boxShadow: 6,
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="h5" gutterBottom>
                      Total de la Venta: {formatter.format(totalPayable)}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                      IVA: {formatter.format(totalVAT)}
                    </Typography>
                  </Card>
                </Box>

                {/* Selección de Tipo de Documento */}
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Tipo de Documento:
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Card
                        sx={{
                          cursor: 'pointer',
                          border: documentType === 'boleta' ? '2px solid #1976d2' : '1px solid #ccc',
                          backgroundColor: documentType === 'boleta' ? '#e3f2fd' : '#fff',
                        }}
                        onClick={() => setDocumentType('boleta')}
                      >
                        <CardActionArea>
                          <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                            <Avatar sx={{ bgcolor: '#1976d2', mr: 2 }}>
                              <DescriptionIcon fontSize="small" />
                            </Avatar>
                            <Box>
                              <Typography variant="h6">Boleta</Typography>
                            </Box>
                          </Box>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Card
                        sx={{
                          cursor: 'pointer',
                          border: documentType === 'factura' ? '2px solid #4caf50' : '1px solid #ccc',
                          backgroundColor: documentType === 'factura' ? '#e8f5e9' : '#fff',
                        }}
                        onClick={() => setDocumentType('factura')}
                      >
                        <CardActionArea>
                          <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                            <Avatar sx={{ bgcolor: '#4caf50', mr: 2 }}>
                              <AssignmentIcon fontSize="small" />
                            </Avatar>
                            <Box>
                              <Typography variant="h6">Factura</Typography>
                            </Box>
                          </Box>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Card
                        sx={{
                          cursor: 'pointer',
                          border: documentType === 'guia_despacho' ? '2px solid #ff9800' : '1px solid #ccc',
                          backgroundColor: documentType === 'guia_despacho' ? '#fff3e0' : '#fff',
                        }}
                        onClick={() => setDocumentType('guia_despacho')}
                      >
                        <CardActionArea>
                          <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                            <Avatar sx={{ bgcolor: '#ff9800', mr: 2 }}>
                              <LocalShippingIcon fontSize="small" />
                            </Avatar>
                            <Box>
                              <Typography variant="h6">Guía de Despacho</Typography>
                            </Box>
                          </Box>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>

                <Divider sx={{ mb: 3 }} />

                {/* Selección de Método de Pago */}
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Método de Pago:
                  </Typography>
                  <Grid container spacing={2}>
                    {paymentMethods.map((method) => (
                      <Grid item xs={12} sm={6} key={method.key}>
                        <Card
                          sx={{
                            cursor: 'pointer',
                            border: paymentMethod === method.key ? '2px solid #1976d2' : '1px solid #ccc',
                            backgroundColor: paymentMethod === method.key ? '#e3f2fd' : '#fff',
                          }}
                          onClick={() => {
                            if (['payment_method_cash', 'payment_method_debit_card', 'payment_method_credit_card'].includes(method.key)) {
                              setPaymentMethod(method.key);
                            } else {
                              setOtherPaymentModalOpen(true);
                            }
                          }}
                        >
                          <CardActionArea>
                            <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                              <Avatar sx={{ bgcolor: getPaymentMethodColor(method.key), mr: 2 }}>
                                {getPaymentMethodIcon(method.key)}
                              </Avatar>
                              <Box>
                                <Typography variant="h6">{formatPaymentMethodLabel(method.key)}</Typography>
                              </Box>
                            </Box>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <Divider sx={{ mb: 3 }} />

                <Box sx={{ textAlign: 'center', mt: 4 }}>
                  <Button
                    variant="contained"
                    color="success"
                    size="large"
                    onClick={handleOpenPaymentModal}
                    startIcon={<SaveIcon />}
                    fullWidth
                    disabled={items.length === 0}
                  >
                    Pagar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* Modal de Pago */}
      <Dialog open={paymentModalOpen} onClose={() => setPaymentModalOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Procesar Pago</DialogTitle>
        <DialogContent>
          {paymentMethod === 'payment_method_cash' ? (
            <Box>
              <Typography variant="h6" gutterBottom>
                Monto Total a Pagar: {formatter.format(totalPayable)}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Seleccione los billetes entregados:
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 2 }}>
                {bills.map((bill) => (
                  <Button
                    key={bill.value}
                    variant="contained"
                    onClick={() => handleBillClick(bill.value)}
                    sx={{
                      backgroundColor: bill.color,
                      color: '#fff',
                      margin: 0.5,
                      minWidth: 80,
                      '&:hover': {
                        backgroundColor: bill.color,
                      },
                    }}
                  >
                    {formatter.format(bill.value)}
                  </Button>
                ))}
              </Box>
              <TextField
                label="Monto Entregado"
                type="number"
                value={cashGiven}
                onChange={(e) => setCashGiven(parseInt(e.target.value) || 0)}
                fullWidth
                sx={{ mb: 2 }}
                inputProps={{ min: 0 }}
              />
              <Typography variant="h6">
                Vuelto: {formatter.format(Math.max(0, cashGiven - totalPayable))}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="h6" gutterBottom>
                Monto Total a Pagar: {formatter.format(totalPayable)}
              </Typography>
              <TextField
                label="Monto a Pagar"
                type="number"
                value={cardAmount}
                onChange={(e) => setCardAmount(parseFloat(e.target.value) || 0)}
                fullWidth
                sx={{ mb: 2 }}
                inputProps={{ min: 0 }}
              />
              <TextField
                label="Número de Voucher"
                value={voucherNumber}
                onChange={(e) => setVoucherNumber(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPaymentModalOpen(false)} startIcon={<CloseIcon />}>
            Cancelar
          </Button>
          <Button
            onClick={handlePaymentSubmit}
            color="primary"
            variant="contained"
            startIcon={<SaveIcon />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handlePaymentSubmit();
              }
            }}
          >
            Generar Venta
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal para Seleccionar Otros Métodos de Pago */}
      <Dialog open={otherPaymentModalOpen} onClose={() => setOtherPaymentModalOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Seleccionar Método de Pago</DialogTitle>
        <DialogContent>
          <List>
            {paymentMethods
              .filter(
                method =>
                  method.key !== 'payment_method_cash' &&
                  method.key !== 'payment_method_debit_card' &&
                  method.key !== 'payment_method_credit_card'
              )
              .map((method) => (
                <ListItem key={method.key} disablePadding>
                  <ListItemButton onClick={() => { setPaymentMethod(method.key); setOtherPaymentModalOpen(false); }}>
                    <ListItemText primary={formatPaymentMethodLabel(method.key)} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOtherPaymentModalOpen(false)} startIcon={<CloseIcon />}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal para Seleccionar Cliente */}
      <Dialog open={customerModalOpen} onClose={() => setCustomerModalOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Seleccionar Cliente</DialogTitle>
        <DialogContent>
          <TextField
            label="Buscar Cliente"
            type="text"
            value={customerSearch}
            onChange={handleCustomerSearch}
            onKeyDown={handleKeyDownCustomer}
            inputRef={customerSearchRef}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled>
                    <SearchIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          {customers.length === 0 ? (
            <Typography variant="body1">No se encontraron clientes.</Typography>
          ) : (
            <List>
              {customers.map((cust, index) => (
                <ListItem key={cust.id} disablePadding>
                  <ListItemButton
                    selected={selectedCustomerIndex === index}
                    onClick={() => handleSelectCustomer(cust)}
                  >
                    <ListItemText primary={`${cust.name} (${cust.tax_id})`} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCustomerModalOpen(false)} startIcon={<CloseIcon fontSize="small" />}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de Recibo Actualizado */}
      <Dialog open={receiptModalOpen} onClose={() => setReceiptModalOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>
          <ReceiptIcon sx={{ mr: 1, fontSize: 30 }} />
          Recibo de Venta
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">Gracias por su compra</Typography>
          <Typography variant="body1">Cliente: {customer ? `${customer.name} (${customer.tax_id})` : 'CLIENTE GENÉRICO (66666666-6)'}</Typography>
          <Typography variant="body1">Fecha: {new Date().toLocaleString()}</Typography>
          <Divider sx={{ my: 2 }} />
          <List>
            {items.map((item) => (
              <ListItem key={item.product_id}>
                <ListItemText
                  primary={`${item.name} x ${item.quantity}`}
                  secondary={`Total: ${formatter.format(item.unit_price * item.quantity)}`}
                />
              </ListItem>
            ))}
          </List>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1">Total Neto: {formatter.format(totalNet)}</Typography>
          <Typography variant="body1">IVA: {formatter.format(totalVAT)}</Typography>
          <Typography variant="body1">Descuento: {formatter.format(totalDiscount)}</Typography>
          <Typography variant="h6">Total a Pagar: {formatter.format(totalPayable)}</Typography>
          <Typography variant="h6">Monto Pagado: {formatter.format(amountPaid)}</Typography>
          {paymentMethod === 'payment_method_cash' && (
            <Typography variant="h6">Vuelto: {formatter.format(change)}</Typography>
          )}
          {remainingBalance > 0 && (
            <Typography variant="h6" color="error">
              Saldo Pendiente: {formatter.format(remainingBalance)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReceiptModalOpen(false)} startIcon={<CloseIcon fontSize="small" />}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Modal de Sugerencias de Productos */}
      <Dialog open={suggestionsModalOpen} onClose={() => setSuggestionsModalOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Sugerencias de Productos</DialogTitle>
        <DialogContent>
          <List>
            {suggestions.map((product, index) => (
              <ListItem key={product.id} disablePadding>
                <ListItemButton
                  selected={selectedSuggestionIndex === index}
                  onClick={() => handleSelectSuggestion(product)}
                >
                  <ListItemText primary={`${product.name} - ${formatter.format(product.price)}`} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuggestionsModalOpen(false)} startIcon={<CloseIcon />}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// Función para obtener el color según el método de pago
const getPaymentMethodColor = (key) => {
  switch (key) {
    case 'payment_method_cash':
      return '#1976d2';
    case 'payment_method_debit_card':
      return '#4caf50';
    case 'payment_method_credit_card':
      return '#ff9800';
    default:
      return '#9c27b0';
  }
};

// Función para obtener el icono según el método de pago
const getPaymentMethodIcon = (key) => {
  switch (key) {
    case 'payment_method_cash':
      return <AccountBalanceWalletIcon fontSize="small" />;
    case 'payment_method_debit_card':
      return <CreditCardIcon fontSize="small" />;
    case 'payment_method_credit_card':
      return <PaymentIcon fontSize="small" />;
    default:
      return <MoreHorizIcon fontSize="small" />;
  }
};

// Función para formatear la etiqueta del método de pago
const formatPaymentMethodLabel = (key) => {
  switch (key) {
    case 'payment_method_cash':
      return 'Efectivo';
    case 'payment_method_debit_card':
      return 'Débito';
    case 'payment_method_credit_card':
      return 'Crédito';
    default:
      return 'Otros';
  }
};

export default POS;
