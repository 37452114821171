// ../components/modals/CreateSupplierModal.js
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

const CreateSupplierModal = ({ open, handleClose, initialData, handleCreateSupplier }) => {
  const [formData, setFormData] = useState({
    name: '',
    tax_id: '',
    address: '',
    phone: '',
    email: '',
    contact_person: '',
    status: 'activo',
  });

  useEffect(() => {
    if (initialData) {
      setFormData((prev) => ({
        ...prev,
        name: initialData.razon_social_emisor || '',
        tax_id: initialData.rut_emisor || '',
        contact_person: initialData.contacto || '',
      }));
    }
  }, [initialData]);

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = () => {
    if (!formData.name || !formData.tax_id || !formData.address || !formData.phone ||
        !formData.email || !formData.contact_person || !formData.status) {
      return;
    }
    handleCreateSupplier(formData);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Crear Proveedor</DialogTitle>
      <DialogContent>
        <TextField
          label="Nombre"
          fullWidth
          margin="normal"
          value={formData.name}
          onChange={(e) => handleInputChange('name', e.target.value)}
        />
        <TextField
          label="RUT/TAX ID"
          fullWidth
          margin="normal"
          value={formData.tax_id}
          onChange={(e) => handleInputChange('tax_id', e.target.value)}
        />
        <TextField
          label="Dirección"
          fullWidth
          margin="normal"
          value={formData.address}
          onChange={(e) => handleInputChange('address', e.target.value)}
        />
        <TextField
          label="Teléfono"
          fullWidth
          margin="normal"
          value={formData.phone}
          onChange={(e) => handleInputChange('phone', e.target.value)}
        />
        <TextField
          label="Email"
          fullWidth
          margin="normal"
          type="email"
          value={formData.email}
          onChange={(e) => handleInputChange('email', e.target.value)}
        />
        <TextField
          label="Persona de Contacto"
          fullWidth
          margin="normal"
          value={formData.contact_person}
          onChange={(e) => handleInputChange('contact_person', e.target.value)}
        />
        <TextField
          label="Estado"
          fullWidth
          margin="normal"
          value={formData.status}
          onChange={(e) => handleInputChange('status', e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} startIcon={<CloseIcon />}>Cancelar</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" startIcon={<SaveIcon />}>
          Crear
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSupplierModal;
