import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Para obtener el orderId desde la URL
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from '@mui/material';
import axios from 'axios';

const JumpsellerOrdersView = () => {
  const { orderId } = useParams(); // Obtener el orderId desde la URL
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Función para obtener los detalles del pedido
  const fetchOrderDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/jumpsellerorders/orders/${orderId}`
      );
      setOrderDetails(response.data);
    } catch (err) {
      setError('Error al cargar los detalles del pedido.');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [orderId]);

  if (loading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!orderDetails) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6">
          No se encontraron detalles para el pedido #{orderId}
        </Typography>
      </Box>
    );
  }

  // Desestructurar los detalles del pedido
  const { order, customer, shipping_address, billing_address, items } = orderDetails;

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(amount);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Detalles del Pedido Jumpseller #{order.orderId || orderId}
      </Typography>

      <>
        <Typography variant="h6" gutterBottom>
          Información del Cliente
        </Typography>
        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Nombre del Cliente</TableCell>
                <TableCell>{customer?.fullname || 'Sin nombre'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email del Cliente</TableCell>
                <TableCell>{customer?.email || 'Sin email'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Fecha del Pedido</TableCell>
                <TableCell>{new Date(order.created_at).toLocaleString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Estado del Pedido</TableCell>
                <TableCell>{order?.status || 'Sin estado'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h6" gutterBottom>
          Información del Pedido
        </Typography>
        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Método de Envío</TableCell>
                <TableCell>{order?.shipping_method_name || 'No especificado'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Método de Pago</TableCell>
                <TableCell>{order?.payment_method_name || 'No especificado'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Subtotal</TableCell>
                <TableCell>{formatCurrency(order?.subtotal || 0)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Envío</TableCell>
                <TableCell>{formatCurrency(order?.shipping || 0)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Impuestos</TableCell>
                <TableCell>{formatCurrency(order?.tax || 0)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell>{formatCurrency(order?.total || 0)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h6" gutterBottom>
          Productos del Pedido
        </Typography>
        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Producto</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell>Precio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items && items.length > 0 ? (
                items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.product_name}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{formatCurrency(item.price)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>No hay productos en este pedido.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Button variant="contained" color="primary" onClick={() => window.history.back()}>
          Volver
        </Button>
      </>
    </Box>
  );
};

export default JumpsellerOrdersView;
