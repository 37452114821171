// src/pages/inadmin/AdminCompaniesPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AdminCompaniesPage = () => {
    const [companies, setCompanies] = useState([]);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({ name: '', address: '', tenant_id: '' });
    const navigate = useNavigate();

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = () => {
        axios.get('/inadmin/companies')
            .then(response => setCompanies(response.data))
            .catch(error => console.error('Error al obtener compañías:', error));
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setFormData({ name: '', address: '', tenant_id: '' });
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        axios.post('/inadmin/companies', formData)
            .then(() => {
                fetchCompanies();
                handleClose();
            })
            .catch(error => console.error('Error al crear compañía:', error));
    };

    const handleEdit = (id) => {
        navigate(`/inadmin/companies/edit/${id}`);
    };

    const handleDelete = (id) => {
        axios.delete(`/inadmin/companies/${id}`)
            .then(() => fetchCompanies())
            .catch(error => console.error('Error al eliminar compañía:', error));
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'name', headerName: 'Nombre', width: 200 },
        { field: 'address', headerName: 'Dirección', width: 300 },
        { field: 'tenant_id', headerName: 'ID de Tenant', width: 150 },
        {
            field: 'actions',
            headerName: 'Acciones',
            width: 200,
            renderCell: (params) => (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleEdit(params.row.id)}
                        style={{ marginRight: 8 }}
                    >
                        Editar
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => handleDelete(params.row.id)}
                    >
                        Eliminar
                    </Button>
                </>
            ),
        },
    ];

    return (
        <Box sx={{ height: 600, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <h2>Gestión de Compañías</h2>
                <Button variant="contained" color="primary" onClick={handleOpen}>
                    Crear Compañía
                </Button>
            </Box>
            <DataGrid
                rows={companies}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
            />

            {/* Dialog para Crear Compañía */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Crear Nueva Compañía</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nombre"
                        name="name"
                        fullWidth
                        variant="standard"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        label="Dirección"
                        name="address"
                        fullWidth
                        variant="standard"
                        value={formData.address}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        label="ID de Tenant"
                        name="tenant_id"
                        fullWidth
                        variant="standard"
                        value={formData.tenant_id}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSubmit}>Crear</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdminCompaniesPage;
