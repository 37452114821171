// src/pages/inadmin/AdminUsersPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AdminUsersPage = () => {
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({ username: '', email: '', password: '', isAdmin: false });
    const navigate = useNavigate();

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = () => {
        axios.get('/inadmin/users')
            .then(response => setUsers(response.data))
            .catch(error => console.error('Error al obtener usuarios:', error));
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setFormData({ username: '', email: '', password: '', isAdmin: false });
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = () => {
        axios.post('/inadmin/users', formData)
            .then(() => {
                fetchUsers();
                handleClose();
            })
            .catch(error => console.error('Error al crear usuario:', error));
    };

    const handleEdit = (id) => {
        navigate(`/inadmin/users/edit/${id}`);
    };

    const handleDelete = (id) => {
        axios.delete(`/inadmin/users/${id}`)
            .then(() => fetchUsers())
            .catch(error => console.error('Error al eliminar usuario:', error));
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'username', headerName: 'Nombre de Usuario', width: 150 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'isAdmin', headerName: 'Es Admin', width: 130, type: 'boolean' },
        {
            field: 'actions',
            headerName: 'Acciones',
            width: 200,
            renderCell: (params) => (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleEdit(params.row.id)}
                        style={{ marginRight: 8 }}
                    >
                        Editar
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => handleDelete(params.row.id)}
                    >
                        Eliminar
                    </Button>
                </>
            ),
        },
    ];

    return (
        <Box sx={{ height: 600, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <h2>Gestión de Usuarios</h2>
                <Button variant="contained" color="primary" onClick={handleOpen}>
                    Crear Usuario
                </Button>
            </Box>
            <DataGrid
                rows={users}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
            />

            {/* Dialog para Crear Usuario */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Crear Nuevo Usuario</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nombre de Usuario"
                        name="username"
                        fullWidth
                        variant="standard"
                        value={formData.username}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        label="Email"
                        name="email"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        label="Contraseña"
                        name="password"
                        type="password"
                        fullWidth
                        variant="standard"
                        value={formData.password}
                        onChange={handleChange}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <input
                            type="checkbox"
                            name="isAdmin"
                            checked={formData.isAdmin}
                            onChange={handleChange}
                        />
                        <span style={{ marginLeft: '8px' }}>Es Administrador</span>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSubmit}>Crear</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdminUsersPage;
