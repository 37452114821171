// src/pages/IntegrationView.js

import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  Button,
  Grid,
  Paper,
  Chip,
} from '@mui/material';
import {
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';

function IntegrationView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [integrationData, setIntegrationData] = useState(null);
  const [loading, setLoading] = useState(true);

  const tenantId = localStorage.getItem('tenantId') || 'default';

  useEffect(() => {
    async function fetchIntegration() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/integrations/${id}`, {
          headers: {
            'x-tenant-id': tenantId,
          },
        });
        setIntegrationData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener la integración:', error);
        setLoading(false);
      }
    }
    fetchIntegration();
  }, [id, tenantId]);

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', mt: 10 }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Cargando...
        </Typography>
      </Box>
    );
  }

  if (!integrationData) {
    return (
      <Box sx={{ textAlign: 'center', mt: 10 }}>
        <Typography variant="h6" sx={{ mt: 2 }}>
          No se encontró la integración solicitada.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/integrations')}
        >
          Volver a Integraciones
        </Button>
      </Box>
    );
  }

  const formatDateTime = (dateTimeString) => {
    const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(dateTimeString).toLocaleString('es-ES', options);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/integrations')}
          sx={{ mr: 2 }}
        >
          Volver
        </Button>
        <Typography variant="h4" gutterBottom>
          Detalles de la Integración
        </Typography>
      </Box>

      {/* Información de la integración */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" component="div" sx={{ mb: 2 }}>
              {integrationData.integration_name}
            </Typography>
            <Typography variant="h6" component="div" sx={{ mb: 1 }}>
              Plataforma: {integrationData.platform || 'No asignada'}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Estado:{' '}
              <Chip
                label={integrationData.status}
                color={
                  integrationData.status === 'activo' ? 'success' : 'default'
                }
              />
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Fecha de creación: {formatDateTime(integrationData.created_at)}
            </Typography>
            <Typography variant="body1">
              Última actualización: {formatDateTime(integrationData.updated_at)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              component={Link}
              to={`/integrations/edit/${integrationData.id}`}
            >
              Editar Integración
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Puedes agregar más detalles o secciones aquí si es necesario */}
    </Box>
  );
}

export default IntegrationView;
