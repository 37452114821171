// src/pages/tabs/SuppliersTab.js

import React, { useState, useEffect, useMemo, forwardRef, useContext } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
} from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon, Save as SaveIcon } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import { VariableSizeList } from 'react-window';
import axios from 'axios';
import PropTypes from 'prop-types';

// Custom Listbox for Autocomplete with virtualization
const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function ListboxComponent(props) {
  const { children, ...other } = props;
  const itemData = useMemo(() => React.Children.toArray(children), [children]);

  const ITEM_HEIGHT = 48;
  const height = Math.min(8 * ITEM_HEIGHT, itemData.length * ITEM_HEIGHT) + 2 * LISTBOX_PADDING;

  return (
    <div>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          height={height}
          width="100%"
          itemSize={() => ITEM_HEIGHT}
          itemCount={itemData.length}
          itemData={itemData}
          outerElementType={OuterElementType}
          overscanCount={5}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
}

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

// ConfirmDeleteModal Component
const ConfirmDeleteModal = ({ open, onClose, onConfirm, title, description }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={onConfirm} color="error" variant="contained">
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

// AssignSupplierModal Component
const AssignSupplierModal = ({
  open,
  onClose,
  onSupplierAssigned,
  setSnackbar,
  availableSuppliers,
  productId,
}) => {
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplierPrice, setSupplierPrice] = useState('');
  const [supplierSKU, setSupplierSKU] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('Available Suppliers:', availableSuppliers);
  }, [availableSuppliers]);

  const handleAssign = async () => {
    // Validar que todos los campos requeridos estén completos
    if (!selectedSupplier || !supplierPrice || !supplierSKU) {
      setSnackbar({
        open: true,
        message: 'Todos los campos son requeridos.',
        severity: 'warning',
      });
      return;
    }

    setLoading(true);
    try {
      const payload = {
        internal_product_id: parseInt(productId, 10),
        supplier_price: parseFloat(supplierPrice),
        supplier_sku: supplierSKU,
      };

      // Verificar que el proveedor seleccionado tenga un 'supplier_id'
      if (!selectedSupplier.id) {
        throw new Error('El proveedor seleccionado no tiene un supplier_id válido.');
      }

      // Cambiar el endpoint para usar el 'supplier_id' del proveedor
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/suppliers/${selectedSupplier.id}/products`,
        payload
      );

      setSnackbar({
        open: true,
        message: 'Proveedor asignado exitosamente.',
        severity: 'success',
      });

      // Resetear campos
      setSelectedSupplier(null);
      setSupplierPrice('');
      setSupplierSKU('');

      onSupplierAssigned();
      onClose();
    } catch (error) {
      console.error('Error al asignar proveedor:', error);
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessages = error.response.data.errors.map((err) => err.msg).join(', ');
        setSnackbar({
          open: true,
          message: `Error: ${errorMessages}`,
          severity: 'error',
        });
      } else if (error.response && error.response.data && error.response.data.error) {
        setSnackbar({
          open: true,
          message: `Error: ${error.response.data.error}`,
          severity: 'error',
        });
      } else {
        setSnackbar({
          open: true,
          message: 'Error al asignar proveedor.',
          severity: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Asignar Nuevo Proveedor</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Selecciona un proveedor y completa los detalles para asignarlo al producto.
        </DialogContentText>
        <FormControl fullWidth variant="outlined" margin="dense">
          <Autocomplete
            id="supplier-autocomplete"
            options={availableSuppliers}
            getOptionLabel={(option) => option.name || 'Sin nombre'}
            ListboxComponent={ListboxComponent}
            renderInput={(params) => (
              <TextField {...params} label="Proveedor" variant="outlined" required />
            )}
            value={selectedSupplier}
            onChange={(event, newValue) => {
              setSelectedSupplier(newValue);
            }}
            renderOption={(props, option) => (
              <li {...props}>
                {typeof option.name === 'string' ? option.name : 'Nombre no disponible'}
              </li>
            )}
            getOptionSelected={(option, value) => option.id === value.id}
          />
        </FormControl>
        <TextField
          margin="dense"
          label="Precio del Proveedor (Neto)"
          type="number"
          fullWidth
          variant="outlined"
          value={supplierPrice}
          onChange={(e) => setSupplierPrice(e.target.value)}
          inputProps={{ min: '0', step: '0.01' }}
          required
        />
        <TextField
          margin="dense"
          label="SKU del Proveedor"
          type="text"
          fullWidth
          variant="outlined"
          value={supplierSKU}
          onChange={(e) => setSupplierSKU(e.target.value)}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancelar
        </Button>
        <Button onClick={handleAssign} disabled={loading} variant="contained" color="primary">
          {loading ? 'Asignando...' : 'Asignar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AssignSupplierModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSupplierAssigned: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  availableSuppliers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      tax_id: PropTypes.string.isRequired,
    })
  ).isRequired,
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

// SuppliersTab Component
const SuppliersTab = ({ productId, onSuppliersUpdate, setSnackbar }) => {
  const [supplierData, setSupplierData] = useState([]);
  const [loadingSupplier, setLoadingSupplier] = useState(true);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [supplierToDelete, setSupplierToDelete] = useState(null);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [localSnackbar, setLocalSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [loadingAllSuppliers, setLoadingAllSuppliers] = useState(true);

  const fetchProductDetails = async () => {
    try {
      // Endpoint para obtener detalles del producto, incluyendo proveedores
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/${productId}/details`);
      const productSuppliers = response.data.suppliers || [];

      const mappedSuppliers = productSuppliers.map((supplier) => ({
        supplier_id: supplier.supplier_id || '',
        supplier_name: supplier.supplier_name || '',
        supplier_price: supplier.supplier_price || '',
        supplier_sku: supplier.supplier_sku || '',
        contact_person: supplier.contact_person || '',
        discount_percentage: supplier.discount_percentage || '0.00',
        discount_start_date: supplier.discount_start_date || null,
        discount_end_date: supplier.discount_end_date || null,
        created_at: supplier.created_at || '',
        updated_at: supplier.updated_at || '',
        internal_product_id: productId,
      }));

      setSupplierData(mappedSuppliers);
    } catch (error) {
      console.error('Error al obtener los proveedores del producto:', error);
      setLocalSnackbar({ open: true, message: 'Error al cargar los proveedores del producto.', severity: 'error' });
    } finally {
      setLoadingSupplier(false);
    }
  };

  const fetchAllSuppliers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/suppliers`);
      const suppliers = response.data.suppliers || [];
      setAllSuppliers(suppliers);
    } catch (error) {
      console.error('Error al obtener todos los proveedores:', error);
      setLocalSnackbar({ open: true, message: 'Error al cargar todos los proveedores.', severity: 'error' });
    } finally {
      setLoadingAllSuppliers(false);
    }
  };

  useEffect(() => {
    fetchProductDetails();
    fetchAllSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const handleSupplierChange = (index, event) => {
    const { name, value } = event.target;
    const updatedSuppliers = [...supplierData];
    updatedSuppliers[index][name] = value;

    setSupplierData(updatedSuppliers);
  };

  const handleSaveSuppliers = async () => {
    try {
      for (const supplier of supplierData) {
        if (
          !supplier.supplier_id ||
          !supplier.supplier_price ||
          !supplier.supplier_sku
        ) {
          setLocalSnackbar({ open: true, message: 'Por favor, completa todos los campos de los proveedores.', severity: 'warning' });
          return;
        }
      }

      // Implementar lógica para guardar múltiples proveedores si es necesario
      setLocalSnackbar({ open: true, message: 'Funcionalidad de guardar proveedores no implementada.', severity: 'info' });
    } catch (error) {
      console.error('Error al actualizar proveedores:', error);
      setLocalSnackbar({ open: true, message: 'Error al actualizar proveedores.', severity: 'error' });
    }
  };

  const handleOpenAssignModal = () => {
    setIsAssignModalOpen(true);
  };

  const handleCloseAssignModal = () => {
    setIsAssignModalOpen(false);
  };

  const handleOpenConfirmDelete = (supplier) => {
    setSupplierToDelete(supplier);
    setIsConfirmDeleteOpen(true);
  };

  const handleCloseConfirmDelete = () => {
    setSupplierToDelete(null);
    setIsConfirmDeleteOpen(false);
  };

  const handleDeleteSupplier = async (supplier) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/suppliers/${supplier.supplier_id}/products/${supplier.internal_product_id}`);
      setLocalSnackbar({ open: true, message: 'Proveedor eliminado correctamente.', severity: 'success' });
      await fetchProductDetails();
    } catch (error) {
      console.error('Error al eliminar proveedor:', error);
      setLocalSnackbar({ open: true, message: 'Error al eliminar proveedor.', severity: 'error' });
    } finally {
      handleCloseConfirmDelete();
    }
  };

  const assignedSupplierIds = useMemo(() => supplierData.map((s) => s.supplier_id), [supplierData]);
  const availableSuppliers = useMemo(() => {
    return allSuppliers.filter((supplier) => !assignedSupplierIds.includes(supplier.id));
  }, [allSuppliers, assignedSupplierIds]);

  if (loadingSupplier || loadingAllSuppliers) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Proveedores
        </Typography>
        {supplierData.length === 0 ? (
          <Typography variant="body1">No hay proveedores asignados a este producto.</Typography>
        ) : (
          supplierData.map((supplier, index) => (
            <Grid container spacing={2} key={index} alignItems="center">
              {/* Nombre del Proveedor */}
              <Grid item xs={12} md={3}>
                <TextField
                  label="Proveedor"
                  name="supplier_name"
                  value={supplier.supplier_name}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              {/* Precio (Neto) */}
              <Grid item xs={12} md={2}>
                <TextField
                  label="Precio (Neto)"
                  name="supplier_price"
                  type="number"
                  value={supplier.supplier_price}
                  onChange={(e) => handleSupplierChange(index, e)}
                  fullWidth
                  margin="normal"
                  inputProps={{ min: '0', step: '0.01' }}
                />
              </Grid>

              {/* SKU Proveedor */}
              <Grid item xs={12} md={2}>
                <TextField
                  label="SKU Proveedor"
                  name="supplier_sku"
                  value={supplier.supplier_sku}
                  onChange={(e) => handleSupplierChange(index, e)}
                  fullWidth
                  margin="normal"
                />
              </Grid>

              {/* Botón de Eliminar Proveedor */}
              <Grid item xs={12} md={1}>
                <IconButton color="error" onClick={() => handleOpenConfirmDelete(supplier)}>
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))
        )}
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleOpenAssignModal}
            sx={{ mr: 2 }}
          >
            Asignar Proveedor
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSaveSuppliers}
          >
            Guardar Proveedores
          </Button>
        </Box>
      </CardContent>

      {/* Modales Integrados */}
      <AssignSupplierModal
        open={isAssignModalOpen}
        onClose={handleCloseAssignModal}
        onSupplierAssigned={fetchProductDetails}
        setSnackbar={setLocalSnackbar}
        availableSuppliers={availableSuppliers}
        productId={productId}
      />

      <ConfirmDeleteModal
        open={isConfirmDeleteOpen}
        onClose={handleCloseConfirmDelete}
        onConfirm={() => handleDeleteSupplier(supplierToDelete)}
        title="Eliminar Proveedor"
        description={`¿Estás seguro de que deseas eliminar al proveedor "${supplierToDelete?.supplier_name}"? Esta acción no se puede deshacer.`}
      />

      {/* Snackbar para Notificaciones */}
      <Snackbar
        open={localSnackbar.open}
        autoHideDuration={6000}
        onClose={() => setLocalSnackbar({ ...localSnackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setLocalSnackbar({ ...localSnackbar, open: false })}
          severity={localSnackbar.severity}
          sx={{ width: '100%' }}
        >
          {localSnackbar.message}
        </Alert>
      </Snackbar>
    </Card>
  );
};

SuppliersTab.propTypes = {
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSuppliersUpdate: PropTypes.func,
  setSnackbar: PropTypes.func.isRequired,
};

export default SuppliersTab;
