// src/pages/tabs/GeneralInfoTab.js

import React from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';

const GeneralInfoTab = ({
  product,
  formData,
  handleInputChange,
  handleSaveProduct,
}) => {
  // Corrected statuses array with proper values and labels
  const statuses = [
    { value: 'available', label: 'Disponible' },
    { value: 'not-available', label: 'No Disponible' },
    { value: 'disabled', label: 'Deshabilitado' },
    { value: 'featured', label: 'Destacado' },
  ];

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Información General</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Nombre del Producto"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="SKU"
            name="sku"
            value={formData.sku}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="status-label">Estado</InputLabel>
            <Select
              labelId="status-label"
              label="Estado"
              name="status"
              value={formData.status}
              onChange={handleInputChange}
            >
              {statuses.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Descripción"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            margin="normal"
            multiline
            rows={6}
          />
          <TextField
            label="Precio"
            name="price"
            type="number"
            value={formData.price}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            margin="normal"
            inputProps={{ min: "0", step: "0.01" }}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleSaveProduct}
        >
          Guardar Cambios
        </Button>
      </Box>
    </Box>
  );
};

export default GeneralInfoTab;
