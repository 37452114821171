// src/pages/tabs/StockTab.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  IconButton,
  Button,
  Divider,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  Inventory as InventoryIcon,
  AttachMoney as AttachMoneyIcon,
  AccessTime as AccessTimeIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import axios from 'axios';
import AssignWarehouseModal from '../modals/AssignWarehouseModal';
import ConfirmDeleteModal from '../modals/ConfirmDeleteModal';
import StatCard from '../components/StatCard';

const StockTab = ({ productId, stockData, setSnackbar, onStockUpdate }) => {
  const [localStockData, setLocalStockData] = useState(stockData || []);
  const [availableWarehouses, setAvailableWarehouses] = useState([]);
  const [loadingStock, setLoadingStock] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [warehouseToDelete, setWarehouseToDelete] = useState(null);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [localSnackbar, setLocalSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [productCostData, setProductCostData] = useState({
    averageCost: 0,
    lastCost: 'No disponible',
  });

  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/warehouses`);
        console.log('Respuesta de almacenes:', response.data); // Para depuración

        // Extraer el arreglo de almacenes de la respuesta
        const warehouses = response.data.warehouses;

        // Verificar que warehouses sea un arreglo antes de asignarlo
        if (Array.isArray(warehouses)) {
          setAvailableWarehouses(warehouses);
        } else {
          console.error('La propiedad "warehouses" no es un arreglo:', warehouses);
          setAvailableWarehouses([]); // Asignar un arreglo vacío como fallback
          setLocalSnackbar({ open: true, message: 'Formato de almacenes inesperado.', severity: 'error' });
        }
      } catch (error) {
        console.error('Error al obtener almacenes disponibles:', error);
        setLocalSnackbar({ open: true, message: 'Error al cargar almacenes disponibles.', severity: 'error' });
      }
    };

    fetchWarehouses();
  }, []);

  const fetchProductDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/${productId}/details`);
      const productWarehouses = response.data.stock || [];

      const mappedWarehouses = productWarehouses.map((warehouse) => ({
        warehouse_id: warehouse.warehouse_id || warehouse.id || '',
        warehouse_name: warehouse.warehouse_name || warehouse.name || '',
        stock_quantity: warehouse.stock_quantity !== undefined && warehouse.stock_quantity !== null ? warehouse.stock_quantity : '',
        minimum_stock: warehouse.minimum_stock !== undefined && warehouse.minimum_stock !== null ? warehouse.minimum_stock : '',
        last_updated: warehouse.last_updated || '',
        status: warehouse.status || 'activo',
      }));

      setLocalStockData(mappedWarehouses);

      // Extraer y almacenar los costos promedio y último costo
      const averageCost = response.data.average_cost || 0;
      const lastCost = response.data.latest_cost ? response.data.latest_cost.cost : 'No disponible';

      setProductCostData({
        averageCost,
        lastCost,
      });

      setLoadingStock(false);
    } catch (error) {
      console.error('Error al obtener los detalles del producto:', error);
      setLoadingStock(false);
      setLocalSnackbar({ open: true, message: 'Error al cargar los almacenes.', severity: 'error' });
    }
  };

  useEffect(() => {
    setLoadingStock(true);
    fetchProductDetails();
  }, [productId]);

  const calculateTotals = () => {
    const totalStock = localStockData
      .filter((stock) => stock.status === 'activo')
      .reduce((sum, stock) => sum + parseInt(stock.stock_quantity || 0, 10), 0);

    const totalMinStock = localStockData
      .filter((stock) => stock.status === 'activo')
      .reduce((sum, stock) => sum + parseInt(stock.minimum_stock || 0, 10), 0);

    const lastUpdatedDate = localStockData
      .filter((stock) => stock.status === 'activo' && stock.last_updated)
      .reduce((latest, stock) => {
        const stockDate = new Date(stock.last_updated);
        return stockDate > latest ? stockDate : latest;
      }, new Date(0));

    return {
      totalStock,
      totalMinStock,
      lastUpdatedDate: lastUpdatedDate.getTime() !== 0 ? lastUpdatedDate : 'No disponible',
    };
  };

  const calculateCosts = () => {
    return {
      averageCost: productCostData.averageCost || 0,
      lastCost: productCostData.lastCost || 'No disponible',
    };
  };

  const { totalStock, totalMinStock, lastUpdatedDate } = calculateTotals();
  const { averageCost, lastCost } = calculateCosts();

  const handleStockChange = (index, event) => {
    const { name, value } = event.target;
    const updatedStock = [...localStockData];
    updatedStock[index][name] = value;

    setLocalStockData(updatedStock);
  };

  const handleSaveStock = async () => {
    setLoadingStock(true);
    try {
      for (const stock of localStockData) {
        if (!stock.warehouse_id || stock.stock_quantity === '' || stock.minimum_stock === '') {
          setLocalSnackbar({ open: true, message: 'Por favor, completa todos los campos de stock.', severity: 'warning' });
          setLoadingStock(false);
          return;
        }
      }

      const payload = {
        stock: localStockData.map((stock) => ({
          warehouse_id: stock.warehouse_id,
          stock_quantity: parseInt(stock.stock_quantity, 10),
          minimum_stock: parseInt(stock.minimum_stock, 10),
          last_updated: stock.last_updated || null,
          status: stock.status || 'activo',
        })),
      };

      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/products/${productId}/stock`,
        payload
      );
      setLocalSnackbar({ open: true, message: 'Stock actualizado correctamente.', severity: 'success' });

      fetchProductDetails();
      if (onStockUpdate) {
        onStockUpdate();
      }
    } catch (error) {
      console.error('Error al actualizar stock:', error);
      setLocalSnackbar({ open: true, message: 'Error al actualizar stock.', severity: 'error' });
    } finally {
      setLoadingStock(false);
    }
  };

  const handleOpenAssignModal = () => {
    setIsAssignModalOpen(true);
  };

  const handleCloseAssignModal = () => {
    setIsAssignModalOpen(false);
  };

  const handleOpenConfirmDelete = (stock) => {
    setWarehouseToDelete(stock);
    setIsConfirmDeleteOpen(true);
  };

  const handleCloseConfirmDelete = () => {
    setWarehouseToDelete(null);
    setIsConfirmDeleteOpen(false);
  };

  const handleDeleteStock = async (stock) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/products/${productId}/warehouses/${stock.warehouse_id}`
      );
      setLocalSnackbar({ open: true, message: 'Stock de almacén eliminado correctamente.', severity: 'success' });
      fetchProductDetails();
      if (onStockUpdate) {
        onStockUpdate();
      }
    } catch (error) {
      console.error('Error al eliminar stock del almacén:', error);
      setLocalSnackbar({ open: true, message: 'Error al eliminar stock del almacén.', severity: 'error' });
    } finally {
      handleCloseConfirmDelete();
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        Resumen de Stock
      </Typography>
      <Divider sx={{ mb: 3 }} />

      {/* Resumen en tarjetas de estadísticas */}
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12} md={3}>
          <StatCard color="#0288d1" title="Stock Total" value={totalStock} icon={InventoryIcon} />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard color="#e53935" title="Stock Mínimo Total" value={totalMinStock} icon={InventoryIcon} />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard
            color="#ffa726"
            title="Costo Promedio"
            value={`$${parseFloat(averageCost).toFixed(0)}`}
            icon={AttachMoneyIcon}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard
            color="#fb8c00"
            title="Último Costo"
            value={`$${parseFloat(lastCost).toFixed(0)}`}
            icon={AttachMoneyIcon}
          />
        </Grid>
      </Grid>

      {/* Última actualización de stock */}
      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
        <AccessTimeIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
        Última Actualización: {lastUpdatedDate !== 'No disponible' ? lastUpdatedDate.toLocaleString() : 'No disponible'}
      </Typography>

      {/* Formulario de edición del stock */}
      {localStockData
        .filter((stock) => stock.status === 'activo')
        .map((stock, index) => (
          <Card key={index} sx={{ mb: 3 }}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                {/* Nombre del Almacén (Solo Lectura) */}
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Almacén"
                    name="warehouse_name"
                    value={stock.warehouse_name}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                {/* ID del Almacén (Invisible) */}
                <Grid item xs={12} md={0}>
                  <TextField
                    label="ID del Almacén"
                    name="warehouse_id"
                    value={stock.warehouse_id}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ display: 'none' }}
                  />
                </Grid>

                {/* Cantidad en Stock */}
                <Grid item xs={12} md={2}>
                  <TextField
                    label="Cantidad en Stock"
                    name="stock_quantity"
                    type="number"
                    value={stock.stock_quantity}
                    onChange={(e) => handleStockChange(index, e)}
                    fullWidth
                    margin="normal"
                    inputProps={{ min: '0', step: '1' }}
                  />
                </Grid>

                {/* Stock Mínimo */}
                <Grid item xs={12} md={2}>
                  <TextField
                    label="Stock Mínimo"
                    name="minimum_stock"
                    type="number"
                    value={stock.minimum_stock}
                    onChange={(e) => handleStockChange(index, e)}
                    fullWidth
                    margin="normal"
                    inputProps={{ min: '0', step: '1' }}
                  />
                </Grid>

                {/* Última Actualización */}
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Última Actualización"
                    name="last_updated"
                    type="text"
                    value={stock.last_updated ? new Date(stock.last_updated).toLocaleString() : 'No disponible'}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                {/* Botón de Eliminar Almacén */}
                <Grid item xs={12} md={2}>
                  <IconButton color="error" onClick={() => handleOpenConfirmDelete(stock)}>
                    <RemoveIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleOpenAssignModal}
          sx={{ mr: 2 }}
        >
          Asignar Almacén
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleSaveStock}
          disabled={loadingStock}
        >
          {loadingStock ? 'Guardando...' : 'Guardar Stock'}
        </Button>
      </Box>

      {/* Nueva sección: Stock Histórico */}
      {localStockData.some((stock) => stock.status === 'inactivo') && (
        <>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mt: 5 }}>
            Stock Histórico
          </Typography>
          <Divider sx={{ mb: 3 }} />

          {localStockData
            .filter((stock) => stock.status === 'inactivo')
            .map((stock, index) => (
              <Card key={`inactive-${index}`} sx={{ mb: 3 }}>
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    {/* Nombre del Almacén */}
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Almacén"
                        name="warehouse_name"
                        value={stock.warehouse_name}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    {/* Cantidad en Stock */}
                    <Grid item xs={12} md={2}>
                      <TextField
                        label="Cantidad en Stock"
                        name="stock_quantity"
                        type="number"
                        value={stock.stock_quantity}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    {/* Stock Mínimo */}
                    <Grid item xs={12} md={2}>
                      <TextField
                        label="Stock Mínimo"
                        name="minimum_stock"
                        type="number"
                        value={stock.minimum_stock}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    {/* Última Actualización */}
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Última Actualización"
                        name="last_updated"
                        type="text"
                        value={stock.last_updated ? new Date(stock.last_updated).toLocaleString() : 'No disponible'}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
        </>
      )}

      {/* Modales */}
      <AssignWarehouseModal
        open={isAssignModalOpen}
        onClose={handleCloseAssignModal}
        availableWarehouses={availableWarehouses}
        productId={productId}
        onWarehouseAssigned={fetchProductDetails}
        setSnackbar={setLocalSnackbar}
      />

      <ConfirmDeleteModal
        open={isConfirmDeleteOpen}
        onClose={handleCloseConfirmDelete}
        onConfirm={() => handleDeleteStock(warehouseToDelete)}
        title="Eliminar Stock del Almacén"
        description={`¿Estás seguro de que deseas eliminar el stock del almacén "${warehouseToDelete?.warehouse_name}"? Esta acción no se puede deshacer.`}
      />

      {/* Snackbar para Notificaciones */}
      <Snackbar
        open={localSnackbar.open}
        autoHideDuration={6000}
        onClose={() => setLocalSnackbar({ ...localSnackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setLocalSnackbar({ ...localSnackbar, open: false })}
          severity={localSnackbar.severity}
          sx={{ width: '100%' }}
        >
          {localSnackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default StockTab;
