// src/pages/inadmin/AdminBannedIpsPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AdminBannedIpsPage = () => {
    const [bannedIps, setBannedIps] = useState([]);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({ ip_address: '', reason: '' });
    const navigate = useNavigate();

    useEffect(() => {
        fetchBannedIps();
    }, []);

    const fetchBannedIps = () => {
        axios.get('/inadmin/banned_ips')
            .then(response => setBannedIps(response.data))
            .catch(error => console.error('Error al obtener IPs baneadas:', error));
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setFormData({ ip_address: '', reason: '' });
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        axios.post('/inadmin/banned_ips', formData)
            .then(() => {
                fetchBannedIps();
                handleClose();
            })
            .catch(error => console.error('Error al bannear IP:', error));
    };

    const handleEdit = (id) => {
        navigate(`/inadmin/banned_ips/edit/${id}`);
    };

    const handleDelete = (id) => {
        axios.delete(`/inadmin/banned_ips/${id}`)
            .then(() => fetchBannedIps())
            .catch(error => console.error('Error al desbannear IP:', error));
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'ip_address', headerName: 'IP Baneada', width: 200 },
        { field: 'reason', headerName: 'Razón', width: 300 },
        {
            field: 'actions',
            headerName: 'Acciones',
            width: 200,
            renderCell: (params) => (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleEdit(params.row.id)}
                        style={{ marginRight: 8 }}
                    >
                        Editar
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => handleDelete(params.row.id)}
                    >
                        Desbannear
                    </Button>
                </>
            ),
        },
    ];

    return (
        <Box sx={{ height: 600, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <h2>Gestión de IPs Baneadas</h2>
                <Button variant="contained" color="primary" onClick={handleOpen}>
                    Bannear IP
                </Button>
            </Box>
            <DataGrid
                rows={bannedIps}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
            />

            {/* Dialog para Bannear IP */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Bannear Nueva IP</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Dirección IP"
                        name="ip_address"
                        fullWidth
                        variant="standard"
                        value={formData.ip_address}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        label="Razón"
                        name="reason"
                        fullWidth
                        variant="standard"
                        value={formData.reason}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSubmit}>Bannear</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdminBannedIpsPage;
