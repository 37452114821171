// src/pages/ProductBulkUpload.js

import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Box,
  Typography,
  MenuItem,
  Grid,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import * as XLSX from 'xlsx';

const requiredFields = ['name', 'sku', 'price']; // Campos obligatorios

const ProductBulkUpload = () => {
  const [file, setFile] = useState(null); // Archivo Excel
  const [fields, setFields] = useState([]); // Campos del Excel
  const [mappedFields, setMappedFields] = useState({}); // Mapeo de campos Excel -> Producto
  const [previewData, setPreviewData] = useState([]); // Vista previa de los productos
  const [categories, setCategories] = useState([]); // Categorías obtenidas de la BD
  const [suppliers, setSuppliers] = useState([]); // Proveedores desde la BD
  const [brands, setBrands] = useState([]); // Marcas desde la BD (ya no se usará)
  const [warehouses, setWarehouses] = useState([]); // Bodegas desde la BD
  const [errors, setErrors] = useState({}); // Errores de validación
  const [loading, setLoading] = useState(false); // Estado de carga
  const [uploading, setUploading] = useState(false); // Estado de envío
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [uploadResult, setUploadResult] = useState(null); // Resultado de la subida

  // Lista de todos los campos del sistema relacionados con productos (sin company_id y sin brand_id)
  const systemFields = [
    { name: 'name', label: 'Nombre' },
    { name: 'sku', label: 'SKU' },
    { name: 'description', label: 'Descripción' },
    { name: 'price', label: 'Precio' },
    { name: 'category_id', label: 'Categoría' },
    { name: 'supplier_id', label: 'Proveedor' },
    // { name: 'brand_id', label: 'Marca' }, // Eliminado
    { name: 'stock_quantity', label: 'Cantidad en Stock' },
    { name: 'supplier_sku', label: 'SKU del Proveedor' },
    { name: 'supplier_cost', label: 'Costo del Proveedor' },
    { name: 'warehouse_id', label: 'Bodega' },
    // { name: 'warehouse_location', label: 'Ubicación' }, // Eliminado en pasos anteriores
    // Agrega todos los campos relevantes desde la estructura de la BD de IntegraNube
  ];

  // Lista de campos mapeables (excluye los selectables y brand_id)
  const mappableFields = systemFields.filter(
    (field) => field.name !== 'supplier_id' && field.name !== 'warehouse_id'
  );

  // Obtener categorías, proveedores, marcas y bodegas desde la base de datos
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryResponse, supplierResponse, brandResponse, warehouseResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/api/categories/all`),
          axios.get(`https://api.integranube.cl/api/suppliers`),
          axios.get(`${process.env.REACT_APP_API_URL}/api/brands/all`),
          axios.get(`https://api.integranube.cl/api/warehouses`),
        ]);

        // Asumiendo que categories y brands devuelven arrays directamente
        setCategories(Array.isArray(categoryResponse.data) ? categoryResponse.data : []);
        // setBrands(Array.isArray(brandResponse.data) ? brandResponse.data : []); // No se usará

        // Para suppliers y warehouses, extraer los arrays de las claves correspondientes
        setSuppliers(Array.isArray(supplierResponse.data.suppliers) ? supplierResponse.data.suppliers : []);
        setWarehouses(Array.isArray(warehouseResponse.data.warehouses) ? warehouseResponse.data.warehouses : []);
      } catch (error) {
        console.error('Error fetching data:', error);
        setSnackbar({
          open: true,
          message: 'Error al obtener datos de referencia.',
          severity: 'error',
        });
      }
    };
    fetchData();
  }, []);

  // Maneja el archivo de Excel
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          if (jsonData.length < 2) {
            setSnackbar({
              open: true,
              message: 'El archivo Excel está vacío o no tiene datos.',
              severity: 'warning',
            });
            return;
          }

          setFields(jsonData[0]); // Primera fila (header) como campos
          setPreviewData(jsonData.slice(1, 6)); // Mostrar los primeros 5 productos en la vista previa
        } catch (error) {
          console.error('Error leyendo el archivo Excel:', error);
          setSnackbar({
            open: true,
            message: 'Error al leer el archivo Excel.',
            severity: 'error',
          });
        }
      };
      reader.readAsArrayBuffer(selectedFile);
    }
  };

  // Validación de los campos obligatorios
  const validateForm = () => {
    let newErrors = {};
    requiredFields.forEach((field) => {
      if (!mappedFields[field]) {
        newErrors[field] = `${field} es obligatorio`;
      }
    });

    // Validar que todos los campos obligatorios estén mapeados
    if (Object.keys(newErrors).length > 0) {
      setSnackbar({
        open: true,
        message: 'Por favor, mapea todos los campos obligatorios.',
        severity: 'error',
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Maneja el cambio del mapeo y limpia el error si el campo es corregido
  const handleFieldMappingChange = (systemField, excelField) => {
    setMappedFields((prev) => ({
      ...prev,
      [systemField]: excelField,
    }));

    // Si el campo es obligatorio y se ha mapeado correctamente, eliminamos el error
    if (requiredFields.includes(systemField) && excelField) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[systemField];
        return newErrors;
      });
    }
  };

  // Función para transformar los datos de Excel según el mapeo
  const transformData = () => {
    const transformed = [];

    previewData.forEach((row, rowIndex) => {
      let product = {};

      // Asignar valores mapeados
      mappableFields.forEach((field) => {
        const excelField = mappedFields[field.name];
        if (excelField) {
          const excelIndex = fields.indexOf(excelField);
          product[field.name] = row[excelIndex] || null;

          // Transformaciones específicas
          if (field.name === 'category_id') {
            const category = categories.find(
              (cat) => cat.name.toLowerCase() === row[excelIndex]?.toString().toLowerCase()
            );
            product[field.name] = category ? category.id : null;
          }
        }
      });

      // Asignar proveedor seleccionado
      if (mappedFields['supplier_id']) {
        product['supplier_id'] = mappedFields['supplier_id'];
      }

      // Asignar bodega seleccionada
      if (mappedFields['warehouse_id']) {
        product['warehouse_id'] = mappedFields['warehouse_id'];
      }

      transformed.push(product);
    });

    return transformed;
  };

  // Maneja el envío de los productos al backend
  const handleUpload = async () => {
    if (!validateForm()) return;

    const dataToUpload = transformData();

    setUploading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/products/bulk-upload`,
        { products: dataToUpload }
      );

      if (response.status === 201) { // Cambié a 201 según el endpoint
        setUploadResult(response.data); // Guardar el resultado para mostrar detalles
        setSnackbar({
          open: true,
          message: 'Productos cargados exitosamente.',
          severity: 'success',
        });
        // Resetear el formulario
        setFile(null);
        setFields([]);
        setMappedFields({});
        setPreviewData([]);
      } else {
        setSnackbar({
          open: true,
          message: 'Error al cargar los productos.',
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Error uploading products:', error);
      setSnackbar({
        open: true,
        message: 'Error al cargar los productos.',
        severity: 'error',
      });
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Subida Masiva de Productos
      </Typography>

      {/* Input para seleccionar archivo */}
      <Button variant="contained" component="label">
        Seleccionar Archivo Excel
        <input type="file" accept=".xlsx, .xls" hidden onChange={handleFileChange} />
      </Button>
      {file && (
        <Typography variant="body1" sx={{ mt: 2 }}>
          Archivo seleccionado: {file.name}
        </Typography>
      )}
      <Box sx={{ my: 4 }}>
        {fields.length > 0 && (
          <Paper sx={{ p: 3 }}>
            <Grid container spacing={3}>
              {/* Columna izquierda: Mapeo de Campos */}
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Mapeo de Campos
                </Typography>
                {mappableFields.map((systemField, index) => (
                  <TextField
                    key={index}
                    label={`${systemField.label} ${requiredFields.includes(systemField.name) ? '*' : ''}`}
                    select
                    value={mappedFields[systemField.name] || ''}
                    onChange={(e) => handleFieldMappingChange(systemField.name, e.target.value)}
                    fullWidth
                    error={!!errors[systemField.name]}
                    helperText={errors[systemField.name] || ''}
                    sx={{ mb: 3 }}
                  >
                    <MenuItem value="">Seleccione una columna</MenuItem>
                    {fields.map((excelField, i) => (
                      <MenuItem key={i} value={excelField}>
                        {excelField}
                      </MenuItem>
                    ))}
                  </TextField>
                ))}

                {/* Select de Proveedor */}
                <TextField
                  label="Proveedor *"
                  select
                  value={mappedFields['supplier_id'] || ''}
                  onChange={(e) => handleFieldMappingChange('supplier_id', e.target.value)}
                  fullWidth
                  error={!!errors['supplier_id']}
                  helperText={errors['supplier_id'] || ''}
                  sx={{ mb: 3 }}
                >
                  <MenuItem value="">Seleccione un proveedor</MenuItem>
                  {Array.isArray(suppliers) && suppliers.map((supplier) => (
                    <MenuItem key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </MenuItem>
                  ))}
                </TextField>

                {/* Select de Bodega */}
                <TextField
                  label="Bodega *"
                  select
                  value={mappedFields['warehouse_id'] || ''}
                  onChange={(e) => handleFieldMappingChange('warehouse_id', e.target.value)}
                  fullWidth
                  error={!!errors['warehouse_id']}
                  helperText={errors['warehouse_id'] || ''}
                  sx={{ mb: 3 }}
                >
                  <MenuItem value="">Seleccione una bodega</MenuItem>
                  {Array.isArray(warehouses) && warehouses.map((warehouse) => (
                    <MenuItem key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Columna derecha: Vista previa de los productos */}
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Vista Previa de Productos
                </Typography>
                <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                  {previewData.length > 0 ? (
                    previewData.map((row, rowIndex) => (
                      <Box key={rowIndex} sx={{ mb: 2, p: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                        {mappableFields.map((field, idx) => {
                          const excelField = mappedFields[field.name];
                          const value = excelField ? row[fields.indexOf(excelField)] : 'N/A';
                          return (
                            <Typography key={idx}>
                              <strong>{field.label}:</strong> {value}
                            </Typography>
                          );
                        })}
                        <Typography>
                          <strong>Proveedor:</strong> {suppliers.find(s => s.id === mappedFields['supplier_id'])?.name || 'N/A'}
                        </Typography>
                        <Typography>
                          <strong>Bodega:</strong> {warehouses.find(w => w.id === mappedFields['warehouse_id'])?.name || 'N/A'}
                        </Typography>
                      </Box>
                    ))
                  ) : (
                    <Typography variant="body2">No hay datos para mostrar.</Typography>
                  )}
                </Box>
              </Grid>
            </Grid>

            {/* Botón para iniciar la carga */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
                disabled={uploading}
              >
                {uploading ? <CircularProgress size={24} /> : 'Iniciar Carga'}
              </Button>
            </Box>
          </Paper>
        )}
      </Box>

      {/* Resultados de la subida masiva */}
      {uploadResult && (
        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            Resultado de la Subida Masiva
          </Typography>
          <Typography variant="subtitle1" color="green">
            Productos Insertados: {uploadResult.inserted.length}
          </Typography>
          <Typography variant="subtitle1" color="blue">
            Productos Actualizados: {uploadResult.updated.length}
          </Typography>
          {uploadResult.skipped.length > 0 && (
            <>
              <Typography variant="subtitle1" color="orange" sx={{ mt: 2 }}>
                Productos Omitidos: {uploadResult.skipped.length}
              </Typography>
              <TableContainer component={Paper} sx={{ mt: 1 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>SKU</TableCell>
                      <TableCell>Razón</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uploadResult.skipped.map((prod, index) => (
                      <TableRow key={index}>
                        <TableCell>{prod.name || 'N/A'}</TableCell>
                        <TableCell>{prod.sku || 'N/A'}</TableCell>
                        <TableCell>{prod.reason}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      )}

      {/* Snackbar para Notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProductBulkUpload;
