// src/pages/HomePage.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Card,
  Typography,
  Avatar,
  CircularProgress,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Divider,
} from '@mui/material';
import {
  Store as StoreIcon,
  Inventory as InventoryIcon,
  MonetizationOn as MonetizationOnIcon,
} from '@mui/icons-material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import axios from 'axios';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const HomePage = () => {
  const theme = useTheme();
  const [metrics, setMetrics] = useState(null);
  const [inventoryValue, setInventoryValue] = useState(null);
  const [barData, setBarData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Configurar axios con la URL base
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  // Obtener datos del dashboard
  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      const [
        metricsRes,
        barRes,
        lineRes,
        pieRes,
        tableRes,
        inventoryRes, // Nuevo endpoint
      ] = await Promise.all([
        axiosInstance.get('/api/dashboard/metrics'),
        axiosInstance.get('/api/dashboard/products-status'),
        axiosInstance.get('/api/dashboard/purchases-monthly'),
        axiosInstance.get('/api/dashboard/categories-distribution'),
        axiosInstance.get('/api/dashboard/critical-stock-products'),
        axiosInstance.get('/api/dashboard/inventory-value'), // Agregar llamada al nuevo endpoint
      ]);

      setMetrics(metricsRes.data);
      setBarData(barRes.data);
      setLineData(lineRes.data);
      setPieData(pieRes.data);
      setTableData(tableRes.data);
      setInventoryValue(inventoryRes.data); // Guardar datos del inventario
      setLoading(false);
    } catch (err) {
      console.error('Error al obtener datos del Dashboard:', err);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <Typography variant="h6" color="error">
          Error al cargar los datos del Dashboard. Inténtalo de nuevo más tarde.
        </Typography>
      </Box>
    );
  }

  // Definir los datos de las métricas, eliminando 'Usuarios Conectados' y 'Stock Crítico'
  const metricsData = [
    {
      title: 'Total de Productos',
      value: metrics.totalProducts ?? 'N/A',
      icon: <StoreIcon fontSize="large" />,
      color: theme.palette.primary.main,
    },
    {
      title: 'Productos Disponibles',
      value: metrics.availableProducts ?? 'N/A',
      icon: <InventoryIcon fontSize="large" />,
      color: theme.palette.success.main,
    },
    {
      title: 'Productos Agotados',
      value: metrics.outOfStockProducts ?? 'N/A',
      icon: <InventoryIcon fontSize="large" />, // Puedes cambiar el icono si lo deseas
      color: theme.palette.error.main,
    },
    {
      title: 'Total de Compras',
      value: metrics.totalPurchases ?? 'N/A',
      icon: <MonetizationOnIcon fontSize="large" />,
      color: theme.palette.info.main,
    },
    // Nuevas tarjetas para Valor Tributario y Valorización Comercial
    {
      title: 'Costo productos en Bodega (Neto)',
      value: inventoryValue ? formatCurrency(inventoryValue.cost_valuation) : 'N/A',
      icon: <MonetizationOnIcon fontSize="large" />, // Puedes elegir un icono diferente
      color: theme.palette.warning.main,
    },
    {
      title: 'Valorización Comercial (Neto)',
      value: inventoryValue ? formatCurrency(inventoryValue.commercial_valuation) : 'N/A',
      icon: <MonetizationOnIcon fontSize="large" />, // Puedes elegir un icono diferente
      color: theme.palette.secondary.main,
    },
  ];

  // Función para formatear el número como moneda chilena
  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
    }).format(amount);
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Bienvenido al ERP IntegraNube Dashboard
      </Typography>

      {/* Sección de Métricas Principales */}
      <Grid container spacing={3}>
        {metricsData.map((metric, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Tooltip title={metric.title} placement="top">
              <Card
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 2,
                  backgroundColor: metric.color,
                  color: '#fff',
                  boxShadow: 3,
                  borderRadius: 2,
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: 'rgba(255, 255, 255, 0.2)',
                    width: 56,
                    height: 56,
                    mr: 2,
                  }}
                >
                  {metric.icon}
                </Avatar>
                <Box>
                  <Typography variant="h6">{metric.value}</Typography>
                  <Typography variant="body2">{metric.title}</Typography>
                </Box>
              </Card>
            </Tooltip>
          </Grid>
        ))}
      </Grid>

      {/* Gráfico de Productos Disponibles vs Agotados */}
      <Box sx={{ mt: 5 }}>
        <Typography variant="h5" gutterBottom>
          Estado de Productos
        </Typography>
        {barData.length > 0 ? (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={barData}>
              <XAxis dataKey="name" stroke={theme.palette.text.primary} />
              <YAxis stroke={theme.palette.text.primary} />
              <RechartsTooltip />
              <Legend />
              <Bar
                dataKey="value"
                fill={theme.palette.primary.main}
                name="Productos"
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Typography variant="body1">No hay datos para mostrar.</Typography>
        )}
      </Box>

      {/* Gráfico de Compras Mensuales */}
      <Box sx={{ mt: 5 }}>
        <Typography variant="h5" gutterBottom>
          Evolución de Compras Mensuales
        </Typography>
        {lineData.length > 0 ? (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={lineData}>
              <XAxis dataKey="month" stroke={theme.palette.text.primary} />
              <YAxis stroke={theme.palette.text.primary} />
              <RechartsTooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="purchases"
                stroke={theme.palette.secondary.main}
                strokeWidth={2}
                name="Compras"
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Typography variant="body1">No hay datos para mostrar.</Typography>
        )}
      </Box>

      {/* Gráfico de Distribución de Categorías */}
      <Box sx={{ mt: 5 }}>
        <Typography variant="h5" gutterBottom>
          Distribución de Categorías
        </Typography>
        {pieData.length > 0 ? (
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={pieData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#8884d8"
                label
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <RechartsTooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <Typography variant="body1">No hay datos para mostrar.</Typography>
        )}
      </Box>

      {/* Sección de Valorización del Inventario */}
      <Box sx={{ mt: 5 }}>
        <Typography variant="h5" gutterBottom>
          Valorización del Inventario
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 2,
                backgroundColor: theme.palette.warning.main,
                color: '#fff',
                boxShadow: 3,
                borderRadius: 2,
              }}
            >
              <Avatar
                sx={{
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                  width: 56,
                  height: 56,
                  mr: 2,
                }}
              >
                <MonetizationOnIcon fontSize="large" />
              </Avatar>
              <Box>
                <Typography variant="h6">Valor Tributario</Typography>
                <Typography variant="body1">
                  {inventoryValue
                    ? formatCurrency(inventoryValue.cost_valuation)
                    : 'N/A'}
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 2,
                backgroundColor: theme.palette.secondary.main,
                color: '#fff',
                boxShadow: 3,
                borderRadius: 2,
              }}
            >
              <Avatar
                sx={{
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                  width: 56,
                  height: 56,
                  mr: 2,
                }}
              >
                <MonetizationOnIcon fontSize="large" />
              </Avatar>
              <Box>
                <Typography variant="h6">Valorización Comercial</Typography>
                <Typography variant="body1">
                  {inventoryValue
                    ? formatCurrency(inventoryValue.commercial_valuation)
                    : 'N/A'}
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Eliminado: Tabla de Productos con Stock Crítico */}
      {/* Eliminado: Lista de Usuarios Conectados */}
    </Box>
  );
};

export default HomePage;
