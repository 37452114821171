// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Layout from './pages/components/Layout';
import HomePage from './pages/HomePage';
import UserPage from './pages/UserPage';
import ProductPage from './pages/ProductsPage';
import ProductView from './pages/ProductView';
import ProductEdit from './pages/ProductEdit';
import ProductCreate from './pages/ProductCreate';
import ProductBulkUpload from './pages/ProductBulkUpload';
import CategoryCreate from './pages/CategoryCreate';
import CategoryPage from './pages/CategoryPage';
import IntegrationPage from './pages/IntegrationPage';
import IntegrationView from './pages/IntegrationView';
import IntegrationEdit from './pages/IntegrationEdit';
import IntegrationCreate from './pages/IntegrationCreate';
import LoginForm from './pages/components/LoginForm';
import ProtectedRoute from './pages/components/ProtectedRoute';
import OrdersPage from './pages/OrdersPage';
import OrderCreatePage from './pages/OrderCreatePage';
import OrderEditPage from './pages/OrderEditPage';
import OrderDetailsPage from './pages/OrderDetailsPage';
import JumpsellerOrdersPage from './pages/JumpsellerOrdersPage';
import JumpsellerOrdersView from './pages/JumpsellerOrdersView';
import AutomationPage from './pages/AutomationPage';
import AutomationForm from './pages/AutomationForm';
import JumpsellerProductsPage from './pages/JumpsellerProductsPage';
import JumpsellerProductView from './pages/JumpsellerProductView';
import PurchasesPage from './pages/PurchasesPage';
import PurchaseDetailsView from './pages/PurchaseDetailsView';
import InventoryPage from './pages/InventoryPage';
import SettingsPage from './pages/SettingsPage';
import SuppliersPage from './pages/SuppliersPage';
import SalesPage from './pages/SalesPage';
import SaleDetailsView from './pages/SaleDetailsView';
import POSPage from './pages/POS';
import SupplierProductsPage from './pages/SupplierProductsPage';
import SuppliersBulkUploadPage from './pages/SuppliersBulkUploadPage';
import QuotationPage from './pages/QuotationPage';
import ImbraSyncPage from './pages/ImbraSyncPage';
import WarehousesPage from './pages/WarehousesPage';
import WarehouseCreate from './pages/WarehouseCreate';
import WarehouseEdit from './pages/WarehouseEdit';
import WarehouseView from './pages/WarehouseView';
import WhatsAppInterface from './pages/WhatsAppInterface';
import CustomersPage from './pages/CustomersPage';
import { CssBaseline, Snackbar, Alert, CircularProgress, Box } from '@mui/material';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Corrección de importación

// Importar componentes de administración
import AdminLayout from './pages/inadmin/AdminLayout';
import AdminUsersPage from './pages/inadmin/AdminUsersPage';
import AdminAdminsPage from './pages/inadmin/AdminAdminsPage';
import AdminBannedIpsPage from './pages/inadmin/AdminBannedIpsPage';
import AdminCompaniesPage from './pages/inadmin/AdminCompaniesPage';
import AdminPermissionsPage from './pages/inadmin/AdminPermissionsPage';
import AdminRefreshTokensPage from './pages/inadmin/AdminRefreshTokensPage';
import AdminTenantsPage from './pages/inadmin/AdminTenantsPage';
import AdminUserCompaniesPage from './pages/inadmin/AdminUserCompaniesPage';
import AdminUserPermissionsPage from './pages/inadmin/AdminUserPermissionsPage';
import AdminLoginForm from './pages/inadmin/AdminLoginForm'; // Componente de login para admin
import AdminDashboard from './pages/inadmin/AdminDashboard';

function App() {
  const navigate = useNavigate();

  // Estados para la autenticación de la aplicación principal
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Estados para la autenticación de administración
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);

  const [loading, setLoading] = useState(true); // Estado de carga
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Función para verificar la autenticación de la aplicación principal
  const verifyAuth = () => {
    const token = localStorage.getItem('token');
    const tenantId = localStorage.getItem('tenantId');
    const companyId = localStorage.getItem('companyId');

    if (token && tenantId && companyId) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          handleLogout();
        } else {
          setIsAuthenticated(true);
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          axios.defaults.headers.common['x-tenant-id'] = tenantId;
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        handleLogout();
      }
    }
  };

  // Función para verificar la autenticación de administración
  const verifyAdminAuth = () => {
    const adminToken = localStorage.getItem('adminToken');

    if (adminToken) {
      try {
        const decoded = jwtDecode(adminToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          handleAdminLogout();
        } else {
          setIsAdminAuthenticated(true);
          axios.defaults.headers.common['Authorization'] = `Bearer ${adminToken}`;
        }
      } catch (error) {
        console.error('Error decoding admin token:', error);
        handleAdminLogout();
      }
    }
  };

  // Uso de useEffect para verificar autenticaciones al montar el componente
  useEffect(() => {
    verifyAuth();
    verifyAdminAuth();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Interceptador de respuestas para manejar errores 401 en la aplicación principal
  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          handleLogout();
          navigate('/login');
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // Interceptador de respuestas para manejar errores 401 en la administración
  useEffect(() => {
    const adminResponseInterceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          handleAdminLogout();
          navigate('/inadmin/login');
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(adminResponseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdminAuthenticated]);

  // Función para manejar el login de la aplicación principal
  const handleLogin = (data) => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('token', data.token);
    localStorage.setItem('tenantId', data.company.tenant_id);
    localStorage.setItem('companyId', data.company.company_id);
    localStorage.setItem('companyName', data.company.company_name);
    localStorage.setItem('user', JSON.stringify(data.user));
    localStorage.setItem('company', JSON.stringify(data.company));
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
    axios.defaults.headers.common['x-tenant-id'] = data.company.tenant_id;
    setSnackbar({
      open: true,
      message: 'Inicio de sesión exitoso',
      severity: 'success',
    });
  };

  // Función para manejar el login de administración
  const handleAdminLogin = (data) => {
    setIsAdminAuthenticated(true);
    localStorage.setItem('adminAuthenticated', 'true');
    localStorage.setItem('adminToken', data.token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
    setSnackbar({
      open: true,
      message: 'Inicio de sesión de admin exitoso',
      severity: 'success',
    });
    navigate('/inadmin/users'); // Redirigir al dashboard de administración después del login
  };

  // Función para manejar el logout de la aplicación principal
  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('token');
    localStorage.removeItem('tenantId');
    localStorage.removeItem('companyId');
    localStorage.removeItem('companyName');
    localStorage.removeItem('user');
    localStorage.removeItem('company');
    delete axios.defaults.headers.common['Authorization'];
    delete axios.defaults.headers.common['x-tenant-id'];
    navigate('/login');
    setSnackbar({
      open: true,
      message: 'Sesión cerrada correctamente',
      severity: 'info',
    });
  };

  // Función para manejar el logout de administración
  const handleAdminLogout = () => {
    setIsAdminAuthenticated(false);
    localStorage.removeItem('adminAuthenticated');
    localStorage.removeItem('adminToken');
    delete axios.defaults.headers.common['Authorization'];
    navigate('/inadmin/login');
    setSnackbar({
      open: true,
      message: 'Sesión de admin cerrada correctamente',
      severity: 'info',
    });
  };

  // Manejar cambios en el almacenamiento local (por ejemplo, cierre de sesión en otra pestaña)
  useEffect(() => {
    const handleStorageChange = () => {
      verifyAuth();
      verifyAdminAuth();
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    // Muestra un indicador de carga mientras se verifica la autenticación
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CssBaseline />
      <Routes>
        {/* Rutas de Administración */}
        <Route path="/inadmin/login" element={<AdminLoginForm onLogin={handleAdminLogin} />} />
        <Route path="/inadmin/*" element={
          <ProtectedRoute isAuthenticated={isAdminAuthenticated}>
            <AdminLayout onLogout={handleAdminLogout} />
          </ProtectedRoute>
        }>
          <Route path="admins" element={<AdminAdminsPage />} />
          <Route path="banned_ips" element={<AdminBannedIpsPage />} />
          <Route path="companies" element={<AdminCompaniesPage />} />
          <Route path="permissions" element={<AdminPermissionsPage />} />
          <Route path="refresh_tokens" element={<AdminRefreshTokensPage />} />
          <Route path="tenants" element={<AdminTenantsPage />} />
          <Route path="user_companies" element={<AdminUserCompaniesPage />} />
          <Route path="user_permissions" element={<AdminUserPermissionsPage />} />
          <Route path="users" element={<AdminUsersPage />} />
          <Route path="dashboard" element={<AdminDashboard />} />
          {/* Ruta por defecto para administración */}
          <Route path="*" element={<Navigate to="/inadmin/dashboard" replace />} />
        </Route>

        {/* Rutas Principales */}
        <Route path="/login" element={<LoginForm onLogin={handleLogin} />} />
        <Route path="/pos" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <POSPage />
          </ProtectedRoute>
        } />
        <Route path="/*" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <Layout onLogout={handleLogout} />
          </ProtectedRoute>
        }>
          <Route index element={<HomePage />} />
          <Route path="users" element={<UserPage />} />
          <Route path="customers" element={<CustomersPage />} />
          <Route path="products" element={<ProductPage />} />
          <Route path="products/view/:id" element={<ProductView />} />
          <Route path="products/edit/:id" element={<ProductEdit />} />
          <Route path="products/create" element={<ProductCreate />} />
          <Route path="products/bulk-upload" element={<ProductBulkUpload />} />
          <Route path="categories" element={<CategoryPage />} />
          <Route path="categories/create" element={<CategoryCreate />} />
          <Route path="integrations" element={<IntegrationPage />} />
          <Route path="integrations/view/:id" element={<IntegrationView />} />
          <Route path="integrations/edit/:id" element={<IntegrationEdit />} />
          <Route path="integrations/create" element={<IntegrationCreate />} />
          <Route path="whatsapp" element={<WhatsAppInterface />} />
          <Route path="purchases" element={<PurchasesPage />} />
          <Route path="purchases/:purchaseId" element={<PurchaseDetailsView />} />
          <Route path="orders" element={<OrdersPage />} />
          <Route path="orders/create" element={<OrderCreatePage />} />
          <Route path="orders/edit/:id" element={<OrderEditPage />} />
          <Route path="orders/:id/details" element={<OrderDetailsPage />} />
          <Route path="jumpseller/orders" element={<JumpsellerOrdersPage />} />
          <Route path="jumpseller/orders/:orderId" element={<JumpsellerOrdersView />} />
          <Route path="jumpseller/products" element={<JumpsellerProductsPage />} />
          <Route path="jumpseller/products/:productId" element={<JumpsellerProductView />} />
          <Route path="automations" element={<AutomationPage />} />
          <Route path="automations/create" element={<AutomationForm />} />
          <Route path="inventory" element={<InventoryPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="suppliers" element={<SuppliersPage />} />
          <Route path="suppliers/:tax_id" element={<SupplierProductsPage />} />
          <Route path="suppliers-bulk-upload" element={<SuppliersBulkUploadPage />} />
          <Route path="sales" element={<SalesPage />} />
          <Route path="sales/:saleId" element={<SaleDetailsView />} />
          <Route path="quotation" element={<QuotationPage />} />
          <Route path="imbrasync" element={<ImbraSyncPage />} />
          <Route path="warehouses" element={<WarehousesPage />} />
          <Route path="warehouses/create" element={<WarehouseCreate />} />
          <Route path="warehouses/edit/:id" element={<WarehouseEdit />} />
          <Route path="warehouses/:warehouseId" element={<WarehouseView />} />
          {/* Ruta por defecto para la aplicación principal */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>

        {/* Rutas Públicas */}
        {/* Si necesitas rutas públicas fuera de las rutas protegidas, agrégalas aquí */}
      </Routes>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
