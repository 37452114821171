// src/components/LoginForm.js

import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from '@mui/material';
import axios from 'axios';
// Importamos la imagen de fondo
import backgroundImage from '../assets/integranube-background.jpg'; // Asegúrate de tener esta imagen en tu carpeta de assets

function LoginForm({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [step, setStep] = useState('login'); // 'login' o 'selectCompany'
  const [userData, setUserData] = useState(null); // Almacena los datos del usuario y el token

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
        email,
        password,
      });

      setLoading(false);

      if (response.data.token) {
        // Guardar los datos del usuario y compañías
        setUserData(response.data);
        setCompanies(response.data.companies);
        setStep('selectCompany'); // Avanzar al paso de selección de compañía
      } else {
        setError('Credenciales incorrectas. Inténtalo de nuevo.');
      }
    } catch (err) {
      setLoading(false);
      setError('Error en la autenticación. Verifica tus credenciales e intenta de nuevo.');
    }
  };

  const handleCompanySelect = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!selectedCompany) {
      setError('Por favor, selecciona una compañía.');
      setLoading(false);
      return;
    }

    try {
      // Buscar la compañía seleccionada en la lista
      const company = companies.find((c) => c.company_id === selectedCompany);

      if (!company) {
        setError('Compañía seleccionada no encontrada.');
        setLoading(false);
        return;
      }

      // Guardar la información necesaria en localStorage
      localStorage.setItem('token', userData.token);
      localStorage.setItem('tenantId', company.tenant_id);
      localStorage.setItem('companyId', company.company_id);
      localStorage.setItem('companyName', company.company_name);
      localStorage.setItem('user', JSON.stringify(userData.user)); // Almacenar 'user' en localStorage
      localStorage.setItem('company', JSON.stringify(company)); // Almacenar 'company' en localStorage

      // Configurar axios para incluir el token y el tenantId en las cabeceras
      axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`;
      axios.defaults.headers.common['x-tenant-id'] = company.tenant_id;

      setLoading(false);

      // Llamar a la función onLogin con la información completa
      onLogin({
        user: userData.user,
        company,
        token: userData.token,
      });
    } catch (err) {
      setLoading(false);
      setError('Error al seleccionar la compañía. Inténtalo de nuevo.');
    }
  };

  const renderLoginForm = () => (
    <Paper
      elevation={6}
      sx={{
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backdropFilter: 'blur(5px)',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      }}
    >
      <Box
        component="form"
        onSubmit={handleLoginSubmit}
        sx={{ mt: 1, width: '100%' }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          IntegraNube Login
        </Typography>
        <TextField
          label="Email"
          variant="outlined"
          margin="normal"
          fullWidth
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Contraseña"
          variant="outlined"
          type="password"
          margin="normal"
          fullWidth
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && (
          <Typography color="error" variant="body2" align="center">
            {error}
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Iniciar Sesión'}
        </Button>
      </Box>
    </Paper>
  );

  const renderCompanySelector = () => (
    <Paper
      elevation={6}
      sx={{
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backdropFilter: 'blur(5px)',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      }}
    >
      <Box
        component="form"
        onSubmit={handleCompanySelect}
        sx={{ mt: 1, width: '100%' }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          Selecciona una Compañía
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel id="company-select-label">Compañía</InputLabel>
          <Select
            labelId="company-select-label"
            value={selectedCompany}
            label="Compañía"
            onChange={(e) => setSelectedCompany(e.target.value)}
            required
          >
            {companies.map((company) => (
              <MenuItem key={company.company_id} value={company.company_id}>
                {company.company_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {error && (
          <Typography color="error" variant="body2" align="center">
            {error}
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Continuar'}
        </Button>
      </Box>
    </Paper>
  );

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="xs">
        {step === 'login' && renderLoginForm()}
        {step === 'selectCompany' && renderCompanySelector()}
      </Container>
    </Box>
  );
}

export default LoginForm;
