import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  LinearProgress,
  Button,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Checkbox,
  Pagination,
  Chip,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from '@mui/material';
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const JumpsellerProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTask, setCurrentTask] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [sortBy, setSortBy] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [bulkEditOpen, setBulkEditOpen] = useState(false);
  const [bulkEditData, setBulkEditData] = useState({
    price: '',
    stock: '',
  });
  const [companies, setCompanies] = useState([]);

  const navigate = useNavigate();

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/companies`
      );
      setCompanies(response.data.companies);
    } catch (error) {
      console.error('Error al cargar compañías:', error);
    }
  };

  const startImportProcess = async () => {
    setLoading(true);
    setProgress(0);
    setCurrentTask('Importando productos... NO CIERRE ESTA PÁGINA');

    try {
      const importResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/jumpsellerProducts/products`
      );

      setCurrentTask(importResponse.data.message || 'Importación completada.');
      setProgress(100);

      fetchProductsWithPagination(page, searchTerm);
    } catch (error) {
      console.error('Error durante la importación:', error);
      setCurrentTask('Error durante la importación.');
    }

    setLoading(false);
  };

  const fetchProductsWithPagination = async (currentPage, search = '') => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/jumpsellerproducts/products`,
        {
          params: {
            page: currentPage,
            limit: limit,
            search: search,
            category: selectedCategory,
            brand: selectedBrand,
            sortBy: sortBy,
            sortOrder: sortOrder,
          },
        }
      );
      if (response.data?.products) {
        const processedProducts = response.data.products.map((product) => {
          let images = product.images;
          if (typeof images === 'string') {
            images = JSON.parse(images);
          }

          return {
            ...product,
            images: images || [],
          };
        });
        setProducts(processedProducts);
        setTotalPages(response.data.totalPages);
      }
    } catch (error) {
      console.error('Error al cargar los productos:', error);
    }
    setLoading(false);
  };

  const fetchFilters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/jumpsellerproducts/products/filters`
      );
      setCategories(response.data.categories);
      setBrands(response.data.brands);
    } catch (error) {
      console.error('Error al cargar filtros:', error);
    }
  };

  useEffect(() => {
    fetchFilters();
    fetchCompanies();
    fetchProductsWithPagination(page, searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchTerm, selectedCategory, selectedBrand, sortBy, sortOrder]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setPage(1);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
    setPage(1);
  };

  const handleSortByChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  const getStockColor = (stock) => {
    if (stock > 10) return 'success';
    if (stock > 0) return 'warning';
    return 'error';
  };

  const goToProductView = (productId) => {
    navigate(`/jumpseller/products/${productId}`);
  };

  const cleanImageUrl = (url) => {
    const index = url.indexOf('?');
    return index !== -1 ? url.substring(0, index) : url;
  };

  const handleSelectProduct = (event, productId) => {
    if (event.target.checked) {
      setSelectedProducts([...selectedProducts, productId]);
    } else {
      setSelectedProducts(selectedProducts.filter((id) => id !== productId));
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = products.map((product) => product.jumpseller_product_id);
      setSelectedProducts(newSelecteds);
    } else {
      setSelectedProducts([]);
    }
  };

  const handleDeleteProduct = async (productId) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este producto?')) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/jumpsellerproducts/products/${productId}`
        );
        alert('Producto eliminado correctamente.');
        fetchProductsWithPagination(page, searchTerm);
      } catch (error) {
        console.error('Error al eliminar el producto:', error);
        alert('Error al eliminar el producto.');
      }
    }
  };

  const handleBulkDelete = async () => {
    if (
      window.confirm(
        '¿Estás seguro de que deseas eliminar los productos seleccionados?'
      )
    ) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/jumpsellerproducts/products/bulk-delete`,
          { productIds: selectedProducts }
        );
        alert('Productos eliminados correctamente.');
        setSelectedProducts([]);
        fetchProductsWithPagination(page, searchTerm);
      } catch (error) {
        console.error('Error al eliminar los productos:', error);
        alert('Error al eliminar los productos.');
      }
    }
  };

  const handleBulkEditOpen = () => {
    setBulkEditOpen(true);
  };

  const handleBulkEditClose = () => {
    setBulkEditOpen(false);
    setBulkEditData({ price: '', stock: '' });
  };

  const handleBulkEditChange = (event) => {
    const { name, value } = event.target;
    setBulkEditData({ ...bulkEditData, [name]: value });
  };

  const handleBulkEditApply = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/jumpsellerproducts/products/bulk-update`,
        {
          productIds: selectedProducts,
          data: bulkEditData,
        }
      );
      alert('Productos actualizados correctamente.');
      handleBulkEditClose();
      setSelectedProducts([]);
      fetchProductsWithPagination(page, searchTerm);
    } catch (error) {
      console.error('Error al actualizar los productos:', error);
      alert('Error al actualizar los productos.');
    }
  };

  // Función para truncar el texto
  const truncateText = (text, maxLength) => {
    if (!text) return 'Sin nombre';
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  // Función para obtener el nombre de la compañía
  const getCompanyName = (company_id) => {
    const company = companies.find((c) => c.id === company_id);
    return company ? company.name : 'Desconocida';
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Productos de Jumpseller
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 2,
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <TextField
          placeholder="Buscar productos..."
          value={searchTerm}
          onChange={handleSearch}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: 300 }}
        />

        <FormControl sx={{ minWidth: 120 }} size="small">
          <InputLabel>Categoría</InputLabel>
          <Select
            value={selectedCategory}
            onChange={handleCategoryChange}
            label="Categoría"
          >
            <MenuItem value="">
              <em>Todas</em>
            </MenuItem>
            {categories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }} size="small">
          <InputLabel>Marca</InputLabel>
          <Select
            value={selectedBrand}
            onChange={handleBrandChange}
            label="Marca"
          >
            <MenuItem value="">
              <em>Todas</em>
            </MenuItem>
            {brands.map((brand) => (
              <MenuItem key={brand} value={brand}>
                {brand}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }} size="small">
          <InputLabel>Ordenar por</InputLabel>
          <Select
            value={sortBy}
            onChange={handleSortByChange}
            label="Ordenar por"
          >
            <MenuItem value="id">ID</MenuItem>
            <MenuItem value="name">Nombre</MenuItem>
            <MenuItem value="price">Precio</MenuItem>
            <MenuItem value="stock">Stock</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }} size="small">
          <InputLabel>Orden</InputLabel>
          <Select
            value={sortOrder}
            onChange={handleSortOrderChange}
            label="Orden"
          >
            <MenuItem value="asc">Ascendente</MenuItem>
            <MenuItem value="desc">Descendente</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={startImportProcess}
          disabled={loading}
        >
          Importar Productos
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleBulkDelete}
          disabled={selectedProducts.length === 0}
        >
          Eliminar Seleccionados
        </Button>

        <Button
          variant="contained"
          onClick={handleBulkEditOpen}
          disabled={selectedProducts.length === 0}
        >
          Modificar Seleccionados
        </Button>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
          <Typography variant="h6" sx={{ ml: 2, mt: 2 }}>
            {currentTask}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ width: '100%', mt: 2 }}
          />
        </Box>
      ) : (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">Lista de productos</Typography>
        </Box>
      )}

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" sx={{ width: '5%' }}>
                <Checkbox
                  indeterminate={
                    selectedProducts.length > 0 &&
                    selectedProducts.length < products.length
                  }
                  checked={
                    products.length > 0 &&
                    selectedProducts.length === products.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell sx={{ width: '7%', padding: '4px' }}>Imagen</TableCell>
              <TableCell sx={{ width: '30%', padding: '4px' }}>Nombre</TableCell>
              <TableCell sx={{ width: '10%', padding: '4px' }}>SKU</TableCell>
              <TableCell sx={{ width: '7%', padding: '4px' }}>Precio</TableCell>
              <TableCell sx={{ width: '7%', padding: '4px' }}>Stock</TableCell>
              <TableCell sx={{ width: '10%', padding: '4px' }}>Marca</TableCell>
              <TableCell sx={{ width: '10%', padding: '4px' }}>Compañía</TableCell>
              <TableCell sx={{ width: '10%', padding: '4px' }}>Integración</TableCell>
              <TableCell sx={{ width: '10%', padding: '4px' }}>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.length > 0 ? (
              products.map((product) => (
                <TableRow key={product.id}>
                  <TableCell padding="checkbox" sx={{ width: '5%' }}>
                    <Checkbox
                      checked={selectedProducts.includes(
                        product.jumpseller_product_id
                      )}
                      onChange={(event) =>
                        handleSelectProduct(
                          event,
                          product.jumpseller_product_id
                        )
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ width: '7%', padding: '3px' }}>
                    {product.images && product.images.length > 0 ? (
                      <>
                        <Avatar
                          variant="square"
                          src={cleanImageUrl(product.images[0].url)}
                          alt={product.name}
                          sx={{ width: 40, height: 40 }}
                        />
                      </>
                    ) : (
                      <Avatar variant="square" sx={{ width: 40, height: 40 }}>
                        N/A
                      </Avatar>
                    )}
                  </TableCell>
                  <TableCell sx={{ width: '30%', padding: '4px' }}>
                    <Tooltip title={product.name || 'Sin nombre'}>
                      <Typography variant="body2">
                        {truncateText(product.name, 60)}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ width: '10%', padding: '4px' }}>
                    {product.sku || 'Sin SKU'}
                  </TableCell>
                  <TableCell sx={{ width: '7%', padding: '4px' }}>
                    {product.price !== undefined && product.price !== null
                      ? new Intl.NumberFormat('es-CL', {
                          style: 'currency',
                          currency: 'CLP',
                        }).format(product.price)
                      : '$ 0'}
                  </TableCell>
                  <TableCell sx={{ width: '7%', padding: '4px' }}>
                    <Chip
                      label={product.stock}
                      color={getStockColor(product.stock)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell sx={{ width: '10%', padding: '4px' }}>
                    {product.brand || 'N/A'}
                  </TableCell>
                  <TableCell sx={{ width: '10%', padding: '4px' }}>
                    {product.integration && product.integration.company_id
                      ? getCompanyName(product.integration.company_id)
                      : 'N/A'}
                  </TableCell>
                  <TableCell sx={{ width: '10%', padding: '4px' }}>
                    {product.integration && product.integration.integration_name
                      ? product.integration.integration_name
                      : 'N/A'}
                  </TableCell>
                  <TableCell sx={{ width: '10%', padding: '4px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{ mr: 1, minWidth: '100px' }}
                      onClick={() => goToProductView(product.id)}
                      startIcon={<EditIcon />}
                    >
                      Ver
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  No se encontraron productos
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        sx={{ mt: 3 }}
        color="primary"
      />

      <Dialog open={bulkEditOpen} onClose={handleBulkEditClose}>
        <DialogTitle>Modificar Productos Seleccionados</DialogTitle>
        <DialogContent>
          <TextField
            label="Precio"
            name="price"
            type="number"
            value={bulkEditData.price}
            onChange={handleBulkEditChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Stock"
            name="stock"
            type="number"
            value={bulkEditData.stock}
            onChange={handleBulkEditChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBulkEditClose}>Cancelar</Button>
          <Button variant="contained" onClick={handleBulkEditApply}>
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default JumpsellerProductsPage;
