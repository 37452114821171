// ProductMappingModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
  CircularProgress
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const ProductMappingModal = ({
  open,
  handleClose,
  productMappings,
  handleMappingChange,
  handleOpenCreateProduct,
  handleSaveMappings
}) => {
  const [selectedMapping, setSelectedMapping] = useState(null);
  const [openSelectionModal, setOpenSelectionModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchFilteredProducts = async (query) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/products/internal`,
        { params: { search: query } }
      );
      setFilteredProducts(response.data.products || []);
    } catch (err) {
      console.error('Error al buscar productos internos:', err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (searchTerm.length >= 3) {
      fetchFilteredProducts(searchTerm);
    } else {
      setFilteredProducts([]);
    }
  }, [searchTerm]);

  const handleSelectProduct = (product) => {
    if (selectedMapping !== null) {
      const updatedMappings = { ...productMappings };
      const { purchaseItemId, subIndex } = selectedMapping;
      updatedMappings[purchaseItemId][subIndex].internalProduct = {
        id: product.id,
        name: product.name,
        sku: product.sku
      };
      // Asegurar que la conversión existe con valores por defecto
      if (!updatedMappings[purchaseItemId][subIndex].conversion) {
        updatedMappings[purchaseItemId][subIndex].conversion = {
          input_name: 'Caja',
          storage_name: 'Unidad',
          factor: 1
        };
      }
      handleMappingChange(updatedMappings);
      setOpenSelectionModal(false);
      setSearchTerm('');
      setSelectedMapping(null);
    }
  };

  const handleConversionChange = (purchaseItemId, subIndex, field, value) => {
    const updated = { ...productMappings };
    if (!updated[purchaseItemId][subIndex].conversion) {
      updated[purchaseItemId][subIndex].conversion = { input_name: 'Caja', storage_name: 'Unidad', factor: 1 };
    }
    updated[purchaseItemId][subIndex].conversion[field] = value;
    handleMappingChange(updated);
  };

  const calculateConvertedQuantity = (mapping) => {
    if (!mapping.internalProduct || !mapping.conversion || !mapping.conversion.factor) return null;
    const qty = parseFloat(mapping.quantity) || 0;
    const factor = parseFloat(mapping.conversion.factor) || 1;
    return (qty / factor).toFixed(2);
  };

  const onSave = () => {
    const mappingsToSend = [];
    for (const pId in productMappings) {
      productMappings[pId].forEach((m) => {
        if (!m.internalProduct || !m.internalProduct.id) return; 
        // Asegurarse de que inputUnit, storageUnit y conversionFactor tengan valores
        const inputUnit = m.conversion?.input_name || 'Caja';
        const storageUnit = m.conversion?.storage_name || 'Unidad';
        const conversionFactor = m.conversion?.factor || 1;
        mappingsToSend.push({
          purchaseItemId: parseInt(pId, 10),
          supplierProductCode: m.supplierProductCode,
          supplierProductName: m.supplierProductName,
          internalProductId: m.internalProduct.id,
          inputUnit,
          storageUnit,
          conversionFactor
        });
      });
    }

    // Filtrar aquellos sin producto interno
    const validMappings = mappingsToSend.filter(m => m.internalProductId && m.supplierProductCode && m.supplierProductName && m.inputUnit && m.storageUnit && m.conversionFactor > 0);

    if (validMappings.length === 0) {
      // Mostrar error si no hay mapeos válidos
      console.error('No hay mapeos válidos para guardar.');
      return;
    }

    handleSaveMappings(validMappings);
  };

  // Deshabilitar el botón de guardar si no hay mapeos válidos
  const canSave = () => {
    for (const pId in productMappings) {
      for (const m of productMappings[pId]) {
        if (m.internalProduct && m.internalProduct.id && m.supplierProductCode && m.supplierProductName) {
          if (m.conversion && m.conversion.input_name && m.conversion.storage_name && m.conversion.factor > 0) {
            return true;
          }
        }
      }
    }
    return false;
  };

  return (
    <Box>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <VisibilityIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="h6">Pareo de Productos</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} elevation={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Código Proveedor</TableCell>
                  <TableCell>Descripción Producto Proveedor</TableCell>
                  <TableCell align="right">Cantidad (Unidades)</TableCell>
                  <TableCell align="right">Precio Factura</TableCell>
                  <TableCell align="right">Total</TableCell>
                  <TableCell>Producto Interno</TableCell>
                  <TableCell>Conversión</TableCell>
                  <TableCell>Cantidad Almacenada</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(productMappings).map(([purchaseItemId, mappings], index) =>
                  mappings.map((mapping, subIndex) => {
                    const convertedQty = calculateConvertedQuantity(mapping);
                    return (
                      <TableRow key={`${purchaseItemId}-${subIndex}`} hover>
                        <TableCell>{index + 1}.{subIndex + 1}</TableCell>
                        <TableCell>{mapping.supplierProductCode || 'SIN_CODIGO'}</TableCell>
                        <TableCell>{mapping.supplierProductName}</TableCell>
                        <TableCell align="right">
                          {parseFloat(mapping.quantity).toFixed(2)}
                        </TableCell>
                        <TableCell align="right">
                          {new Intl.NumberFormat('es-CL', {
                            style: 'currency',
                            currency: 'CLP',
                          }).format(parseFloat(mapping.price) || 0)}
                        </TableCell>
                        <TableCell align="right">
                          {new Intl.NumberFormat('es-CL', {
                            style: 'currency',
                            currency: 'CLP',
                          }).format(parseFloat(mapping.total) || 0)}
                        </TableCell>
                        <TableCell>
                          {mapping.internalProduct ? (
                            <Typography>{mapping.internalProduct.name}</Typography>
                          ) : (
                            <Typography color="textSecondary">No mapeado</Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          {mapping.internalProduct ? (
                            <Box display="flex" flexDirection="column" gap={1}>
                              <TextField
                                label="Unidad Entrada"
                                variant="outlined"
                                size="small"
                                value={mapping.conversion?.input_name || 'Caja'}
                                onChange={(e) => handleConversionChange(purchaseItemId, subIndex, 'input_name', e.target.value)}
                                sx={{ width: '120px' }}
                              />
                              <TextField
                                label="Unidad Almacenamiento"
                                variant="outlined"
                                size="small"
                                value={mapping.conversion?.storage_name || 'Unidad'}
                                onChange={(e) => handleConversionChange(purchaseItemId, subIndex, 'storage_name', e.target.value)}
                                sx={{ width: '120px' }}
                              />
                              <TextField
                                type="number"
                                label="Factor"
                                variant="outlined"
                                size="small"
                                value={mapping.conversion?.factor || 1}
                                onChange={(e) => handleConversionChange(purchaseItemId, subIndex, 'factor', parseFloat(e.target.value) || 1)}
                                sx={{ width: '80px' }}
                              />
                            </Box>
                          ) : (
                            <Typography color="textSecondary">---</Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          {convertedQty && mapping.conversion && mapping.conversion.storage_name ? (
                            <Typography>{convertedQty} {mapping.conversion.storage_name}</Typography>
                          ) : (
                            <Typography color="textSecondary">---</Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => {
                              setSelectedMapping({ purchaseItemId, subIndex });
                              setOpenSelectionModal(true);
                            }}
                            sx={{ mr: 1 }}
                          >
                            Mapear
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            startIcon={<DeleteIcon />}
                            onClick={() => {
                              const updatedMappings = { ...productMappings };
                              updatedMappings[purchaseItemId].splice(subIndex, 1);
                              handleMappingChange(updatedMappings);
                            }}
                          >
                            Eliminar
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} startIcon={<CloseIcon />}>Cerrar</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            startIcon={<CheckCircleIcon />}
            disabled={!canSave()}
          >
            Guardar Pareo
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSelectionModal}
        onClose={() => setOpenSelectionModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <VisibilityIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="h6">Seleccionar Producto Interno</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Buscar producto..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ mb: 2 }}
          />
          {loading ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Paper} elevation={3}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell>Precio</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProducts.length > 0 ? (
                    filteredProducts.map((product) => (
                      <TableRow key={product.id} hover>
                        <TableCell>{product.id}</TableCell>
                        <TableCell>{product.name}</TableCell>
                        <TableCell>{product.sku}</TableCell>
                        <TableCell>
                          {new Intl.NumberFormat('es-CL', {
                            style: 'currency',
                            currency: 'CLP',
                          }).format(product.price)}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<CheckCircleIcon />}
                            onClick={() => handleSelectProduct(product)}
                          >
                            Seleccionar
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        {searchTerm.length >= 3
                          ? 'No se encontraron productos.'
                          : 'Ingrese al menos 3 caracteres para buscar.'}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenSelectionModal(false);
              setSelectedMapping(null);
            }}
            color="secondary"
            startIcon={<CloseIcon />}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProductMappingModal;
