// src/pages/WarehousesCreate.js

import React, { useState } from 'react';
import axios from 'axios';
import {
  Button,
  TextField,
  Box,
  Typography,
  Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function CreateWarehouse() {
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    description: '',
    status: 'activo',
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  // Manejar cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validar el formulario
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'El nombre es obligatorio';
    if (!formData.location) newErrors.location = 'La ubicación es obligatoria';
    return newErrors;
  };

  // Manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/warehouses`, formData);
      alert('Bodega creada correctamente');
      navigate('/warehouses');
    } catch (error) {
      console.error('Error al crear la bodega:', error);
      alert('Error al crear la bodega');
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Paper sx={{ padding: 4, maxWidth: 600, margin: '0 auto' }}>
        <Typography variant="h5" gutterBottom>
          Crear Nueva Bodega
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Nombre"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            label="Ubicación"
            name="location"
            value={formData.location}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.location}
            helperText={errors.location}
          />
          <TextField
            label="Descripción"
            name="description"
            value={formData.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <TextField
            label="Estado"
            name="status"
            value={formData.status}
            onChange={handleChange}
            select
            SelectProps={{
              native: true,
            }}
            fullWidth
            margin="normal"
          >
            <option value="activo">Activo</option>
            <option value="inactivo">Inactivo</option>
          </TextField>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" type="submit">
              Crear
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
}

export default CreateWarehouse;
