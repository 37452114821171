import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Grid,
  TextField,
} from '@mui/material';

const CompatibilityOEMTab = ({ compatibility, oem_identifiers }) => {
  const [activeTab, setActiveTab] = useState(0);

  // Añadir logs para depuración
  console.log('Compatibilidad:', compatibility);
  console.log('OEM Identifiers:', oem_identifiers);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box mt={4}>
      <Typography variant="h6" gutterBottom>
        Compatibilidades y OEM Identifiers
      </Typography>

      {/* Tabs para seleccionar entre Compatibilidad y OEM Identifiers */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="Compatibilidades y OEM"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Compatibilidades" />
        <Tab label="OEM Identifiers" />
      </Tabs>

      {/* Mostrar el contenido basado en la pestaña seleccionada */}
      {activeTab === 0 && (
        <Box mt={2}>
          <Typography variant="h6">Compatibilidades</Typography>
          {compatibility && compatibility.length > 0 ? (
            <Grid container spacing={2}>
              {compatibility.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <TextField
                    label="Marca"
                    value={item.make || 'N/A'}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    InputProps={{ readOnly: true }}
                  />
                  {/* ... otros campos de compatibilidad ... */}
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body1">
              No hay compatibilidades disponibles.
            </Typography>
          )}
        </Box>
      )}

      {activeTab === 1 && (
        <Box mt={2}>
          <Typography variant="h6">OEM Identifiers</Typography>
          {oem_identifiers && oem_identifiers.length > 0 ? (
            <Grid container spacing={2}>
              {oem_identifiers.map((oem, index) => (
                <Grid item xs={12} key={index}>
                  <TextField
                    label="Tipo de Identificador"
                    value={oem.type_name || 'N/A'}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    label="Valor del Identificador"
                    value={oem.identifier_value || 'N/A'}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body1">
              No hay identificadores OEM disponibles.
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CompatibilityOEMTab;
