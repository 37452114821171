import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';

function CategoryCreate() {
  const [categoryData, setCategoryData] = useState({
    name: '',
    description: '',
    parent_category_id: null, // Si es null, es categoría raíz
  });
  const [categories, setCategories] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Obtener todas las categorías para mostrarlas en el select
  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await axios.get('${process.env.REACT_APP_API_URL}/api/categories/all');
        setCategories(response.data); // Se asume que devuelve un array con todas las categorías
      } catch (error) {
        console.error('Error al obtener categorías:', error);
      }
    }
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    setCategoryData({
      ...categoryData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (categoryData.name.trim() === '') {
      alert('El nombre es obligatorio.');
      return;
    }
    setIsSubmitting(true);

    try {
      const response = await axios.post('${process.env.REACT_APP_API_URL}/api/categories/create', categoryData);
      alert('Categoría creada con éxito.');
      // Redireccionar a la página de la categoría creada o limpiar el formulario si es necesario
      console.log(response.data);
    } catch (error) {
      console.error('Error al crear la categoría:', error);
      alert('Error al crear la categoría');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={12} sm={8} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Crear Categoría
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Nombre"
                name="name"
                value={categoryData.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Descripción"
                name="description"
                value={categoryData.description}
                onChange={handleChange}
                fullWidth
                margin="normal"
                multiline
                minRows={3}
              />

              <FormControl fullWidth margin="normal">
                <InputLabel>Categoría Madre</InputLabel>
                <Select
                  name="parent_category_id"
                  value={categoryData.parent_category_id || ''}
                  onChange={handleChange}
                >
                  <MenuItem value="">Ninguna (Categoría raíz)</MenuItem>
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button variant="contained" color="primary" type="submit" fullWidth disabled={isSubmitting}>
                {isSubmitting ? 'Guardando...' : 'Guardar Categoría'}
              </Button>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default CategoryCreate;
