// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css'; // Elimina o comenta esta línea si no deseas usar index.css
import AppWrapper from './App';
// import reportWebVitals from './reportWebVitals'; // Opcional, elimina si no usas reportWebVitals

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById('root')
);

// reportWebVitals(); // Opcional, elimina si no usas reportWebVitals
