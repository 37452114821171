// src/pages/WhatsAppInterface.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Divider,
  TextField,
  IconButton,
  Button,
  Paper,
  AppBar,
  Toolbar,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatIcon from '@mui/icons-material/Chat';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

function WhatsAppInterface() {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [openNewChat, setOpenNewChat] = useState(false);
  const [newChatName, setNewChatName] = useState('');
  const [newChatAvatar, setNewChatAvatar] = useState('');

  useEffect(() => {
    fetchChats();
  }, []);

  useEffect(() => {
    if (selectedChat) {
      fetchMessages(selectedChat.id);
    }
  }, [selectedChat]);

  const fetchChats = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/whatsapp/chats`);
      setChats(response.data);
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  const fetchMessages = async (chatId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/whatsapp/chats/${chatId}/messages`);
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim() || !selectedChat) return;
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/whatsapp/send-message`, {
        to: selectedChat.phone,
        message: newMessage,
      });
      setMessages([...messages, { sender: 'you', text: newMessage, timestamp: new Date() }]);
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleCreateNewChat = async () => {
    if (!newChatName.trim() || !newChatAvatar.trim()) return;
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/whatsapp/create-chat`, {
        name: newChatName,
        avatar: newChatAvatar,
      });
      setChats([...chats, response.data]);
      setOpenNewChat(false);
      setNewChatName('');
      setNewChatAvatar('');
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };

  const filteredChats = chats.filter((chat) =>
    chat.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    chat.lastMessage.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ display: 'flex', height: '100vh', backgroundColor: '#f0f0f0' }}>
      {/* Chat List Drawer */}
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: 300,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 300, boxSizing: 'border-box' },
        }}
      >
        {/* Toolbar with Buttons */}
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 2,
            bgcolor: '#ffffff',
            boxShadow: 1,
          }}
        >
          <Typography variant="h6">Chats</Typography>
          <Tooltip title="Crear Nuevo Chat">
            <IconButton color="primary" onClick={() => setOpenNewChat(true)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>

        {/* Search Bar */}
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
          <SearchIcon color="action" />
          <TextField
            placeholder="Buscar o empezar nuevo chat"
            variant="standard"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ ml: 1 }}
          />
        </Box>
        <Divider />

        {/* Chats List */}
        <List>
          {filteredChats.map((chat) => (
            <ListItem
              button
              key={chat.id}
              selected={selectedChat && selectedChat.id === chat.id}
              onClick={() => setSelectedChat(chat)}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: '#e0f7fa',
                },
              }}
            >
              <ListItemAvatar>
                <Avatar src={chat.avatar} alt={chat.name} />
              </ListItemAvatar>
              <ListItemText
                primary={chat.name}
                secondary={chat.lastMessage}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* Chat Window */}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        {selectedChat ? (
          <>
            {/* Chat Header */}
            <AppBar position="static" sx={{ bgcolor: '#075E54' }}>
              <Toolbar>
                <Avatar src={selectedChat.avatar} alt={selectedChat.name} />
                <Box sx={{ ml: 2, flexGrow: 1 }}>
                  <Typography variant="h6">{selectedChat.name}</Typography>
                  <Typography variant="body2">Última vez hoy</Typography>
                </Box>
                <Tooltip title="Opciones">
                  <IconButton>
                    <MoreVertIcon sx={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
              </Toolbar>
            </AppBar>

            {/* Messages */}
            <Box sx={{ flexGrow: 1, p: 2, overflowY: 'auto', bgcolor: '#e5ddd5' }}>
              {messages.map((msg, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: msg.sender === 'you' ? 'flex-end' : 'flex-start',
                    mb: 1,
                  }}
                >
                  <Paper
                    sx={{
                      p: 1,
                      maxWidth: '60%',
                      bgcolor: msg.sender === 'you' ? '#dcf8c6' : 'white',
                    }}
                  >
                    <Typography variant="body1">{msg.text}</Typography>
                    <Typography variant="caption" sx={{ display: 'block', textAlign: 'right' }}>
                      {new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </Typography>
                  </Paper>
                </Box>
              ))}
            </Box>

            {/* Message Input */}
            <Box sx={{ p: 2, display: 'flex', alignItems: 'center', bgcolor: '#f0f0f0' }}>
              <TextField
                variant="outlined"
                placeholder="Escribe un mensaje"
                fullWidth
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSendMessage();
                    e.preventDefault();
                  }
                }}
              />
              <IconButton color="primary" onClick={handleSendMessage}>
                <SendIcon />
              </IconButton>
            </Box>
          </>
        ) : (
          // Placeholder cuando no se selecciona ningún chat
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: '#f0f0f0',
            }}
          >
            <Typography variant="h5" color="textSecondary">
              Selecciona un chat para comenzar
            </Typography>
          </Box>
        )}
      </Box>

      {/* Dialogo para Crear Nuevo Chat */}
      <Dialog open={openNewChat} onClose={() => setOpenNewChat(false)}>
        <DialogTitle>Crear Nuevo Chat</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre del Contacto"
            type="text"
            fullWidth
            variant="outlined"
            value={newChatName}
            onChange={(e) => setNewChatName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="URL del Avatar"
            type="url"
            fullWidth
            variant="outlined"
            value={newChatAvatar}
            onChange={(e) => setNewChatAvatar(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewChat(false)} color="secondary" startIcon={<CloseIcon />}>
            Cancelar
          </Button>
          <Button onClick={handleCreateNewChat} color="primary" startIcon={<ChatIcon />}>
            Crear
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default WhatsAppInterface;
